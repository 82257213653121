import React, { useEffect, useState } from "react";
import { MoviesItem } from "../../CardItems/CardItem";
import { Dna } from "react-loader-spinner";
import axios from "axios";
import { endpoints } from "../../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { loadSpinner } from "../../../../redux-toolkit/features/Offline/OfflineSlice";
import { toast } from 'react-toastify';
import Toastr from "../../../Toastr/Toastr";
import './movies.css'
import { getAccessToken, logout } from "../../../../config/helper";


export const MoviesList = () => {
  const { isOpen } = useSelector((store) => store.offline);
  const dispatch = useDispatch();
  const [moviesItem, setMoviesItem] = useState([]);
  const [apiMessage, setApiMessage] = useState('')

  const fetchData = async () => {


    try {
      const response = await axios(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.movies}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // token: process.env.REACT_APP_MOVIE_KEY
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );
   
      const allDetails = response?.data;
      
      if (allDetails.status === 1) {
      
     
        setMoviesItem(response?.data);
      }
      else{
        setApiMessage(allDetails.message)
        toast(allDetails.message)
      }
      
      dispatch(loadSpinner());
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(()=>{

          logout()
        }, 4000)
     
      } 
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [setMoviesItem]);

  

  if (isOpen) {
    return (
      <div className="flex justify-center items-center">
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }


  

  return (
    // <div>
      <div className="movie-outmost-container">
          {
            moviesItem?.data?.data?.map((each)=>(
              <>
              <div className="movie-location">{each.name}, {each.state}</div>
             
              <div className="movie-inner-container">

             {each?.tickets?.map((item, index) => (<MoviesItem key={index} card={item} /> ))}
        </div>
            
              </>
            ))
          
    }
        
        <Toastr seconds={15000} />
      </div>
   
  );
};
