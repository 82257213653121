import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order slice",
  initialState: {
    orderResponse: {
      status: 0,
      current_balance: 0,
      order_number: "",
      message: '',
      user_email: ""    
    },  
  },
  reducers: {
    setOrderResponse: (state, action) => {
      console.log('action', action)
      state.orderResponse = action.payload;
    },
  }
});

export const {
  setOrderResponse
} = orderSlice.actions;

export default orderSlice.reducer;
