import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
import React, {useEffect} from 'react';
import './WordsSlider.css';
// import ring from "../assets/ring.png"
import box from '../assets/box.png';

const WordsSlider = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    let sections = gsap.utils.toArray('.panel');

    gsap.to(sections, {
      xPercent: -110 * (sections.length - 1),
      ease: 'none',
      scrollTrigger: {
        trigger: '.wordsslide-container',
        pin: true,
        scrub: 0.5,
        snap: {
          snapTo: 1 / (sections.length - 1),
          duration: 5,
          ease: 'power1.inOut', // Corrected property from 'delay' to 'ease'
        },
        stagger: {
          amount: 0.3,
        },
        end: '+=3500',
      },
    });

    gsap.set('.wordsslide-container', {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    });
  }, []);

  useEffect(() => {
    const horizontalSections = gsap.utils.toArray('section.horizontal');

    horizontalSections.forEach(function (sec, i) {
      var thisPinWrap = sec.querySelector('.pin-wrap');
      var thisAnimWrap = thisPinWrap.querySelector('.animation-wrap');

      var getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth);

      gsap.fromTo(
        thisAnimWrap,
        {
          x: () =>
            thisAnimWrap.classList.contains('to-right') ? 0 : getToValue(),
        },
        {
          x: () =>
            thisAnimWrap.classList.contains('to-right') ? getToValue() : 0,
          ease: 'none',
          scrollTrigger: {
            trigger: sec,
            start: 'top top',
            end: () => '+=' + (thisAnimWrap.scrollWidth - window.innerWidth),
            pin: thisPinWrap,
            invalidateOnRefresh: true,
            //anticipatePin: 1,
            scrub: true,
            //markers: true,
          },
        },
      );
    });
  }, []);

  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);

  //   let proxy = { skew: 0 },
  //     skewSetter = gsap.quickSetter(".skewElem", "skewY", "deg"), // fast
  //     clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

  //   ScrollTrigger.create({
  //     onUpdate: (self) => {
  //       let skew = clamp(self.getVelocity() / -300);
  //       if (Math.abs(skew) > Math.abs(proxy.skew)) {
  //         proxy.skew = skew;
  //         gsap.to(proxy, {
  //           skew: 20,
  //           duration: 0.2,
  //           ease: "power3",
  //           overwrite: true,
  //           onUpdate: () => skewSetter(proxy.skew),
  //         });
  //       }
  //     },
  //     onScroll: () => {
  //       ScrollTrigger.update();
  //     },
  //   });

  //   gsap.set(".skewElem", { transformOrigin: "right center", force3D: true });

  //   // Cleanup function to unregister ScrollTrigger on component unmount
  //   return () => {
  //     ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  //   };
  // }, []);

  return (
    <div>
      <div className="wordsslide-container ">
        <section className="panel transact-box-section">
          <div className="slide-text-1">
            <h1>
              Transact, earn points <br></br> and redeem
            </h1>
          </div>
        </section>
        <section className="panel">
          <div className="slide-text-2">
            <h1>
              More, from the <br></br> bank you love
            </h1>
          </div>
        </section>
        <section className="panel">
          <div className="slide-text-3">
            <h1>
            Earn and Redeem  <br></br> your <span className='first-coin-text'>FirstCoins </span>
              {/* <img src={coin} alt="coin image" className="coin" /> */}
            </h1>
          </div>
        </section>
        {/* <section className="panel">
          <div className="slide-text-4">
            <h1>
              Items you love, <br></br> delivered to you
              <img src={box} alt="box image" className="box" />
            </h1>
          </div>
        </section> */}
        {/* <section className="panel">
    FIVE
  </section> */}
      </div>
    </div>
  );
};

export default WordsSlider;
