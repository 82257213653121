import React from "react";
import AdBanner from "../../components/AdBanner/AdBanner";
import Categories from "../../components/Categories/Categories";
import CurrentPageNav from "../../components/Navbar/CurrentPageNav";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <>
      <Categories />
      <AdBanner />
      {/* <CurrentPageNav /> */}
      <div className="contact-page-container">
        <div className="contact-left">
          <div className="contact-left-inner">
            <h1>We are ready to serve you</h1>
            <p>
              For enquiries, compliments and suggestions. Please send an email
              to: firstcontact@firstbank.com or use the form to send us a
              feedback.
            </p>

            <p className="address-in-contact">
              Samuel Asabia House <br></br> 35 Marina <br></br> P.O. Box 5216,{" "}
              <br></br> Lagos, <br></br> Nigeria.
            </p>

            <p> Email: firstcontact@firstbank.com</p>
            <p className="contact-num">Phone: +234 708 062 50</p>
          </div>
        </div>

        <div className="contact-right">
          <h2 className="issue-form">Issue Form</h2>
          <form action="" className="contact-form">
            <div className="textarea-container">
              <p>First Name</p>
                <input type="text" id="html" name="fav_language" />
            </div>
            <div className="textarea-container">
              <p>Last Name</p>
                <input type="text" id="html" name="fav_language" />
            </div>
            <div className="textarea-container">
              <p>Email Address</p>
                <input type="text" id="html" name="fav_language" />
            </div>
            <div className="textarea-container">
              <p>Phone Number</p>
                <input type="text" id="html" name="fav_language" />
            </div>
            <div className="textarea-container">
              <p>Subject</p>
                <input type="text" id="html" name="fav_language" />
            </div>
            <div className="textarea-container">
              <p>Message</p>
              <textarea name="" id=""></textarea>
            </div>
            <button className="contact-btn">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
