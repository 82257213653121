import {useInView} from 'react-intersection-observer';
import {useAnimation} from 'framer-motion';
import {useEffect} from 'react';

export const useAnimationInView = (thresh = 0.5) => {
  const controls = useAnimation();
  const [element, view] = useInView({threshold: thresh});
  useEffect(() => {
    if (view) {
      controls.start('show');
    } else {
      controls.start('hidden');
    }
  }, [view, controls]);

  return [element, controls];
};
