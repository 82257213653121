


export function loadScript(url, id) {
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;
  newScript.src = url;
  newScript.id = id;

  const returnPromise = new Promise((resolve, reject) => {
    newScript.onload = resolve;
    newScript.onerror = reject;
  });

  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentElement.insertBefore(newScript, firstScript);

  return returnPromise;
}
let modifier;


export const initWheel = (Prizes, rotationDuration) => {
    const props = {
      name: "Workout",
      radius: 0.84,
      itemLabelRadius: 0.93,
      itemLabelRadiusMax: 0.35,
      itemLabelRotation: 180,
      itemLabelAlign: "left",
      itemLabelColors: ["#5E5E5E"],
      itemLabelBaselineOffset: -0.07,
      itemLabelFont: "Inter",
      itemLabelFontSizeMax: 14,
      itemBackgroundColors: [
        "#C5E1FC",
        "#fff",
      ],
      rotationSpeedMax: 3,
      rotationResistance: 1,
      
      rotationSpeed: 3,
      lineWidth: 1,
      lineColor: "#F0BD2D",
      borderColor: "#F0BD2D",
      image: document.getElementById("center"),
      overlayImage: document.getElementById("overlay"),
      items: Prizes,
    };
    const container = document.querySelector('.wheel-container')
   
    if(container){
        const wheelInstance = new window.spinWheel.Wheel(container, props);
       
        if (wheelInstance) {
          const duration = rotationDuration;
          const winningItemRotation = getRandomInt(360, 360 * 1.75) + modifier;
          modifier += 360 * 1.75;
          wheelInstance.spin(duration, winningItemRotation);
        }
    }
  };

    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    };

