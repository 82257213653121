import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import "./Voucher.css";
import Categories from "../../components/Categories/Categories";
import AdBanner from "../../components/AdBanner/AdBanner";
import Uber from "../../assets/images/uber.png";
import VoucherModal from "./VoucherModal";

import useLockScrolling from "../../components/lockScroll/lockScroll";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { authHeaders } from "../../config/helper";
import { TailwindSpinner } from "../../components/Spinner/TailwindSpinner";

const VoucherCheckout = () => {
  let result = JSON.parse(sessionStorage.getItem("first-bank-loyalty"));
  const membershipID = result?.data.customer.Membership_id;
  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");
  const productName = parts[5];

  //////////////////////////////////////////////////
  const [selectedOption, setSelectedOption] = useState("");
  const [successModal, showSuccessModal] = useState(false);
  const [inputvalue, setInputValue] = useState("");
  const [email, setEmail] = useState("");
  const [voucherOptions, setVoucherOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [canSubmit, setCanSubmit] = useState(false);
  const [partnerLogo, setPartnerLogo] = useState("");
  const [checkoutDetails, setCheckoutDetails] = useState({});

  const handleInputChange = (event) => {
    const inputsValue = event.target.value;
    const numericValue = inputsValue.replace(/\D/g, "");

    setInputValue(numericValue);
  };

  const updateSelectedOption = (event) => {
    setSelectedOption(event.target.value);
  };
  //   const dispatch = useDispatch();

  useLockScrolling(successModal);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const { partner_id: partnerId, name: partnerName } = useParams();

  useEffect(() => {
    axios
      .get(
        `https://vouchers.perxclm.com/voucher_service_api.php?api=get_a_voucher&id=${partnerId}`,
        { headers: authHeaders }
      )
      .then((res) => {
        setVoucherOptions(res.data);
        setPartnerLogo(res.data[0]?.partner_logo);
      })
      .catch((err) => console.log("err", err))
      .finally(setIsLoading(false));
  }, [partnerId]);

  useEffect(() => {
    setSelectedOption(voucherOptions[0]?.price);
  }, [voucherOptions]);

  useEffect(() => {
    const filteredData = voucherOptions.filter(
      (each) => each.price === selectedOption
    );
    setCheckoutDetails({
      ...filteredData[0],
      price: `${selectedOption}`,
      email: email,
      memberid: membershipID,
      product_category: productName,
      actual_worth: selectedOption,
      api_type: "lsl_voucher_redemption",
    });
  }, [selectedOption, email, inputvalue]);

  const generateUberVoucherMutation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}/generate_uber_voucher`,
        checkoutDetails,
        { headers: authHeaders }
      );
      if (response.status === 200) {
        showSuccessModal(true);
        setEmail("");

        setInputValue("");
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    mutate,
    isLoading: isLoadingVoucher,
    isError: isErrorVoucher,
    isSuccess,
  } = useMutation(generateUberVoucherMutation);
  const [emailCheck, setEmailCheck] = useState(false);
  useEffect(() => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailCheck(true);
      return;
    } else {
      setEmailCheck(false);
      return;
    }
  }, [email]);

  const handlePlaceOrder = (e) => {
    e.preventDefault();

    mutate();
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="voucher">
      <Categories />
      <AdBanner />
      <div className="voucher-checkout-container">
        <div className="left-checkout-container">
          <img src={partnerLogo} alt={partnerName} />
          <div>
            <p>
              *This voucher can only be redeemed for{" "}
              <span className="capitalize">{partnerName}</span> services in
              Nigeria
            </p>
          </div>
        </div>
        <div className="right-checkout">
          <form
            onSubmit={(e) => handlePlaceOrder(e)}
            className="right-checkout-container"
          >
            <h2>
              Redeem a <span className="capitalize">{partnerName}</span> Voucher
            </h2>
            <div class="container-recipien">
              <p className="text-label">Choose Amount</p>
              <select
                class="input email-inpu"
                onChange={updateSelectedOption}
                value={selectedOption}
              >
                <option value="" disabled selected>
                  Select a voucher value
                </option>
                {voucherOptions?.length > 0 &&
                  voucherOptions?.map((option) => (
                    <option
                      key={option.id}
                      value={option.price}
                      className="dropdown-option"
                    >
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>

            <div class="container-recipient">
              <div class="title">Recipient</div>
              <p className="text-label">Email Address</p>
              <input
                class="input email-input"
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <p className="text-label__">Phone Number</p>
              <input
                class="input phone-input"
                type="tel"
                required
                value={inputvalue}
                onChange={handleInputChange}
                placeholder="Phone Number"
                maxLength={11}
              />
            </div>
            <div className="voucher-total-container">
              <p>Total</p>
              <h6> {(2 * selectedOption).toLocaleString()} FirstCoins</h6>
            </div>
            <div className="voucher-btn-container">
              <button
                className="voucher-btn"
                disabled={
                  [email, inputvalue].some((each) => each === "") ||
                  inputvalue?.length !== 11 ||
                  !emailCheck
                }
              >
                {" "}
                {isLoadingVoucher ? <TailwindSpinner /> : "Process Order"}{" "}
              </button>
            </div>
          </form>
          <div className="below-checkout-form">
            <p>
              By placing an order, you agree to FirstBank Terms and Privacy
              policy
            </p>
            <p>
              Information and update on this order will be sent to your
              FirstBank registered email address
            </p>
          </div>
        </div>
      </div>
      {successModal && <VoucherModal showSuccessModal={showSuccessModal} />}
    </div>
  );
};

export default VoucherCheckout;
