import React, { useEffect, useState } from "react";
import {
  getAccessToken,
  myStore,
  setAccessToken,
  storeData,
} from "../../../config/helper";
import axiosProperty from "../../../service/HttpService";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingIcon, LoadingSVG } from "../../../assets/icons";
import "./SingleSignOn.css";
import LoginTandC from "../../../components/LoginTandC/LoginTandC";
import { useMutation } from "react-query";
import { TailwindSpinner } from "../../../components/Spinner/TailwindSpinner";

const SingleSignOn = ({ nagateNav }) => {
  useEffect(() => {
    nagateNav(false);
  }, [nagateNav]);
  const navigate = useNavigate();
  const location = useLocation();
  // LOGIN USING SINGLE SIGN ON START
  const [mainCIFID, setMainCIFID] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [consentModal, setConsentModal] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  const fetchData = async (membershipId, token) => {
    try {
      // Construct the URL with query parameters for membership_id and token
      const url = `${process.env.REACT_APP_FIRSTBANK_BASEURL}/firstbankloyalty/sso/login?membership_id=${membershipId}&token=${token}`;

      const response = await axiosProperty.get(url, {
        headers: { Accept: "application/json" },
      });

      if (response?.data?.status === 1) {
        // Store data and set token on successful response
        storeData(myStore, response?.data?.success);
        setAccessToken(response?.data?.success?.token?.accessToken);
        setMainCIFID(response?.data?.success?.customer?.cifid);
        // setMainCIFID(response?.success?.customer?.cifid);

        // Check login and consent flags to determine navigation
        const { LoggedIn_flag, consent_flag } = response.data.success.customer;
        setTimeout(() => {
          if (LoggedIn_flag === 0) {
            window.location = "/login"; // Redirect to login if user is not logged in
          } else if (consent_flag === 0) {
            //  setConsentModal(true); // Show consent modal if consent is needed
          } else {
            window.location = "/redeem";
          }
        }, 5000);
      } else {
        // Handle error message from response
       // setErrorMessages(response.data.message);
      }
    } catch (error) {
      // Catch and set error message if request fails
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after request completion
    }
  };

  // Trigger the SSO login request with membershipId and token as parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const membershipId = params.get("membership_id");
    const token = process.env.REACT_APP_SSO_TOKEN;

    fetchData(membershipId, token);
  }, [location.search, navigate]);

  // LOGIN USING SINGLE SIGN ON END

  ///////////////CONSENT CHECK START/////////////
  //   const [token, setToken] = useState("");

  //   useEffect(() => {
  //     const getToken = getAccessToken();
  //     setToken(getToken);
  //   }, [consentModal]);

  const consentCheck = async () => {
    try {
      const response = await axiosProperty.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}/update_consent`,
        { cifid: mainCIFID },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setConsentModal(false);
        window.location = "/redeem";
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    mutate,
    isLoading: isLoadingConsent,
    isError: isErrorConsent,
  } = useMutation(consentCheck);

  //////////CONSENT CHECK END//////////////
  return (
    <>
      <div className="single-sign-on-container">
        {loading ? (
          <LoadingSVG />
        ) : (
          <>
            <div className="single-sign-on-content-container">
              <h1 className="single-sign-on-header">Please wait</h1>
              <TailwindSpinner />
            </div>
            <div>
              <p>{errorMessages}</p>
            </div>
          </>
        )}
      </div>
      {consentModal && (
        <LoginTandC mutate={mutate} isLoadingConsent={isLoadingConsent} />
      )}
    </>
  );
};

export default SingleSignOn;
