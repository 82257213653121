import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './ItemsSlider.css';

import {Link} from 'react-router-dom';
import {getMemberId} from '../../../config/helper';

const ItemsSlider = ({data}) => {
  

  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 2,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  const result = getMemberId();
  return (
    <>
      <Carousel responsive={responsive} arrows={true} ssr={true}>
        {data.map((each, index) => {
          const {image, text, title, id} = each;
          return (
            <div key={id + index} className="cardi">
              <div className="image-container-slide">
                <img className="image-area" src={image} alt={text} />
              </div>

              <div className="card-text-container">
                <h3 className="">{title}</h3>
                <p>{text}</p>
              </div>
              <div className="">
                {result ? (
                  <Link to="/redeem">
                    <button className="explore-button">Explore</button>
                  </Link>
                ) : (
                  <Link to="/login">
                    <button className="explore-button">Explore</button>
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default ItemsSlider;
