import React, { useState, useEffect } from "react";
import Categories from "../../components/Categories/Categories";
import axios from "axios";
import { endpoints } from "../../utils/constant";
import { ExperienceItems } from "../../components/Cards/CardItems/CardItem";

const Experience = () => {
  const [experienceItems, setExperienceItems] = useState([]);
  const [searchItems, setSearchItems] = useState(null);
  const [experienceStatus, setExperienceStatus] = useState(true);
  const [searchStatus, setSearchStatus] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchError, setSearchError] = useState(false);

  const fetchExperience = async () => {
    try {
      const experienceResponse = await axios(
        `${process.env.REACT_APP_EXPERIENCE_URL}${endpoints.experience}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            token: `${process.env.REACT_APP_EXPERIENCE_KEY}`,
          },
        }
      );
      setExperienceItems(experienceResponse.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSearch = async () => {
    try {
      const searchResponse = await axios.post(
        `${process.env.REACT_APP_EXPERIENCE_URL}${endpoints.searchExperience}`,
        {
          city_id: { searchString },
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            token: `${process.env.REACT_APP_EXPERIENCE_KEY}`,
          },
        }
      );
      if (searchResponse?.data?.message === "Products not found") {
        setSearchError(true);
        setSearchStatus(false);
      }
      setSearchItems(searchResponse?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchExperience(setExperienceItems);
  }, [setExperienceItems]);

  return (
    <div>
      <Categories />
      <div>
        <div className="bg-[url('./assets/images/experience.png')]  sm:h-[461px] flex flex-col my-auto bg-no-repeat bg-cover sm:bg-cover sm:pt-8 sm:pb-32">
          <div className="flex pt-10 mx-auto text-center w-[65%]">
            <h5 className="sm:font-medium text-2xl capitalize sm:text-6xl text-white justify-center flex mx-auto leading-loyalty tracking-loyal">
              Go On your latest adventure
            </h5>
          </div>

          <div className="bg-button w-full sm:w-[80vw] md:w-[80vw] lg:w-[70vw] sm:mx-auto rounded-xl my-20 px-3 sm:px-0 py-3 sm:py-0 sm:h-[171px]">
            <div className="sm:flex py-8 sm:py-0 justify-between rounded-3xl sm:gap-5 sm:rounded-[400px] bg-white mx-2 sm:mx-12 my-3 sm:my-12 px-6">
              <div className="flex flex-col justify-center sm:w-1/3 mb-4 sm:mt-4 sm:mb-0 ">
                <label
                  className=" sm:flex capitalize font-bold leading-loyalty text-sm"
                  htmlFor=""
                >
                  where
                </label>
                <input
                  style={{ border: "none", borderBottomColor: "gray" }}
                  className="border-b border-gray-400 py-4 px-2 rounded-0 sm:border-0  sm:outline-none "
                  type="text"
                  placeholder="Search for countries and cities"
                  onChange={(event) => setSearchString(event.target.value)}
                />
              </div>

              <div className="flex flex-col justify-center sm:w-1/3 mb-4 sm:mt-4 sm:mb-0 ">
                <label
                  className="sm:flex capitalize font-bold leading-loyalty text-sm"
                  htmlFor=""
                >
                  when
                </label>
                <input
                  style={{ border: "none", borderBottomColor: "gray" }}
                  className="border-b border-gray-400 py-4 px-2 rounded-0 sm:border-0 sm:outline-none "
                  type="date"
                  placeholder="Choose date"
                  onChange={(event) => setSearchString(event.target.value)}
                />
              </div>

              <div className="flex gap-12 items-center">
                <button
                  onClick={() => {
                    setExperienceStatus(false);
                    setSearchStatus(true);
                    setSearchError(false);
                    fetchSearch(setSearchString);
                  }}
                  className="flex py-2 my-2 sm:my-6 items-center h-8 border-2 border-black bg-add rounded-[40px]"
                >
                  <p className="capitalize mx-3 px-2 font-bold leading-loyalty text-base">
                    search
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
        {experienceStatus ? (
          <div className="">
            <div className="my-12 w-fit mx-auto">
              <h3 className="flex capitalize w-fit mx-auto">
                popular activities
              </h3>
            </div>

            <div className=" flex w-full px-8 flex-wrap">
              {experienceItems?.data?.map((item, index) =>
                index < 16 ? <ExperienceItems key={index} card={item} /> : ""
              )}
            </div>
            <div className="flex py-32">
              <button className="mx-auto bg-add px-20 lg:px-36 py-2.5 capitalize font-bold text-base leading-loyalty tracking-loyal border-2 rounded border-solid border-black">
                load more
              </button>
            </div>
          </div>
        ) : null}

        {searchError ? (
          <h2 className="flex py-3 justify-center">Products not found</h2>
        ) : null}

        {searchStatus ? (
          <>
            <div className="my-12 w-fit mx-auto">
              <h3 className="flex capitalize w-fit mx-auto">
                Experiences in {searchString}
              </h3>
            </div>
            <div className=" flex flex-wrap mx-auto gap-4">
              {searchItems !== null &&
                searchItems?.map((item, index) =>
                  index < 16 ? <ExperienceItems key={index} card={item} /> : ""
                )}
            </div>
            <div className="flex py-32">
              <button className="mx-auto bg-add px-36 py-2.5 capitalize font-bold text-base leading-loyalty tracking-loyal border-2 rounded border-solid border-black">
                load more
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Experience;
