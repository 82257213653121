import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = ({ currentRoute }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const formatName = (str) =>{
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return (
    //px-4
    <nav className=" lg:px-36 sm:px-16 bg-[#F4F4F4]">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center tracking-[-2%] text-[13px] font-medium">
          <Link to="/" className="text-black hover:underline">
            Home
          </Link>
          <span className="mx-2 ">{'>'}</span>
        </li>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return isLast ? (
            
            isNaN(name) && <li className="ml-2 tracking-[-2%] text-[13px] font-medium flex items-center" key={name}>
            <span className="text-black">{formatName(name)}</span>
          </li>
            
          ) : (
            <li className="ml-2 tracking-[-2%] text-[13px] font-medium flex items-center" key={name}>
              <Link to={routeTo} className="text-[#000000] hover:underline">
                {formatName(name)} 
              </Link>
              {isNaN(pathnames[index+1]) && <span className="mx-2 ">{'>'}</span>}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;