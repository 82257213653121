import React, { useState, useEffect, useRef } from "react";
// import { BsNodePlusFill } from "react-icons/bs";
import "./TransferCoinModal.css";
import { OffVisibilityIcon, VisibilityIcon } from "../../assets/svg/icons";
import { TailwindSpinner } from "../../components/Spinner/TailwindSpinner";

function TransferCoinModal(props) {
  const {
    transferCoinPassword,
    setTransferCoinPassword,
    setPasswordModal,
    handleConfirmTransferSubmit,
    confirmCoinTransferError,
    setForgotPinModal,
    transferData,
    confirmPointsTransfer
  } = props;
  const handleForgotPinModal = () => {
    setForgotPinModal(true);
    setPasswordModal(false);
  };

  const passwordModalRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword(!showPassword);



  useEffect(() => {
    const closeModal = (event) => {
      if (
        passwordModalRef.current &&
        !passwordModalRef.current.contains(event.target)
      ) {
        setPasswordModal(false);
      }
    };
    window.addEventListener("mousedown", closeModal);

    return () => {
      window.removeEventListener("mousedown", closeModal);
    };
  }, []);

  return (
    <div className="transfer-coin-modal">
      <div className="transfer-coin-modal-content" ref={passwordModalRef}>
        <span className="close-btn" onClick={() => setPasswordModal(false)}>
          &times;
        </span>
        <h2 className="transfer-coin-modal-header">Confirm Transfer</h2>
        <div>
          <p className="transfer-coin-modal-recipient-details">
            You are sending &nbsp;
            <span className="transfer-coin-recipient-detail-span">
              {transferData.amount} Coins &nbsp;
            </span>
            to &nbsp;
            <span className="transfer-coin-recipient-detail-span">
              {transferData.recepient_id}
            </span>
          </p>
        </div>
        <form onSubmit={handleConfirmTransferSubmit}>
          <div className="transfer-input-field-container">
            <div className="transfer-coin-label-modal">
              <p className="beneficiary-text1">Enter your pin to proceed</p>
              <p
                className="transfer-coin-modal-beneficiary-reset-text"
                onClick={handleForgotPinModal}
              >
                Forgot Pin?
              </p>
            </div>
            <input
              type={showPassword ? "number" : "password"}
              value={transferCoinPassword}
              onChange={(e) => setTransferCoinPassword(e.target.value)}
              name="transferCoinPassword"
              className={`transfer-input-field-modal ${confirmCoinTransferError && 'border-red-800 border-2'}`}
              placeholder="Enter Pin"
              maxLength={4}
            />
            <span className="toggle-pin" onClick={togglePassword}>
              {showPassword ? <OffVisibilityIcon/> : <VisibilityIcon />}
            </span>
            
          <div className="text-red-800">{confirmCoinTransferError}</div>
          </div>
          

          <div className="transfer-btn-container-modal">
            <button type="submit">{confirmPointsTransfer ? <TailwindSpinner/> : "Transfer"}</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TransferCoinModal;
