import { createSlice } from "@reduxjs/toolkit";

export const  firstTimeLoginSlice = createSlice({
    name: "first time login",
    initialState:{
        user: null,
        signUp: null,
        isLoggedIn: false
    },
    reducers:{
        login: (state, action) =>{
            // state.user = action.payload.
            state.isLoggedIn = action.payload
        },
        firstTimeLogin:(state, action)=>{
            state.signUp = action.payload
        },
        logout:(state)=>{
            state.isLoggedIn = false
        }
    }
})

export const { login, firstTimeLogin , logout} = firstTimeLoginSlice.actions;
export const selectUser = (state) => state.user.user;
export default firstTimeLoginSlice.reducer;

