import React from "react";
import "./TermsAndCondition.css";
import { firstTimeLogin } from "../../redux-toolkit/features/defaultLogin/loginSlice";
import { useDispatch } from "react-redux";

const TermsAndConditon = ({
  showModal,
  setShowTermsAndCondition,
  signUpData,
  setSignUpData,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(firstTimeLogin(signUpData));
    setShowTermsAndCondition(false);
    setSignUpData({
      membershipId: "",
      password: "",
      confirmPassword: "",
      loggedIn: true,
    });
    window.location ='/shops'
  };
  return (
    <>
      <div
        className="modal terms-modal"
        style={{ display: showModal ? "block" : "none" }}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title m-auto" id="exampleModalLongTitle">
                Terms & Conditions
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" onClick={()=>setShowTermsAndCondition(false)}>&times;</span>
        </button>
                {/* <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setShowTermsAndCondition(false)}></button> */}
            </div>
            <div className="modal-body">
              <h1 className="terms-modal-text">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum
                minima dolores laborum, delectus autem, consequatur
                necessitatibus nesciunt hic, tempore at exercitationem ut non.
                Dolorum repellendus autem dicta eveniet facere dolore sed
                consequatur! Nobis porro, placeat doloremque officiis ea quae
                autem incidunt minus itaque hic at adipisci iste voluptates,
                atque obcaecati. Vero explicabo molestiae obcaecati sed! Quas,
                provident. Nesciunt non quia eveniet repellat doloribus neque
                doloremque, impedit praesentium! Labore nisi, dolores dolorum
                error ratione odit saepe modi incidunt quam sint minus officia
                aperiam in culpa minima corrupti reprehenderit atque ad
                repudiandae! Blanditiis, fuga? Odio assumenda vel dolor dolores
                aspernatur fuga eos ad, earum animi alias, rerum, sit
                perspiciatis maiores! Repudiandae optio commodi cumque harum
                maiores vero nesciunt. Nam blanditiis alias quam molestias,
                reprehenderit architecto officia delectus voluptatem. Fuga
                beatae eveniet asperiores id eaque molestias iusto, cumque
                dolorum iste vitae aliquid soluta odit illum? Asperiores ipsa
                quos temporibus adipisci earum tenetur consequatur.
              </h1>
              <h1 className="terms-modal-text">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Harum
                minima dolores laborum, delectus autem, consequatur
                necessitatibus nesciunt hic, tempore at exercitationem ut non.
                Dolorum repellendus autem dicta eveniet facere dolore sed
                consequatur! Nobis porro, placeat doloremque officiis ea quae
                autem incidunt minus itaque hic at adipisci iste voluptates,
                atque obcaecati. Vero explicabo molestiae obcaecati sed! Quas,
                provident. Nesciunt non quia eveniet repellat doloribus neque
                doloremque, impedit praesentium! Labore nisi, dolores dolorum
                error ratione odit saepe modi incidunt quam sint minus officia
                aperiam in culpa minima corrupti reprehenderit atque ad
                repudiandae! Blanditiis, fuga? Odio assumenda vel dolor dolores
                aspernatur fuga eos ad, earum animi alias, rerum, sit
                perspiciatis maiores! Repudiandae optio commodi cumque harum
                maiores vero nesciunt. Nam blanditiis alias quam molestias,
                reprehenderit architecto officia delectus voluptatem. Fuga
                beatae eveniet asperiores id eaque molestias iusto, cumque
                dolorum iste vitae aliquid soluta odit illum? Asperiores ipsa
                quos temporibus adipisci earum tenetur consequatur.
              </h1>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn m-auto"
                onClick={handleSubmit}
              >
                Accept & Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditon;
