export const endpoints = {
  productWeek: "/api/product_of_the_week",
  popularCategory: "/api/popular_categories",
  categories: "/api/multi_categories",
  movies: "/all_movies",
  movie: "/single_movie/?product_code=",
  singleMovies: "/singleMovies.json",
  experience: "/rewardsbox/api/v1/?api=exp_random",
  searchExperience: "/rewardsbox/api/v1/?api=get_experience",
  singleExperience: "/rewardsbox/api/v1/?api=product_details&product_code=",
  catalogues: "/api/catalogues/",
  // singleItem: "/api/items/",
  singleItem: "/api/items/",
  resetPassword: "/request_reset?membership_id=",
  // otp: "/api/verify_token?otp=",
  otp: "/verify_token",
  setPassword: "/reset_password?",
  allNotification: "/notifications/",
  singleNotification: "/notifications/view/",
  searchFlight: "/rewardsbox/api/v3/flight/?api=flight_search",
  meal: "/api/meal_vendors",
  restaurantLocation: "/api/vendor_locations/",
  mealMenu: "/api/meals_order_page?",
  menu_categories: "/api/menu_categories?cat_id=",

  airtime_bills: "/getbiller_categories",
  single_biller: "/get_biller_items",
  statement: "/statement?member_id=",
  profile_password: "/change_password",
  change_pin: "/change_pin",
  sort: "/sort?",
  eventsRedemption: "/services/redeem_api.php",
  generate_discount: "/generate_voucher",
  cartCount: "/api/cart/items/",
  wishCount: "/api/wishlist/items/",
  
};
