import { configureStore } from "@reduxjs/toolkit";
import offlineReducer from "./features/Offline/OfflineSlice";
import scrollReducer from "./features/scroll/scrollSlice";
import passwordSlice from "./features/password/showPasswordSlice";
import firstTimeLoginSlice from "./features/defaultLogin/loginSlice";
import freqQuestReducer from "./features/freqQuest/freqQuestSlice";
import forgotPasswordReducer from "./features/forgotPassword/forgotPasswordSlice";
import cartReducer from "./features/cart/cartSlice";
import descriptionReducer from "./features/Shop/SingleItemDescriptionSlice";
import eventsSlice from "./features/Events/EventsSlice";
import flightSlice from "./features/Flight/flightSlice";
import  sideBarSlice  from "./features/SideBarSlice/SideBarSlice";
import dashboardSlice from "./features/dashboard/dashboardSlice";
import orderSlice from "./features/order/orderSlice";

export const store = configureStore({
  reducer: {
    offline: offlineReducer,
    scroll: scrollReducer,
    password: passwordSlice,
    firstTime: firstTimeLoginSlice,
    freqQuest: freqQuestReducer,
    forgotPassword: forgotPasswordReducer,
    cart: cartReducer,
    description: descriptionReducer,
    events: eventsSlice,
    flight: flightSlice,
    sideBar: sideBarSlice,
    dashboard: dashboardSlice,
    order: orderSlice
  }
});
