import React, {useRef, useEffect, useState} from 'react';
import './Discount.css';
import modalImage from '../../assets/images/flight-and-hotel-modal.png';
import {DiscountTimes, ModalTime, PercenatgeIcon} from '../../assets/icons';
import DiscountSuccessModal from './DiscountSuccessModal';
import axios from 'axios';
import {endpoints} from '../../utils/constant';
import {TailwindSpinner} from '../../components/Spinner/TailwindSpinner';
import { authHeaders } from '../../config/helper';

const DiscountModal = ({
  setShowDiscountModal,
  merchantData,
  showDiscountModal,
  merchant_id,
  percentageVal,
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  let result = JSON.parse(sessionStorage.getItem('first-bank-loyalty'));
  const membershipID = result?.data.customer.Membership_id;
  const [selectedMerchantId, setSelectedMerchantId] = useState('');
  const [selectedMerchantName, setSelectedMerchantName] = useState('');
  const [selectedMerchantBranch, setSelectedMerchantBranch] = useState('');
  const [isErrorText, setIsErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const handleMerchantChange = event => {
    setSelectedMerchantId(event.target.value);
  };

  const handleProperties = each => {
    setSelectedMerchantName(each.merchant_name);
    setSelectedMerchantBranch(each.address);
  };

  const sendGenerateVouchers = async () => {
    const url = `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.generate_discount}`;
    const params = {
      membership_id: membershipID,
      merchant_id: merchant_id,
      percentage_val: percentageVal,
      merchant_branch_id: selectedMerchantId,
      merchant_name: selectedMerchantName,
      merchant_branch: selectedMerchantBranch,
    };

    setIsLoading(true)
    try {
      const postVouchers = await axios.post(url, params, {headers: authHeaders});
      
      if (postVouchers.data.status === 0) {
        setIsErrorText('Select A Branch');
        
        setIsLoading(false)
      }

      if (postVouchers.data.status === 1) {
        
        setShowSuccessModal(true);
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  };
  const modalRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowDiscountModal(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    sendGenerateVouchers();
  };

  //console.log(merchantData, "merchantData");

  return (
    <div className="discount-modal-container">
      <div className="inner-discount-modal" ref={modalRef}>
        <div className="discount-modal-cancel">
          <span onClick={() => setShowDiscountModal(false)}>
            <DiscountTimes />
          </span>
        </div>
        <div>
          <div className="discount-modal-image-container">
            <div className='merchant-image-container'>

            <img
              src={merchantData?.[0]?.merchant_img}
              alt={merchantData?.[0]?.merchant_name}
              className='merchant-image'
              />
              </div>
            <p>{merchantData?.[0]?.merchant_name}</p>
          </div>
          <div className='discount-description-container'>
            <div className='discount-description-icon'>
              <PercenatgeIcon/>
            </div>
            <p className='discount-description'>
              {merchantData?.[0]?.description}
            </p>
          </div>
          <div className="voucher-info">
            <p>
              Your discount voucher can only be used at the following &nbsp;
              {merchantData?.[0]?.merchant_name} locations
            </p>
      
          </div>
          <div className="merchant-address-container">
            
            {merchantData?.map(each => {
              return (
                <div className="merchant-address" key={each.merchant_id}>
                  <div
                    onClick={() => handleProperties(each)}
                    className="">
                      <div className='flex gap-2'>
                      <input
                      type="radio"
                      style={{cursor: "pointer"}}
                      name="merchant"
                      value={each.merchant_id}
                      checked={selectedMerchantId === each.merchant_id}
                      onChange={handleMerchantChange}
                    />
                    <p>{each.address}</p>

                      </div>
                    
                    <div className="merchant-open-time">
                      <ModalTime />
                      <p>Open until 8 PM</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button className="discount-modal-btn" onClick={handleClick} disabled={selectedMerchantId === ''}>
          {isLoading ? <TailwindSpinner /> : 'Generate Voucher'}
        </button>
        <div className="text-center text-red-700 pb-2">{isErrorText}</div>
      </div>
      {showSuccessModal && (
        <DiscountSuccessModal setShowSuccessModal={setShowSuccessModal} />
      )}
    </div>
  );
};

export default DiscountModal;
