import React, { useRef, useEffect } from "react";

import "../../components/DeleteAddressModal/DeleteAddressModal.css";

const DeleteBeneficiaryModal = ({
  HandleDeleteBeneficiary,
  setBeneficiaryDeleteModal,
}) => {
  const deleteAddressModal = useRef(null);
  useEffect(() => {
    function closeModal(event) {
      if (
        deleteAddressModal.current &&
        !deleteAddressModal.current.contains(event.target)
      ) {
        setBeneficiaryDeleteModal(false);
      }
    }
    window.addEventListener("mousedown", closeModal);
    return () => {
      window.removeEventListener("mousedown", closeModal);
    };
  }, []);

  return (
    <div className="delete-modal-container">
      <div className="inner-modal-container" ref={deleteAddressModal}>
        <p className="modal-text-1">Delete Beneficiary</p>
        <p className="modal-text-2">
          Are you sure you would like to delete this beneficiary?
        </p>
        <div className="modal-btn-container">
          <button
            className="modal-delete-btn"
            onClick={() => [HandleDeleteBeneficiary()]}
          >
            Delete
          </button>
          <button
            className="modal-cancel-btn"
            onClick={() => setBeneficiaryDeleteModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBeneficiaryModal;
