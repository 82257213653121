import React from "react";
import "./TransferCoinModal.css";

function ForgotPinModal(props) {
  const {
    transferCoinPassword,
    setTransferCoinPassword,
    setPasswordModal,
    handleConfirmTransferSubmit,
    confirmCoinTransferError,
    setForgotPinModal,
    handleForgotPin,
  } = props;

  return (
    <div className="transfer-coin-modal">
      <div className="transfer-coin-modal-content">
        <span className="close-btn" onClick={() => setForgotPinModal(false)}>
          &times;
        </span>
        <h2 className="transfer-coin-modal-header">Forgot Pin?</h2>
        <div>
          <p className="transfer-coin-modal-recipient-details">
            A new pin will be sent to your registered email address
          </p>
        </div>

        <form onSubmit={handleForgotPin}>
          <div className="transfer-btn-container-modal">
            <button type="submit">Continue</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPinModal;
