import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quest1: false,
  quest2: false,
  quest3: false,
  quest4: false
};

const freqQuestSlice = createSlice({
  name: "frequently asked questions",
  initialState,
  reducers: {
    showQuest1: state => {
      state.quest1 = true;
    },
    hideQuest1: state => {
      state.quest1 = false;
    },
    showQuest2: state => {
      state.quest2 = true;
    },
    hideQuest2: state => {
      state.quest2 = false;
    },
    showQuest3: state => {
      state.quest3 = true;
    },
    hideQuest3: state => {
      state.quest3 = false;
    },
    showQuest4: state => {
      state.quest4 = true;
    },
    hideQuest4: state => {
      state.quest4 = false;
    }
  }
});

export const {
  showQuest1,
  hideQuest1,
  showQuest2,
  hideQuest2,
  showQuest3,
  hideQuest3,
  showQuest4,
  hideQuest4
} = freqQuestSlice.actions;
export default freqQuestSlice.reducer;
