import React, { useState, useEffect } from "react";
import cancel from "../../assets/svg/cancel.svg";
import search from "../../assets/svg/search.svg";
import { endpoints } from "../../utils/constant";
import axios from "axios";
import { MovieModalItem } from "../Cards/CardItems/CardItem";
import { getAccessToken, logout } from "../../config/helper";
import { ToastContainer, toast } from "react-toastify";

const MoviesModal = ({ visible, onClose }) => {
  const [movieItem, setMovieItem] = useState([]);
  const [searchMovie, setSearchMovie] = useState([]);
  const [queryMovie, setQueryMovie] = useState("");


  const fetchModals = async () => {
    try {
      const response = await axios(
          `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.movies}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
          }
        }
      );

      const allModals = response.data;
      let movieModalItem = allModals.data.find((item) => {
        return item.tickets.length >= 20;
      });
      setMovieItem(movieModalItem.tickets);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(()=>{

          logout()
        }, 4000)
     
      } 
      console.log(error);
    }
  };

  useEffect(() => {
    fetchModals();
  }, [setMovieItem]);

  useEffect(() => {
    const search = movieItem.filter((item) =>
      item.title.toLowerCase().includes(queryMovie.toLowerCase())
    );
    setSearchMovie(search);
  }, [movieItem, queryMovie]);

  if (!visible) return null;
  return (
    <div className="fixed overflow-y-auto justify-end top-30 left-0 w-full h-full z-10 bg-[#808080] bg-opacity-10 backdrop-blur-sm flex sm:top-[200px]">
      <div className="w-[500px]">
        <div className="bg-search h-fit px-3 py-9 sm:px-10">
          <div className="flex justify-between items-center">
            <p className="capitalize text-white py-2 font-bold items-center flex text-xl sm:text-2xl">
              select a movie
            </p>
            <button className="h-fit">
              <img
                onClick={onClose}
                className="w-8 h-8"
                src={cancel}
                alt="close"
              />
            </button>
          </div>
          <div className="mt-3 sm:mr-14 sm:mt-5">
            <div className="relative">
              <input
                className="bg-search text-white border-white rounded-[100px] pl-9 py-4 w-full lowercase"
                type="search"
                value={queryMovie}
                placeholder="search for a movie"
                onChange={(event) => setQueryMovie(event.target.value)}
              />
              <img
                className=" absolute top-4 sm:top-[18px] left-3"
                src={search}
                alt="search"
              />
            </div>
          </div>

          <div className="my-6 h-[1150px] sm:h-[1750px]">
            {Boolean(searchMovie.length) &&
              searchMovie
                .slice(0, 6)
                .map((item) => <MovieModalItem key={item.id} card={item} />)}
            {Boolean(!searchMovie.length) && (
              <h1 className="text-3xl text-white">No Search Results</h1>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MoviesModal;
