import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {
  createDeliveryAddressApi,
  deleteDeliveryAddressApi,
  editDeliveryAddress,
  getCitiesListApi,
  getDeliveryAddressListApi,
  selectedAddressApi,
  updateDeliveryAddressApi,
} from '../../../components/AddressList/AddressListService';
import {
  getCartItemsApi,
  redemptionApi,
} from '../../../components/CartItemsService/CartItemsService';
// import { setOrderResponse } from './cartSlice';

// SerializableStateInvariantMiddleware

// GET ALL ADDRESSES START

export const getDeliveryAddress = createAsyncThunk(
  'user-address/getDeliveryAddress',
  async () => {
    const response = await getDeliveryAddressListApi();
    return response.data;
  },
);

//EDIT ADDRESS

export const getAddressToEdit = createAsyncThunk(
  'edit/getAddressToEdit',
  async id => {
    const response = await editDeliveryAddress(id);
    //  console.log(response, "my response");
    return response.data.shipping_details;
  },
);

//REDEMPTION

const saveTosessionStorage = cartData => {
  sessionStorage.setItem('finalCheckoutData', JSON.stringify(cartData));
};

export const cartRedemption = createAsyncThunk(
  'redemption/cartRedemption',
  async ({addressID, deliveryFee}, {dispatch}) => {
    const response = await redemptionApi(addressID, deliveryFee);

    dispatch(saveTosessionStorage(response?.data));

    return response?.data;
  },
);

// export const cartRedemption = createAsyncThunk(
//   'redemption/cartRedemption',
//   async ({addressID, deliveryFee}) => {
//     const response = await redemptionApi(addressID, deliveryFee);
//     console.log(response, addressID, deliveryFee, 'cartslice own');
//     return response?.data;
//   },
// );

//SELECTED ADDRESS.

export const selectedShippingAddress = createAsyncThunk(
  'selectedAddress/selectedShippingAddress',
  async id => {
    return await selectedAddressApi(id);
  },
);

// GET ALL CITIES

export const getAllCities = createAsyncThunk(
  'cities/getAllCities',
  async id => {
    const response = await getCitiesListApi(id);
    return response.data.cities;
  },
);

// GET ALL ITEMS

export const getCartItems = createAsyncThunk('cart/getCartItems', async () => {
  const response = await getCartItemsApi();
  return response;
});

// GET ALL ADDRESSES END

// UPDATE ADDRESS
export const updateDeliveryAddressAction = createAsyncThunk(
  'user-address/updateDeliveryAddressAction',
  async ({id, data}) => {
    // console.log(id, data, "UPDATE ADDRESS SLICE");
    const response = await updateDeliveryAddressApi(id, data);
    return response.data;
  },
);

// DELETE ADDRESS START

export const deleteDeliveryAddressAction = createAsyncThunk(
  'user-address/deleteDeliveryAddressAction',
  async id => {
    // console.log(id, "delete address slice id")
    await deleteDeliveryAddressApi(id);
    return id;
  },
);

// CREATE ADDRESSE START

export const createDeliveryAddressAction = createAsyncThunk(
  'user-address/createDeliveryAddressAction',
  async data => {
    const response = await createDeliveryAddressApi(data);
    return response.data;
  },
);

// CREATE ADDRESSE END

export const cartSlice = createSlice({
  name: 'cart slice',
  initialState: {
    finalCheckoutData: [],
    isFinalCheckout: true,
    ItemList: [],
    pickupCart: [],
    deliveryCart: [],
    addAddress: {},
    addressBox: false,
    pickupPersonContainer: [],
    editBox: [],
    pickerList: false,
    addressList: false,
    placeOrder: false,
    checkoutForm: false,
    isLoading: true,
    createAddressStatus: null,
    updateAddressStatus: null,
    isEdit: false,
    cities: [],
    isCities: false,
    isCartItems: false,
    subTotal: 0,
    cartTotalItems: 0,
    deliveryTotalAmount: 0,
    pickupTotalAmount: 0,
    deliveryFee: 0,
    Total: 0,
    editableAddress: {},
    edited: false,
    addressSelected: false,
    orderResponse: {
      status: 0,
      current_balance: 0,
      order_number: '',
      message: '',
      user_email: '',
    },
  },
  reducers: {
    addToPickupCart: (state, action) => {
      state.pickupCart = action.payload;
      // console.log( state.pickupCart, " state.pickupCart");
    },
    setOrderResponse: (state, action) => {
      console.log('action', action);
      state.orderResponse = action.payload;
    },
    resetCartTotalItems: state => {
      state.cartTotalItems = 0;
    },
    increaseCartTotalItems: state => {
      if (!state.cartTotalItems) {
        state.cartTotalItems = 1;
      } else {
        state.cartTotalItems += 1;
      }
    },
    addToDeliveryCart: (state, action) => {
      state.deliveryCart = action.payload;
      // console.log(state.deliveryCart, "state.deliveryCart");
    },
    cartAddAddress: (state, action) => {
      state.addAddress.push(action.payload);
    },
    addPickupPerson: (state, action) => {
      state.pickupPersonContainer.push(action.payload);
    },
    showAddressBox: state => {
      state.addressBox = true;
    },
    hideAddressBox: state => {
      state.addressBox = false;
    },
    showPickerList: state => {
      state.pickerList = true;
    },
    hideAddressList: state => {
      state.addressList = true;
    },
    hidePickerList: state => {
      state.pickerList = false;
    },
    showOrders: state => {
      state.placeOrder = true;
    },
    showCheckoutForm: state => {
      state.checkoutForm = true;
    },
    showEdit: state => {
      state.isEdit = true;
    },
    hideEdit: state => {
      state.isEdit = false;
    },
    clearCart: state => {
      state.pickupCart = [];
      state.deliveryCart = [];
    },
    hideCheckoutForm: state => {
      state.checkoutForm = false;
    },
    // editAddress: (state, action) => {
    //   state.addAddress.filter((each) => {
    //     if (each.id === action.payload) {
    //       state.editBox.push(each);
    //     }
    //     return state.editBox;
    //   });
    // },
    resetCreateAddressStatus: state => {
      state.createAddressStatus = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(cartRedemption.pending, state => {
      state.isFinalCheckout = 'loading';
    });
    builder.addCase(cartRedemption.rejected, state => {
      state.isFinalCheckout = 'error';
    });
    builder.addCase(cartRedemption.fulfilled, (state, action) => {
      state.isFinalCheckout = 'success';
      // console.log(action, 'cart slice action');
      state.finalCheckoutData = action.payload;
    });

    builder.addCase(getDeliveryAddress.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getDeliveryAddress.fulfilled, (state, action) => {
      // console.log(action.meta, "state action");
      state.addAddress = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getDeliveryAddress.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createDeliveryAddressAction.pending, state => {
      state.createAddressStatus = 'loading';
    });
    builder.addCase(createDeliveryAddressAction.fulfilled, state => {
      state.createAddressStatus = 'success';
    });
    builder.addCase(createDeliveryAddressAction.rejected, state => {
      state.createAddressStatus = 'error';
    });
    builder.addCase(updateDeliveryAddressAction.pending, state => {
      state.updateAddressStatus = 'loading';
    });
    builder.addCase(updateDeliveryAddressAction.fulfilled, (state, action) => {
      state.updateAddressStatus = 'success';
    });
    builder.addCase(updateDeliveryAddressAction.rejected, state => {
      state.updateAddressStatus = 'error';
    });
    builder.addCase(deleteDeliveryAddressAction.fulfilled, (state, action) => {
      const newAddressList = state.addAddress?.filter(address => {
        return address.id !== action.payload;
      });
      state.addAddress = newAddressList;
    });
    builder.addCase(getAllCities.fulfilled, (state, action) => {
      state.cities = action.payload;
      state.isCities = true;
    });
    builder.addCase(getAllCities.rejected, state => {
      state.isCities = false;
    });
    builder.addCase(getAllCities.pending, state => {
      state.isCities = false;
    });
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      const {data} = action.payload || {};

      // Filter items based on Redemption_method
      const pickupCart = (data?.data || []).filter(
        item => item.Redemption_method === 1,
      );
      const deliveryCart = (data?.data || []).filter(
        item => item.Redemption_method === 2,
      );

      // Extract other necessary data
      const {
        Cart_total_amount_in_point,
        Total_cart_item,
        Total_delivery_amount,
      } = data || {};

      // Update the state
      state.pickupCart = pickupCart;
      state.deliveryCart = deliveryCart;
      state.subTotal = Cart_total_amount_in_point;
      state.cartTotalItems = Total_cart_item;
      state.deliveryFee = Total_delivery_amount;
      state.Total =
        (Cart_total_amount_in_point || 0) + (Total_delivery_amount || 0);
      state.isCartItems = true;
    });

    builder.addCase(getCartItems.rejected, state => {
      state.isCartItems = false;
    });
    builder.addCase(getCartItems.pending, state => {
      state.isCartItems = false;
    });
    builder.addCase(selectedShippingAddress.fulfilled, state => {
      state.addressSelected = true;
    });
    builder.addCase(selectedShippingAddress.pending, state => {
      state.addressSelected = false;
    });
    builder.addCase(selectedShippingAddress.rejected, state => {
      state.addressSelected = false;
    });
    builder.addCase(getAddressToEdit.fulfilled, (state, action) => {
      state.editableAddress = action.payload;
      // console.log(state.editableAddress, "state.editableAddress");
      state.addressSelected = true;
    });
    builder.addCase(getAddressToEdit.pending, state => {
      state.addressSelected = false;
    });
    builder.addCase(getAddressToEdit.rejected, state => {
      state.addressSelected = false;
    });
  },
});

export const {
  addToPickupCart,
  addToDeliveryCart,
  cartAddAddress,
  showAddressBox,
  hideAddressBox,
  showPickerList,
  hidePickerList,
  hideAddressList,
  addPickupPerson,
  showOrders,
  showCheckoutForm,
  hideCheckoutForm,
  editAddress,
  deleteAddress,
  resetCreateAddressStatus,
  showEdit,
  resetCartTotalItems,
  hideEdit,
  clearCart,
  setOrderResponse,
  increaseCartTotalItems,
} = cartSlice.actions;

export default cartSlice.reducer;
