import React, {useEffect} from 'react';
import './SectionFour.css';
import gift from '../../../assets/images/earngreen.png';
import {motion} from 'framer-motion';
import {
  sectionTwoTextAnimation,
  sectionTwoImageAnimationThree,
} from '../../../animation';
// import {useAnimationInView} from './useAnimationInView';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
import {useAnimationInView} from '../../LandingPage/components/useAnimationInView';

const SectionFour = () => {
  const [element, controls] = useAnimationInView();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let proxy = {skew: 0},
      skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'), // fast
      clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      onUpdate: self => {
        let skew = clamp(self.getVelocity() / -300);
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {
            skew: 0,
            duration: 0.5,
            ease: 'power3',
            overwrite: true,
            onUpdate: () => skewSetter(proxy.skew),
          });
        }
      },
      onScroll: () => {
        ScrollTrigger.update();
      },
    });

    gsap.set('.skewElem', {transformOrigin: 'right center', force3D: true});

    // Cleanup function to unregister ScrollTrigger on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);
  return (
    <div className="sectiontwosubsectiontwo-container skewElem" ref={element}>
      <div className="section-two-img-container___">
        <motion.img
          whileHover={{scale: 0.8, borderRadius: '100%'}}
          variants={sectionTwoImageAnimationThree}
          animate={controls}
          transition={{
            delay: 0.02,
            type: 'tween',
            duration: 0.8,
          }}
          src={gift}
          alt="Open Account Form"
          className="open-acct-image____mobile"
        />

        <motion.div
          variants={sectionTwoTextAnimation}
          animate={controls}
          transition={{
            delay: 0.02,
            type: 'tween',
            duration: 0.8,
          }}
          className="open-right-text___">
          <div>
            <h1 className="open-acct-header___">
              Earn FirstCoins, Get Rewards
            </h1>
          </div>
          <div>
            <p className="open-acct-parag___">
              Earn FirstCoins and Redeem amazing products on the rewards
              platform. From meals to airtime and more
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default SectionFour;
