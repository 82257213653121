import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./EventSlider.css";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const EventSlider = ({ sliderImage1, sliderImage2, sliderImage3 }) => {

  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

//   useLayoutEffect(() => {
//     const scriptTag1 = document.createElement("script");
//     scriptTag1.src = "engine1/wowslider.js";
//     document.body.appendChild(scriptTag1);
//   }, []);

//   useEffect(() => {
//     const scriptTag = document.createElement("script");
//     scriptTag.src = "engine1/script.js";
//     document.body.appendChild(scriptTag);
//   }, []);

  return (
  



      <div className="image-container">


      
<Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        <SwiperSlide>
          <img className="d-block w-100" src={sliderImage1} alt="First slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="d-block w-100" src={sliderImage2} alt="Second slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="d-block w-100" src={sliderImage3} alt="Third slide" />
        </SwiperSlide>
        
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>

      

     
      </div>
      
  
  );
};

export default EventSlider;

 {/* <Carousel interval={3000}>
        <Carousel.Item>
          <img className="d-block w-100" src={sliderImage1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={sliderImage2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={sliderImage3} alt="Third slide" />
        </Carousel.Item>
      </Carousel> */}



      {/* <div className="row">
        <div id="wowslider-container1" style={{ zIndex: 0 }}>
          <div className="ws_images">
            <ul>
              <li>
                <img src={sliderImage1} alt="1" title="1" id="wows1_0" />
              </li>
              <li>
                <img src={sliderImage2} alt="2" title="2" id="wows1_1" />
              </li>
              <li>
                <img src={sliderImage3} alt="3" title="3" id="wows1_2" />
              </li>
            </ul>
          </div>
          <div className="ws_bullets">
            <div >
              <a href="#" title="1">
                <span>1</span>
              </a>
              <a href="#" title="2">
                <span>2</span>
              </a>
              <a href="#" title="3">
                <span>3</span>
              </a>
            </div>
          </div>

          <div className="ws_shadow"></div>
        </div>
      </div> */}
