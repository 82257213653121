import React from 'react';
import laptop1 from '../../assets/images/laptop1.png';
import cancel from '../../assets/svg/x-modal.svg';
import pick_modal from '../../assets/svg/pick_modal.svg';
import delivery_modal from '../../assets/svg/delivery_modal.svg';
import plus from '../../assets/svg/plus.svg';
import subtract from '../../assets/svg/subtract.svg';
import AddToCart from '../buttons/AddToCart';
import {
  alterRedemption,
  doGet,
  formatValue,
  getData,
  handleCount,
  myStore,
  token,
} from '../../config/helper';
import {useState, useEffect, useRef} from 'react';
import {TailwindSpinner} from '../Spinner/TailwindSpinner';

const CartModal = ({visible, onClose, item}) => {
  item.Merchandize_item_code = item?.Merchandize_item_code
    ? item?.Merchandize_item_code
    : item?.Item_code;
  const [branches, setBranches] = useState({});
  const [selectField, setSelectField] = useState('block');
  const [quantity, setQuantity] = useState(1);
  const [disbaleCartBtn, setDisableCartBtn] = useState('hidden');
  const cartRef = useRef(true);
  const [itemState, setItemState] = useState({
    ...item,
    Merchandize_item_code: item?.Merchandize_item_code || item?.Item_code,
    item_quantity: 1,
    Delivery_method: 2,
  });
  const [isInCart, setIsInCart] = useState(0);
  const [pointer, setPointer] = useState('');
  const [pickupVisibility, setPickupVisibility] = useState(true);
  const [deliveryVisibility, setDeliveryVisibility] = useState(true);
  const [buttonLabel, setButtonLabel] = useState('none');
  const [pickupLocation, setPickupLocation] = useState('');
  const [showPickUp, setShowPickUp] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [itemSize, setItemSize] = useState('');
  const [flag3Check, setFlag3Check] = useState(true);

  sessionStorage.removeItem('finalCheckoutData');
  sessionStorage.removeItem('cartStatusMessage');

  const addBranch = () => {
    item = {
      ...item,
      branch_code: document.querySelector('#branches').value,
      item_quantity: quantity,
      attributes: `${item?.Attributes?.attribute_name}-${itemSize}`,
    };
    setPickupLocation(document.querySelector('#branches').value);
    setItemState(item);
    // setPickupLocationValue(document.querySelector('#branches').value);
  };

  // console.log(pickupLocationValue, 'pickupLocationValue');

  // const handleChange = e => {
  //   const selectedValue = e.target.value;

  //   setPickupLocationValue(selectedValue);
  // };

  useEffect(() => {
    doGet(
      setIsInCart,
      `api/checkcart?membership_id=${
        getData(myStore)?.data?.customer?.Membership_id
      }&item_code=${itemState?.Merchandize_item_code}&token=${token}&type=1`,
    );
    let merch_item_code = itemState?.Merchandize_item_code;
    doGet(
      setIsInCart,
      `api/checkcart?membership_id=${
        getData(myStore)?.data?.customer?.Membership_id
      }&item_code=${merch_item_code}&token=${token}&type=1`,
    );

    if (isInCart?.status === 0) {
      if (item?.Delivery_method === '1' || item?.Delivery_method === '3') {
        doGet(
          setBranches,
          'api/branches/' +
            itemState?.Merchandize_item_code +
            '?token=' +
            token,
        );
        setPickupVisibility(true);
        setShowPickUp(true);
      }
      if (item?.Delivery_method === '2' || item?.Delivery_method === '3')
        setDeliveryVisibility(true);
      setShowDelivery(true);
      if (item?.Delivery_method === '2') {
        setSelectField('none');
        setShowDelivery(true);
        setPickupVisibility(false);
      }
      setPointer('');
    } else {
      setPointer('none');
      setButtonLabel('Added To Cart');
    }
  }, [item]);

  useEffect(() => {
    doGet(
      setBranches,
      'api/branches/' + item?.Merchandize_item_code + '?token=' + token,
    );
    doGet(
      setBranches,
      'api/branches/' + itemState?.Merchandize_item_code + '?token=' + token,
    );
  }, [itemState]);

  useEffect(() => {
    item = {
      ...item,
      item_quantity: quantity,
      attributes: `${item?.Attributes?.attribute_name}-${itemSize}`,
    };
    setItemState(item);
  }, [quantity, itemSize]);

  useEffect(() => {
    if (item?.Attributes?.variants?.length === 1) {
      setItemSize(item?.Attributes?.variants[0]);
    }

    if (item?.Attributes?.variants?.length >= 1 && itemSize != '') {
      setFlag3Check(false);
    } else if (item?.Attributes?.variants?.length === 0) {
      setFlag3Check(false);
    } else {
      setFlag3Check(true);
    }
  }, [itemState]);

  if (!visible) return null;

  return (
    <div
      // onClick={onClose}
      className="fixed overflow-y-auto justify-end  top-0 left-0 w-full h-full z-10 bg-[#808080] bg-opacity-10 backdrop-blur-sm flex">
      <div className="w-[450px] relative top-0 left-0 h-fit bg-white">
        <div className="px-4 min-h-[700px] py-3">
          <div className="flex justify-between items-center">
            <p className="capitalize py-2 font-bold text-2xl">
              select attributes
            </p>
            <button className="h-fit">
              <img onClick={onClose} className="" src={cancel} alt="close" />
            </button>
          </div>
          <div className="flex flex-col space-y-5">
            <div className="flex gap-3">
              <div className="border w-fit rounded border-solid">
                <img src={item?.Item_image} alt={item?.Merchandize_item_name} />
              </div>
              <div className="w-1/2 space-y-8">
                <h2 className="text-xl leading-loyalty tracking-loyal font-normal">
                  {item?.Merchandize_item_name
                    ? item?.Merchandize_item_name
                    : item?.Item_name}
                </h2>
                <p className="capitalize font-bold text-base leading-loyalty tracking-loyal">
                  {formatValue(
                    (item?.Billing_price_in_points
                      ? item?.Billing_price_in_points
                      : item?.Price
                      ? item?.Price
                      : item?.Item_price) * quantity,
                  )}{' '}
                  FirstCoins
                </p>
              </div>
            </div>
            <br />

            {/* <p className="py-3 text-submit font-medium text-sm leading-loyalty tracking-loyal">
              A microcopy specific to this item
            </p> */}

            <div className="flex gap-3 items-center">
              <label
                className="font-bold text-sm tracking-loyal text-cart leading-loyalty"
                htmlFor="">
                Quantity
              </label>
              <div className="flex gap-3">
                <img
                  src={subtract}
                  alt="subtract"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    handleCount(quantity, setQuantity, '-');
                  }}
                />
                <input
                  className="w-[40px] px-2 text-center border-2 border-solid border-black rounded"
                  type="text"
                  value={quantity}
                />
                <img
                  src={plus}
                  alt="plus"
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    handleCount(quantity, setQuantity, '+');
                  }}
                />
              </div>
            </div>

            {item?.Attributes?.variants?.length > 0 && (
              <div className="py-3">
                <p className="font-bold text-sm text-cart pb-3 leading-loyalty tracking-loyal">
                  {item?.Attributes?.variants?.length > 1
                    ? `Please select a ${item?.Attributes?.attribute_name}`
                    : 'Size'}
                </p>
                <div>
                  <div className="uppercase flex gap-2 font-medium leading-loyalty tracking-tighter text-[#6a6a6a]">
                    {item?.Attributes?.variants?.map(each => (
                      <button
                        onClick={() => setItemSize(each)}
                        style={{
                          backgroundColor: itemSize === each ? '#ccc' : '#fff',
                        }}
                        className="text-[#6a6a6a] cursor-pointer p-3 rounded border border-solid"
                        key={each}>
                        {each}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="flex space-x-6 py-3">
              {(item?.Delivery_method === '1' ||
                item?.Delivery_method === '3') && (
                <div
                  className="w-1/2 bg-[#f5f5f5] rounded p-6"
                  style={{
                    // display: pickupVisibility,
                    cursor: 'pointer',
                    backgroundColor: pickupVisibility
                      ? 'transparent'
                      : '#cbe0f5',
                    border: '1px solid #ccc',
                  }}
                  onClick={() => [
                    alterRedemption(
                      1,
                      // setDeliveryVisibility,
                      itemState,
                      setItemState,
                      setSelectField,
                    ),
                    // setPickupLocation(true),
                    setPickupVisibility(false),
                    setDeliveryVisibility(true),
                  ]}>
                  <p className="flex gap-3 font-bold text-base leading-loyalty tracking-loyal capitalize">
                    <img src={pick_modal} alt="pick" /> pickup
                  </p>
                  <p className="text-[#313131] font-medium text-base leading-loyalty tracking-loyal">
                    Items should be picked up within 12 days after ordering
                  </p>
                </div>
              )}

              {(item?.Delivery_method === '2' ||
                item?.Delivery_method === '3') && (
                <div
                  className="w-1/2 bg-[#f5f5f5] rounded p-3"
                  style={{
                    // display: deliveryVisibility,
                    cursor: 'pointer',
                    backgroundColor: deliveryVisibility
                      ? 'transparent'
                      : '#cbe0f5',
                    border: '1px solid #ccc',
                  }}
                  onClick={() => [
                    alterRedemption(
                      2,
                      // setPickupVisibility,
                      itemState,
                      setItemState,
                      setSelectField,
                    ),
                    // setPickupLocation(false)
                    setDeliveryVisibility(false),
                    setPickupVisibility(true),
                  ]}>
                  <p className="flex gap-3 font-bold text-base leading-loyalty tracking-loyal capitalize">
                    <img src={delivery_modal} alt="pick" /> Delivery
                  </p>
                  <p className="text-[#313131] font-medium text-base leading-loyalty tracking-loyal">
                    Items should be delivered within 12 days after ordering
                  </p>
                </div>
              )}
            </div>

            {!pickupVisibility &&
              (item?.Delivery_method === '1' ||
                item?.Delivery_method === '3') && (
                <div>
                  <select
                    className="px-2 py-3 w-full border text-sm capitalize font-bold text-[#313131] border-solid  rounded-sm cursor-pointer"
                    label="Select pickup location"
                    id="branches"
                    // value=""
                    onChange={addBranch}>
                    <option>
                      {item?.branches?.length > 0 ? (
                        'select pickup location'
                      ) : (
                        <TailwindSpinner />
                      )}
                    </option>
                    {item?.branches?.map((branch, index) => (
                      <option
                        className="capitalize"
                        value={branch?.Branch_code}
                        onChange={e => console.log(e.target.value)}
                        key={`${branch?.Branch_name} + ${index}`}>
                        {branch?.Branch_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

            {isInCart?.status !== undefined && isInCart?.status === 1 ? (
              <div>
                <button className="mt-6 w-full text-base font-bold justify-center flex mx-auto py-3 px-[18px] bg-white rounded border-2 text-added border-solid border-added">
                  Added to cart
                </button>
              </div>
            ) : (
              <div className="disabled" ref={cartRef}>
                {(itemState?.Redemption_method == 1 ||
                  itemState?.Redemption_method == 3) &&
                itemState?.branch_code == undefined ? (
                  ' '
                ) : (
                  <AddToCart
                    flag1={pickupVisibility}
                    flag2={deliveryVisibility}
                    flag3={flag3Check}
                    item={itemState}
                    label={buttonLabel}
                    pickupLocation={pickupLocation}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
