import React, {useEffect, useState} from 'react';
import {ArrowRight} from '../../assets/icons';
import './FlightCheckout.css';
import logo from './../../assets/images/company-logo.png';
import arrowRight from './../../assets/images/arrow-right.png';
import revFactorial from './../../assets/images/reverse-factorial.png';
import redRevFactorial from './../../assets/images/red-rev-fav.png';
import Select from 'react-dropdown-select';
import FlightOrderModal from '../../components/FlightOrderModal/FlightOrderModal';
import mobileArrow from './../../assets/images/mobileflightarrow.png';
import FlightCheckoutForm from '../FlightCheckoutForm/FlightCheckoutForm';
import ChildrenFCF from '../ChildrenFCF/ChildrenFCF';
import ToddlerFCF from '../ToddlerFCF/ToddlerFCF';
import {nanoid} from 'nanoid';
import AdBanner from '../AdBanner/AdBanner';

// import { current } from "@reduxjs/toolkit";

const FlightCheckout = ({onMobile, flightPassangerProp}) => {
  const title = [
    {
      id: 1,
      name: 'Mr',
    },
    {
      id: 2,
      name: 'Mrs',
    },
    {
      id: 3,
      name: 'Ms',
    },
    {
      id: 4,
      name: 'Sir',
    },
    {
      id: 5,
      name: 'Dr',
    },
    {
      id: 6,
      name: 'HRH',
    },
    {
      id: 7,
      name: 'Hon',
    },
    {
      id: 8,
      name: 'Pst',
    },
    {
      id: 9,
      name: 'Sen',
    },
  ];

  const countries = [
    {name: 'Afghanistan', code: 'AF', id: 1},
    {name: 'Åland Islands', code: 'AX', id: 2},
    {name: 'Albania', code: 'AL', id: 3},
    {name: 'Algeria', code: 'DZ', id: 4},
    {name: 'American Samoa', code: 'AS', id: 5},
    {name: 'AndorrA', code: 'AD', id: 6},
    {name: 'Angola', code: 'AO', id: 7},
    {name: 'Anguilla', code: 'AI', id: 8},
    {name: 'Antarctica', code: 'AQ', id: 9},
    {name: 'Antigua and Barbuda', code: 'AG', id: 10},
    {name: 'Argentina', code: 'AR', id: 11},
    {name: 'Armenia', code: 'AM', id: 12},
    {name: 'Aruba', code: 'AW', id: 13},
    {name: 'Australia', code: 'AU', id: 14},
    {name: 'Austria', code: 'AT', id: 15},
    {name: 'Azerbaijan', code: 'AZ', id: 16},
    {name: 'Bahamas', code: 'BS', id: 17},
    {name: 'Bahrain', code: 'BH', id: 18},
    {name: 'Bangladesh', code: 'BD', id: 19},
    {name: 'Barbados', code: 'BB', id: 20},
    {name: 'Belarus', code: 'BY', id: 21},
    {name: 'Belgium', code: 'BE', id: 22},
    {name: 'Belize', code: 'BZ', id: 23},
    {name: 'Benin', code: 'BJ', id: 24},
    {name: 'Bermuda', code: 'BM', id: 25},
    {name: 'Bhutan', code: 'BT', id: 26},
    {name: 'Bolivia', code: 'BO', id: 27},
    {name: 'Bosnia and Herzegovina', code: 'BA', id: 28},
    {name: 'Botswana', code: 'BW', id: 29},
    {name: 'Bouvet Island', code: 'BV', id: 30},
    {name: 'Brazil', code: 'BR', id: 31},
    {name: 'British Indian Ocean Territory', code: 'IO', id: 32},
    {name: 'Brunei Darussalam', code: 'BN', id: 33},
    {name: 'Bulgaria', code: 'BG', id: 34},
    {name: 'Burkina Faso', code: 'BF', id: 35},
    {name: 'Burundi', code: 'BI', id: 36},
    {name: 'Cambodia', code: 'KH', id: 37},
    {name: 'Cameroon', code: 'CM', id: 38},
    {name: 'Canada', code: 'CA', id: 39},
    {name: 'Cape Verde', code: 'CV', id: 40},
    {name: 'Cayman Islands', code: 'KY', id: 114},
    {name: 'Central African Republic', code: 'CF', id: 115},
    {name: 'Chad', code: 'TD', id: 116},
    {name: 'Chile', code: 'CL', id: 117},
    {name: 'China', code: 'CN', id: 118},
    {name: 'Christmas Island', code: 'CX', id: 119},
    {name: 'Cocos (Keeling) Islands', code: 'CC', id: 120},
    {name: 'Colombia', code: 'CO', id: 121},
    {name: 'Comoros', code: 'KM', id: 123},
    {name: 'Congo', code: 'CG', id: 124},
    {name: 'Congo, The Democratic Republic of the', code: 'CD', id: 125},
    {name: 'Cook Islands', code: 'CK', id: 1275},
    {name: 'Costa Rica', code: 'CR', id: 126},
    {name: "Cote D'Ivoire", code: 'CI', id: 127},
    {name: 'Croatia', code: 'HR', id: 128},
    {name: 'Cuba', code: 'CU', id: 129},
    {name: 'Cyprus', code: 'CY', id: 130},
    {name: 'Czech Republic', code: 'CZ', id: 131},
    {name: 'Denmark', code: 'DK', id: 132},
    {name: 'Djibouti', code: 'DJ', id: 133},
    {name: 'Dominica', code: 'DM', id: 134},
    {name: 'Dominican Republic', code: 'DO', id: 135},
    {name: 'Ecuador', code: 'EC', id: 136},
    {name: 'Egypt', code: 'EG', id: 137},
    {name: 'El Salvador', code: 'SV', id: 138},
    {name: 'Equatorial Guinea', code: 'GQ', id: 139},
    {name: 'Eritrea', code: 'ER', id: 140},
    {name: 'Estonia', code: 'EE', id: 141},
    {name: 'Ethiopia', code: 'ET', id: 142},
    {name: 'Falkland Islands (Malvinas)', code: 'FK', id: 143},
    {name: 'Faroe Islands', code: 'FO', id: 144},
    {name: 'Fiji', code: 'FJ', id: 145},
    {name: 'Finland', code: 'FI', id: 146},
    {name: 'France', code: 'FR', id: 147},
    {name: 'French Guiana', code: 'GF', id: 148},
    {name: 'French Polynesia', code: 'PF', id: 149},
    {name: 'French Southern Territories', code: 'TF', id: 150},
    {name: 'Gabon', code: 'GA', id: 151},
    {name: 'Gambia', code: 'GM', id: 152},
    {name: 'Georgia', code: 'GE', id: 153},
    {name: 'Germany', code: 'DE', id: 154},
    {name: 'Ghana', code: 'GH', id: 155},
    {name: 'Gibraltar', code: 'GI', id: 156},
    {name: 'Greece', code: 'GR', id: 157},
    {name: 'Greenland', code: 'GL', id: 158},
    {name: 'Grenada', code: 'GD', id: 159},
    {name: 'Guadeloupe', code: 'GP', id: 160},
    {name: 'Guam', code: 'GU', id: 161},
    {name: 'Guatemala', code: 'GT', id: 162},
    {name: 'Guernsey', code: 'GG', id: 163},
    {name: 'Guinea', code: 'GN', id: 164},
    {name: 'Guinea-Bissau', code: 'GW', id: 165},
    {name: 'Guyana', code: 'GY', id: 166},
    {name: 'Haiti', code: 'HT', id: 167},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM', id: 168},
    {name: 'Holy See (Vatican City State)', code: 'VA', id: 169},
    {name: 'Honduras', code: 'HN', id: 170},
    {name: 'Hong Kong', code: 'HK', id: 171},
    {name: 'Hungary', code: 'HU', id: 172},
    {name: 'Iceland', code: 'IS', id: 173},
    {name: 'India', code: 'IN', id: 174},
    {name: 'Indonesia', code: 'ID', id: 175},
    {name: 'Iran, Islamic Republic Of', code: 'IR', id: 176},
    {name: 'Iraq', code: 'IQ', id: 177},
    {name: 'Ireland', code: 'IE', id: 178},
    {name: 'Isle of Man', code: 'IM', id: 179},
    {name: 'Israel', code: 'IL', id: 180},
    {name: 'Italy', code: 'IT', id: 181},
    {name: 'Jamaica', code: 'JM', id: 182},
    {name: 'Japan', code: 'JP', id: 183},
    {name: 'Jersey', code: 'JE', id: 184},
    {name: 'Jordan', code: 'JO', id: 185},
    {name: 'Kazakhstan', code: 'KZ', id: 186},
    {name: 'Kenya', code: 'KE', id: 187},
    {name: 'Kiribati', code: 'KI', id: 188},
    {name: "Korea, Democratic People'S Republic of", code: 'KP', id: 189},
    {name: 'Korea, Republic of', code: 'KR', id: 190},
    {name: 'Kuwait', code: 'KW', id: 191},
    {name: 'Kyrgyzstan', code: 'KG', id: 2100},
    {name: "Lao People'S Democratic Republic", code: 'LA', id: 192},
    {name: 'Latvia', code: 'LV', id: 193},
    {name: 'Lebanon', code: 'LB', id: 194},
    {name: 'Lesotho', code: 'LS', id: 195},
    {name: 'Liberia', code: 'LR', id: 196},
    {name: 'Libyan Arab Jamahiriya', code: 'LY', id: 197},
    {name: 'Liechtenstein', code: 'LI', id: 198},
    {name: 'Lithuania', code: 'LT', id: 199},
    {name: 'Luxembourg', code: 'LU', id: 200},
    {name: 'Macao', code: 'MO', id: 201},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', id: 202},
    {name: 'Madagascar', code: 'MG', id: 203},
    {name: 'Malawi', code: 'MW', id: 204},
    {name: 'Malaysia', code: 'MY', id: 205},
    {name: 'Maldives', code: 'MV', id: 206},
    {name: 'Mali', code: 'ML', id: 207},
    {name: 'Malta', code: 'MT', id: 208},
    {name: 'Marshall Islands', code: 'MH', id: 209},
    {name: 'Martinique', code: 'MQ', id: 210},
    {name: 'Mauritania', code: 'MR', id: 211},
    {name: 'Mauritius', code: 'MU', id: 212},
    {name: 'Mayotte', code: 'YT', id: 213},
    {name: 'Mexico', code: 'MX', id: 214},
    {name: 'Micronesia, Federated States of', code: 'FM', id: 215},
    {name: 'Moldova, Republic of', code: 'MD', id: 216},
    {name: 'Monaco', code: 'MC', id: 217},
    {name: 'Mongolia', code: 'MN', id: 2127},
    {name: 'Montserrat', code: 'MS', id: 218},
    {name: 'Morocco', code: 'MA', id: 219},
    {name: 'Mozambique', code: 'MZ', id: 220},
    {name: 'Myanmar', code: 'MM', id: 223},
    {name: 'Namibia', code: 'NA', id: 224},
    {name: 'Nauru', code: 'NR', id: 225},
    {name: 'Nepal', code: 'NP', id: 226},
    {name: 'Netherlands', code: 'NL', id: 227},
    {name: 'Netherlands Antilles', code: 'AN', id: 228},
    {name: 'New Caledonia', code: 'NC', id: 229},
    {name: 'New Zealand', code: 'NZ', id: 230},
    {name: 'Nicaragua', code: 'NI', id: 231},
    {name: 'Niger', code: 'NE', id: 232},
    {name: 'Nigeria', code: 'NG', id: 233},
    {name: 'Niue', code: 'NU', id: 234},
    {name: 'Norfolk Island', code: 'NF', id: 235},
    {name: 'Northern Mariana Islands', code: 'MP', id: 236},
    {name: 'Norway', code: 'NO', id: 237},
    {name: 'Oman', code: 'OM', id: 238},
    {name: 'Pakistan', code: 'PK', id: 239},
    {name: 'Palau', code: 'PW', id: 240},
    {name: 'Palestinian Territory, Occupied', code: 'PS', id: 241},
    {name: 'Panama', code: 'PA', id: 242},
    {name: 'Papua New Guinea', code: 'PG', id: 243},
    {name: 'Paraguay', code: 'PY', id: 244},
    {name: 'Peru', code: 'PE', id: 245},
    {name: 'Philippines', code: 'PH', id: 246},
    {name: 'Pitcairn', code: 'PN', id: 247},
    {name: 'Poland', code: 'PL', id: 248},
    {name: 'Portugal', code: 'PT', id: 249},
    {name: 'Puerto Rico', code: 'PR', id: 250},
    {name: 'Qatar', code: 'QA', id: 251},
    {name: 'Reunion', code: 'RE', id: 252},
    {name: 'Romania', code: 'RO', id: 253},
    {name: 'Russian Federation', code: 'RU', id: 254},
    {name: 'RWANDA', code: 'RW', id: 255},
    {name: 'Saint Helena', code: 'SH', id: 256},
    {name: 'Saint Kitts and Nevis', code: 'KN', id: 257},
    {name: 'Saint Lucia', code: 'LC', id: 258},
    {name: 'Saint Pierre and Miquelon', code: 'PM', id: 259},
    {name: 'Saint Vincent and the Grenadines', code: 'VC', id: 260},
    {name: 'Samoa', code: 'WS', id: 261},
    {name: 'San Marino', code: 'SM', id: 262},
    {name: 'Sao Tome and Principe', code: 'ST', id: 263},
    {name: 'Saudi Arabia', code: 'SA', id: 264},
    {name: 'Senegal', code: 'SN', id: 265},
    {name: 'Serbia and Montenegro', code: 'CS', id: 266},
    {name: 'Seychelles', code: 'SC', id: 267},
    {name: 'Sierra Leone', code: 'SL', id: 268},
    {name: 'Singapore', code: 'SG', id: 269},
    {name: 'Slovakia', code: 'SK', id: 270},
    {name: 'Slovenia', code: 'SI', id: 271},
    {name: 'Solomon Islands', code: 'SB', id: 272},
    {name: 'Somalia', code: 'SO', id: 273},
    {name: 'South Africa', code: 'ZA', id: 274},
    {
      name: 'South Georgia and the South Sandwich Islands',
      code: 'GS',
      id: 275,
    },
    {name: 'Spain', code: 'ES', id: 276},
    {name: 'Sri Lanka', code: 'LK', id: 277},
    {name: 'Sudan', code: 'SD', id: 278},
    {name: 'Suriname', code: 'SR', id: 279},
    {name: 'Svalbard and Jan Mayen', code: 'SJ', id: 280},
    {name: 'Swaziland', code: 'SZ', id: 281},
    {name: 'Sweden', code: 'SE', id: 282},
    {name: 'Switzerland', code: 'CH', id: 283},
    {name: 'Syrian Arab Republic', code: 'SY', id: 284},
    {name: 'Taiwan, Province of China', code: 'TW', id: 285},
    {name: 'Tajikistan', code: 'TJ', id: 286},
    {name: 'Tanzania, United Republic of', code: 'TZ', id: 287},
    {name: 'Thailand', code: 'TH', id: 288},
    {name: 'Timor-Leste', code: 'TL', id: 289},
    {name: 'Togo', code: 'TG', id: 290},
    {name: 'Tokelau', code: 'TK', id: 291},
    {name: 'Tonga', code: 'TO', id: 292},
    {name: 'Trinidad and Tobago', code: 'TT', id: 293},
    {name: 'Tunisia', code: 'TN', id: 294},
    {name: 'Turkey', code: 'TR', id: 295},
    {name: 'Turkmenistan', code: 'TM', id: 296},
    {name: 'Turks and Caicos Islands', code: 'TC', id: 297},
    {name: 'Tuvalu', code: 'TV', id: 298},
    {name: 'Uganda', code: 'UG', id: 299},
    {name: 'Ukraine', code: 'UA', id: 300},
    {name: 'United Arab Emirates', code: 'AE', id: 301},
    {name: 'United Kingdom', code: 'GB', id: 302},
    {name: 'United States', code: 'US', id: 303},
    {name: 'United States Minor Outlying Islands', code: 'UM', id: 304},
    {name: 'Uruguay', code: 'UY', id: 305},
    {name: 'Uzbekistan', code: 'UZ', id: 306},
    {name: 'Vanuatu', code: 'VU', id: 307},
    {name: 'Venezuela', code: 'VE', id: 308},
    {name: 'Viet Nam', code: 'VN', id: 309},
    {name: 'Virgin Islands, British', code: 'VG', id: 310},
    {name: 'Virgin Islands, U.S.', code: 'VI', id: 311},
    {name: 'Wallis and Futuna', code: 'WF', id: 312},
    {name: 'Western Sahara', code: 'EH', id: 313},
    {name: 'Yemen', code: 'YE', id: 314},
    {name: 'Zambia', code: 'ZM', id: 315},
    {name: 'Zimbabwe', code: 'ZW', id: 316},
  ];

  const gender = [
    {gender: 'Agender', id: 1},
    {gender: 'Androgyne', id: 2},
    {gender: 'Androgynous', id: 3},
    {gender: 'Bigender', id: 4},
    {gender: 'Man', id: 6},
    {gender: 'Male', id: 7},
    {gender: 'Intersex', id: 8},
    {gender: 'Intergender', id: 9},
    {gender: 'Gender Fluid', id: 10},
    {gender: 'Genderweird', id: 11},
    {gender: 'Genderqueer', id: 12},
    {gender: 'Femme', id: 13},
    {gender: 'Feminine', id: 14},
    {gender: 'Female', id: 15},
    {gender: 'Demiguy', id: 16},
    {gender: 'Demigirl', id: 17},
    {gender: 'Cis Woman', id: 18},
    {gender: 'Cis Masculine', id: 19},
    {gender: 'Cis Male', id: 38},
    {gender: 'Cis Feminine', id: 21},
    {gender: 'Cis Female', id: 22},
    {gender: 'Masculine', id: 23},
    {gender: 'Neutrois', id: 24},
    {gender: 'Nonbinary', id: 25},
    {gender: 'Other', id: 26},
    {gender: 'Pangender', id: 27},
    {gender: 'Third Gender', id: 28},
    {gender: 'Trans Female', id: 29},
    {gender: 'Trans Male', id: 30},

    {gender: 'Trans Man', id: 31},
    {gender: 'Trans Feminine', id: 32},
    {
      gender: 'Trans Femme',
      id: 33,
    },
    {gender: 'Trans Woman', id: 34},
    {gender: 'Woman', id: 35},
  ];

  const months = [
    {
      name: 'Jan',
      id: 1,
      days: 31,
    },
    {
      name: 'Feb',
      id: 2,
      days: 28,
    },
    {
      name: 'Mar',
      id: 3,
      days: 31,
    },
    {
      name: 'Apr',
      id: 4,
      days: 30,
    },
    {
      name: 'May',
      id: 5,
      days: 31,
    },
    {
      name: 'Jun',
      id: 6,
      days: 30,
    },
    {
      name: 'Jul',
      id: 7,
      days: 31,
    },
    {
      name: 'Aug',
      id: 8,
      days: 31,
    },
    {
      name: 'Sep',
      id: 9,
      days: 30,
    },
    {
      name: 'Oct',
      id: 10,
      days: 31,
    },
    {
      name: 'Nov',
      id: 11,
      days: 30,
    },
    {
      name: 'Dec',
      id: 12,
      days: 31,
    },
  ];

  const [country, setCountry] = useState([]);

  const [orderModel, setOrderModel] = useState(false);
  const [adultFormArray, setAdultFormArray] = useState([]);
  const [counter1, setCounter1] = useState(1);

  const [passangerTitle, setPassangerTitle] = useState([]);
  const [passangerGender, setPassangerGender] = useState([]);
  const [passangerDOBMonth, setPassangerDOBMonth] = useState([]);
  const [passangerDOBYear, setPassangerDOBYear] = useState([]);
  const [passangerDOBDay, setPassangerDOBDay] = useState([]);
  const [allPassangersData, setAllPassangersData] = useState([]);
  const [adultPassangerData, setAdultPassangerData] = useState({
    title: '',
    lastName: '',
    firstName: '',
    middleName: '',
    nationality: '',
    gender: '',
    year: '',
    month: '',
    day: '',
    passportNumber: '',
    passportExpDate: '',
    passportIssuing: '',
    id: nanoid(),
  });

  const [yearArray, setYearArray] = useState([]);
  const [lastDay, setLastDay] = useState(0);
  const [dayArray, setDayArray] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [adultDataArray, setAdultDataArray] = useState([]);
  const dateArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  function generateArrayOfYears(k) {
    var max = new Date().getFullYear() - 12;
    var min = max - k;
    var years = [];
    for (var i = max; i >= min; i--) {
      years.push({year: i, id: i});
    }
    // console.log(years);
    setYearArray(years);
    return years;
  }

  function generateLastDay(month, year) {
    return setLastDay(new Date(year, month, 0).getDate());
  }

  // useEffect(()=>{

  //   setAdultDataArray([...adultDataArray, adultPassangerData]);

  // }, [adultPassangerData])

  // console.log(lastDay, "lastDay");

  function generateDaysArray() {
    const days = [];
    for (let i = 1; i <= lastDay; i += 1) {
      days.push({day: i, id: i});
    }
    return setDayArray(days);
  }

  useEffect(() => {
    generateArrayOfYears(90);
  }, []);

  useEffect(() => {
    if (passangerDOBMonth.length > 0 && passangerDOBYear.length > 0) {
      const monthIndex = dateArray.indexOf(`${passangerDOBMonth[0].name}`);
      generateLastDay(monthIndex + 1, passangerDOBYear[0].year);
      generateDaysArray();
    }
  }, [passangerDOBMonth, passangerDOBYear, lastDay]);

  // console.log(passangerTitle, country);

  // useEffect(()=>{

  // }, [passangerDOBYear,passangerDOBMonth, passangerDOBDay, lastDay,  allPassangersData ])

  useEffect(() => {
    if (passangerDOBDay.length) {
      setOtherData({
        ...otherData,
        day: passangerDOBDay[0].day,
      });
    }

    if (passangerTitle.length) {
      setOtherData({
        ...otherData,
        title: passangerTitle[0].name,
      });
    }

    if (country.length) {
      setOtherData({
        ...otherData,
        nationality: country[0].name,
      });
    }

    if (passangerGender.length) {
      setOtherData({
        ...otherData,
        gender: passangerGender[0].gender,
      });
    }

    // if (passangerDOBDay.length) {
    //   console.log(passangerDOBDay);
    //   setOtherData({
    //     ...otherData,
    //     day: passangerDOBDay[0].day,
    //   });
    // }

    // if (passangerDOBMonth.length) {
    //   console.log(passangerDOBMonth);
    //   setOtherData({
    //     ...otherData,
    //     month: passangerDOBMonth[0].name,
    //   });
    // }

    if (passangerDOBYear.length) {
      console.log(passangerDOBYear);
      setOtherData({
        ...otherData,
        year: passangerDOBYear[0].year,
      });
    }
    if (passangerDOBDay.length) {
      setOtherData({
        ...otherData,
        day: passangerDOBDay[0].day,
      });
    }
  }, [
    passangerTitle,
    country,
    lastDay,
    passangerDOBYear,
    passangerDOBMonth,
    passangerDOBDay,
    passangerGender,
    dayArray,
    yearArray,
  ]);

  const handleChange = e => {
    const value = e.target.value;

    if (passangerDOBMonth.length) {
      console.log(passangerDOBMonth);
      setOtherData({
        ...otherData,
        month: passangerDOBMonth[0].name,
      });
    }

    setAdultPassangerData({
      ...adultPassangerData,
      ...otherData,

      [e.target.name]: value,
    });

    const data = adultDataArray.some(each => each.id === adultPassangerData.id);
    if (data) {
      const updateAdultDataArray = adultDataArray.map(each => {
        if (each.id === adultPassangerData.id) {
          return {...each, ...otherData, [e.target.name]: value};
        } else {
          return each;
        }
      });
      setAdultDataArray(updateAdultDataArray);
    }

    if (!data) {
      setAdultDataArray([...adultDataArray, adultPassangerData]);
    }
  };

  console.log(otherData, 'otherData');
  console.log(adultPassangerData, 'allPassangers');

  // console.log(allPassangersData, "allPassangersData");
  //   useEffect(() => {
  //     console.log(adultPassangerData, "adultPassangerData");
  // if(!adultPassangerData){
  //       setAdultPassangerData([...adultPassangerData, adultPassangerData]);
  // }
  //   }, [])

  const handleFlightOrder = () => {
    setOrderModel(true);
  };

  return (
    <div className="flight-checkout-page">
      <div className="flight-checkout-page-l">
        <div className="to-fro-name">
          <div className="to-fro-name-inner">
            <p className="to-fro-parag">Lagos</p>
            <ArrowRight />
            <p className="to-fro-parag">Owerri</p>
          </div>
          <div className="to-fro-date">
            <p className="to-fro-date-p">Nov 1 </p>
            <span>-</span>
            <p className="to-fro-date-p">Nov 5</p>
          </div>
        </div>

        <div className="flight-final-checkout-detail">
          {/* map card begining */}

          {!onMobile && (
            <div className="non-stop-l_">
              <div className="mobile-flight-adjust-1">
                <img src={logo} alt="logo" className="company-logo" />
                <p className="company-name">Emirate</p>
              </div>
              <div className="mobile-flight-adjust-2">
                <div className="leaving-time-mobile">
                  <p className="leaving-time">09:00AM</p>
                  <p className="from">Ondo</p>
                </div>
                <div className="arrow-container">
                  <p className="duration">1h 20min</p>
                  <img src={mobileArrow} alt="arrow-right" />
                  {/* <p className="non-stop">Non-stop</p> */}
                </div>
                <div className="leaving-time-mobile">
                  <p className="arriving-time">10: 20AM</p>
                  <p className="from">Lagos</p>
                </div>
                <div className="non-stop-r-container">
                  <div className="non-stop-r">
                    <p className="r-from">from</p>
                    <p className="r-FirstCoins">12,488 FirstCoins</p>
                    {/* <button
                            className="r-btn"
                         
                          >
                            View
                          </button> */}
                  </div>
                </div>
              </div>
            </div>
          )}

          {onMobile && (
            <div className="non-stop-container-single">
              <div className="non-stop-l-single non-stop-l-single-adjust">
                <div>
                  <img src={logo} alt="logo" className="company-logo" />
                  <p className="company-name">Emirate</p>
                </div>
                <div>
                  <p className="leaving-time">09 : 12AM</p>
                  <p className="from">London</p>
                </div>
                <div className="arrow-container">
                  <p className="duration">22h 10min</p>
                  <img src={arrowRight} alt="arrow-right" />
                  <p className="non-stop">Non-stop</p>
                </div>
                <div>
                  <p className="arriving-time">01:55PM</p>
                  <p className="from">Germany</p>
                </div>
              </div>
            </div>
          )}

          {/* map card ending */}

          <div>
            <div className="flight-detail-instruction">
              <div className="rev-fac-outter">
                <img
                  src={revFactorial}
                  alt="reverse factorial"
                  className="rev-fac"
                />
              </div>
              <div className="instruction-parag-outter">
                <p className="instruction-parag">
                  Use all given names and surnames exactly as they appear on
                  your passport/ID to avoid complications.
                </p>
              </div>
            </div>
          </div>
          <div className="populated-flight-form">
            <div className="passenger-details-parag-cont">
              <div className="passenger-details-parag">
                Passenger {1} (Adult - Primary Contact)
              </div>
            </div>
            <div>
              <div className="detail-line-1">
                <div className="title-dropdown-container">
                  <p className="line-p">
                    Surname<sup className="red-span">*</sup>
                  </p>
                  <div className="title-select-inner">
                    <div className="title-select">
                      <Select
                        options={title}
                        labelField="name"
                        valueField="id"
                        placeholder="title"
                        onChange={value => setPassangerTitle(value)}
                        searchable={true}
                        searchBy="name"
                      />
                    </div>
                    <div className="title-lastname-container">
                      <input
                        type="text"
                        name="lastName"
                        value={adultPassangerData.lastName}
                        onChange={handleChange}
                        placeholder="LastName"
                        className="title-lastname"
                      />
                    </div>
                  </div>
                </div>

                <div className="title-dropdown-container title___">
                  <p className="line-p">
                    First Name<sup className="red-span">*</sup>
                  </p>

                  <div className="intl-name-container">
                    <input
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      value={adultPassangerData.firstName}
                      // onChange={handleChange}
                      placeholder="First Name"
                      className="title-lastname title-lastname_"
                    />
                  </div>
                </div>
              </div>

              <div className="section-2-detail-container">
                <div className="mid-name">
                  <p className="line-p">
                    Middle Name<sup className="red-span">*</sup>
                  </p>

                  <div className="intl-name-container_1">
                    <input
                      type="text"
                      name="middleName"
                      onChange={handleChange}
                      value={adultPassangerData.middleName}
                      // onChange={handleChange}
                      placeholder="Middle Name"
                      className="title-lastname__ "
                    />
                  </div>
                </div>

                <div className="right-nationality-container">
                  <div className="right-nationality-container-inner">
                    <p className="line-p">
                      Nationality<sup className="red-span">*</sup>
                    </p>
                    <div className="intl-name-container_1 intl-name-container_1-adjust">
                      <Select
                        options={countries}
                        labelField="name"
                        valueField="id"
                        placeholder="Nationality"
                        onChange={values => setCountry(values)}
                        searchable={true}
                        searchBy="name"
                        className="nationality-container"
                      />
                    </div>
                  </div>

                  <div className="gender-input-adjust">
                    <p className="line-p">
                      Gender<sup className="red-span">*</sup>
                    </p>
                    <div className="intl-name-container_1">
                      <Select
                        options={gender}
                        labelField="gender"
                        valueField="id"
                        placeholder="Gender"
                        onChange={value => setPassangerGender(value)}
                        searchable={true}
                        searchBy="gender"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="checkout-dob">
                <div>
                  <p className="line-p">
                    Date of birth <span className="red-span">*</span>
                  </p>
                </div>
                <div className="checkout-dob-inner">
                  <div className="title-select">
                    <Select
                      options={yearArray}
                      labelField="year"
                      valueField="id"
                      placeholder="Year"
                      onChange={value => setPassangerDOBYear(value)}
                      searchable={true}
                      searchBy="year"
                      className="year-day"
                    />
                  </div>
                  <div className="title-select ts_adjust">
                    <Select
                      options={months}
                      labelField="name"
                      valueField="id"
                      placeholder="Month"
                      onChange={value => setPassangerDOBMonth(value)}
                      searchable={true}
                      searchBy="name"
                    />
                  </div>
                  <div className="title-select">
                    <Select
                      options={dayArray}
                      labelField="day"
                      valueField="id"
                      placeholder="Day"
                      onChange={values => setPassangerDOBDay(values)}
                      searchable={true}
                      searchBy="day"
                      className="year-day"
                    />
                  </div>
                </div>
              </div>

              <div className="passenger-details-parag">Passport Details</div>

              <div className="flight-detail-instruction_">
                <div className="rev-fac-outter">
                  <img
                    src={redRevFactorial}
                    alt="reverse factorial"
                    className="rev-fac"
                  />
                </div>
                <div className="instruction-parag-outter">
                  <p className="instruction-parag">
                    All details provided by you must be accurate, true and
                    non-misleading. In the event you provide the wrong
                    information you shall be held liable for such misconduct.
                  </p>
                </div>
              </div>
              <div className="passport-details">
                <div className="passport-details-inner">
                  <p className="line-p">
                    Passport Number <span className="red-span">*</span>
                  </p>
                  <input
                    type="text"
                    name="passportNumber"
                    onChange={handleChange}
                    value={adultPassangerData.passportNumber}
                    // onChange={handleChange}
                    className="passport-details-input"
                    placeholder="Passport Number"
                  />
                </div>
                <div className="passport-details-inner">
                  <p className="line-p">
                    Passport Expiry Date<span className="red-span">*</span>
                  </p>
                  <input
                    type="text"
                    name="passportExpDate"
                    value={adultPassangerData.passportExpDate}
                    onChange={handleChange}
                    // onChange={handleChange}
                    className="passport-details-input"
                    placeholder="Passport Expiry Date"
                  />
                </div>
                <div className="passport-details-inner">
                  <p className="line-p">
                    Passport Issuing Authority
                    <span className="red-span">*</span>
                  </p>
                  <input
                    type="text"
                    name="passportIssuing"
                    value={adultPassangerData.passportIssuing}
                    onChange={handleChange}
                    // value={adultPassangerData.passportIssuing}
                    // onChange={handleChange}
                    className="passport-details-input"
                    placeholder="Passport Issuing A..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="detail-section">
            {[...Array(flightPassangerProp[0].num - 1)].map((e, i) => (
              <FlightCheckoutForm
                title={title}
                countries={countries}
                // setCountry={setCountry}
                redRevFactorial={redRevFactorial}
                key={i}
                index={i}
                setAdultDataArray={setAdultDataArray}
                adultDataArray={adultDataArray}
              />
            ))}

            {[...Array(flightPassangerProp[1].num)].map((e, i) => (
              <ChildrenFCF
                title={title}
                countries={countries}
                // setCountry={setCountry}
                redRevFactorial={redRevFactorial}
                key={i}
                index={i}
                flightPassangerProp={flightPassangerProp}
                setAdultDataArray={setAdultDataArray}
                adultDataArray={adultDataArray}
              />
            ))}

            {[...Array(flightPassangerProp[2].num)].map((e, i) => (
              <ToddlerFCF
                title={title}
                countries={countries}
                // setCountry={setCountry}
                redRevFactorial={redRevFactorial}
                key={i}
                index={i}
                flightPassangerProp={flightPassangerProp}
                setAdultDataArray={setAdultDataArray}
                adultDataArray={adultDataArray}
              />
            ))}

            <div className="booking-recipient">
              <p className="booking-parag-1">Booking Recipient</p>
              <p className="booking-parag-2">
                Please enter email address of the booking details and
                confirmation recipient
              </p>
              <div className="booking-recipient-inner">
                <div className="booking-recipient-input">
                  <p className="line-p">Email Address</p>
                  <input type="text" className="passport-details-input_" />
                </div>
                <div className="booking-recipient-input">
                  <p className="line-p">Phone Number</p>
                  <input type="text" className="passport-details-input_" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="flight-order-btn" onClick={handleFlightOrder}>
          Process Order
        </button>
      </div>
      <div className="flight-checkout-page-r">
        <div className="flight-detail-suammary">
          <div className="flight-detail-suammary-inner-1">
            <p className="flight-detail-suammary-p1">Summary</p>
            <p className="flight-detail-suammary-p2">2 items</p>
          </div>
          <div className="flight-detail-suammary-inner-2">
            <div className="adult-travelling-num">
              <p className="flight-detail-suammary-p3">2 x Adult</p>
              <p className="flight-detail-suammary-p3">4,800 FirstCoins</p>
            </div>
            <div className="children-travelling-num">
              <p className="flight-detail-suammary-p3">2 x Children</p>
              <p className="flight-detail-suammary-p3">2 x Children</p>
            </div>
          </div>
          <div className="flight-detail-suammary-inner-3">
            <p className="flight-detail-suammary-p4">Total</p>
            <p className="flight-detail-suammary-p5">2890 FirstCoins</p>
          </div>
        </div>

        <button className="flight-order-btn-mobile" onClick={handleFlightOrder}>
          Process Order
        </button>
        <div className="flight-detail-suammary-inner-4">
          <p className="flight-detail-suammary-p6">
            By placing an order, you agree to (provider)’s Terms and Privacy
            policy
          </p>
          <p className="flight-detail-suammary-p6">
            Information and update on this order will be sent to your FirstBank
            registered email address
          </p>
        </div>
        <div className="checkout-flight-banner">
          {/* <p>Ad Banner</p> */}
          <AdBanner />
        </div>
      </div>
      {orderModel && <FlightOrderModal />}
    </div>
  );
};

export default FlightCheckout;
