import React from 'react';

function Navbar({activePage, setActivePage}) {
  return (
    <nav className="w-full">
      <div className="px-3">
        <ul className="flex text-delivery gap-4 capitalize justify-center py-3">
          <li
            onClick={() => setActivePage('all')}
            className={
              activePage === 'all'
                ? 'text-blue-700 cursor-pointer'
                : 'cursor-pointer'
            }>
            all
          </li>
          <li
            onClick={() => setActivePage('pickup-and-delivery')}
            className={
              activePage === 'pickup-and-delivery'
                ? 'text-blue-700 cursor-pointer'
                : 'cursor-pointer'
            }>
            pickup &amp; delivery
          </li>
          <li
            onClick={() => setActivePage('pickup')}
            className={
              activePage === 'pickup'
                ? 'text-blue-700 cursor-pointer'
                : 'cursor-pointer'
            }>
            pickup
          </li>
          <li
            onClick={() => setActivePage('delivery')}
            className={
              activePage === 'delivery'
                ? 'text-blue-700 cursor-pointer'
                : 'cursor-pointer'
            }>
            delivery
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
