import React, {useState, useEffect} from 'react';
import {ArrivalItems} from '../../CardItems/CardItem';
import {endpoints} from '../../../../utils/constant';
import axios from 'axios';
import {Rings} from 'react-loader-spinner';
import {useDispatch, useSelector} from 'react-redux';
import {loadSpinner} from '../../../../redux-toolkit/features/Offline/OfflineSlice';
import {doGet} from '../../../../config/helper';

function ArrivalList() {
  const [arrivalItems, setArrivalItems] = useState([]);
  const {isOpen} = useSelector(store => store.offline);
  const dispatch = useDispatch();

  useEffect(() => {
    doGet(setArrivalItems, 'api/product_of_the_week');
    dispatch(loadSpinner());
  }, []);

  if (
    arrivalItems?.data?.length === 0 ||
    arrivalItems?.data?.length === undefined
  ) {
    return (
      <div className="flex py-[200px] pl-[10px] justify-center">
        <Rings
          visible={true}
          height="150"
          width="150"
          color="#003B65"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }
  return (
    <div className="sm:px-[70px]">
      {arrivalItems?.data?.length !== 0 && (
        <div className="flex overflow-x-scroll scrollbar-hide px-3 mx-auto gap-4 sm:overflow-hidden sm:flex-wrap">
          {arrivalItems?.data?.map((item, index) =>
            index <= 3 ? <ArrivalItems key={index} card={item} /> : '',
          )}
        </div>
      )}
    </div>
  );
}

export default ArrivalList;
