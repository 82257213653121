import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Categories from "../../components/Categories/Categories";
import spur from "../../assets/images/spur.png";
import calendar from "../../assets/svg/calendar-clock.svg";
import minus from "../../assets/svg/minus_meals.svg";
import plus from "../../assets/svg/plus_meals.svg";
import "./Restaurant.css";
import axios from "axios";
import { endpoints } from "../../utils/constant";
import {
  doNonJsonPost,
  doPost,
  formatValue,
  getData,
  myStore,
  removeData,
  storeData,
  storeMealsData,
} from "../../config/helper";
// import Toastr from "../../components/Toastr/Toastr";
import { toast } from "react-toastify";
import { Toastr } from "../../components";
import ApiConfig from "../../service/ApiConfig";

const RestaurantMenu = () => {
  const [label, setLabel] = useState("Add To Cart");
  const [pointer, setPointer] = useState("pointer");
  const [dynamicStyle, setDynamicStyle] = useState({
    backgroundColor: "#ffc107",
    color: "#000",
    borderColor: "#000",
  });
  const [response, setResponse] = useState(null);
  const [leftPanel, setLeftPanel] = useState(null);
  const [mealMenu, setMealMenu] = useState(null);
  const [mealMenuMessage, setMealMenuMessage] = useState(null);
  const { Partner_id, Branch_id } = useParams();
  const [cat_id, setCat_id] = useState(0);
  const [mealValue, setMealValue] = useState(0);
  const [filteredMealState, setFilteredMealState] = useState({});
  const [partnerbranch, setPartnerBranch] = useState("loading Branch");
  const [mealsCount, setMealsCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [mealCart, setMealCart] = useState([]);
  const branch_name = getData("meals-branches");
  var locationUrl = window.location.href.split("/")[5];
  //console.log(branch_name?.data);
  var bnp = branch_name?.data.find((bn) => {
    return parseInt(bn.Branch_id) === parseInt(locationUrl);
  });

  const Add = (mealCart) => {
    storeData("meals-count", mealCart?.length);
    setLabel("Adding To Cart");
    setPointer("none");
    setMealsCount(mealCart?.length);
    const membership_id = getData(myStore)?.data?.customer?.Membership_id;

    let mealsdata;
    mealsdata = mealCart?.map((filtered) => {
      if (filtered.menuKey > 0) {
        return {
          redemption_method: 1,
          branch: bnp?.Branch_code,
          item_code: filtered?.Company_merchandize_item_code,
          membership_id,
          item_price: filtered?.Billing_price_in_points,
          item_quantity: filtered?.menuKey,
          type: 1,
          checkbranch: 0,
        };
      }
      // return null;
    });

    if (mealsdata) {
      mealsdata = mealsdata.filter(
        (data) => data !== null || data !== undefined
      );
      console.log("mealsdata", mealsdata);

      mealsdata = JSON.stringify(mealsdata);
      let data = {
        mealsdata,
        membership_id,
      };

      axios
        .post(`${ApiConfig.rootAPI}/api/cart/add_meal_to_cart`, data)
        .then((res) => {
          setResponse(res.data?.message);
          console.log("res", res);
          toast(res.data?.message);
          console.log("response", response);
          sessionStorage.removeItem("my-meals");
          removeData("my-meals");
          setMealCart([]);
          setMealsCount(0);
          setTotalPrice(0);
          removeData("my-meals");
        })
        .catch((error) => {
          toast(error.response?.statusText);
        })
        .finally((e) => setLabel("Add To Cart"));
    }
  };

  const evaluateQuantity = (mealCategory) => {
    getData("my-meals")?.data?.map((dt) => {
      if (
        dt?.Company_merchandize_item_code ===
        mealCategory?.Company_merchandize_item_code
      ) {
        console.log("yes ...", dt, mealCategory);
        return dt.menuKey;
      } else {
        return mealCategory?.menuKey;
      }
    });
  };

  const setQuantity = (x) => {
    getData("my-meals")?.data.forEach((mymeal) => {
      return mymeal.Company_merchandize_item_code === x;
    });
  };

  useEffect(() => {
    if (response?.status === 1 || response?.status === 3) {
      setMealsCount(mealsCount + 1);
      if (getData("meals-count")?.data === mealsCount) {
        setLabel("Added To Cart");
        sessionStorage.removeItem("my-meals");
        sessionStorage.removeItem("meals-count");
        window.location = "/cart-items";
      }
    }
  }, [response?.status]);

  //};

  useEffect(() => {
    const fetchLeftPanel = async () => {
      try {
        const leftResponse = await axios(
          `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.mealMenu}partner_id=${Partner_id}&branch_id=${Branch_id}`
        );
        setLeftPanel(leftResponse.data?.categories);
        setCat_id(leftResponse.data?.categories[0]?.Merchandize_category_id);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLeftPanel();
  }, []);

  useEffect(() => {
    const menuCategory = async () => {
      try {
        const menuResponse = await axios(
          `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.menu_categories}${cat_id}`
        );
        if (menuResponse.data.data) {
          setMealMenu(menuResponse.data?.data);
          setMealMenuMessage(null);
        } else {
          setMealMenuMessage(menuResponse.data.message);
          // toast(menuResponse.data.message)
        }
      } catch (error) {
        console.log(error);
      }
    };
    menuCategory();
  }, [cat_id]);

  const addToMealCart = (meal) => {
    const existingItem = mealCart.filter(
      (cartItem) =>
        cartItem.Merchandize_item_name === meal.Merchandize_item_name
    );

    if (existingItem.length < 1) {
      meal.quantity = 1;
      meal.menuKey = 1;
      setMealCart((current) => [...mealCart, meal]);
    } else {
      const updatedItems = mealCart.map((item) => {
        if (
          item.Merchandize_item_name === meal.Merchandize_item_name &&
          item.menuKey < 5
        ) {
          meal.quantity = item.quantity;
          return {
            ...item,
            quantity: item.quantity + 1,
            menuKey: item.menuKey + 1,
          };
        }
        return item;
      });

      setMealCart(updatedItems);
    }
  };

  const removeFromMealCart = (meal) => {
    const updatedItems = mealCart.map((item) => {
      if (
        item.Merchandize_item_name === meal.Merchandize_item_name &&
        item.menuKey > 0
      ) {
        meal.quantity = item.quantity;
        return {
          ...item,
          quantity: item.quantity - 1,
          menuKey: item.menuKey - 1,
        };
      }
      return item;
    });
    // console.log('updatedItems', updatedItems)

    // const newItems = updatedItems.filter((me) => me.menuKey !== 0)
    // console.log('newItems', newItems)
    setMealCart(updatedItems);
  };

  const removeItem = (item) => {
    const updatedCartItems = mealCart.filter(
      (cartItem) =>
        cartItem.Merchandize_item_name !== item.Merchandize_item_name
    );
    setMealCart(updatedCartItems);
  };

  useEffect(() => {
    if (!Array.isArray(mealCart) || mealCart.length === 0) {
      setTotalPrice(0);
    } else {
      const totalAmount = mealCart.reduce((accumulator, meal) => {
        // Check if menuKey and Billing_price_in_points are valid numbers
        const menuKey = Number(meal?.menuKey);
        const billingPrice = Number(meal?.Billing_price_in_points);

        if (!isNaN(menuKey) && !isNaN(billingPrice)) {
          return accumulator + menuKey * billingPrice;
        } else {
          return accumulator;
        }
      }, 0);

      setTotalPrice(totalAmount);
    }
  }, [mealCart]);

  const filteredMeal = mealMenu?.filter((meal) => meal.menuKey > 0);
  storeMealsData("my-meals", mealCart ? mealCart : []);

  return (
    <div className="bg-[#f8f8f8]">
      <Categories />
      <div className="bg-[url('./assets/images/meal.png')] h-[266px] flex items-center my-auto bg-no-repeat bg-cover sm:bg-cover sm:pt-8 sm:pb-32">
        <h2 className="flex font-bold w-fit mx-auto text-5xl lg:text-7xl leading-loyalty -tracking-wide text-white">
          {getData("label")?.data?.Partner_name}
        </h2>
      </div>
      <div className=" flex-col lg:px-[100px] ">
        <div className="hidden lg:flex gap-9  pt-12 pb-4">
          <h6>{getData("label")?.data?.Partner_name}</h6>
          <h6>{getData("label")?.data?.Partner_name}</h6>
          <h6>{getData("label")?.data?.Partner_name}</h6>
        </div>

        <div className=" space-y-4 md:flex justify-between bg-white p-6">
          <div className="flex gap-7 items-center">
            <img className="w-[119px] h-[105px]" src={spur} alt="spur" />
            <h2 className="capitalize font-bold text-xl leading-[140.62%]">
              {getData("label")?.data?.Partner_name}
            </h2>
          </div>
          <div className="flex gap-7 items-center">
            <img className="w-[45px] h-[45px]" src={calendar} alt="calendar" />
            <span className="space-y-1">
              <p className="font-semibold text-base leading-[140.62%]">
                Weekends 11:00 am - 9:00 pm
              </p>
              <p className="font-semibold text-base leading-[140.62%]">
                Weekends 11:00 am - 9:00 pm
              </p>
            </span>
          </div>
        </div>

        {/* left panel */}
        <div className="flex lg:hidden">
          <select
            className="w-full mx-3 my-3 p-2 rounded border-black border-2 border-solid"
            name=""
          >
            {leftPanel?.map((left, i) => (
              <option key={left?.Merchandize_category_id}>
                {left?.Merchandize_category_name}
              </option>
            ))}
          </select>
        </div>

        <div className="py-20">
          <h6 className="capitalize flex mx-auto justify-center font-bold text-4xl leading-loyalty tracking-tight">
            menu
          </h6>

          <div className="lg:flex space-y-8 gap-6">
            <div className="hidden lg:flex flex-col w-1/4 h-fit bg-white py-7 mt-8 pl-6">
              <ul className="space-y-7 pl-0 w-fit">
                {leftPanel?.map((left) => (
                  <div key={left?.Merchandize_category_id}>
                    <li
                      className={`cursor-pointer ${
                        cat_id === left?.Merchandize_category_id
                          ? "py-2 pl-2 pr-5 font-bold border-2 border-[#003B65] rounded w-full bg-[#CFF7FF] text-[#003B65]"
                          : ""
                      }`}
                      onClick={() => {
                        setCat_id(left?.Merchandize_category_id);
                      }}
                    >
                      {left?.Merchandize_category_name}
                    </li>
                  </div>
                ))}
              </ul>
            </div>

            <div className="lg:w-1/2 space-y-4 h-fit">
              <h2 className="font-bold text-xl leading-loyalty bg-[#f2f2f2] p-6">
                {leftPanel?.map((left) => (
                  <div key={left?.Merchandize_category_id}>
                    {left?.Merchandize_category_id === cat_id
                      ? left?.Merchandize_category_name
                      : null}
                  </div>
                ))}
              </h2>

              {mealMenuMessage === null ? (
                mealMenu?.map((mealCategory, index) => (
                  <>
                    <div
                      key={mealCategory?.Company_merchandize_item_code}
                      className="bg-white flex gap-3 lg:gap-4 px-6 mx-2 lg:mx-0 py-2 lg:py-4"
                    >
                      <div className="h-fit">
                        <img
                          className="w-[81px] h-[78px] object-contain object-center lg:w-[108px] lg:h-[104px]"
                          src={mealCategory?.Item_image}
                          alt={mealCategory?.Merchandize_item_name}
                        />
                      </div>
                      <div className="w-full space-y-6 lg:space-y-12">
                        <div>
                          <p className="font-medium text-base leading-loyalty tracking-loyal">
                            {mealCategory?.Merchandize_item_name}
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="font-bold text-base leading-loyalty text-button tracking-loyal">
                              {formatValue(
                                mealCategory?.Billing_price_in_points
                              )}{" "}
                              FirstCoin
                            </p>
                          </div>

                          <div className="flex gap-2 lg:gap-4">
                            <img
                              className="cursor-pointer w-6 lg:w-8"
                              src={minus}
                              alt="minus"
                              onClick={() => removeFromMealCart(mealCategory)}
                            />
                            <span>
                              {() => {
                                evaluateQuantity(mealCategory);
                              }}
                            </span>
                            <small>
                              {
                                setQuantity(
                                  mealCategory?.Company_merchandize_item_code
                                )?.menuKey
                              }
                            </small>
                            <img
                              onClick={() => addToMealCart(mealCategory)}
                              className="cursor-pointer w-6 lg:w-8"
                              src={plus}
                              alt="plus"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div>
                  <h6> {mealMenuMessage} </h6>
                </div>
              )}
            </div>

            <div className="mx-2 lg:mx-0 lg:w-1/4 h-fit bg-white p-6">
              <h4 className="pb-8 border-b-2 border-solid ">Summary</h4>
              {filteredMeal?.length === 0 ? (
                <h6>No meals Selected</h6>
              ) : (
                <>
                  {mealCart?.map(
                    (meal) =>
                      meal.menuKey > 0 && (
                        <span
                          className="flex border-b-2 py-6 border-solid justify-between"
                          key={meal.id}
                        >
                          <p className="font-normal text-base leading-loyalty">
                            {meal?.menuKey} x {meal?.Merchandize_item_name}
                          </p>
                          <p className="font-normal text-base leading-loyalty">
                            {formatValue(
                              Number(meal?.menuKey) *
                                Number(meal?.Billing_price_in_points)
                            )}{" "}
                            FirstCoin
                          </p>
                        </span>
                        // Update the total price using the setTotalPrice function
                        // setTotalPrice(prevTotalPrice => prevTotalPrice + (filtered?.menuKey * filtered?.Billing_price_in_points))
                      )
                  )}
                </>
              )}

              {mealCart.length > 0 && (
                <span className="flex py-6 border-b-2 border-solid justify-between">
                  <p className="font-bold text-base leading-loyalty">Total</p>
                  <p className="font-bold text-lg leading-loyalty text-search">
                    {formatValue(totalPrice)} FirstCoins
                  </p>
                </span>
              )}

              <div className="py-6 space-y-2">
                <p>Pickup Location</p>
                <p>{bnp?.Branch_name}</p>
              </div>
              <div>
                <button
                  disabled={mealCart.length === 0}
                  className="bg-add border-2  w-full font-bold text-base leading-loyalty border-black py-2 px rounded border-solid"
                  style={{ cursor: pointer }}
                  onClick={() => Add(mealCart)}
                >
                  {/* <Link to={`/restaurant-checkout`} className="text-black"> */}
                  {label}
                  {/* </Link> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toastr seconds={2000} />
    </div>
  );
};

export default RestaurantMenu;
