import React, {useState, useEffect} from 'react';
import {ShopCategoryItems} from '../../../components/Cards/CardItems/CardItem';
import dress from '../../../assets/images/dress-up.png';
import price_up from '../../../assets/svg/price_up.svg';
import price_down from '../../../assets/svg/price_down.svg';
import axios from 'axios';
import {endpoints} from '../../../utils/constant';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadSpinner} from '../../../redux-toolkit/features/Offline/OfflineSlice';
import {Dna} from 'react-loader-spinner';
// import {doGet, getData} from '../../../config/helper';
import {useQuery} from 'react-query';
import {TailwindSpinner} from '../../../components/Spinner/TailwindSpinner';

export const SingleCategoryList = ({activePage}) => {
  const url = window.location.href;

  const {isOpen} = useSelector(store => store.offline);
  const dispatch = useDispatch();

  const [catalogueItems, setCatalogueItems] = useState([]);
  const {Merchandize_category_id} = useParams();
  const [activePopularity, setActivePopularity] = useState(true);
  const [activeAscending, setActiveAscending] = useState(false);
  const [activeDesecending, setActiveDescending] = useState(false);
  const [singleCategoryName, setSingleCategoryName] = useState('Loading...');
  const [queryType, setQueryType] = useState(3);

  const [page, setPage] = useState(
    `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.catalogues}${Merchandize_category_id}?page=1`,
  );

  const handlePopularity = () => {
    setActiveDescending(false);
    setActiveAscending(false);
    setActivePopularity(true);
  };

  const handleAscendingCategory = () => {
    const categoryAscend = [...shopData].sort((a, b) => a.Price - b.Price);
    setShopData(categoryAscend);
    setActiveAscending(true);
    setActiveDescending(false);
    setActivePopularity(false);
  };

  const handleDescendingCategory = () => {
    const categoryDescend = [...shopData].sort((a, b) => b.Price - a.Price);
    setShopData(categoryDescend);
    setActiveDescending(true);
    setActiveAscending(false);
    setActivePopularity(false);
  };

  const fetchCatalogues = async () => {
    try {
      const listResponse = await axios.get(page, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        params: {
          type: queryType,
        },
      });
      setCatalogueItems(listResponse?.data?.items);
      setSingleCategoryName(listResponse);
      

      dispatch(loadSpinner());
      return listResponse?.data?.items;
    } catch (error) {
      console.log(error);
    }
  };

  const [shopData, setShopData] = useState(catalogueItems);

  const {data, isLoading, isError, refetch} = useQuery(
    ['singleItemsPage', page, Merchandize_category_id, queryType],
    fetchCatalogues,

    {
      // staleTime: 300000,
      onSuccess: newData => {
        if (newData && newData && Array.isArray(newData)) {
          setShopData(prevItems => [...prevItems, ...newData]);
        } else {
          console.error('Unexpected newData structure:', newData);
        }
      },
    },
  );

  useEffect(() => {
    setPage(
      `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.catalogues}${Merchandize_category_id}?page=1`,
    );
  }, [url, Merchandize_category_id]);

  useEffect(() => {
    refetch();
    setShopData([]);
    setSingleCategoryName('Loading...');
    setPage(
      `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.catalogues}${Merchandize_category_id}?page=1`,
    );
  }, [Merchandize_category_id, url, queryType]);

  useEffect(() => {
    if (activePage === 'pickup-and-delivery') {
      setQueryType(3);
    } else if (activePage === 'pickup') {
      setQueryType(1);
    } else if (activePage === 'delivery') {
      setQueryType(2);
    } else {
      setQueryType('');
    }
  }, [activePage]);

  const handlePageNumber = () => {
    setPage(singleCategoryName?.data?.next_page);
  };

  return (
    <div className="">
      <div className="bg-[url('./assets/images/order_hero.png')] bg-no-repeat py-20 bg-cover">
        <h2
          className="flex font-extrabold text-4xl leading-loyalty tracking-tighter text-button justify-center"
          style={{textT: 'capitalise'}}>
          {isLoading
            ? 'Loading...'
            : singleCategoryName?.data?.category[0]?.Merchandize_category_name}
        </h2>
        <p className="flex justify-center pt-3 font-medium text-base leading-shop text-pick tracking-loyal mx-[27px] text-center sm:mx-[440px]"></p>
      </div>
      <div className="">
        {shopData.length != 0 && (
          <div className="md:flex justify-end gap-6 my-4 px-2 items-center">
            <p className="capitalize my-2 font-medium text-base leading-loyalty tracking-tight text-list">
              sort:
            </p>
            <div className="flex mr-[10%] w-fit gap-4 items-center">
              {/* <button
            onClick={handlePopularity}
            className={` ${
              activePopularity ? "bg-button text-white" : "bg-white text-black"
            } flex font-bold text-base leading-loyalty tracking-tight capitalize items-center rounded border-solid border-sub border px-2 py-2.5`}
          >
            popularity
          </button> */}

              <button
                onClick={handleAscendingCategory}
                className={`${
                  activeAscending
                    ? 'bg-button text-white'
                    : 'bg-white text-black'
                } flex gap-2 font-bold text-base leading-loyalty tracking-tight capitalize items-center rounded border-solid border-sub border px-2 py-2.5`}>
                price: lowest to highest <img src={price_up} alt="price up" />
              </button>

              <button
                onClick={handleDescendingCategory}
                className={`${
                  activeDesecending
                    ? 'bg-button text-white'
                    : 'bg-white text-black'
                } flex gap-2 font-bold text-base leading-loyalty tracking-tight capitalize items-center rounded border-solid border-sub border px-2 py-2.5`}>
                price: highest to lowest
                <img src={price_down} alt="pricedown" />
              </button>
            </div>
          </div>
        )}

        <div className="flex flex-wrap w-full px-[10%] justify-between">
          {[...new Set([...shopData])]?.map((item, index) => (
            <ShopCategoryItems key={index} card={item} />
          ))}
        </div>

        {['all', 'pickup', 'delivery', 'pickup-and-delivery'].includes(
          activePage,
        ) &&
          shopData.length === 0 && (
            <div className="flex items-center bg-transparent justify-center h-[500px] capitalize text-black">
              {isLoading ? (
                <TailwindSpinner />
              ) : (
                <h3>No {activePage} items!</h3>
              )}
            </div>
          )}
        {singleCategoryName?.data?.next_page === null ||
        shopData.length === 0 ? (
          ''
        ) : (
          <div
            onClick={handlePageNumber}
            className="mx-auto mt-14 mb-28 w-fit py-3 px-16 text-base leading-loyalty font-bold rounded border-solid border-2 border-list capitalize cursor-pointer">
            {isLoading ? <TailwindSpinner /> : 'load more'}
          </div>
        )}
        <img className="w-full mt-4 mb-4" src={dress} alt="your advert here" />
      </div>
    </div>
  );
};
