import React, {useEffect} from 'react';

const ReturnPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="px-4 lg:px-[100px]">
      <div className="pt-12 pb-16">
        <h3 className="flex text-center pb-6 justify-center font-bold text-3xl">
          First Rewards Returns Policy
        </h3>
        <p className="font-normal text-base leading-product tracking-loyal">
          All order(s) are processed and shipped within fifteen (15) working
          days to the indicated address. Orders are not shipped or delivered on
          weekends and public holidays. Kindly note that shipping cost is
          calculated on a per-product basis, and this is based on the distance
          and the weight of the item(s)
        </p>
      </div>
      <div className="pb-16">
        <h3 className="flex justify-center pb-6 font-bold text-3xl">
          Warranty Policy
        </h3>
        <p className="font-normal text-base leading-product tracking-loyal">
          Products with a warranty are limited to either the repair or
          replacement of the defective product during its warranty period. The
          warranty does not cover:
        </p>
        <ol className="list-decimal">
          <li>
            Products found to be defective after the warranty period has
            expired.
          </li>
          <li>
            Products subjected to misuse or abuse, whether by accident or other
            causes. Such product conditions will be determined by Loyalty
            Solutions at its sole and unfettered discretion.
          </li>
          <li>
            Products damaged due to a natural disaster, including but not
            limited to lightning, flooding, earthquake, or fire.
          </li>
          <li>
            Products modified in any way NOT described explicitly in the
            corresponding product's user manuals. (NOTE: The product's user
            manuals provide instructions for installing, configuring, and
            maintaining your Moxa product. Please refer to the user manuals
            before installing or configuring the product.)
          </li>
        </ol>
      </div>
      <div className="pb-16">
        <h3 className="flex justify-center pb-6 font-bold text-3xl">
          Returns Policy
        </h3>
        <p className="font-normal text-base leading-product tracking-loyal">
          Thank you for your redemption. In other to be eligible for a refund,
          the Vendor ( also referred to as “We” in this context) must receive
          your request within 7 days of receiving your item. We’ll only accept
          return requests for items that are unopened, unused, and with the
          original tags still intact. The product(s) must be in its original
          packaging, if applicable, in the same condition it was received. We do
          not accept returns for perishable items, intimate items, gift cards,
          and beauty items. <br />
          <br /> Exchanges- In the unlikely event that you receive a damaged or
          defective item, the Partner Merchant will exchange the item for a new
          one. Please send an email to order@rewardsboxng.com containing the
          complaints about the item delivered and reasons for return/exchange
          and also attached the delivery invoice that came with it (as proof
          that it was delivered by us) <br />
          <br />
          Refunds- once we receive your returned item, our team will review your
          request and inspect the item. We will send you an email to confirm
          that we’ve received your item and are processing your request. We’ll
          follow up with another email once the request has been processed to
          let you know if your return and reason were approved. If approved, you
          will be contacted by our team to resolve the issue either by
          exchanging the item with the one that suits you or refund your loyalty
          point to your loyalty account within two weeks. Please note that this
          refund will be a bit lesser than the initial points deducted due to
          the shipping cost.
        </p>
      </div>
    </div>
  );
};

export default ReturnPolicy;
