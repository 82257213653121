import React from "react";
import { useNavigate } from "react-router-dom";
import "./PasswordResetModal.css";
import resetSuccessPic from "../../assets/images/password-reset.png";

const PasswordResetModal = ({ setShowModal }) => {
  const navigate = useNavigate();

  const handleContinue = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <>
      <div
        className="modal  password-reset-modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body_">
              <div className="password-reset-modal-inner-body">
                <img
                  src={resetSuccessPic}
                  alt="resetSuccessPic"
                  className="resetSuccessPic"
                />
                <p className="resetSuccessPic-text1">Password Reset!</p>
                <p className="resetSuccessPic-text2">
                  You have succesfully reset your password
                </p>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
              <button
                type="button"
                className="password-reset-modal_"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetModal;
