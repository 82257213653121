import HttpService from "../../service/HttpService"
import ApiConfig from "../../service/ApiConfig"

const params =   {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: process.env.REACT_APP_EVENT_KEY
    }
  }

export const getAllEventsList = async ()=>{
    try {
        return await HttpService.get(ApiConfig.events, params)
    } catch (error) {
        console.error(error)
    }
}







