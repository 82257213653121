import React, {useEffect, useState} from 'react';
import './NetworkStatusListener.css';

const NetworkStatusListener = () => {
  const [isOffline, setIsOffline] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [isWeakConnection, setIsWeakConnection] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
      setIsOnline(true);

      setTimeout(() => {
        setIsOnline(false);
      }, 3000);
    };

    const handleOffline = () => {
      setIsOffline(true);
    };

    if (!navigator.onLine) {
      setIsOffline(true);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  //   useEffect(() => {
  //     const connection =
  //       navigator.connection ||
  //       navigator.mozConnection ||
  //       navigator.webkitConnection;

  //     if (connection) {
  //       const effectiveType = connection.effectiveType;

  //       console.log('Effective Connection Type:', effectiveType);

  //       // Check if the user is online
  //       if (!navigator.onLine) {
  //         console.log(
  //           'User is offline. Insufficient data to access the internet.',
  //         );
  //       } else {
  //         // Log network strength based on effectiveType
  //         if (effectiveType === 'slow-2g' || effectiveType === '2g') {
  //           console.log('Weak Network');
  //         } else {
  //           console.log('Strong Network');
  //         }
  //       }

  //       console.log('Round-Trip Time:', connection.rtt);
  //     }
  //   }, []);

  return (
    <div>
      {isOffline && (
        <div className="offline-check">
          <p>Currently offline</p>
        </div>
      )}
      {isOnline && (
        <div className="online-check">
          <p>Online now</p>
        </div>
      )}
    </div>
  );
};

export default NetworkStatusListener;
