import { deleteAccessToken, myStore } from "../../../config/helper";


export default function Logout(){

    sessionStorage.removeItem(myStore);
    sessionStorage.removeItem("my-meals");
    deleteAccessToken();
    window.location = "./"
    // if (window.location.hostname === 'localhost'){
    //     window.location = "./"
    // }else{
    // window.location = "https://loyalty-fb.vercel.app/";   
    // }

}   