import React, {useRef, useState, useEffect} from 'react';
import pick_modal from '../../../assets/svg/pick_modal.svg';
import delivery_modal from '../../../assets/svg/delivery_modal.svg';
import AddToCart from '../../../components/buttons/AddToCart';
import SingleItemDescriptiveDetails from './SingleItemDescriptiveDetails';
import AdBanner from '../../../components/AdBanner/AdBanner';
import SingleItemImageModal from '../../../components/Modals/SingleItemImageModal';
import Categories from '../../../components/Categories/Categories';
import axios from 'axios';
import {endpoints} from '../../../utils/constant';
import {useParams} from 'react-router-dom';
import {formatValue} from '../../../config/helper';
import Select from 'react-dropdown-select';
import {useQuery} from 'react-query';
import {TailwindSpinner} from '../../../components/Spinner/TailwindSpinner';

const SingleItem = () => {
  const [showModal, setShowModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [singleItem, setSingleItem] = useState(null);

  const [pickState, setPickState] = useState(true);
  const [deliveryState, setDeliveryState] = useState(true);
  const [locationState, setLocationState] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [deliveryColor, setDeliveryColor] = useState(false);
  const [deliveryColorValue, setDeliveryColorValue] = useState(0);
  const {Company_merchandise_item_id} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pickupLocationValue, setPickLocationValue] = useState('');
  const [itemState, setItemState] = useState([]);
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(true);
  const [itemSize, setItemSize] = useState('');
  const [flag3Check, setFlag3Check] = useState(true);

  const safeDocument = typeof document !== 'undefined' ? document : {};
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const {body} = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;
    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue('padding-right'),
      ) || 0;

    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;
    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';
    scrollBlocked.current = false;
  };

  const addBranch = () => {
    const item = {
      ...singleItem[0],
      branch_code: document.querySelector('#branches').value,
      attributes: `${singleItem?.[0]?.Attributes?.attribute_name}-${itemSize}`,
      // item_quantity: quantity,
    };
    setPickLocationValue(document.querySelector('#branches').value);
    console.log(document.querySelector('#branches').value, 'addeess');
    setItemState(item);
  };

  const handleOnClose = () => {
    setShowModal(!showModal);
    allowScroll();
  };

  const handlePickState = () => {
    setPickState(pickState);
    setCheck1(false);
    setCheck2(true);
    setDeliveryState(!deliveryState);
    setLocationState(!locationState);
    setDeliveryColorValue(1);
    setDeliveryColor(!deliveryColor);
  };

  const handleDeliveryState = () => {
    setPickState(!pickState);
    setCheck2(false);
    setCheck1(true);
    setDeliveryState(deliveryState);
    setDeliveryColorValue(2);
    setDeliveryColor(!deliveryColor);
  };

  const fetchSingleItem = async () => {
    setIsLoading(true);
    try {
      const responseItem = await axios(
        `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.singleItem}${Company_merchandise_item_id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      setSingleItem(responseItem?.data.data);
      setItemState(responseItem?.data.data?.[0]);
      setDeliveryMethod(responseItem?.data.data[0].Delivery_method);
      setIsLoading(false);
      return responseItem?.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setItemState({
      ...singleItem?.[0],
      Merchandize_item_code:
        singleItem?.[0]?.Merchandize_item_code || singleItem?.[0]?.Item_code,
      item_quantity: 1,
      Delivery_method: 2,
      attributes: `${singleItem?.[0]?.Attributes?.attribute_name}-${itemSize}`,
    });
  }, [singleItem]);

  useEffect(() => {
    fetchSingleItem();
  }, []);

  useEffect(() => {
    if (singleItem?.[0]?.Attributes?.variants?.length === 1) {
      setItemSize(singleItem?.[0]?.Attributes?.variants[0]);
    }

    if (singleItem?.[0]?.Attributes?.variants?.length >= 1 && itemSize != '') {
      setFlag3Check(false);
    } else if (singleItem?.[0]?.Attributes?.variants?.length === 0) {
      setFlag3Check(false);
    } else {
      setFlag3Check(true);
    }
  }, [singleItem]);

  // console.log(singleItem, 'single item');

  return (
    <>
      <Categories />
      <AdBanner />
      <div className="mt-4 px-4 sm:mx-[20px] lg:mx-[252px]">
        {isLoading ? (
          <div className="flex justify-center items-center h-[500px] ">
            <TailwindSpinner />
          </div>
        ) : (
          singleItem !== null && (
            <>
              <div className="space-y-5 min-h-screen : sm:flex gap-4 sm:mt-28 sm:mb-20">
                <div className="flex items-center w-full bg-[#f2f2f2] border border-solid rounded sm:w-[53%]">
                  <img
                    onClick={() => {
                      setShowModal(!showModal);
                      blockScroll();
                    }}
                    className="my-[25px] mx-auto img-zoom sm:my-40"
                    src={singleItem[0]?.Item_image}
                    alt={singleItem[0]?.Merchandize_item_name}
                  />
                </div>
                <div className="sm:w-[47%]">
                  <div className="">
                    <div className="flex gap-3">
                      <div className="space-y-3">
                        <h2 className="text-2xl leading-loyalty font-bold tracking-loyal">
                          {singleItem[0]?.Merchandize_item_name}
                        </h2>
                        <p className="capitalize font-bold text-button text-base leading-loyalty tracking-loyal">
                          {formatValue(singleItem[0]?.Billing_price_in_points)}{' '}
                          FirstCoins
                        </p>
                      </div>
                    </div>

                    {singleItem?.[0]?.Attributes?.variants?.length > 0 && (
                      <div className="py-3">
                        <p className="font-bold text-sm text-cart pb-3 leading-loyalty tracking-loyal">
                          {singleItem?.[0]?.Attributes?.variants?.length > 1
                            ? `Please select a ${singleItem?.[0]?.Attributes?.attribute_name}`
                            : 'Size'}
                        </p>
                        <div>
                          <div className="uppercase flex gap-2 font-medium leading-loyalty tracking-tighter text-[#6a6a6a]">
                            {singleItem?.[0]?.Attributes?.variants?.map(
                              each => (
                                <button
                                  onClick={() => setItemSize(each)}
                                  style={{
                                    backgroundColor:
                                      itemSize === each ? '#ccc' : '#fff',
                                  }}
                                  className="text-[#6a6a6a] cursor-pointer p-3 rounded border border-solid"
                                  key={each}>
                                  {each}
                                </button>
                              ),
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex flex-col py-3">
                      <div>
                        <p className="font-bold text-sm text-cart pb-3 leading-loyalty tracking-loyal">
                          Select a delivery method
                        </p>
                      </div>

                      <div className="flex  gap-3">
                        {deliveryMethod === '1' ? (
                          <div onClick={handlePickState} className="space-y-8">
                            <div
                              className={` ${
                                deliveryColor && deliveryColorValue === 1
                                  ? 'rounded border-2 border-solid'
                                  : 'rounded-none'
                              } w-1/2 cursor-pointer bg-[#f5f5f5]  p-6`}>
                              <p className="flex gap-3 font-bold text-base leading-loyalty tracking-loyal capitalize">
                                <img src={pick_modal} alt="pick" /> pickup
                              </p>
                              <p className="text-[#313131] py-2 font-medium text-base leading-loyalty tracking-loyal">
                                Items should be picked up within 12 days after
                                ordering
                              </p>
                            </div>
                            <div>
                              <select
                                className="px-2 py-3 w-full border text-sm font-bold capitalize text-[#313131] border-solid  rounded-sm"
                                label="Select pickup location"
                                id="branches"
                                onChange={addBranch}>
                                <option className="capitalize">
                                  Select pickup location
                                </option>
                                {singleItem?.[0]?.branches?.map(
                                  (branch, index) => (
                                    <option
                                      key={`${branch?.Branch_name} + ${index}`}
                                      value={branch?.Branch_code}
                                      onChange={e =>
                                        console.log(e.target.value)
                                      }>
                                      {branch.Branch_name}
                                    </option>
                                  ),
                                )}
                              </select>
                            </div>
                          </div>
                        ) : null}

                        {deliveryMethod === '2' ? (
                          <div
                            onClick={handleDeliveryState}
                            className={` ${
                              deliveryColor
                                ? 'rounded border-2 border-solid '
                                : 'rounded-none'
                            } w-1/2 cursor-pointer bg-[#f5f5f5]  p-6`}>
                            <p className="flex gap-3 font-bold text-base leading-loyalty tracking-loyal capitalize">
                              <img src={delivery_modal} alt="delivery" />
                              Delivery
                            </p>
                            <p className="text-[#313131] py-2 font-medium text-base leading-loyalty tracking-loyal">
                              Items would be delivered within 15 days after
                              ordering
                            </p>
                          </div>
                        ) : null}

                        {deliveryMethod === '3' ? (
                          <div className="flex flex-col space-y-8">
                            <div className="flex gap-3">
                              {/* {pickState ? ( */}
                              <div
                                onClick={handlePickState}
                                className={` ${
                                  deliveryColorValue === 1
                                    ? 'rounded border-2 bg-[#cbe0f5]'
                                    : 'rounded-none'
                                } w-1/2 cursor-pointer bg-[#f5f5f5]  p-6`}>
                                <p className="flex gap-3 font-bold text-base leading-loyalty tracking-loyal capitalize">
                                  <img src={pick_modal} alt="pick" /> pickup
                                </p>
                                <p className="text-[#313131] py-2 font-medium text-base leading-loyalty tracking-loyal">
                                  Items should be picked up within 12 days after
                                  ordering
                                </p>
                              </div>
                              {/* ) : null} */}

                              {/* {deliveryState ? ( */}
                              <div
                                onClick={handleDeliveryState}
                                className={` ${
                                  deliveryColorValue === 2
                                    ? 'rounded border-2 border-solid bg-[#cbe0f5]'
                                    : 'rounded-none'
                                } w-1/2 cursor-pointer bg-[#f5f5f5]  p-6`}>
                                <p className="flex gap-3 font-bold text-base leading-loyalty tracking-loyal capitalize">
                                  <img src={delivery_modal} alt="pick" />
                                  Delivery
                                </p>
                                <p className="text-[#313131] py-2 font-medium text-base leading-loyalty tracking-loyal">
                                  Items would be delivered within 15 days after
                                  ordering
                                </p>
                              </div>
                              {/* ) : null} */}
                            </div>

                            {deliveryColorValue === 1 ? (
                              <div>
                                <div>
                                  <select
                                    className="px-2 py-3 w-full border text-sm font-bold capitalize text-[#313131] border-solid rounded-sm"
                                    label="Select pickup location"
                                    id="branches"
                                    onChange={addBranch}>
                                    <option className="capitalize">
                                      select pickup location
                                    </option>
                                    {singleItem?.[0]?.branches?.map(
                                      (branch, index) => (
                                        <option
                                          key={`${branch?.Branch_name} + ${index}`}
                                          value={branch?.Branch_code}
                                          onChange={e =>
                                            console.log(e.target.value)
                                          }>
                                          {branch.Branch_name}
                                        </option>
                                      ),
                                    )}
                                  </select>
                                </div>

                                {/* <option className="capitalize">Select pick up location</option>
                              {singleItem[0]?.branches.map((branch) => (
                                <option key={branch.Branch_id}>
                                  {branch.Branch_name}
                                </option>
                              ))} */}
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {console.log({
                      check1,
                      check2,
                      flag3Check,
                      pickupLocationValue,
                      itemState,
                      singleItem,
                    })}
                    <AddToCart
                      flag1={check1}
                      flag2={check2}
                      flag3={flag3Check}
                      pickupLocation={pickupLocationValue}
                      item={itemState}
                    />
                  </div>
                </div>
              </div>

              <div className="my-5 sm:my-0">
                <SingleItemDescriptiveDetails singleItem={itemState} />
              </div>
            </>
          )
        )}
      </div>
      {showModal ? (
        <SingleItemImageModal
          itemState={itemState}
          onClose={handleOnClose}
          visible={showModal}
        />
      ) : null}
    </>
  );
};

export default SingleItem;
