import Countdown from "react-countdown";
import './AuctionCountDown.css';

const splitTime = (value) => {
  const time = value.toString();
  return time.length > 1 ? [time[0], time[1]] : ["0", time];
};

const renderer = ({ days, hours, minutes, seconds }) => {
  const [daysUnit, daysTense] = splitTime(days);
  const [hoursUnit, hoursTense] = splitTime(hours);
  const [minutesUnit, minutesTense] = splitTime(minutes);
  const [secondsUnit, secondsTense] = splitTime(seconds);

  return (
    <div className="flex items-center space-x-1">
      {/* Days */}
      <div>
        <div className="flex space-x-1">
          <div className="countdownCardStyle">
            <span className="countdownTextStyle">{daysUnit}</span>
          </div>
          <div className="countdownCardStyle">
            <span className="countdownTextStyle">{daysTense}</span>
          </div>
        </div>
        <div className="text-center mt-1">DAYS</div>
      </div>
      <div className="countdownSeperator">:</div>

      {/* Hours */}
      <div>
        <div className="flex space-x-1">
          <div className="countdownCardStyle">
            <span className="countdownTextStyle">{hoursUnit}</span>
          </div>
          <div className="countdownCardStyle">
            <span className="countdownTextStyle">{hoursTense}</span>
          </div>
        </div>
        <div className="text-center mt-1">HOURS</div>
      </div>
      <div className="countdownSeperator">:</div>

      {/* Minutes */}
      <div>
        <div className="flex space-x-1">
          <div className="countdownCardStyle">
            <span className="countdownTextStyle">{minutesUnit}</span>
          </div>
          <div className="countdownCardStyle">
            <span className="countdownTextStyle">{minutesTense}</span>
          </div>
        </div>
        <div className="text-center mt-1">MINUTES</div>
      </div>
      <div className="countdownSeperator">:</div>

      {/* Seconds */}
      <div>
        <div className="flex space-x-1">
          <div className="countdownCardStyle">
            <span className="countdownTextStyle">{secondsUnit}</span>
          </div>
          <div className="countdownCardStyle">
            <span className="countdownTextStyle">{secondsTense}</span>
          </div>
        </div>
        <div className="text-center mt-1">SECONDS</div>
      </div>
    </div>
  );
};

const AuctionCountDown = ({ startDate, endDate }) => {
  // Validate props
  if (!startDate || !endDate) {
    console.error("Missing 'startDate' or 'endDate' prop in AuctionCountDown");
    return null;
  }

  // Parse the startDate and endDate
  const startTimestamp = new Date(startDate).getTime();
  const endTimestamp = new Date(endDate).getTime();

  if (isNaN(startTimestamp) || isNaN(endTimestamp)) {
    console.error("Invalid 'startDate' or 'endDate' format. Use valid date strings.");
    return null;
  }

  // Calculate time difference
  if (endTimestamp <= startTimestamp) {
    console.error("'endDate' must be later than 'startDate'");
    return null;
  }

  return (
    <div>
      <p className="countDownTimeLeft">Time left</p>
      <Countdown date={endTimestamp} renderer={renderer} />
    </div>
  );
};

export default AuctionCountDown;
