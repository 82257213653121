import React, {useEffect, useState} from 'react';
import EventSlider from '../../components/EventSlider/EventSlider';
import './Discount.css';
import DiscountCard from './DiscountCard';
// import discountImg from '../../assets/images/DiscountImg.png';
import {useQuery} from 'react-query';

import EventCard from '../../components/EventCard/EventCard';
import SingleEvent from '../../components/SingleEvent/SingleEvent';
import Categories from '../../components/Categories/Categories';
import AdBanner from '../../components/AdBanner/AdBanner';
import CurrentPageNav from '../../components/Navbar/CurrentPageNav';
import EventModal from '../../components/EventModal/EventModal';
import useLockScrolling from '../../components/lockScroll/lockScroll';
import {useDispatch, useSelector} from 'react-redux';
import {getAllEvents} from '../../redux-toolkit/features/Events/EventsSlice';
import DiscountModal from './DiscountModal';
import axios from 'axios';
import {LoadingIcon, LoadingSVG} from '../../assets/icons';
import discountSlide from '../../assets/images/Discounts-min.png'
import image1 from '../../assets/images/Zaron-min.jpg'
import image2 from '../../assets/images/FreshFlowers-min.jpg'
import image3 from '../../assets/images/SouthernOcean-min.jpg'
import { getAccessToken, logout } from '../../config/helper';
import { ToastContainer, toast } from "react-toastify";
const Discount = props => {
  
  //   const dispatch = useDispatch();
  const [discountData, setDiscountData] = useState();



  const fetchDiscounts = async()=>{
    try{
      const response = await axios.get(
        'https://demodiscounts.mypremiumincentives.com/api.php?api=get_discount_merchants',
        {
          headers: {
            Token: process.env.REACT_APP_DISCOUNT_KEY,
            Accept: 'application/json, text/plain, */*',
          },
        },
      );
      if(!getAccessToken()){
     
          toast.error("Authentication failed. Please log in again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          //import { ToastContainer, toast } from "react-toastify";
         // <ToastContainer />
          setTimeout(()=>{
  
            logout()
          }, 4000)
       
         }
      setDiscountData(response.data.data);
      return response;
    }catch(error){
      console.error(error);
    }
  }

  

  const {isLoading, error, data} = useQuery('discounts', fetchDiscounts);

  
  useEffect(() => {
    setDiscountData(data?.data.data);
  }, [data]);

 

  const [isOpen, setIsOpen] = useState(false);
  const [merchantId, setMerchantId] = useState();
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select District');
  const [percentageVal, setPercentageVal] = useState(null);

  useLockScrolling(showDiscountModal);
  // GETTING ALL EVENTS START

  // GETTING ALL EVENTS END.

  const handleShowSingleEvent = id => {
    // setShowSingleEvent(true);
    // const result = allEvents.filter((each) => each.id === id);
    // setPickedEvent(...result);
  };

  const options = ['Option 1', 'Option 2', 'Option 3'];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = option => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const {
    isLoading: isLoadingMerchant,
    error: merchantError,
    data: merchantData,
  } = useQuery(['merchant location', merchantId], async () =>
    axios
      .get(
        `https://demodiscounts.mypremiumincentives.com/api.php?api=get_merchants_locations&merchant_id=${merchantId}`,
        {
          headers: {
            Token: process.env.REACT_APP_DISCOUNT_KEY,
            Accept: 'application/json, text/plain, */*',
          },
        },
      )
      .then(res => res.data.data).catch(err => {
        console.log('err', err);
        if (err.response && err.response.status === 401) {
          logout();
        }
      })
      
  );



  const handleClick = (id, discount_percentage) => {
    setMerchantId(id);
    setShowDiscountModal(true);
    setPercentageVal(discount_percentage);
  };

 

  if (isLoading)
    return (
      <div className="loading-svg">
        <LoadingIcon />
      </div>
    );
  if (error) return <p>Error occured at: {error.message}</p>;

 

  return (
    <div className="event-main-container">
      <Categories />
      <AdBanner />
     
      <div className='discount-main-container'>
   
        <EventSlider sliderImage1={image1} sliderImage2={image2} sliderImage3={image3}  />
        <div className="events-container">
          <h1>Get Amazing Discounts</h1>
          <div className="event-cards-container">
          
            <div className="discount-card-container">
              {discountData?.map(each => {
                return (
                  <DiscountCard
                    each={each}
                    key={each.merchant_id}
                    setShowDiscountModal={setShowDiscountModal}
                    showDiscountModal={showDiscountModal}
                    handleClick={handleClick}
                    isLoadingMerchant={isLoadingMerchant}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {showDiscountModal && (
        <DiscountModal
          setShowDiscountModal={setShowDiscountModal}
          merchantData={merchantData}
          showDiscountModal={showDiscountModal}
          merchant_id={merchantId}
          percentageVal={percentageVal}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Discount;
