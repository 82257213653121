import React, { useEffect, useState } from "react";
import plus from "../../assets/svg/faqPlus.svg";
import minus from "../../assets/svg/faqMinus.svg";
import "./FAQ.css";

const faqs = [
  {
    id: 1,
    question: "What is the First Bank’s Rewards Program?",
    answer:
      "The FirstBank’s Rewards program is a customer retention strategy that encourages and rewards loyal customers to keep using the bank's services. Customers will earn points Known as FirstFirstCoins when they carry out eligible transactions with the bank’s products from cards, mobile apps, USSD, etc.",
  },
  {
    id: 2,
    question: "Who can participate in this program?",
    answer:
      "The Reward program is only available to all FirstBank new and existing customers.",
  },
  {
    id: 3,
    question:
      "Are there any hidden fees or charges attached to this Rewards Program?",
    answer: "No, the program is free and offered at no cost to customers.",
  },
  {
    id: 4,
    question:
      "If I have multiple accounts, will I also get rewards offers on the accounts?",
    answer:
      "Yes. If these accounts, products, and services are all linked to one unique customer.",
  },
  {
    id: 5,
    question: "Who can participate in this program?",
    answer:
      "The Reward program is only available to all FirstBank new and existing customers.",
  },
  {
    id: 6,
    question: "How can I earn Loyalty Points?",
    answer:
      "Loyalty points are earned when you carry out eligible transactions. These transactions will include: Successful activation of the mobile app, transfers from one account to non-FirstBank accounts, bill payments with the mobile app, USSD, cards, airtime purchases, loan repayments, and prepaid cards.",
  },
  {
    id: 7,
    question: "How will I know I’m making progress with building my point?",
    answer:
      "Each time you reach a particular threshold for the various campaign rules categories and also at the end of every month you will receive an email notifying you of the number of points you have earned.",
  },
  {
    id: 8,
    question: "Are there earning caps?",
    answer:
      "There is no limit to the number of points you can earn on eligible transactions, the more you transact the more you earn.",
  },
  {
    id: 9,
    question: "What can I redeem with my earned Points?",
    answer:
      "Reward Points can be redeemed by activating and logging in to the loyalty platform where you can access a lot of items ranging from Airtime & bills, Events, Movies, Vouchers and you can even convert point to cash.",
  },
  {
    id: 10,
    question: "Where can I view my accumulated Points balance?",
    answer:
      "Once a customer is onboarded on the loyalty program, he automatically gets a rewards wallet that can be accessed by logging into the customer portal using the member ID and password. Every loyalty point earned by carrying out eligible transactions will be stored in the reward wallet.",
  },
  {
    id: 11,
    question:
      "If I don’t have enough Points for the award I want, can I buy the extra Points I need?",
    answer:
      "No. A sufficient number of Points must be available in your account to redeem the award you want. Points are not available for purchase.",
  },
  {
    id: 12,
    question:
      "How do I receive the item selected from the FirstBank Rewards Portal?",
    answer:
      "Items can either be picked up from your preferred merchant location using an e-voucher which has a validity of 5 days or gets the delivery of your items to the provided location at redemption which takes 15 working days.",
  },
  {
    id: 13,
    question: "Can I change the username and password given to me?",
    answer:
      "Usernames are unique and cannot be changed, but you can change your password. It is advised that you change your password as soon as you log into the customer portal. Please note that you should never disclose your username and password to anyone.",
  },
  {
    id: 14,
    question: "What do I do if I forget my password?",
    answer:
      "If you forget your password, click on the “Forgot password” button, and follow the instructions to receive an OTP. You can then use the OTP to reset your password. These codes are personal to you and must be kept safe. Do not disclose your codes to any other person or keep them with other program documentation. You are responsible for all transactions carried out using your valid user authentication codes.",
  },
  {
    id: 15,
    question: "Do I need a loyalty card to join the FirstBank Rewards program?",
    answer:
      "Loyalty cards are not required to join the rewards program. Once you have a FirstBank account and are enrolled in the program you start earning loyalty points when you carry out eligible transactions using any of our approved channels.",
  },
  {
    id: 16,
    question: "Can I transfer my Rewards Point?",
    answer:
      "Members of the program can transfer points from their account to another member's account via their profile. You will be required to input your transfer pin.",
  },
  {
    id: 17,
    question: "How do I get my transfer Pin?",
    answer:
      "Once enrolled you will be sent your transfer pin along with your username and password.",
  },

  {
    id: 18,
    question: "What do I do if I forget my transfer Pin?",
    answer:
      "Simply log into your FirstBank loyalty account. Go to your profile and click on reset pin and follow the instruction. A new pin will be sent to your registered email address.",
  },
  {
    id: 19,
    question: "If I order more than one item, will they be shipped together?",
    answer:
      "Yes, if you order multiple items at the same time they will be delivered together because they have one order number.",
  },
  {
    id: 20,
    question:
      "Must I redeem my points each time I carry out a qualifying transaction?",
    answer:
      "No, you can accumulate your points to redeem higher value of items of your choice However, it is compulsory you redeem your loyalty points before the stipulated time to avoid forfeiture of points.",
  },
  {
    id: 21,
    question: "How long can I keep my FirstBank Reward Points?",
    answer:
      "You can keep your FirstBank Rewards Points for a maximum of 356 days. However, if your Reward Points are not redeemed within the stated period, they expire and will be automatically deducted from your points balance.",
  },
  {
    id: 22,
    question: "Can I exchange my FirstBank Rewards Points for cash?",
    answer:
      "Rewards on the FirstBank Program are strictly points based and are only redeemable for items displayed on the portal. Points cannot be monetized.",
  },
  {
    id: 23,
    question: "What happens to my earned point if I close my account?",
    answer:
      "We advise you to use up all your point or the points will be forfeited.",
  },
  {
    id: 24,
    question: "How do I qualify to get a discount?",
    answer:
      "Once enrolled and you meet the set criteria set by FirstBank you can have access to the discount provided by various merchants, all you must do is select your preferred merchant and location and then generate a discount voucher which will be sent to your registered email. You will be required to present this voucher at your selected location.",
  },
  {
    id: 25,
    question: "How do I opt out of the rewards program?",
    answer:
      "Kindly login to your loyalty account. Go to your profile and click opt-out from the dashboard page.",
  },
];

const FAQ = () => {
  const [faqShown, setFaqShown] = useState(1);

  const handleShowFaq = (current) => {
    if (current === faqShown) {
      current = 0;
    }
    setFaqShown(current);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="flex justify-center">
        <h3 className="text-button mt-20 text-4xl header-text">
          Frequently Asked Questions
        </h3>
      </div>

      <div className="px-[236px] py-4 main-faq-container mb-24 ">
        {faqs.map((faq) => (
          <div
            key={faq.id}
            oncl
            className="py-4 border-b border-quick border-solid  "
          >
            <div className="flex items-center justify-between">
              <div className="faqQuestionsContainer">
                <h4>{faq.question}</h4>
              </div>
              <div onClick={() => handleShowFaq(faq.id)}>
                {faqShown !== faq.id && (
                  <img className="cursor-pointer" src={plus} alt="plus" />
                )}
                {faqShown === faq.id && (
                  <img className="cursor-pointer" src={minus} alt="minus" />
                )}
              </div>
            </div>
            <div className="">
              {faqShown === faq.id && <p className="pt-1">{faq.answer}</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
