import React, {useEffect} from 'react';
import './SectionThree.css';
import code from '../../../assets/images/earnPurple.png';
import {motion} from 'framer-motion';

import {
  sectionTwoImageAnimationsOne,
  sectionTwoTextAnimationOne,
} from '../../../animation';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
import {useAnimationInView} from '../../LandingPage/components/useAnimationInView';

const SectionThree = () => {
  const [element, controls] = useAnimationInView();
  useEffect(() => {
    // import { gsap } from "gsap";
    // import { ScrollTrigger } from "gsap/ScrollTrigger.js";
    gsap.registerPlugin(ScrollTrigger);

    let proxy = {skew: 0},
      skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'), // fast
      clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      onUpdate: self => {
        let skew = clamp(self.getVelocity() / -300);
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {
            skew: 0,
            duration: 0.5,
            ease: 'power3',
            overwrite: true,
            onUpdate: () => skewSetter(proxy.skew),
          });
        }
      },
      onScroll: () => {
        ScrollTrigger.update();
      },
    });

    gsap.set('.skewElem', {transformOrigin: 'right center', force3D: true});

    // Cleanup function to unregister ScrollTrigger on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);
  return (
    <div className="sectiontwosubsectionone-container skewElem" ref={element}>
      <div className="sub-section-two-img-container__">
        <motion.div
          className="open-left-text"
          variants={sectionTwoTextAnimationOne}
          animate={controls}
          transition={{
            delay: 0.02,
            type: 'tween',
            duration: 0.8,
          }}>
          <h1 className="open-acct-header__">
            Perform transactions on any of our channels
          </h1>

          <p className="open-acct-parag__">
            A brief copy about the various transactions one can perform with
            FirstBank and any additonal text.
          </p>
        </motion.div>

        <motion.img
          variants={sectionTwoImageAnimationsOne}
          animate={controls}
          transition={{delay: 0.03, type: 'tween', duration: 0.8}}
          whileHover={{scale: 0.8, borderRadius: '100%'}}
          src={code}
          alt="Open Account Form"
          className="open-acct-image__"
        />
      </div>
    </div>
  );
};

export default SectionThree;
