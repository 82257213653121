import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import './SectionSix.css';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
const SectionSix = () => {
  useEffect(() => {
    // import { gsap } from "gsap";
    // import { ScrollTrigger } from "gsap/ScrollTrigger.js";
    gsap.registerPlugin(ScrollTrigger);

    let proxy = {skew: 0},
      skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'), // fast
      clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      onUpdate: self => {
        let skew = clamp(self.getVelocity() / -300);
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {
            skew: 0,
            duration: 0.5,
            ease: 'power3',
            overwrite: true,
            onUpdate: () => skewSetter(proxy.skew),
          });
        }
      },
      onScroll: () => {
        ScrollTrigger.update();
      },
    });

    gsap.set('.skewElem', {transformOrigin: 'right center', force3D: true});

    // Cleanup function to unregister ScrollTrigger on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <>
      <div className="join-reward-container skewElem">
        <h1 className="join-header">Join First Rewards Today</h1>
        <div className="below-join-reward">
          <div className="join-reward-left-container">
            <h3 className="below-join-reward-header">
              Already Banking With Us?
            </h3>
            <p className="below-join-reward-parag">
              If you're registered for Online Banking or use the FirstMobile
              app, joining takes just a few minutes.
            </p>
            <Link
              to="https://fbn-sigma.vercel.app/login/"
              className="join-and-open-accct-header">
              Login
            </Link>
          </div>
          <div className="join-reward-right-container">
            <h3 className="below-join-reward-header">
              Not Banking With Us Yet?
            </h3>
            <p className="below-join-reward-parag">
              You can open a FirstBank account online in minutes and then join
              First Rewards Now
            </p>
            <Link
              to="https://openaccounts2.firstbanknigeria.com/onboarding/"
              className="join-and-open-accct-header ">
              Open an account
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionSix;
