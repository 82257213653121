import React, {useState} from 'react';
import ReactPaginate from 'react-js-pagination';

const Pagination = ({itemsPerPage, data, handlePageChange, currentPage}) => {
  //   console.log(data, 'pagination data');

  //   const [currentPage, setCurrentPage] = useState(1);

  //   const handlePageChange = pageNumber => {
  //     setCurrentPage(pageNumber);
  //   };

  //   const renderData = () => {
  //     const start = (currentPage - 1) * itemsPerPage;
  //     const end = start + itemsPerPage;
  //     return data
  //       ?.slice(start, end)
  //       ?.map(item => <div key={item.id}></div>);
  //   };

  //console.log(itemsPerPage, data, currentPage);

  return (
    <div>
      <ReactPaginate
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={data?.length}
        pageRangeDisplayed={10} // Adjust as needed
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  );
};

export default Pagination;
