import React, { useEffect, useState } from "react";
import "./ForgotMembershipId.css";
import logo from "../../../assets/images/logo.svg";
import { Key } from "../../../assets/icons";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  showChevUp,
  showChevDown,
} from "../../../redux-toolkit/features/forgotPassword/forgotPasswordSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ApiConfig from "../../../service/ApiConfig";
import axiosProperty from "../../../service/HttpService";
import { getAccessToken } from "../../../config/helper";
import { useMutation } from "react-query";
import { TailwindSpinner } from "../../../components/Spinner/TailwindSpinner";
import { ToastContainer, toast } from "react-toastify";

const ForgotMembershipId = ({ nagateNav }) => {
  //  const [showBVNReason, setShowBVNReason] = useState(false)
  const { chevs } = useSelector((store) => store.forgotPassword);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const RetrieveMembershipID = async () => {
    if (!email) {
   
      toast.error("Email is required. Please enter a valid email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  
    try {
      const response = await axiosProperty.post(
        ApiConfig.forgotMembershiID,
        { email },
        {
          headers: {
            Accept: 'application/json',
            //Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
  
      if (response.status === 200) {
        setEmail(''); 
        toast.success("An email has been sent to the email address linked to your account.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error in RetrieveMembershipID:", error);
    }
  };
  

const {mutate, isSuccess, isError, isLoading} = useMutation(RetrieveMembershipID);

 const handleShowReason = ()=>{
   if(chevs){
     dispatch(showChevDown())
    }else{
     dispatch(showChevUp())
   }
 }

 const handleSubmit=()=>{
  mutate()
 }

  useEffect(() => {
    nagateNav(false);
  }, []);

  const emailCheck =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return (
    <>
      <div className="forgot-memid-container">
        <div className="logo-container-position">
          <Link to='/login'>
          
          <img src={logo} alt="first bank logo" />
          </Link>
        </div>
        <div className="forgot-password-outter-container-adjust">
          <div className="text-container__">
            <div className="inner-text-container-adjust">
              <div className="forgot-mem-text1">
                <h1>Forgot Your Membership ID?</h1>
                <p>Enter your email below to receive your membership ID</p>
              </div>

              <div className="forgot-mem-input-container">
                <p className="mem_id">Enter your Email</p>
                <div className="input-and-btn-conatainer-adjust">
                  <input
                    placeholder="Enter your Email"
                    type="email"
                    value={email}
                    className="forgot-mem-input"
                    onChange={(e)=>setEmail(e.target.value)}
                  />

                  {/* <div className="bvn-reason-container" onClick={handleShowReason}>
                    <div className="top-bvn-reason-container" >
                      <p className="bvn-reason-text1">
                        Why do we need your BVN?
                      </p>

                      <div>
                        {chevs ? (
                          <span onClick={() => dispatch(showChevDown())}>
                            <BsChevronUp className="chevs" />
                          </span>
                        ) : (
                          <span onClick={() => dispatch(showChevUp())}>
                            <BsChevronDown className="chevs" />
                          </span>
                        )}
                      </div>
                    </div>

                    {chevs && (
                      <>
                        {" "}
                        <div>
                          <p className="bvn-reason-text2">
                            We need your BVN to give us access to your
                            Membership ID
                          </p>
                        </div>
                        <div className="key-text3-container">
                          <Key />
                          <p className="bvn-reason-text3">
                            Your BVN does not give us access to your bank
                            account details or transactions
                          </p>
                        </div>
                      </>
                    )}
                  </div> */}

                  <button
                    className="forget-memid-btn"
                     onClick={handleSubmit}
disabled={!emailCheck.test(email)  }
                  >
                    {isLoading ? <TailwindSpinner/> : "Continue"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotMembershipId;
