import React, {useEffect} from 'react';
import './SectionSeven.css';
import freqPlus from '../../../assets/freq-plus.png';
import freqMinus from '../../../assets/freq-minus.png';
import {useDispatch, useSelector} from 'react-redux';
import {
  showQuest1,
  hideQuest1,
  showQuest2,
  hideQuest2,
  showQuest3,
  hideQuest3,
  showQuest4,
  hideQuest4,
} from '../../../redux-toolkit/features/freqQuest/freqQuestSlice';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';

// import { ScrollTrigger } from "gsap/ScrollTrigger.js";

const SectionSeven = () => {
  const dispatch = useDispatch();
  const {quest1, quest2, quest3, quest4} = useSelector(
    store => store.freqQuest,
  );
  useEffect(() => {
    // import { gsap } from "gsap";
    // import { ScrollTrigger } from "gsap/ScrollTrigger.js";
    gsap.registerPlugin(ScrollTrigger);

    let proxy = {skew: 0},
      skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'), // fast
      clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      onUpdate: self => {
        let skew = clamp(self.getVelocity() / -300);
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {
            skew: 0,
            duration: 0.5,
            ease: 'power3',
            overwrite: true,
            onUpdate: () => skewSetter(proxy.skew),
          });
        }
      },
      onScroll: () => {
        ScrollTrigger.update();
      },
    });

    gsap.set('.skewElem', {transformOrigin: 'right center', force3D: true});

    // Cleanup function to unregister ScrollTrigger on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <>
      <div className="freq-quest-container skewElem">
        <div className="freq-inner-container">
          <h1 className="freq-text">Frequently Asked Questions</h1>

          <div className="freq-container">
            <div className="header-and-icon-container">
              <h3>What is the First Bank’s Rewards Program?</h3>

              {!quest1 && (
                <img
                  src={freqPlus}
                  alt="icon"
                  className="freq-plus"
                  onClick={() => dispatch(showQuest1())}
                />
              )}

              {quest1 && (
                <img
                  src={freqMinus}
                  alt="icon"
                  onClick={() => dispatch(hideQuest1())}
                />
              )}
            </div>

            {quest1 && (
              <p className="freq-parag">
           The FirstBank’s Rewards program is a customer retention strategy that encourages and rewards loyal customers to keep using the bank's services. Customers will earn points Known as FirstFirstCoins when they carry out eligible transactions with the bank’s products from cards, mobile apps, USSD, Internet banking.
              </p>
            )}
          </div>
          <div className="freq-container">
            <div className="header-and-icon-container">
              <h3>Who can participate in this program?</h3>

              {!quest2 && (
                <img
                  src={freqPlus}
                  alt="icon"
                  className="freq-plus"
                  onClick={() => dispatch(showQuest2())}
                />
              )}

              {quest2 && (
                <img
                  src={freqMinus}
                  alt="icon"
                  onClick={() => dispatch(hideQuest2())}
                />
              )}
            </div>

            {quest2 && (
              <p className="freq-parag">
               The Reward program is available to all FirstBank new and existing customers.
              </p>
            )}
          </div>
          <div className="freq-container">
            <div className="header-and-icon-container">
              <h3>
                Are there any hidden fees or charges attached to this Rewards
                Program?
              </h3>

              {!quest3 && (
                <img
                  src={freqPlus}
                  alt="icon"
                  className="freq-plus"
                  onClick={() => dispatch(showQuest3())}
                />
              )}

              {quest3 && (
                <img
                  src={freqMinus}
                  alt="icon"
                  onClick={() => dispatch(hideQuest3())}
                />
              )}
            </div>

            {quest3 && (
              <p className="freq-parag">
              No, the program is free and offered at no cost to customers.
              </p>
            )}
          </div>
          <div className="freq-container">
            <div className="header-and-icon-container">
              <h3>
                If I have multiple accounts, will I also get rewards offers on
                the accounts?
              </h3>

              {!quest4 && (
                <img
                  src={freqPlus}
                  alt="icon"
                  className="freq-plus"
                  onClick={() => dispatch(showQuest4())}
                />
              )}

              {quest4 && (
                <img
                  src={freqMinus}
                  alt="icon"
                  onClick={() => dispatch(hideQuest4())}
                />
              )}
            </div>

            {quest4 && (
              <p className="freq-parag">
                Yes. If these accounts, products, and services are all linked.
              </p>
            )}
          </div>
          {/* <div className="freq-container">
            <div className="header-and-icon-container">
              <h3>Are there zero fees?</h3>
              <img src={freqMinus} alt="icon" />
            </div>
            <p className="freq-parag">
              At First rewards we believe businesses shouldn’t have to wait or
              pay to access money they’ve already earned. We don’t charge you to
              sign up. We don’t charge you when you receive payments. And we
              don’t charge you when you make payments. There are zero fees.
              Seriously.
            </p>
          </div> */}
          {/* <div className="freq-container">
            <div className="header-and-icon-container">
              <h3>Are there zero fees?</h3>
              <img src={freqPlus} alt="icon" />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SectionSeven;
