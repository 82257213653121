import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  visibility: false,
};

const showPasswordSlice = createSlice({
  name: "show password",
  initialState,
  reducers: {
    showPassword: (state) => {
      state.show = true;
    },
    hidePassword: (state) => {
      state.show = false;
    },
    showConfirmPassword: (state) => {
      state.visibility = true;
    },
    hideConfirmPassword: (state) => {
      state.visibility = false;
    },
  },
});

export const { showPassword, hidePassword, showConfirmPassword, hideConfirmPassword } = showPasswordSlice.actions;
export default showPasswordSlice.reducer;
