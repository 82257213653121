import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './Voucher.css';

const Card = ({each}) => {
  return (
    <div className="card" key={each.id}>
      <Link
        to={`/vouchers/checkout/${each.partner_name}/${each.partner_id}`}
        className="voucher-link">
        <img src={each.partner_logo} alt={each.partner_name} />
        <div className="card-text">
          <p className="capitalize">{each.partner_name}</p>
          <button>Generate Vouchers</button>
        </div>
      </Link>
    </div>
  );
};

export default Card;
