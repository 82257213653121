import React from 'react';
import laptop2 from '../../assets/images/laptop2.png';
import cancel from '../../assets/svg/x-modal.svg';

const SingleItemImageModal = ({visible, onClose, itemState}) => {
  if (!visible) return null;

  return (
    <div className="fixed overflow-y-auto justify-center top-2 left-0 w-full h-full z-10 bg-[#808080] bg-opacity-10 backdrop-blur-sm flex">
      <div className=" w-[600px]">
        <div className="bg-white rounded px-4 py-8">
          <div className="flex justify-between my-3 items-center">
            <h2 className="font-bold text-2xl leading-loyalty capitaize tracking-loyal">
              Product image
            </h2>
            <button
              onClick={onClose}
              className="flex bg-[#f0f0f0] rounded p-2 gap-2 items-center h-fit">
              <img className="h-5 w-5" src={cancel} alt="close" />
              <p className="capitalize font-medium text-base leading-loyalty tracking-loyal">
                close
              </p>
            </button>
          </div>
          <div className="flex justify-center object-contain">
            <img
              className="w-full h-full"
              src={itemState?.Item_image}
              alt="laptop2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleItemImageModal;
