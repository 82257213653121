import React, { useEffect, useRef } from "react";
import { initWheel, loadScript } from "./spinner";
import './WheelComponent.css';

const SpinWheel = ({Prizes, rotationDuration}) => {


useEffect(() => {
  async function startSystem(){
    if(!document.querySelector('#sweepstakes')){
      loadScript('https://cdn.jsdelivr.net/npm/spin-wheel@5.0.2/dist/spin-wheel-iife.js', 'sweepstakes')
    .then((response) => {
      
      initWheel(Prizes, rotationDuration)
    }).catch(console.error)
    }
  }

  startSystem()
})

  return (
    <>
    <div className="wheelContainer">

 
      <img
        id="center"
        style={{ display: "none" }}
        src="/center.svg"
        alt="center"
      />
      
      <img
        id="overlay"
        style={{ display: "none" }}
        src="/overlay.svg"
        alt="overlay"
      />

<div className="wheel-container"></div>
</div>
    </>
  );
};

export default SpinWheel;
