import { Link } from "react-router-dom";
import "../../pages/Sweepstake/SweepStakes.css";

const SweepStakeCard = ({ sweepStake }) => {
 
  return (
    <div className="sweepstake-card">
      <div className="sweepstake-card-image">
        <img className="sweepstake-img" src={sweepStake?.Prize_image} alt={sweepStake?.Prize + sweepStake?.Prize_image} />
      </div>
      <div className="sweepstake-card-title-and-description ">
        <h1 className="sweepstake-card-title">{sweepStake?.Sweepstake_name}</h1>
        <p className="sweepstake-card-description">{sweepStake?.Prize_description}</p>
        <p className="sweepstake-card-date" >{sweepStake?.date}</p>
      </div>
      <div className="sweepstake-card-button-container">
      <Link
         to={`/sweepstakes/${sweepStake?.Sweepstake_id}`} 
           >
        <button className="sweepstake-card-button"  >View</button>
          </Link>
      </div>
    </div>
  );
};

export default SweepStakeCard;
