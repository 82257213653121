import React, { useEffect, useRef, useState } from "react";
import "./OtpInput.css";

import logo from "../../../assets/images/logo.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Envelop, Message, Passkey } from "../../../assets/icons";
import axios from "axios";
import { endpoints } from "../../../utils/constant";
import { ToastContainer, toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { TailwindSpinner } from "../../../components/Spinner/TailwindSpinner";
const OtpInput = ({ nagateNav }) => {
  const [timer, setTimer] = useState("00:00");
  const [showResend, setShowResend] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpValue1, setOtpValue1] = useState("");
  const [otpValue2, setOtpValue2] = useState("");
  const [otpValue3, setOtpValue3] = useState("");
  const [otpValue4, setOtpValue4] = useState("");
  const [otpValue5, setOtpValue5] = useState("");
  const [otpValue6, setOtpValue6] = useState("");
  const [otpStatus, setOtpStatus] = useState(null);
  const [otpLength, setOtpLength] = useState(false);
  const { memberid } = useParams();
  const [restartTimer, setRestartTimer] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  //console.log(memberid, 'member-id');

  const Ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [number, setNumber] = useState(null);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const otpNumber = pathParts[pathParts.length - 1];
    setNumber(otpNumber);
  }, [location]);

  const OtpDetails = async () => {

    const iv = CryptoJS.lib.WordArray.random(16);

    if (process.env.REACT_APP_ENCRYPTION_KEY.length !== 16) {
      throw new Error("Encryption key must be 16 characters for AES-128-CTR.");
    }
    
    
    const encryptedOTP = CryptoJS.AES.encrypt(
      getAllOtp,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY), 
      {
        iv: iv, 
        mode: CryptoJS.mode.CTR, 
        padding: CryptoJS.pad.NoPadding, 
      }
    ).toString();
    
    const params = {
      membership_id: number,
      otp: encryptedOTP,
      iv: iv.toString(CryptoJS.enc.Base64), 
    };

    setIsLoading(true)


    //const params = { otp: getAllOtp, membership_id: number };
    try {
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.otp}`,
        params
      );
      if (otpResponse?.data?.status === 0) {
        setOtpStatus(otpResponse);
        setIsLoading(false)
      }
      if (otpResponse?.data?.status === 1) {
        navigate(`/reset-password/${number}`);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  const getAllOtp =
    otpValue1 + otpValue2 + otpValue3 + otpValue4 + otpValue5 + otpValue6;

  const handleChange1 = (e) => {
    const otpValue = e.target.value;
    setOtpValue1(otpValue);
  };

  const handleChange2 = (e) => {
    const otpValue = e.target.value;
    setOtpValue2(otpValue);
  };

  const handleChange3 = (e) => {
    const otpValue = e.target.value;
    setOtpValue3(otpValue);
  };

  const handleChange4 = (e) => {
    const otpValue = e.target.value;
    setOtpValue4(otpValue);
  };

  const handleChange5 = (e) => {
    const otpValue = e.target.value;
    setOtpValue5(otpValue);
  };

  const handleChange6 = (e) => {
    const otpValue = e.target.value;
    setOtpValue6(otpValue);
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total,
      minutes,
      seconds,
    };
  };

  const passwordOTP = async () => {
    try {
      const getPin = await axios.post(
        `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.resetPassword}${memberid}`
      );

      if (getPin?.data?.status === 1) {
        // setDisplayMessage(true);
        //navigate(`/otp-input/${memberid}`);
        setRestartTimer(!restartTimer);
        setShowResend(false);
        toast.success("OTP resent, check your mail!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total === 0) {
      setShowResend(true);
    }
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("05:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time

    deadline.setSeconds(deadline.getSeconds() + 300);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, [restartTimer]);

  const handleResendOTP = () => {
    //setShowOtpModal(true);
    passwordOTP();
  };

  const handleGetAnotherOTP = () => {
    // clearTimer(getDeadTime());
    setShowOtpModal(false);
  };

  //////////Timer End///////////

  const autoTab = (e) => {
    if (`${e.target.value.length}` === e.target.getAttribute("maxlength")) {
      const inputs = document.getElementsByClassName("otp-pin-input");
      for (let i = 0; i < inputs.length; i++) {
        if (e.target == inputs[i]) {
          i++;
          if (i < inputs.length) {
            let next = inputs[i];
            next.focus();
          }
        }
      }
    }
  };

  const handleSubmitOTP = (e) => {
    e.preventDefault();
    OtpDetails();
  };

  useEffect(() => {
    nagateNav(false);
  }, []);

  return (
    <>
      <div className="otp__main-container">
        <div>
          <div
            className="modal  terms-modal"
            style={{ display: showOtpModal ? "block" : "none" }}
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="otp-modal-header">
                  <h5 className="modal-title m-auto" id="exampleModalLongTitle">
                    Resend OTP
                  </h5>
                  <p className=" m-auto">
                    Please choose where you would like the OTP sent
                  </p>
                </div>
                <div className="otp-modal-body">
                  <button className="otp-modal-btn">
                    <Message />
                    SMS OTP
                  </button>
                  <button className="otp-modal-btn">
                    <span>
                      <Envelop />
                    </span>
                    Email OTP
                  </button>
                </div>
                <div className="otp-modal-footer">
                  <button
                    type="button"
                    className="btn m-auto"
                    onClick={handleGetAnotherOTP}
                  >
                    Get One-Time Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="logo-container-position">
          <Link to='/login' >
          <img src={logo} alt="first bank logo" />
          </Link>
        </div>
        <div className="otp-outter">
          <div className="text-container_">
            <div className="inner-otp-container">
              <div className="enter-otp-header-text">
                <div className="passkey-container">
                  <Passkey />
                </div>
                <h1>Enter OTP</h1>
                <p>Please enter the OTP sent to your email</p>
              </div>

              <div className="otp-main-container">
                <div className="otp-number-input">
                  <div className="pin-container">
                    <input
                      autoFocus={true}
                      maxLength="1"
                      tabIndex={0}
                      onKeyUp={autoTab}
                      value={otpValue1}
                      className={`${
                        otpLength
                          ? "border-1 border-solid border-[#FA2323]"
                          : "border-[#cccccc]"
                      } otp-pin-input`}
                      onChange={handleChange1}
                    />
                    <input
                      maxLength="1"
                      tabIndex={1}
                      onKeyUp={autoTab}
                      value={otpValue2}
                      className={`${
                        otpLength
                          ? "border-1 border-solid border-[#FA2323]"
                          : "border-[#cccccc]"
                      } otp-pin-input`}
                      onChange={handleChange2}
                    />
                    <input
                      maxLength="1"
                      tabIndex={2}
                      onKeyUp={autoTab}
                      value={otpValue3}
                      className={`${
                        otpLength
                          ? "border-1 border-solid border-[#FA2323]"
                          : "border-[#cccccc]"
                      } otp-pin-input`}
                      onChange={handleChange3}
                    />
                    <input
                      maxLength="1"
                      tabIndex={3}
                      onKeyUp={autoTab}
                      value={otpValue4}
                      className={`${
                        otpLength
                          ? "border-1 border-solid border-[#FA2323]"
                          : "border-[#cccccc]"
                      } otp-pin-input`}
                      onChange={handleChange4}
                    />
                    <input
                      maxLength="1"
                      tabIndex={4}
                      onKeyUp={autoTab}
                      value={otpValue5}
                      className={`${
                        otpLength
                          ? "border-1 border-solid border-[#FA2323]"
                          : "border-[#cccccc]"
                      } otp-pin-input`}
                      onChange={handleChange5}
                    />
                    <input
                      maxLength="1"
                      tabIndex={5}
                      onKeyUp={autoTab}
                      value={otpValue6}
                      className={`${
                        otpLength
                          ? "border-1 border-solid border-[#FA2323]"
                          : "border-[#cccccc]"
                      } otp-pin-input`}
                      onChange={handleChange6}
                    />
                  </div>
                  <div className="timer-container">
                    <p className="timer">
                      Your Passcode will expire in {timer}
                    </p>
                  </div>

                  {otpStatus ? (
                    <p className="pt-3 font-medium text-sm text-[#FA2323]">
                      {otpStatus?.data?.message}
                    </p>
                  ) : null}

                  <button className="submit-otp" onClick={handleSubmitOTP}>
                    {isLoading ? <TailwindSpinner/> :  "Continue"}
                  </button>

                  {showResend && (
                    <div className="resend-code-container">
                      <p className="resend-code">
                        Didn’t receive any code?{" "}
                        <span
                          className="resend-code-span"
                          onClick={handleResendOTP}
                        >
                          Resend
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default OtpInput;
