import axios from "axios";

 const axiosProperty = {
    get: axios.get,
    put: axios.put,
    post: axios.post,
    delete: axios.delete,
    all: axios.all
}

export default axiosProperty