import React, { useRef, useState } from "react";
import "./HelpWidget.css";
import { ChatIcon, SendMessage, Times } from "../../assets/icons";
import { useEffect } from "react";
import axiosProperty from "../../service/HttpService";
import ApiConfig from "../../service/ApiConfig";
import { ToastContainer, toast } from "react-toastify";
import { useMutation } from "react-query";
import { TailwindSpinner } from "../Spinner/TailwindSpinner";
import { authHeaders, getAccessToken } from "../../config/helper";

const HelpWidget = () => {
  const widgetRef = useRef();
  const [formData, setFormData] = useState({
    membership_id: "",
    message: "",
  });

  const [openWidget, setOpenWidget] = useState(false);
  const [chatBot, setChatBot] = useState([]);
  const [showChatInput, setShowChatInput] = useState('')

  const handleToggle = () => {
    setOpenWidget(!openWidget);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const customerData = JSON.parse(
      sessionStorage?.getItem("first-bank-loyalty")
    );
    const membershipID = customerData?.data?.customer?.Membership_id;
    setFormData((prev) => ({ ...prev, membership_id: membershipID }));

    function closeWidget(event) {
      if (widgetRef.current && !widgetRef.current.contains(event.target)) {
        setOpenWidget(false);
      }
    }
    document.addEventListener("mousedown", closeWidget);
    return () => {
      document.removeEventListener("mousedown", () => {});
    };
  }, []);

  //////////////SUBMIT MESSAGE START///////////////

  const sendMessage = async () => {
    try {
      const response = await axiosProperty.post(
        ApiConfig.helpWidgetSend,
        formData,{headers: authHeaders}
      );
      if (response.status === 200) {
        setOpenWidget(false);
        toast.success(
          "Request Received Successfully, we will be in touch soon",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

        setFormData((prev) => ({
          ...prev,
          message: "",
        }));
      }
    } catch (error) {}
  };

  const { mutate, isLoading, isError } = useMutation(sendMessage);

  const handleSubmit = () => {
    if (formData.message === "") {
      toast.error("Message is required!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    mutate();
  };

  //////////////SUBMIT MESSAGE END///////////////

  //console.log(window?.location?.pathname);
  //console.log(formData, "formdata");

  const faq = [
    {
      id: 0,
      question: "Why can’t I change my password?",
      answer:
        "Please ensure that you’ve entered your current password correctly before attempting to change it",
    },
    {
      id: 1,
      question: "How can I convert my points into cash?",
      answer:
        "Simply select the “Points to Cash” feature and follow the on-screen instructions.",
    },
    {
      id: 2,
      question: "How do I transfer points to someone else?",
      answer:
        "Use the “Points Transfer” feature and follow the prompts to complete the transfer.",
    },
    {
      id: 3,
      question: "Can I download my movie tickets?",
      answer:
        "Yes, you can. Go to your inbox by clicking on the Mail icon, then click on “SAVE AS PDF” to download your tickets.",
    },
    {question: 'How can I find my membership ID?', answer: 'The quickest way is to check your email. Alternatively, you can click on “Forgot Membership ID” and follow the steps provided.'},
    {question: 'How can I reset my password?', answer: 'Simply click on “Forgot Password” and follow the prompts.'},
    {id:4, question: 'Other questions',answer: `${ getAccessToken() !== null ? 'Kindly enter your question below and we’ll get back to you as soon as possible' : 'Kindly login into your account to make input visible'  }` }
  ];

  // useEffect(()=>{
  //   setChatBot((prev) => prev.filter((each) => each.id !== 4))
 
  // }, [ getAccessToken() ])



  const handleChatBot = (id) => {
    if (chatBot.some((each) => each.id === id)) {
      return;
    }
    if(getAccessToken() !== null){
      setShowChatInput(true)
    }
    const filteredMesssage = faq.filter((message) => message.id === id);
    setChatBot((prev) => [...prev, ...filteredMesssage]);
  };

  ///////SCROLL TO THE BOTTOM OF CHAT WHEN A NEW QUESTION IS CLICKED////////

  const chatEndRef = useRef(null); 

  
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatBot]);

  return (
    <>
      {openWidget && (
        <div
          ref={widgetRef}
          className={`help-widget-text-container ${
            openWidget ? "open" : "close"
          }`}
        >
          <div className="help-widget">
            <div className="help-widget-title-container">
              <h5 className="help-widget-title">Help Center</h5>
              <span
                onClick={() => setOpenWidget(false)}
                className="close-widget"
              >
                <Times />
              </span>
            </div>
            <div className="chat-container">
              <div className="chat-header">
                <p className="support-bot-text">Support bot</p>
                <p className="response-text">
                  Welcome to FirstRewards support, how may I help you today?
                </p>
              </div>
              {faq.map((each) => (
                <div key={each.id} className="chat-questions-container">
                  <p
                    className="chat-questions"
                    onClick={() => handleChatBot(each.id)}
                  >
                    {each.question}
                  </p>
                </div>
              ))}
            </div>
            { chatBot.length>0 && <div className="chat-question-and-response-container">
              <div className="chat-question-and-response-inner-container">
                {chatBot.map((each) => (
                  <div
                    key={each.id + "-" + each.question}
                    className="chat-question-and-answer-container"
                  >
                    <div className="question-area">
                      <p className="chat-questions-area">{each.question}</p>
                    </div>

                    <div className="answer-area">
                      <p className="support-bot-text">Support bot</p>
                      <p className="response-text-in-chat">{each.answer}</p>
                    </div>
                  </div>
                ))}
                  <div ref={chatEndRef} />
              </div>
            </div>}
          {showChatInput && <div className="chat-message-box">
            <input
                name="message"
                placeholder="Type your question here"
                value={formData.message}
                onChange={handleChange}
                className="help-widget-textarea"
              ></input>
              <span  className="send-message-icon" onClick={handleSubmit}>
                <SendMessage formData={formData} />
              </span>
            </div>}
          </div>
        </div>
      )}
      {/* {!["/login", "/forgot-membershipid", "/forgot-password"].includes(
        window?.location?.pathname
      ) && ( */}
        <div className="help-widget-toggle" onClick={handleToggle}>
          <ChatIcon /> Help
        </div>
      {/* )} */}
      <ToastContainer />
    </>
  );
};

export default HelpWidget;
