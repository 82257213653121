import React, { useState, useEffect, useRef, useContext } from "react";
import logo from "../../assets/images/logo.svg";
import user from "../../assets/images/user-icon.png";
import "./CategoryNav.css";
import {
  authHeaders,
  customerPortal,
  doGet,
  doPost,
  formatValue,
  getAccessToken,
  getData,
  logout,
  myStore,
  storeData,
} from "../../config/helper";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer, toast } from "react-toastify";
import {
  showPassword,
  hidePassword,
} from "../../redux-toolkit/features/password/showPasswordSlice";
import { endpoints } from "../../utils/constant";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MobileModalCancel } from "../../assets/icons";
import { AppContext } from "../AppContext";

import { Envelop, Hamburger, SearchMobile } from "../../assets/icons";
import MobileModalOne from "../MobileModal/MobileModalOne";
import { Link } from "react-router-dom";
import { getCartItems } from "../../redux-toolkit/features/cart/cartSlice";
import axios from "axios";
import NavigationModal from "../Modals/NavigationModal";
import { SilverNavIcon, AvatarIcon } from "../../assets/svg/icons";
import PointToCash from "../PointToCash/PointToCash";
import RedeemedModal from "../Modals/RedeemedModal";

const CategoryNav = () => {
  const { isFinalCheckout } = useSelector((store) => store.cart);
  const dispatch = useDispatch();
  const { show } = useSelector((store) => store.password);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const { cartTotalItems } = useSelector((store) => store.cart);
  const [currentBalance, setCurrentBalance] = useState("0.00");
  const [notificationDigits, setNotificationDigits] = useState([]);
  // const [customerBalance, setCustomerBalance] = useState('0.00');
  const [toSearch, setToSearch] = useState(false);
  // const searchRef = useRef(null)
  const [searchResults, setSearchResults] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [lastSeen, setLastSeen] = useState("");
  let searchRef = useRef();
  let memberRef = useRef(null);

  useEffect(() => {
    sessionStorage.setItem(
      "currentBalance",
      currentBalance?.profile?.[0]?.Current_balance
    );
  }, [currentBalance]);
  useEffect(() => {
    const lastSeenObject = sessionStorage.getItem("first-bank-loyalty");
    setLastSeen(
      JSON.parse(lastSeenObject)?.data?.customer?.Last_login_datetime
    );
  }, []);

  // const searchProducts = e => {
  //   storeData('search-name', e.target.value);
  //   if (e.key === 'Enter' && e.target.value !== '') {
  //     doPost(
  //       setSearchResults,
  //       JSON.stringify({item_name: e.target.value}),
  //       'api/search_items',
  //     );
  //     setToSearch(true);
  //   }
  // };

  // useEffect(() => {
  //   dispatch(getCartItems());
  //   if (toSearch == true) {
  //     storeData('search', searchResults?.items);
  //     window.location = '/search';
  //   }
  //   //
  // }, [searchResults]);

  const safeDocument = typeof document !== "undefined" ? document : {};
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;
    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    html.style.position = "relative"; /* [1] */
    html.style.overflow = "hidden"; /* [2] */
    body.style.position = "relative"; /* [1] */
    body.style.overflow = "hidden"; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;
    html.style.position = "";
    html.style.overflow = "";
    body.style.position = "";
    body.style.overflow = "";
    body.style.paddingRight = "";
    scrollBlocked.current = false;
  };

  const notificationNumbers = async () => {
    try {
      const notifyRes = await axios(
        `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.allNotification}${
          getData(myStore)?.data?.customer?.Membership_id
        }`,
        { headers: authHeaders }
      );

      setNotificationDigits(notifyRes?.data?.unread);
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("You already have an active session.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          logout();
        }, 4000);
      }
      console.log(error);
    }
  };

  function MouseOver() {
    setIsHovered(true);
  }
  function MouseOut() {
    setIsHovered(false);
  }

  // track when a user is back online to refetch the notifications numbers, so they got logged out if they're logged in somewhere else or their authentications
  const { balanceStateCheck, setBalanceStateCheck } = useContext(AppContext);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        notificationNumbers();
        setBalanceStateCheck(!balanceStateCheck)
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (getAccessToken() !== null) {
      notificationNumbers();
    }
  }, [lastSeen]);
  //////PointToCash//////////////
  const [pointToCash, setPointToCash] = useState(false);
  const [successConversion, setSuccessConversion] = useState(false);

  const [insufficientPoint, setInsufficientPoint] = useState(false);
  const handlePointToCash = () => {
    setPointToCash(!pointToCash);
    setSuccessConversion(false);
  };

 

  useEffect(() => {
    if (getAccessToken() !== null) {
      doGet(
        setCurrentBalance,
        `profile?membership_id=${
          getData(myStore)?.data?.customer?.Membership_id
        }`
      );
    }
  }, [notificationDigits, successConversion, balanceStateCheck, lastSeen]);


  return (
    <>
      <nav className="static bg-white navbar navbar-expand-lg navbar-light category-nav">
        <ul className="m-auto mb-2 navbar-nav mb-lg-0 ">
          <li className="px-2 nav-item navbar-item hamburger-menu">
            <span
              className=""
              onClick={() => {
                setShowModal(true);
                blockScroll();
              }}
            >
              <Hamburger />
            </span>
          </li>
          <li className="nav-item navbar-item image-sizing-mobile">
            <Link
              // to="https://loyalty-fb.vercel.app/"
              to={"/"}
              className="nav-link"
              aria-current="page"
            >
              <img src={logo} alt="logo" />
            </Link>
          </li>
          {/* <li className="w-[25%]">
            
            <div className="relative hidden md:hidden lg:flex form-group has-search hide-search-bar">
              <input
                type="text"
                className="form-control"
                placeholder="Search for products"
            
              />
              <span className="absolute top-2 left-5">
                <button
                  onClick={() => {
                    searchProducts(searchRef.current.value);
                  }}>
                  <SearchIcon />
                </button>
              </span>
            </div>
          
          </li> */}

          {/* <div
            className="items-center hidden lg:flex relative cursor-pointer"
            onMouseOver={MouseOver}
            onMouseOut={MouseOut}>
            <SilverNavIcon />
            {isHovered && <p className='ranking-text'>Silver Member</p>}
          </div> */}

          <div className="items-center nav-balance gap-2 lg:flex">
            <li className="flex">
              {getData(myStore)?.data?.customer?.Membership_id
                ? show
                  ? formatValue(
                      currentBalance?.profile
                        ? currentBalance?.profile[0]?.Current_balance
                        : 0
                    )
                  : "****"
                : "0.00"}
              &nbsp; FirstCoins
            </li>

            <div className="hidden lg:flex">
              {show ? (
                <AiOutlineEye onClick={() => dispatch(hidePassword())} />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => dispatch(showPassword())}
                />
              )}
            </div>
          </div>
          <li>
            <button
              type="button"
              className="convert-point-btn"
              onClick={handlePointToCash}
            >
              Convert points to cash
            </button>
          </li>

          {/* <Link to="/wish-list" className="text-black px-2.5">
            <li className="nav-item navbar-item ">
              <Heart className="heart-icon-nav" />
            </li>
          </Link> */}

          <Link
            to={`/notification/${
              getData(myStore)?.data?.customer?.Membership_id
            }`}
            className="relative text-black px-2.5"
          >
            <span className="absolute text-[12px] text-white -right-2 lg:-right-1.5 -top-3 bg-red-500 rounded-full p-[3px]">
              {notificationDigits}
            </span>
            <Envelop />
          </Link>

          {/* <Link to="/cart-items" className="text-black px-2.5">
            <li className="relative">
              <span className="absolute text-sm -right-2 -top-2 bg-add rounded-full px-[3px]">
                {cartTotalItems}
              </span>
              <Cart />
            </li>
          </Link> */}
          <li className="nav-item navbar-item last-seen-li">
            <div className="last-seen-container">
              <p className="last-seen-title">Last Seen</p>
              <p className="last-seen-time">{lastSeen}</p>
            </div>
          </li>

          <li className="nav-item navbar-item user-icon-container">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <AvatarIcon />
                {getData(myStore)?.data?.customer?.Membership_id
                  ? " Hi, " + getData(myStore)?.data?.customer?.Membership_id
                  : "visitor"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {getData(myStore)?.data?.customer?.Membership_id && (
                  <>
                    <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item onClick={() => logout()}>
                      Logout
                    </Dropdown.Item>
                  </>
                )}

                {!getData(myStore)?.data?.customer?.Membership_id && (
                  <>
                    <Dropdown.Item href="/">Login</Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>

        <div className="hidden form-group has-search search-mobile ">
          <span className="search-icon">
            <SearchMobile />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Search for products"
            // onKeyDownCapture={searchProducts}
            ref={searchRef}
          />
        </div>
      </nav>

      {showModal ? (
        <div className="absolute gap-8 flex z-30 top-0 w-full h-full bg-[#808080] bg-opacity-10 backdrop-blur-sm ">
          <div className="flex w-[90%]">
            <div className="w-[90%]">
              <NavigationModal
                allowScroll={allowScroll}
                setShowModal={setShowModal}
                handlePointToCash={handlePointToCash}
              />
            </div>
            <div
              onClick={() => {
                setShowModal(false);
                allowScroll();
              }}
              className="w-fit h-fit"
            >
              <MobileModalCancel />
            </div>
          </div>
        </div>
      ) : null}

      {pointToCash && (
        <PointToCash
          setPointToCash={setPointToCash}
          setSuccessConversion={setSuccessConversion}
          pointToCash={pointToCash}
          successConversion={successConversion}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default CategoryNav;
