import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { searchFlightDetails } from "../../../components/FlightService/FlightService";

export const searchFlightResults = createAsyncThunk(
    "flight/searchFlightResults",
    async (result) => {
        try {
      
      const response = await searchFlightDetails(result);
      return response.data
    } catch (error) {
      console.error(error.message);
    }
  }
);


const flightSlice = createSlice({
  name: "flight",
  initialState: {
    searchedResults: [],
    isGettingResults: true,
    search: ""
  },
  reducers: {
      searchFlight: (state, action)=>{
          state.search = action.payload;
      }
  },
  extraReducers: (builder) => {
    builder.addCase(searchFlightResults.pending, (state) => {
      state.isGettingResults = true;
    });
    builder.addCase(searchFlightResults.fulfilled, (state, action) => {
      state.searchedResults = action.payload;
      state.isGettingResults = false;    
    });
    builder.addCase(searchFlightResults.rejected, (state) => {
      state.isGettingResults = true;
    });
  },
});

export const { searchedResults, isGettingResults, searchFlight } = flightSlice.actions;
export default flightSlice.reducer;




