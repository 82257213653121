import React, { useEffect, useState } from "react";
// import EventSlider from '../../components/EventSlider/EventSlider';
import "./EventsLandingPage.css";

import EventCard from "../../components/EventCard/EventCard";
import SingleEvent from "../../components/SingleEvent/SingleEvent";
import Categories from "../../components/Categories/Categories";
import AdBanner from "../../components/AdBanner/AdBanner";
// import CurrentPageNav from '../../components/Navbar/CurrentPageNav';
import EventModal from "../../components/EventModal/EventModal";
import useLockScrolling from "../../components/lockScroll/lockScroll";
// import {useDispatch, useSelector} from 'react-redux';
// import {getAllEvents} from '../../redux-toolkit/features/Events/EventsSlice';
import eventSliderImage from "../../assets/images/Events.png";
import ApiConfig from "../../service/ApiConfig";
import HttpService from "../../service/HttpService";
import { useQuery } from "react-query";
import { TailwindSpinner } from "../../components/Spinner/TailwindSpinner";
import { getAccessToken, logout } from "../../config/helper";
import { ToastContainer, toast } from "react-toastify";
import { LoadingIcon } from "../../assets/icons";
const EventsLandingPage = () => {
  // const dispatch = useDispatch();
  const [showSingleEvent, setShowSingleEvent] = useState(false);
  const [pickedEvent, setPickedEvent] = useState({});
  // const [vipTicketNumber, setVipTicketNumber] = useState(0);
  // const [earlyBirdTicketNumber, setEarlyBirdTicketNumber] = useState(0);
  // const [regularTicketNumber, setRegularTicketNumber] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [limit, setLimit] = useState(4);
  const [allEvents, setAllEvents] = useState([]);
  const [suggestItems, setSuggestItems] = useState([]);
  const [max, setMax] = useState(0);
  useLockScrolling(showSuccessModal);
  ////////////// GETTING ALL EVENTS START/////////////////

  const params = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  const getAllEventsList = async () => {
    try {
      const response = await HttpService.get(ApiConfig.events, params);

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        //import { ToastContainer, toast } from "react-toastify";
        // <ToastContainer />
        setTimeout(() => {
          logout();
        }, 4000);
      }
    }
  };

  const { data, isLoading, isError } = useQuery("allEvents", getAllEventsList, {
    staleTime: 300000,
  });

  // console.log(data, 'all event data use query');
  useEffect(() => {
    //  dispatch(getAllEvents());
    setMax(data?.data?.events?.length);
  }, [data]);
  /////////////////////////////////////

  useEffect(() => {
    setAllEvents(data?.data?.events);
  }, [data]);

  // GETTING ALL EVENTS END.

  const handleLoadMoreEvents = () => {
    if (limit <= max) {
      setLimit(limit + 4);
    }
  };

  const handleShowSingleEvent = (id) => {
    setShowSingleEvent(true);
    const result = allEvents.filter((each) => each.id === id);
    const suggestItems = allEvents.filter((each) => each.id !== id);
    const truncatedItems = suggestItems.slice(0, 4);
    setSuggestItems(truncatedItems);
    setPickedEvent(...result);
  };

  console.log(allEvents, "allEvents");

  return (
    <div className="event-main-container">
      <Categories />
      <AdBanner />
      {/* <CurrentPageNav /> */}
      {!showSingleEvent && (
        <div>
          <div className="event-hero-img-container">
            <img
              className="event-hero-img"
              src={eventSliderImage}
              alt="event img"
            />
          </div>
          {/* <EventSlider sliderImage1={eventSliderImage} sliderImage2={eventSliderImage} sliderImage3={eventSliderImage} /> */}
          {isLoading ? (
            <div className="loading-events">
              <LoadingIcon />
            </div>
          ) : allEvents?.length > 0 ? (
            <div className="events-outter-container">
              <div className="events-container">
                <h1>Latest Events</h1>
                <div className="event-cards-container">
                  {allEvents
                    ?.slice(0, limit)
                    ?.map(
                      ({
                        title,
                        amount,
                        time,
                        date,
                        id,
                        artwork,
                        description,
                        venue,
                        tickets,
                        banner,
                      }) => {
                        return (
                          <EventCard
                            banner={banner}
                            artwork={artwork}
                            venue={venue}
                            title={title}
                            tickets={tickets}
                            amount={amount}
                            description={description}
                            id={id}
                            time={time}
                            date={date}
                            handleShowSingleEvent={handleShowSingleEvent}
                          />
                        );
                      }
                    )}
                </div>
              </div>
              <div className="event-btn-container">
                <button
                  className="event-btn"
                  onClick={handleLoadMoreEvents}
                  disabled={limit >= max}
                >
                  Load More
                </button>
              </div>
            </div>
          ) : (
            <div className="no-events-container">
              <div className="no-events-inner-container">
                <h1>No events found.</h1>
                <p>
                  Check back soon for more events! Feel free to explore other
                  categories.
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      {showSingleEvent && (
        <SingleEvent
          pickedEvent={pickedEvent}
          setShowSuccessModal={setShowSuccessModal}
          suggestItems={suggestItems}
          handleShowSingleEvent={handleShowSingleEvent}
        />
      )}

      {showSuccessModal && (
        <EventModal setShowSuccessModal={setShowSuccessModal} />
      )}
      <ToastContainer />
    </div>
  );
};

export default EventsLandingPage;
