import React, { useState, useRef, useEffect } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import Categories from "../../components/Categories/Categories";
import "./OptOut.css";
import OptOutModal from "./OptOutModal";
import axiosProperty from "../../service/HttpService";
import ApiConfig from "../../service/ApiConfig";
import { authHeaders, logout } from "../../config/helper";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { ToastContainer, toast } from "react-toastify";

const OptOut = () => {
  const dropdownRef = useRef();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [successModal, setConsentModal] = useState(false);
  const [reason, setReason] = useState(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [textareaInput, setTextareaInput] = useState("");
  const optoutData = { reason: reason, more_reason: textareaInput };

  const selectReason = (reson) => {
    setReason(reson);
    setIsOpen(false);
  };

  const OptoutFn = async () => {
    try {
      const response = await axiosProperty.post(ApiConfig.optout, optoutData, {
        headers: authHeaders,
      });
      if (response.status === 200) {
        setConsentModal(false);
        logout();
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(OptoutFn);

  const handleOptout = () => {
    if (reason && textareaInput) {
      mutate();
    } else {
      toast.error("Please select a reason and provide more information", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
  };

  const reasonsArray = [
    "I don’t find the rewards valuable or relevant.",
    "The program is too complicated to use.",
    "I’ve found a better alternative elsewhere.",
    "I don’t understand how the program works.",
    "It takes too long to earn rewards.",
    "I’m not interested in loyalty programs.",
    "Other reasons.",
  ];

  useEffect(() => {
    const handleClose = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  });

  return (
    <div>
      <Categories />
      <div className="optout-page">
        <div className="optout-inner-page">
          <div>
            <h3>Opt Out</h3>
            <p>
              Note that you would lose all your FirstCoins when you opt out of
              the program
            </p>
            <div className="optout-dropdown-container" ref={dropdownRef}>
              <p className="optout-label"> Reason for Opting out </p>
              <button className="dropdown-button" onClick={toggleDropdown}>
                <p>{reason ?? "Select a reason"}</p>

                {isOpen ? <BsChevronUp /> : <BsChevronDown />}
              </button>
              {isOpen && (
                <ul className="dropdown-list">
                  {reasonsArray.map((each) => (
                    <li
                      style={{ padding: "1px" }}
                      key={each}
                      onClick={() => selectReason(each)}
                    >
                      {each}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="optout-textarea">
              <p className="optout-label">Tell us more</p>
              <textarea
                className="optout-textarea-field"
                name="textareaInput"
                value={textareaInput}
                onChange={(e) => setTextareaInput(e.target.value)}
              />
            </div>
            <div className="optout-btn-container">
              <button onClick={() => setConsentModal(true)}>Opt Out</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {successModal && (
        <OptOutModal
          isLoading={isLoading}
          handleOptout={handleOptout}
          setConsentModal={setConsentModal}
        />
      )}
    </div>
  );
};

export default OptOut;
