import React, {useEffect, useState} from 'react';
import './Hero.css';
import Navbar from './Navbar';
import FBNVideo from  "../../../assets/hero-section-video.mp4";
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

const Hero = () => {
  gsap.registerPlugin(ScrollTrigger);

  const [controlVideo, setControlVideo] = useState(false);

  window.addEventListener('scroll', () => {
    window.pageYOffset > 0 ? setControlVideo(true) : setControlVideo(false);
  });

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero-container',
        scrub: true,
        pin: true,
        start: '50% 50%',
        end: '+=200%',
      },
    });

    tl.from('.advert-video-container', {
      scale: 0.5,
      ease: 'none',
    })
      .to('.advert-video-container', {
        scale: 1, // Enlarge to full screen size
        ease: 'none',
        duration: 2, // Adjust the duration as needed
      })
      .to('.hero-container', {
        scale: 1, // Enlarge the hero-container to full screen size
        ease: 'none',
        duration: 1, // Adjust the duration as needed
      })
      .to('.advert-video-container', {
        scale: 0.5, // Return to the original scale
        ease: 'none',
      });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="hero-container">
        <div className="center-text-container">
          <h1 className="center-text">Banking that</h1>
          <h1 className="center-text">Rewards you</h1>
        </div>

        <div
          className={`${
            controlVideo ? 'advert-video-to-top' : 'advert-video-container'
          }`}>
          <video autoPlay muted loop className="video-ad">
            <source src={FBNVideo} type="video/mp4"></source>
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Hero;
