import HttpService from '../../service/HttpService';
import ApiConfig from '../../service/ApiConfig';
let result = JSON.parse(sessionStorage.getItem('first-bank-loyalty'));
const membershipID = result?.data.customer.Membership_id;

// const params = {"address_id": 4,

// "member_id": parseInt(membershipID)}

export const selectedAddressApi = async address_id => {
  const params = {
    address_id: Number(address_id),
    member_id: parseInt(membershipID),
  };

  return await HttpService.post(ApiConfig.selectedAddress, params);
};

// console.log(params, "params");

export const getDeliveryAddressListApi = async () => {
  return await HttpService.get(ApiConfig.userAddress);
};

export const editDeliveryAddress = async id => {
  const url = `${ApiConfig.edit}${id}/edit/${membershipID}`;
  return await HttpService.get(url);
};

export const getCitiesListApi = async id => {
  const url = `${ApiConfig.cities}/${id}`;

  // console.log(url, id);
  return await HttpService.get(url);
};

export const createDeliveryAddressApi = async data => {
  return await HttpService.post(ApiConfig.newAddress, data);
};

export const deleteDeliveryAddressApi = async id => {
  const url = `${ApiConfig.deleteAddress}${id}/delete/${membershipID}`;
  return await HttpService.get(url);
};

export const updateDeliveryAddressApi = async (id, data) => {
  const url = `${ApiConfig.updateAddress}${id}/update`;
  console.log(id, data, 'Update Address Service');
  return await HttpService.post(url, data);
};
