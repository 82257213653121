import React, { useRef, useEffect } from "react";
import "../../pages/Voucher/Voucher.css";
import modalImage from "../../assets/images/flight-and-hotel-modal.png";

const TransferSuccessModal = ({
  setTransferSuccessModal,
  setConfirmCoinTransferError,
}) => {
  const modalRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setTransferSuccessModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="delete-modal-container">
      <div className="inner-modal-container" ref={modalRef}>
        <img src={modalImage} alt="success modal" className="success-modal" />
        <p className="booking-text">Transfer Successful!</p>
        <p className="booking-text-1">
          You have succesfully sent Coins to another member
        </p>
        <button
          className="thanks-btn"
          onClick={() => [
            setTransferSuccessModal(false),
            setConfirmCoinTransferError(""),
          ]}
        >
          Okay Thanks!
        </button>
      </div>
    </div>
  );
};

export default TransferSuccessModal;
