import React, { useEffect, useState,useRef } from "react";
import { AiOutlineEye } from "react-icons/ai";
import logo from "../../../assets/images/logo.png";
import "./FirstTimeLogIn.css";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import {
  showPassword,
  hidePassword,
  showConfirmPassword,
  hideConfirmPassword,
} from "../../../redux-toolkit/features/password/showPasswordSlice";
import { firstTimeLogin } from "../../../redux-toolkit/features/defaultLogin/loginSlice";
import TermsAndCondition from "../../../components/TermsAndCondition/TermsAndConditon";
import { getData, handleRequest, myStore } from "../../../config/helper";

const FirstTimeLogIn = ({ nagateNav }) => {
  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
  const [checkMem, setCheckMem] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState({});
  const { show, visibility } = useSelector((store) => store.password);
  const dispatch = useDispatch();
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null)
  const [display, setDisplay] = useState("hidden");
  const [label, setLabel] = useState("")

  const [signUpData, setSignUpData] = useState({
    membershipId: "",
    password: "",
    confirmPassword: "",
    loggedIn: true,
  });


  const handleChange = (e) => {
    // const value = e.target.value;
    // setSignUpData({ ...signUpData, [e.target.name]: value });
    if (passwordRef.current.value.length < 6){
      setLabel("password should be at least 6 characters")
      setDisplay("visible")
    }else{
      setDisplay("hidden")
    }
  };

  const handleTermsAndConditionModal = (e) => {
   
    handleRequest(
      setLoginResponse,
      JSON.stringify({
        membership_id: getData(myStore)?.data?.customer?.Membership_id,
        new_password: passwordRef.current.value,
        confirm_password: passwordRef.current.value
      }),
      "api/reset_password"
    );

    if (signUpData.membershipId !== "" && signUpData.password !== "") {
     

      setShowTermsAndCondition(true);
      setCheckMem(false);
      setCheckPassword(false);
    }
  };

  useEffect(() => {
    nagateNav(false);
  }, []);

  useEffect(() => {
    if (loginResponse?.status == 1) {
      setShowTermsAndCondition(true);
      //window.location = "/shops";
    }
  }, [loginResponse]);

  return (
    <>
      <section className="first-time-login-container">
        <div className="first-time-login-left">
          <div className="input-container">
            <img src={logo} alt="first bank logo" className="first-time-logo" />
            <h1>First Time Log In</h1>
            <p className="info-para">
              Looks like this is your first time logging in. Please change your
              password to continue
            </p>
            <div className="title-and-input-container">
              <div>
                <p>Membership ID</p>
              
                <input
                  placeholder="Membership ID" style={{cursor:"none"}}
                  className={checkMem ? "warning-mem" : "first-time-login-input"}
                  name="membershipId"
                  value={getData(myStore)?.data?.customer?.Membership_id}
        
                />
              </div>
              <div className="new-password-mobile password-error-adjust">
                <p>New Password</p>
                <input
                  placeholder="Password"
                  type={show ? "text" : "password"}
                  className={checkPassword ? "warning-password" : "first-time-login-input"}
                  name="password"
                  ref={passwordRef}
                  onKeyUp={handleChange}
                
                />
                {show ? (
                  <AiOutlineEye
                    className="eye-icon"
                    onClick={() => dispatch(hidePassword())}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="eye-icon"
                    onClick={() => dispatch(showPassword())}
                  />
                )}
                   {(
                  <div className="warning ft-password-warning-adjust" style={{visibility:display
                  }}>
                   {label}
                  </div>
                )}
              </div>
              {/* <div>
                <p>Confirm Password</p>
                <input
                  type={visibility ? "text" : "password"}
                  className="first-time-login-input"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={signUpData.confirmPassword}
                  onChange={handleChange}
                />
                {visibility ? (
                  <AiOutlineEye
                    className="eye-icon"
                    onClick={() => dispatch(hideConfirmPassword())}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="eye-icon"
                    onClick={() => dispatch(showConfirmPassword())}
                  />
                )}
              </div> */}
            </div>
            <button
              className="login-firsttime-button"
              onClick={handleTermsAndConditionModal}
            >
              Login
            </button>
          </div>
        </div>
        <div className="first-time-login-right">
          <div className="first-time-login-right-inner-container">
            <h1 className="not-sure-text">Not sure how it works?</h1>
            <p className="not-sure-subtext">
              The First Rewards was launched in July 2023 as a way to say thank
              you to our customers.
            </p>
            <p className="not-sure-subtext">
              To enrol and receive rewards, simply download the mobile app and
              turn on Rewards in the menu OR walk into any of our branches and
              request to be enrolled.
            </p>
          </div>
        </div>
        {/* <div className="first-time-login-last-section-mobile">
          <p className="first-time-login-last-section-mobile-text"> Copyright 2022 FirstBank of Nigeria limited. An FBNHoldings Company.</p>
        </div> */}
      </section>
      <TermsAndCondition
        showModal={showTermsAndCondition}
        setShowTermsAndCondition={setShowTermsAndCondition}
        signUpData={signUpData}
        setSignUpData={setSignUpData}
      />
    </>
  );
};

export default FirstTimeLogIn;
