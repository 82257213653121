import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    membershipID: null,
    chevs: false
}


const forgotPasswordSlice = createSlice({
    name: "forgot password slice",
    initialState,
    reducers:{
        getMembershipID:(state, action)=>{
            state.membershipID = action.payload
        },
        showChevUp:(state)=>{
            state.chevs = true
        },
        showChevDown:(state)=>{
            state.chevs = false
        }

    }
})

export const {getMembershipID, showChevUp, showChevDown} = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;