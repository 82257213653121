import React from "react";
import { useParams } from "react-router-dom";
import { MovieList } from "../../components/Cards/CardList/Movie/MovieList";
import Categories from "../../components/Categories/Categories";
import CurrentPageNav from "../../components/Navbar/CurrentPageNav";

export default function SingleMovie() {
  const { product_code } = useParams();
  return (
    <div className="bg-[#111111]">
      <Categories />
      <MovieList product_code={product_code} />
    </div>
  );
}
