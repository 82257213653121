import React, {useState} from 'react';
import Categories from '../../../components/Categories/Categories';
import SubNav from '../../../components/Navbar/subNav';
import SingleCategoryHero from './SingleCategoryHero';
import {SingleCategoryList} from './SingleCategoryList';

export const SingleCategory = () => {
  const [activePage, setActivePage] = useState('all');
  return (
    <div>
      <Categories />
      <SubNav setActivePage={setActivePage} activePage={activePage} />
      {/* <SingleCategoryHero /> */}
      <SingleCategoryList activePage={activePage} />
    </div>
  );
};
