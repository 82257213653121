import React, { useState } from "react";
import AdBanner from "../../components/AdBanner/AdBanner";
import Categories from "../../components/Categories/Categories";
import "./SweepStakes.css";
import { getAccessToken, logout } from "../../config/helper";
import ApiConfig from "../../service/ApiConfig";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import PrizeWheelComponent from "../../components/WheelComponent/WheelComponent";
import image1 from "../../assets/images/images/sweepstake1.png";
import SweepStakeModal from "../../components/SweepStakes/SweepStakeModal";
import axiosProperty from "../../service/HttpService";
import { useQuery } from "react-query";
import { LoadingSVG } from "../../assets/icons";
import { useEffect } from "react";
import { AuctionCountDown } from "../../components";
import SpinWheelComponent from "../../components/WheelComponent/WheelComponent";
import SpinWheel from "../../components/WheelComponent/WheelComponent";
import { ToastContainer, toast } from "react-toastify";

const SingleSweepStake = () => {
  const { SweepStake_id: sweepstakeId } = useParams();
  const [winnerModal, setWinnerModal] = useState(false);
  const [rotationDuration, setRotationDuration] = useState(0)
  // const [singleSweepStake, setSingleSweepStake] = useState([]);

  // useEffect(()=>{

  // }, [])

  const fetchSweepstakes = async () => {
    // location.reload()
    // window.location.reload();
    try {
      const response = await axiosProperty.get(
        `${ApiConfig.getSweepStakes}/${sweepstakeId}`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      if (response.status === 200) {
        if (response?.data?.data?.Winner !== null) {
          setWinnerModal(true);
        }
      }
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          logout();
        }, 4000);
      }
    }
  };

  const {
    data: sweepStakes,
    isLoading,
    isError,
  } = useQuery("sweepstakes", fetchSweepstakes, {
    cacheTime: 0,
    retryDelay: 0,
    refetchOnMount: true,
  });

  // const handleSpinComplete = (segment) => {
  //   alert(`Congratulations! You won: ${segment.label}`);
  // };

  const calculateMilliseconds = (startDate, endDate) => {
    // Parse the dates
    const startTimestamp = new Date(startDate).getTime();
    const endTimestamp = new Date(endDate).getTime();
  
    
    // if (isNaN(startTimestamp) || isNaN(endTimestamp)) {
    //   throw new Error("Invalid date format. Please provide valid date strings.");
    // }
  
   
    // if (endTimestamp <= startTimestamp) {
    //   throw new Error("'endDate' must be later than 'startDate'.");
    // }
  
    
    return endTimestamp - startTimestamp;
  };

  return (
    <>
      <Categories />
      <div>
        <Breadcrumb />
      </div>
      {!sweepStakes?.Prizes ? (
        <div className="single-sweepstake-loader">
          <LoadingSVG />
        </div>
      ) : (
        <div className="single-sweepstake-container">
          <div className="single-sweepstake-upperbox">
            <div className="single-sweepstake-image-container">
              {sweepStakes?.images?.map((image) => (
                <div className="single-sweepstake-image-card" key={image} >
                  <img
                    src={image}
                    alt="prize"
                    className="single-sweepstake-image"
                  />
                </div>
              ))}
            </div>
            <div className="single-sweepstake-wheel-container">
              {!winnerModal && (
                <PrizeWheelComponent Prizes={sweepStakes?.Prizes} rotationDuration= { calculateMilliseconds(sweepStakes?.Creation_date, sweepStakes?.End_date)} />
              )}

              {/* {!winnerModal && <SpinWheel/>} */}
              {winnerModal && (
                <div className="single-sweepstake-lowerbox-winner">
                  <h1 className="sweepstake-card-title">
                    {sweepStakes?.Sweepstake_name}
                  </h1>
                  <p className="sweepstake-card-description">
                    {sweepStakes?.Description}
                  </p>
                  <p className="sweepstake-card-date">
                    Ends {sweepStakes?.End_date}
                  </p>
                </div>
              )}
            </div>
          </div>
          {!winnerModal && (
            <div className="single-sweepstake-lowerbox">
              <h1 className="sweepstake-card-title">
                {sweepStakes?.Sweepstake_name}
              </h1>
              <p className="sweepstake-card-description">
                {sweepStakes?.Description}
              </p>
              <p className="sweepstake-card-date">
                Ends {sweepStakes?.End_date}
              </p>
              <div>
            
                <AuctionCountDown endDate={sweepStakes?.End_date} startDate={sweepStakes?.Creation_date} />
              </div>
              {/* <p className="sweepstake-card-date">Ends 2nd December 2024</p> */}
            </div>
          )}
        </div>
      )}

      <div className="mt-[10em] mb-4">
        <AdBanner />
      </div>
      {winnerModal && <SweepStakeModal setWinnerModal={setWinnerModal} />}
      <ToastContainer />
    </>
  );
};

export default SingleSweepStake;
