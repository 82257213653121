export const landingPageHeroVideo = {
  hidden: {scale: 1, y: 0},
  show: {scale: 2.5, y: -200},
};

export const headerAnimations = {
  hidden: {
    y: 100,
    opacity: 0,
    transition: {
      type: 'spring',
      damping: 12,
      stiffness: 200,
    },
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 12,
      stiffness: 200,
      duration: 20,
    },
  },
};

export const headerAnimationsOne = {
  hidden: {
    y: 150,
    opacity: 0,
    transition: {
      type: 'spring',
      damping: 12,
      stiffness: 200,
    },
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 12,
      stiffness: 200,
      duration: 25,
    },
  },
};

export const navAnimation = {
  hidden: {y: -20, opacity: 0},
  show: {y: 1, opacity: 1},
};

export const homeAnimation = {
  hidden: {x: -400, opacity: 0},
  show: {x: 0, opacity: 1},
};

export const sectionTwoImageAnimationOne = {
  hidden: {y: 200, opacity: 0},
  show: {y: 0, opacity: 1},
};

export const sectionTwoImageAnimationsOne = {
  hidden: {scale: 0, opacity: 0},
  show: {scale: 1, opacity: 1},
};

export const sectionTwoTextAnimation = {
  hidden: {x: 200, opacity: 0},
  show: {x: 1, opacity: 1},
};

export const containerText = {
  show: (i = 1) => ({
    opacity: 1,
    transition: {staggerChildren: 0.005, delayChildren: i},
  }),
  hidden: {
    opacity: 0,
  },
};

export const waveText = {
  show: {
    y: 0,
    opacity: 1,

    transition: {
      type: 'spring',
      damping: 12,
      stiffness: 200,
    },
  },
  hidden: {
    y: 1,
    opacity: 0,

    transition: {
      type: 'spring',
      damping: 12,
      stiffness: 200,
    },
  },
};

export const waveTextMagic = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {
      delay: 1.5, // Adjust the delay based on the duration of sectionOneAnimation
      ease: 'easeOut',
      duration: 1.5,
    },
  },
};

export const sectionOneAnimation = {
  hidden: {x: '-200%', opacity: 0},
  show: {
    x: '200%', // Set the desired value to move the element across the screen
    opacity: 1,
    transition: {
      delay: 0.5,
      ease: 'easeOut',
      duration: 2,
    },
  },
};

export const sectionOneAnimationParag = {
  hidden: {x: -100, opacity: 0},
  show: {
    x: '20%',
    opacity: 1,
  },
};

export const sectionOneAnimationText = {
  hidden: {x: -200, opacity: 0},
  show: {x: 1, opacity: 1},
};

export const sectionTwoTextAnimationOne = {
  hidden: {x: -200, opacity: 0},
  show: {x: 1, opacity: 1},
};

export const sectionTwoImageAnimationThree = {
  hidden: {x: -200, opacity: 0},
  show: {x: 1, opacity: 1},
};

// gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

//Comment me out to see issue
// const smoother = ScrollSmoother.create({
//   wrapper: "#smooth-wrapper",
//   content: "#smooth-content",
//   smooth: 2,
//   normalizeScroll: true,
//   ignoreMobileResize: true,
//   effects: true,
//   preventDefault: true,
// });

//Horizontal Scroll Galleries
// if (document.getElementById("portfolio")) {
//   const horizontalSections = gsap.utils.toArray(".horiz-gallery-wrapper");

//   horizontalSections.forEach(function (sec, i) {
//     const pinWrap = sec.querySelector(".horiz-gallery-strip");

//     let pinWrapWidth;
//     let horizontalScrollLength;

//     function refresh() {
//       pinWrapWidth = pinWrap.scrollWidth;
//       horizontalScrollLength = pinWrapWidth - window.innerWidth;
//     }

// window.addEventListener("load", function () {
// refresh();
// Pinning and horizontal scrolling
// let scrollTween = gsap.to(pinWrap, {
//   scrollTrigger: {
//     scrub: true,
//     trigger: sec,
//     pin: sec,
//     start: "center center",
//     end: () => `+=${pinWrapWidth}`,
//     invalidateOnRefresh: true,
//   },
//   x: () => -horizontalScrollLength,
//   ease: "none",
// });

//     pinWrap.querySelectorAll("[data-speed-x]").forEach((el, i) => {
//       let speed = parseFloat(el.getAttribute("data-speed-x"));
//       gsap.to(el, {
//         x: () => (1 - speed) * (window.innerWidth + el.offsetWidth),
//         ease: "none",
//         scrollTrigger: {
//           containerAnimation: scrollTween,
//           trigger: el,
//           onRefresh: (self) => {
//             let start = Math.max(0, self.start);
//             self.setPositions(
//               start,
//               start + (self.end - self.start) / Math.abs(speed)
//             ); // adjust for how long it'll stay in view
//             self.animation.progress(0);
//           },
//           scrub: true,
//         },
//       });
//     });

//     ScrollTrigger.addEventListener("refreshInit", refresh);

//   });
// }
