import React from "react";
import "./RedeemCarousel.css";
import Carousel from "react-multi-carousel";
import aeroplaneImage from "../../assets/images/redeem-aeroplane.png";
import movieImage from "../../assets/images/redeem-movie.png";
import experienceImage from "../../assets/images/redeem-experience.png";
import airtimeImage from "../../assets/images/redeem-airtime.png";
import discountImage from "../../assets/images/redeem-discount.png";
import hotelImage from "../../assets/images/redeem-hotel.png";
import { Link } from "react-router-dom";


const RedeemCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const data = [
   
    { image: movieImage, name: "Movies", id: 2, url: "/movies" },
   
    {
      image: airtimeImage,
      name: "Airtime & Bills",
      id: 4,
      url: "/airtime&bills",
    },
    { image: discountImage, name: "Discounts", id: 5, url: "/discounts" },
    { image: hotelImage, name: "Hotels", id: 6, url: "/hotel" },
    // { image: aeroplaneImage, name: "Flights", id: 1, url: "/flight" },
    { image: experienceImage, name: "Experiences", id: 3, url: "/experience" },
  ];

  return (
    <>
      <div className="redeem-carousel-main">
        <Carousel responsive={responsive} arrows={true} ssr={true} className="redeem-carousel" >
          {data.map((each) => (
            <div className="redeem-card" key={each.id}>
              <div className="redeem-card-image-container">
                <img
                  src={each.image}
                  className="redeem-card-image"
                  alt={each.name}
                />
                

              </div>
              <div className="redeem-button-container">
                <h4 className="redeem-title">{each.name}</h4>
                <Link to={each.url}>
                  <button className="redeem-button">Redeem vouchers</button>
                </Link>

                
              </div>
              <div className="redeem-pricing-container">
                  <div className="redeem-pricing">from 500 FirstCoins</div>
                </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className="mobile-redeem-slider">
        {data.map((each) => (
          <div className="redeem-card" key={each.id}>
            <div className="redeem-card-image-container">
              <img
                src={each.image}
                className="redeem-card-image"
                alt={each.name}
              />
            </div>
            <div className="redeem-button-container">
              <h4 className="redeem-title">{each.name}</h4>
              <Link to={each.url}>
                <button className="redeem-button">Redeem vouchers</button>
              </Link>

              <div className="redeem-pricing-container-mobile">
                <div className="redeem-pricing-mobile">from 500 FirstCoins</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RedeemCarousel;
