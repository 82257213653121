import React, {useRef, useEffect} from 'react';
import './Discount.css';
import modalImage from '../../assets/images/flight-and-hotel-modal.png';

const DiscountSuccessModal = ({setShowSuccessModal}) => {
  const modalRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowSuccessModal(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="delete-modal-container">
      <div className="inner-modal-container" ref={modalRef}>
        <img src={modalImage} alt="success modal" className="success-modal" />
        <p className="booking-text text-center">Voucher Generated!</p>
        <p className="booking-text-1 text-center">
          Please check your registered FirstBank email for your voucher details
          and code
        </p>
        <button
          className="thanks-btn"
          onClick={() => setShowSuccessModal(false)}>
          Okay Thanks!
        </button>
      </div>
    </div>
  );
};

export default DiscountSuccessModal;
