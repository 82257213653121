import React, {useEffect, useState} from 'react';
import {useQuery, useMutation} from 'react-query';
// import { useHistory } from "react-router-dom";
import './Voucher.css';
import Categories from '../../components/Categories/Categories';
import AdBanner from '../../components/AdBanner/AdBanner';
import VoucherCard from './VoucherCard';
import Uber from '../../assets/images/uber.png';
import axios from 'axios';
import VoucherCheckout from './VoucherCheckout';
import { getAccessToken, logout } from '../../config/helper';
import { ToastContainer, toast } from "react-toastify";

// import useLockScrolling from "../../components/lockScroll/lockScroll";

const Voucher = () => {
  const [vouchers, setVouchers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
       `${process.env.REACT_APP_FIRSTBANK_BASEURL}/vouchers`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`
        }
      }
      )
      .then(res => {
     
        
        setVouchers(res?.data?.data[0]);
      }).catch(err => {
        console.log('err', err);
        if (err.response.status === 401) {
          toast.error("Authentication failed. Please log in again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
  
           //import { ToastContainer, toast } from "react-toastify";
           // <ToastContainer />
          setTimeout(()=>{
  
            logout()
          }, 4000)
       
        } 
      }).finally(setIsLoading(false));
  }, []);

  if (isLoading) return <div>Loading...</div>;

 
  return (
    <div>
      <Categories />
      <AdBanner />
      <div className="discount-container">
        <div className="get-voucher">Grab a voucher with your FirstCoins</div>
        <div className="card-container">
          <div className="inner-card-container">
            {vouchers?.map(each => {
              return <VoucherCard each={each} />;
            })}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Voucher;
