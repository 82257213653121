import HttpService from "../../service/HttpService";
import ApiConfig from "../../service/ApiConfig";
import {useSelector} from "react-redux"

const params = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    token: process.env.REACT_APP_FLIGHT_KEY,
  },
};

export const searchFlightDetails = async (result) => {
    
    try {
   
   const allData =  await HttpService.get(ApiConfig.flightSearch.concat(result), params)
    return allData.data
  } catch (error) {
    console.error(error.message);
  }
}
