import React from 'react';
import './MobileItemList.css';

// import food from '../../../../assets/food.png';
// import basket from '../../../../assets/basket.png';
// import aeroplane from '../../../../assets/aeroplane.png';
import {getMemberId} from '../../../../config/helper';
import {Link} from 'react-router-dom';

// const data = [
//   {
//     image: food,
//     title: 'Food',
//     id: 1,
//     text: 'Online supermarket where you can find everyday groceries, household items and more.',
//   },
//   {
//     image: basket,
//     title: 'Shop',
//     id: 2,
//     text: 'Online supermarket where you can find everyday groceries, household items and more.',
//   },
//   {
//     image: aeroplane,
//     title: 'Travel',
//     id: 3,
//     text: 'Online supermarket where you can find everyday groceries, household items and more.',
//   },
//   {
//     image: food,
//     title: 'Food',
//     id: 4,
//     text: 'Online supermarket where you can find everyday groceries, household items and more.',
//   },
//   {
//     image: basket,
//     title: 'Shop',
//     id: 6,
//     text: 'Online supermarket where you can find everyday groceries, household items and more.',
//   },
//   {
//     image: aeroplane,
//     title: 'Travel',
//     id: 5,
//     text: 'Online supermarket where you can find everyday groceries, household items and more.',
//   },
//   {
//     image: basket,
//     title: 'Shop',
//     id: 10,
//     text: 'Online supermarket where you can find everyday groceries, household items and more.',
//   },
//   {
//     image: aeroplane,
//     title: 'Travel',
//     id: 7,
//     text: 'Online supermarket where you can find everyday groceries, household items and more.',
//   },
// ];

const MobileItemList = ({data}) => {
  const result = getMemberId();
  return (
    <div className="mobile-item-list">
      {data.map(each => {
        const {image, title, id, text} = each;
        return (
          <div className="mobile-card" key={id}>
            <img src={image} alt={title} className="mobile-card-image" />
            <div>
              <h1 className="mobile-card-title">{title}</h1>
              <p className="mobile-card-text">{text}</p>
              {/* <button className="mobile-card-btn">
                </button> */}
              {result ? (
                <Link to="/redeem">
                  <button className="mobile-card-btn">Explore</button>
                </Link>
              ) : (
                <Link to="/login">
                  <button className="mobile-card-btn">Explore</button>
                </Link>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileItemList;
