import React, { useState, useEffect, useRef, useContext } from "react";
import Categories from "../../components/Categories/Categories";
import { useQuery, useMutation } from "react-query";
import "./Profile.css";
import "./OrderHistory.css";
import "./TransferCoin.css";
import "./DeliveryAddresses.css";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import {
  AuctionIcon,
  ChangeIcon,
  DashboardIcon,
  DeliveryIcon,
  GoldBigIcon,
  GoldIcon,
  OffVisibilityIcon,
  OrderIcon,
  PdfIcon,
  PlatinumBigIcon,
  PlatinumIcon,
  SilverBigIcon,
  SilverIcon,
  StatementIcon,
  TierIcon,
  TransferIcon,
  TrophyIcon,
  VisibilityIcon,
  XlsxIcon,
} from "../../assets/svg/icons";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  BeneficiaryDelete,
  LoadingIcon,
  Rocket,
  PasswordTimes,
  PasswordMark,
} from "../../assets/icons";
import axios from "axios";
import {
  authHeaders,
  baseurl,
  formatValue,
  getAccessToken,
  logout,
  storeData,
} from "../../config/helper";
import { endpoints } from "../../utils/constant";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TransferCoinModal from "./TransferCoinModal";
import ForgotPinModal from "./ForgotPinModal";
import TransferSuccessModal from "./TransferSuccessModal";
import useLockScrolling from "../../components/lockScroll/lockScroll";
import StatementModal from "../../components/Modals/StatementModal";
import PasswordModal from "../../components/Modals/PasswordModal";
import PinModal from "../../components/Modals/PinModal";
import AuctionModal from "../../components/Modals/AuctionModal";
import DeleteBeneficiaryModal from "./DeleteBeneficiaryModal";
import {
  setDashboard,
  setTier,
  setStatements,
  setTransfer,
  setPin,
  setPassword,
} from "../../redux-toolkit/features/dashboard/dashboardSlice";
import { states } from "../../data/states";
import Pagination from "../../components/Pagination/Pagination";
import { TailwindSpinner } from "../../components/Spinner/TailwindSpinner";
import { AppContext } from "../../components/AppContext";

let result = JSON.parse(sessionStorage.getItem("first-bank-loyalty"));
const membershipID = result?.data.customer.Membership_id;
const Profile = () => {
  const { balanceStateCheck, setBalanceStateCheck } = useContext(AppContext);
  const editAddressModal = useRef(null);
  const modalRef = useRef(null);
  const [selectedId, setSelectedId] = useState(null);
  const [addressSaved, setAddressSaved] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statement, setStatement] = useState([]);
  const [oldPasssword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [profilePasswordStatus, setProfilePasswordStatus] = useState(null);
  const [profileErrorOne, setProfileErrorOne] = useState(false);
  const [profileErrorTwo, setProfileErrorTwo] = useState(false);
  const [profileErrorThree, setProfileErrorThree] = useState(false);
  const [showFilteredTable, setShowFilteredTable] = useState(true);
  const [showFilteredTableResult, setShowFilteredTableResult] = useState(true);
  // setProfileAddress;
  // selectedState
  const [transferSuccessModal, setTransferSuccessModal] = useState(false);
  const [addressFromCityId, setAddressFromCityId] = useState(null);
  const [selectedState, setStateOption] = useState("State");
  const [selectedCity, setCityOption] = useState("City");
  const [editAddressFormGroup, setEditAddressFormGroup] = useState(false);
  const [clickState, setClickState] = useState(0);
  const [deleteProfileAddress, setDeleteProfileAddress] = useState(false);
  const [isEditAlert, setIsEditAlert] = useState(false);

  const [isDeleteAlert, setIsDeleteAlert] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  // const [addressFromStateId, setAddressFromStateId] = useState("");
  const [accessKey, setAccessKey] = useState("");

  useEffect(() => {
    const key = sessionStorage.getItem("accessToken");
    setAccessKey(key);
  }, []);

  const options = states;
  const [addressFormGroup, setAddressFormGroup] = useState({
    first_name: "",
    last_name: "",
    state_id: selectedId,
    city_id: addressFromCityId,
    address: "",
    phone_no: "",
    email_address: "",
    member_id: membershipID,
  });

  const [auction, setAuction] = useState([]);
  const dispatch = useDispatch();
  const {
    addAddress,

    checkoutForm,

    isEdit,
  } = useSelector((store) => store.cart);
  const [addressForm, setAddressForm] = useState(false);
  const [changeProfileAddress, setChangeProfileAddress] = useState(false);
  const [deleteMyAddress, setDeleteMyAddress] = useState(false);
  const [getAddressId, setGetAddressId] = useState(
    addAddress.defaultAddress?.address_id
  );
  const [city, setCity] = useState("");
  const [editForm, setEditForm] = useState(false);

  const [beneficiary, setBeneficiary] = useState(false);
  const [checkboxState, setCheckboxState] = useState(0);
  const [passwordModal, setPasswordModal] = useState(false);
  const [transferCoinError, setTransferCoinError] = useState("");
  const [forgotPinModal, setForgotPinModal] = useState(false);
  const [confirmCoinTransferError, setConfirmCoinTransferError] = useState("");
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const [pinModalIsOpen, setPinModalIsOpen] = useState(false);
  const [auctionModalIsOpen, setAuctionModalIsOpen] = useState(false);
  const [beneficiaryDeleteModal, setBeneficiaryDeleteModal] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState(null);
  // const [tierPercentage, setTierPercentage] = useState(50);

  const navigate = useNavigate();
  // setStateOption;
  const [transferData, setTransferData] = useState({
    membership_id: membershipID,
    recepient_id: "",
    amount: "",
    checkbox: checkboxState,
  });

  const [transferCoinPassword, setTransferCoinPassword] = useState("");

  const [confirmDataTransfer, setConfirmDataTransfer] = useState({
    ...transferData,
    user_pin: "",
  });

  const handleTransferChange = (e) => {
    const { name, value } = e.target;

    setTransferData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [selectedOption, setSelectedOption] = useState("");
  const ITEMS_PER_PAGE = 10;
  const uniqueResponse = {};

  useEffect(() => {
    setConfirmDataTransfer({ ...transferData, user_pin: transferCoinPassword });
  }, [transferData, transferCoinPassword]);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        `${baseurl}/profile?token=LSLonlypass&membership_id=${membershipID}`,
        { headers: authHeaders }
      );

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          logout();
        }, 4000);
      }
    }
  };

  const {
    isLoading,
    error,
    data,
    refetch: refetchDashboard,
  } = useQuery("dashboard", fetchDashboardData);

  // GETTING ALL CITIIES START

  // console.log(cityData, "cityData");

  // GETTING ALL CITIES END

  const {
    isLoading: loadingBeneficiaries,
    error: beneficiariesError,
    data: beneficiariesData,
    refetch: refetchBeneficiaries,
  } = useQuery("beneficiaries", () =>
    axios
      .get(
        `${baseurl}/profile/my_beneficiaries?membership_id=${membershipID}`,
        { headers: authHeaders }
      )
      .then((res) => res.data)
      .then((res) => res.data)
  );

  const deleteBeneficiaryMutation = useMutation((id) =>
    axios.get(
      `${baseurl}/profile/beneficiary/delete?membership_id=${membershipID}&id=${id}`,
      { headers: authHeaders }
    )
  );

  const HandleDeleteBeneficiary = async () => {
    await deleteBeneficiaryMutation.mutateAsync(beneficiaryId);
    refetchBeneficiaries();
    setBeneficiaryDeleteModal(false);
  };

  // Transfer coin function start

  const transferCoinsMutation = useMutation((data) =>
    axios.post(`${baseurl}/points/transfer`, data, { headers: authHeaders })
  );

  const transferCoinsConfirmMutation = useMutation((data) =>
    axios.post(`${baseurl}/points/transfer/confirm`, data, {
      headers: authHeaders,
    })
  );

  const forgotPinMutation = useMutation((data) =>
    axios.post(`${baseurl}/change_pin`, data, { headers: authHeaders })
  );

  const handleForgotPin = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPinMutation.mutateAsync({
        membership_id: membershipID,
      });
      if (response.data.status === 1) {
        setPasswordModal(true);
        setForgotPinModal(false);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const [confirmPointsTransfer, setConfirmPointsTransfer] = useState(false);

  const handleConfirmTransferSubmit = async (e) => {
    e.preventDefault();
    setConfirmPointsTransfer(true);
    try {
      const response = await transferCoinsConfirmMutation.mutateAsync(
        confirmDataTransfer
      );
      //console.log('Tranresponse', response);
      if (response.data.status === 1) {
        refetchBeneficiaries();
        setPasswordModal(false);
        refetchDashboard();
        setTransferSuccessModal(true);
        setConfirmPointsTransfer(false);
        setTransferCoinPassword("");
        setConfirmCoinTransferError(" ");
        setConfirmDataTransfer({
          ...transferData,
          user_pin: "",
        });
        setBalanceStateCheck(!balanceStateCheck);
        setTransferData({
          membership_id: membershipID,
          recepient_id: "",
          amount: "",
          checkbox: checkboxState,
        });
      }
      if (response.data.status === 400) {
        // console.log(response.data.message);
        setConfirmPointsTransfer(false);
        setConfirmCoinTransferError(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const [transferPoints, setTransferPoints] = useState(false);

 
  


  const handleTransferSubmit = async (e) => {
    e.preventDefault();
    setTransferPoints(true);
    try {
      const response = await transferCoinsMutation.mutateAsync(transferData);
      // console.log('response', response);
      if (response.data.status === 200) {
        setPasswordModal(true);
        setTransferPoints(false);
        setTransferCoinError("");
      }

      if (response.data.status === 0) {
        setTransferPoints(false);
      }

      setTransferCoinError(response.data.message);
    } catch (error) {
      setTransferPoints(false);
      // console.error('error', error);
      console.error(error.message);
    }
  };

  // Transfer point functions end
  /* The above code is declaring a state variable called `currentPage` using the `useState` hook in a
React component. The initial value of `currentPage` is set to 1. The `setCurrentPage` function can
be used to update the value of `currentPage` later in the component's lifecycle. */
  const [currentPage, setCurrentPage] = useState(1);
  const [statementCurrentPage, setStatementCurrentPage] = useState(1);
  const [statementFilterPage, setStatementFilterPage] = useState(1);
  // const [auctionPage, setAuctionPage] = useState(1);

  // Calculate the total number of pages
  /* The above code is calculating the total number of pages needed to display a list of items in a
paginated format. It uses the length of the `orderHistoryData` array and divides it by the constant
`ITEMS_PER_PAGE`, then rounds up to the nearest integer using the `Math.ceil()` method. The result
is stored in the `totalPages` variable. The `?.` operator is used to check if `orderHistoryData` is
not null or undefined before accessing its length property. */
  const totalPages = Math.ceil(orderHistory?.length / ITEMS_PER_PAGE);
  // const statementTotalPages = Math.ceil(statement?.length / ITEMS_PER_PAGE);
  // const filteredTotalPages = Math.ceil(
  //   filteredTableResult?.length / ITEMS_PER_PAGE,
  // );
  const auctionTotalPages = Math.ceil(auction?.length / ITEMS_PER_PAGE);

  // Calculate the index of the first and last item on the current page
  const firstIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const lastIndex = firstIndex + ITEMS_PER_PAGE;
  const statementFirstIndex = (statementCurrentPage - 1) * ITEMS_PER_PAGE;
  const statementLastIndex = statementFirstIndex + ITEMS_PER_PAGE;
  const filteredFirstIndex = (statementFilterPage - 1) * ITEMS_PER_PAGE;
  const filteredLastIndex = filteredFirstIndex + ITEMS_PER_PAGE;
  // const auctionFirstIndex = (auctionPage - 1) * ITEMS_PER_PAGE;
  // const auctionLastIndex = auctionFirstIndex + ITEMS_PER_PAGE;

  // Slice the data array to get the items for the current page

  // const currentData = orderHistory?.slice(firstIndex, lastIndex);
  // const statementCurrentData = statement?.slice(
  //   statementFirstIndex,
  //   statementLastIndex,
  // );

  // const filteredCurrentData = filteredTableResult?.slice(
  //   filteredFirstIndex,
  //   filteredLastIndex,
  // );

  // data.data

  // const auctionCurrentData = auction?.slice(0, 10);

  // handle statement transaction type function
  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  // const orders = currentData?.map(order => {
  //   const deliveryItems = order?.orderItems?.filter(
  //     item => item.delivery_method === 'Delivery',
  //   );

  //   const pickupItems = order?.orderItems?.filter(
  //     item => item.delivery_method === 'Pick up',
  //   );

  //   return {order, deliveryItems, pickupItems};
  // });

  //  END

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setChangeProfileAddress(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        editAddressModal.current &&
        !editAddressModal.current.contains(event.target)
      ) {
        setAddressForm(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [oldPassswordCheck, setOldPasswordCheck] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [isSendingPassword, setIsSendingPassword] = useState(false);

  const sendPassword = async () => {
    try {
      setIsSendingPassword(true);
      const passwordRes = await axios.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.profile_password}`,
        {
          confirm_password: confirmNewPassword,
          current_password: oldPasssword,
          new_password: newPassword,
          membership_id: membershipID,
        },
        { headers: authHeaders }
      );

      if (passwordRes?.data?.status === 1) {
        handleOpenPasswordModal();
      } else if (passwordRes?.data?.status === 1002) {
        setOldPasswordCheck(true);
        setProfilePasswordStatus(passwordRes?.data);
        setIsPasswordMatch(false);
      } else {
        setIsPasswordMatch(true);
        setOldPasswordCheck(false);
        setProfilePasswordStatus(passwordRes?.data);
      }

      // if (passwordRes?.data?.status === 0) {
      //   setProfilePasswordStatus(passwordRes?.data);
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSendingPassword(false);
    }
  };

  const sendPin = async () => {
    try {
      const pinRes = await axios.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.change_pin}`,
        {
          membership_id: membershipID,
        },
        { headers: authHeaders }
      );
      if (pinRes?.data?.status === 1) {
        handleOpenPinModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // RASAQ LOGIC SECTION END

  // const [passwordLengthCheck, setPasswordLengthCheck] = useState(false);
  const [isEightChars, setIsEightChars] = useState(false);
  const [hasLetter, setHasLetter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [similarPassword, setSimilarPassword] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);

  const handleSubmitPassword = (event) => {
    event.preventDefault();

    // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

    // const isPasswordValid = passwordRegex.test(newPassword);
    if (oldPasssword.trim() === "") {
      setProfileErrorOne(true);
    } else if (newPassword.trim() === oldPasssword.trim()) {
      setSimilarPassword(true);
    } else if (newPassword.trim() === "") {
      setProfileErrorTwo(true);
    } else if (confirmNewPassword.trim() === "") {
      setProfileErrorThree(true);
    } else {
      sendPassword();
    }
  };

  useEffect(() => {
    setIsEightChars(newPassword.length >= 8);
    setHasLetter(/(?=.*[A-Za-z])/.test(newPassword));
    setHasNumber(/(?=.*\d)/.test(newPassword));
    setHasSpecialChar(/(?=.*[@$!%*#?&])/.test(newPassword));
    setHasUppercase(/(?=.*[A-Z])/.test(newPassword));
  }, [newPassword]);

  const handleSubmitPin = () => {
    sendPin();
  };

  //   panel navigation logic begins
  // const handleStateUpdate = (key) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     dashboard: false,
  //     tier: false,
  //     auction: false,
  //     statement: false,
  //     order: false,
  //     transfer: false,
  //     delivery: false,
  //     pin: false,
  //     password: false,
  //     [key]: true,
  //   }));
  // };

  const {
    dashboard,
    tier,

    statements,

    transfer,

    pin,
    password,
  } = useSelector((store) => store.dashboard);

  const handleDashboard = () => {
    dispatch(setDashboard());
    navigate("/profile");
  };

  const handleTier = () => {
    dispatch(setTier());
    navigate("/profile");
  };

  const handleStatement = () => {
    dispatch(setStatements());
    setShowFilteredTable(true);
    navigate("/profile");
  };

  const handleTransfer = () => {
    dispatch(setTransfer());
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("point-transfer")) {
      dispatch(setTransfer());
    }
  }, [location]);

  const handlePassword = () => {
    dispatch(setPassword());
    navigate("/profile");
  };

  const handlePin = () => {
    dispatch(setPin());
    navigate("/profile");
  };

  //   panel navigation logic ends

  // tier and   progress logic starts
  const percentage = 20;
  let barColor;
  let plateName = "";

  if (percentage <= 33) {
    barColor = <SilverBigIcon />;
    plateName = "silver";
  } else if (percentage <= 66) {
    barColor = <GoldBigIcon />;
    plateName = "gold";
  } else {
    barColor = <PlatinumBigIcon />;
    plateName = "platinum";
  }

  //   progress logic ends

  // statement logic

  const uniqueData = statement
    .map((item) => {
      if (uniqueResponse[item.Transaction_type]) {
        return null;
      }
      uniqueResponse[item.Transaction_type] = true;
      return item;
    })
    .filter((item) => item !== null);

  // handle statement search logic

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Pad single-digit month and day values with leading zeros
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  const handleSearchStatement = () => {
    setShowFilteredTable(false);
    sendStatement();
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const exportToPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#table" });
    doc.save("transaction_reports.pdf");
  };

  // excel logic
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      filteredCurrentData.length > 0 ? filteredCurrentData : currentPageData
    );

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "transaction_reports.xlsx");
  };

  useLockScrolling(
    deleteMyAddress ||
      isEdit ||
      checkoutForm ||
      addressForm ||
      transferSuccessModal ||
      forgotPinModal ||
      passwordModal ||
      addressSaved
  );

  const handleOpenViewModal = (data) => {
    storeData("statement", data);
    setViewModalIsOpen(true);
  };
  const handleCloseViewModal = () => {
    setViewModalIsOpen(false);
  };

  const handleOpenPasswordModal = () => {
    setPasswordModalIsOpen(true);
  };

  const handleClosePasswordModal = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setPasswordModalIsOpen(false);
  };

  // password visibility
  const handleOldPasswordToggle = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleNewPasswordToggle = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleConfirmNewPasswordToggle = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleOpenPinModal = () => {
    setPinModalIsOpen(true);
  };

  const handleClosePinModal = () => {
    setPinModalIsOpen(false);
  };

  setTimeout(() => {
    setIsEditAlert(false);
    setIsDeleteAlert(false);
  }, 3000);

  const handleCloseAuctionModal = () => {
    setAuctionModalIsOpen(false);
  };

  //PAGINATION LOGIN
  const itemsPerPage = 10;
  const [currentNewPage, setCurrentNewPage] = useState(1);
  const [currentFilteredPage, setCurrentFilteredPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [filteredTableResult, setFilteredTableResult] = useState([]);
  const [filteredCurrentData, setFilteredCurrentData] = useState([]);

  const handlePageChange = (pageNumber) => {
    setCurrentNewPage(pageNumber);
  };

  const handleFilteredPageChange = (pageNumber) => {
    setCurrentFilteredPage(pageNumber);
  };

  const fetchStatement = async () => {
    try {
      const StatementRes = await axios.get(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.statement}${membershipID}`,
        { headers: authHeaders }
      );

      setStatement(StatementRes.data);
    } catch (error) {
      console.log(error);
    }
  };

  //auction

  const sendStatement = async () => {
    try {
      const stateRes = await axios.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.sort}`,
        {
          member_id: membershipID,
          start_date: startDate,
          end_date: endDate,
          type: selectedOption,
        },
        { headers: authHeaders }
      );
      if (stateRes.data.status === 1) {
        setFilteredTableResult(stateRes.data?.data);
        setShowFilteredTableResult(true);
      } else {
        setShowFilteredTableResult(false);
        setFilteredTableResult(stateRes?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStatement();
  }, []);

  useEffect(() => {
    const start = (currentNewPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const slicedData = statement?.slice(start, end);
    setCurrentPageData(slicedData);
  }, [currentNewPage, statement]);

  useEffect(() => {
    const start = (currentFilteredPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const slicedData = filteredTableResult?.slice(start, end);
    setFilteredCurrentData(slicedData);
  }, [currentFilteredPage, filteredTableResult]);

  //FOR MAIN STATEMENT


 
  
  

  if (isLoading) {
    return (
      <div className="loading-svg">
        <LoadingIcon />
      </div>
    );
  }
  return (
    <div className="bg-[#f8f8f8]">
      <Categories />
      <div className="flex gap-5 lg:w-3/4 lg:mx-auto py-9 first-container">
        {/* LEFT PANEL OF DASHBOARD */}
        <div className="hidden lg:flex min-w-[33%] h-fit space-y-16 py-9 bg-white flex-col">
          <div className="px-[30px]">
            <h6 className="capitalize">Welcome</h6>
            <p>{membershipID}</p>
          </div>
          <ul className="px-0 space-y-3 ">
            <li
              onClick={handleDashboard}
              className={`${
                dashboard ? "bg-dash font-medium" : "bg-white"
              } flex cursor-pointer gap-3 items-center  px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}
            >
              <DashboardIcon /> <span>Account Dashboard</span>
            </li>
            <li
              onClick={handleTier}
              className={`${
                tier ? "bg-dash font-medium" : "bg-white"
              } flex cursor-pointer gap-3 items-center px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}
            >
              <TierIcon /> <span>Tier Status</span>
            </li>
            <li
              onClick={handleStatement}
              className={`${
                statements ? "bg-dash font-medium" : "bg-white"
              } flex cursor-pointer gap-3 items-center px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}
            >
              <StatementIcon /> <span>Statement</span>
            </li>
            {/* <li
              onClick={handleOrder}
              className={`${
                order ? 'bg-dash font-medium' : 'bg-white'
              } flex cursor-pointer gap-3 items-center px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}>
              <OrderIcon /> <span>Order History</span>
            </li> */}
            {/* <li
              onClick={handleAuction}
              className={`${
                auctions ? 'bg-dash font-medium' : 'bg-white'
              } flex cursor-pointer gap-3 items-center px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}>
              <AuctionIcon /> <span>Auction Bid History</span>
            </li> */}
            <li
              onClick={handleTransfer}
              className={`${
                transfer ? "bg-dash font-medium" : "bg-white"
              } flex cursor-pointer gap-3 items-center px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}
            >
              <TransferIcon /> <span>Transfer FirstCoins</span>
            </li>
            {/* <li
              onClick={handleDelivery}
              className={`${
                delivery ? 'bg-dash font-medium' : 'bg-white'
              } flex cursor-pointer gap-3 items-center px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}>
              <DeliveryIcon /> <span>Delivery Addresses</span>
            </li> */}
            <li
              onClick={handlePassword}
              className={`${
                password ? "bg-dash font-medium" : "bg-white"
              } flex cursor-pointer gap-3 items-center px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}
            >
              <TransferIcon /> <span>Change Password</span>
            </li>
            <li
              onClick={handlePin}
              className={`${
                pin ? "bg-dash font-medium" : "bg-white"
              } flex cursor-pointer gap-3 items-center px-4 py-3.5 text-base  leading-loyalty tracking-loyal`}
            >
              <ChangeIcon /> <span>Reset PIN</span>
            </li>
          </ul>
        </div>

        {/* RIGHT PANEL */}
        <div className="bg-white lg:w-2/3 dashboard">
          {/* DASHBOARD */}
          {dashboard && (
            <div className="dashboard-outter-container">
              <div className="dashboard-container">
                <h6>Dashboard</h6>
                <div className="balance-outter-container">
                  <div className="balance-container">
                    <div className="balance">
                      <p>Total Current Balance</p>
                      <h6> {formatValue(data?.profile[0]?.Current_balance)}</h6>
                    </div>
                    <div className="balance">
                      <p>Total Gained FirstCoins</p>
                      <h6>
                        {formatValue(data?.profile[0]?.Total_gained_points)}
                      </h6>
                    </div>
                    <div className="balance">
                      <p>Total Redeemed FirstCoins</p>
                      <h6>
                        {/* {console.log("data", data)} */}
                        {formatValue(data?.profile[0]?.Total_redeem_points)}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="dashboard-address-section">
                  <div className="left-dashboard-address-section">
                    {/* {data?.defaultAddress.length !== 0 ? (
                      <p>Profile</p>
                    ) : (
                      <p>No Address</p>
                    )} */}

                    {/* <div className="dashboard-address">
                      <p>
                        {data?.defaultAddress[0]?.first_name}{" "}
                        {data?.defaultAddress[0]?.last_name}
                      </p>
                      <p className="my-address-property">
                        {data?.defaultAddress[0]?.full_address}
                      </p>
                      <p>{data?.defaultAddress[0]?.phone_no}</p>
                    </div> */}
                  </div>
                  {/* <div className="right-dashboard-address-section">
                    {data?.defaultAddress.length !== 0 ? (
                      <div
                        className="add-address-dashboard-section"
                        // onClick={handleChangeProfileAddress}
                        onClick={handleDelivery}>
                        <Pen />
                        <p>Change Address</p>
                      </div>
                    ) : (
                      <div
                        className="add-address-dashboard-section"
                        onClick={handleAddAddress}>
                        <Pen />
                        <p>Add Address</p>
                      </div>
                    )}
                  </div> */}
                </div>
                <div className="optout-container">
                  <p className="optout-text">
                    If you would like to leave the rewards program, click the
                    button below
                  </p>
                  <Link to="/optout">
                    <button className="optout-btn">opt-out of program</button>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {/* DASHBOARD ENDED */}

          {/* TIER STATUS */}
          {tier && (
            <div className="m-7">
              <div className="">
                <h6 className="text-2xl font-bold leading-loyalty tracking-loyal">
                  Tier Status
                </h6>
              </div>
              <div className="p-8 rounded bg-search my-7">
                {/* <div className="flex items-center justify-between">
                  <div className="flex gap-2.5">
                    {barColor}
                    <span>
                      {plateName === 'gold' && (
                        <h6 className="text-xl font-bold capitalize tracking-loyal text-add">
                          {plateName}
                        </h6>
                      )}
                      {plateName === 'silver' && (
                        <h6 className="font-bold text-xl capitalize tracking-loyal text-[#c9c9c9]">
                          {plateName}
                        </h6>
                      )}
                      {plateName === 'platinum' && (
                        <h6 className="font-bold text-xl capitalize tracking-loyal text-[#e5e9ef]">
                          {plateName}
                        </h6>
                      )}

                      <p className="text-sm font-medium leading-loose text-white lg:text-base">
                        Spend 3,000 FirstCoins to reach Platninum
                      </p>
                    </span>
                  </div>
                  <Link
                    to={'/shops'}
                    className="justify-center w-1/3 py-2 text-base font-bold border-2 border-black rounded lg:flex bg-add leading-loyalty text-black">
                    Redeem Items
                  </Link>
                </div>
                <div>
                  <Line
                    percent={33}
                    strokeWidth={1}
                    trailColor="#013459"
                    strokeColor="#f0bd2d"
                  />
                  <div className="flex justify-between">
                    <h6 className="text-white">Silver</h6>
                    <h6 className="text-white">Gold</h6>
                    <h6 className="text-white">Platinum</h6>
                  </div>
                </div>
                <button className="flex lg:hidden  mt-3.5  bg-add rounded border-black border-2 font-bold text-base leading-loyalty px-5 py-2">
                  Redeem Items
                </button> */}
                <h4 className="text-[#fff] flex items-center">
                  Coming Soon <Rocket />{" "}
                </h4>
              </div>

              <div className="space-y-8">
                <h6>Benefits you get at different tiers</h6>
                <div className="flex gap-4 lg:gap-12">
                  <span className="flex w-1/3 gap-4 lg:gap-8">
                    <h6>SILVER</h6>
                    <SilverIcon />
                  </span>

                  <p className="text-sm font-normal leading-shop tracking-loyal">
                    Spend 1,000 FirstCoins to reach Silver
                  </p>
                </div>
                <div className="flex gap-4 lg:gap-12">
                  <span className="flex w-1/3 gap-4 lg:gap-8">
                    <h6>GOLD</h6>
                    <GoldIcon />
                  </span>

                  <p className="text-sm font-normal leading-shop tracking-loyal">
                    Spend 3,000 FirstCoins to reach Gold.
                  </p>
                </div>
                <div className="flex gap-4 lg:gap-12">
                  <span className="flex w-1/3 gap-4 lg:gap-8">
                    <h6>PLATINUM</h6>
                    <PlatinumIcon />
                  </span>

                  <p className="text-sm font-normal leading-shop tracking-loyal">
                    Spend 7,000 FirstCoins to reach Platinum
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* TIER STATUS ENDED */}

          {/* STATEMENT STARTS */}
          {statements && (
            <div className="m-7 ">
              <div>
                <h6 className="text-2xl font-bold leading-loyalty tracking-loyal">
                  Statement
                </h6>
              </div>

              <div className="flex flex-col gap-4 md:flex-row lg:items-center">
                <div className="flex flex-col lg:w-1/4 md:space-y-3 lg:space-y-8 xl:space-y-3">
                  <label
                    className="text-base font-normal leading-5 capitalize tracking-loyal"
                    htmlFor=""
                  >
                    start date
                  </label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="w-full pl-2 h-11"
                    placeholder="From"
                    max={getCurrentDate()}
                  />
                </div>
                <div className="flex flex-col lg:w-1/4 md:space-y-3 lg:space-y-8 xl:space-y-3">
                  <label
                    className="text-base font-normal leading-5 capitalize tracking-loyal"
                    htmlFor=""
                  >
                    end date
                  </label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className="w-full pl-2 h-11"
                    placeholder="To"
                    max={getCurrentDate()}
                  />
                </div>
                <div className="flex flex-col space-y-3 lg:w-1/4">
                  <label
                    className="text-base font-normal leading-5 capitalize tracking-loyal"
                    htmlFor=""
                  >
                    Trans. Type
                  </label>

                  <select
                    type="date"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="w-full h-11 px-3 border-[#ccc] border-solid rounded border"
                  >
                    <option>Select</option>
                    {uniqueData?.map((option, i) => (
                      <option key={i} value={option?.Transaction_type_id}>
                        {option.Transaction_type}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="lg:w-1/4">
                  <button
                    onClick={handleSearchStatement}
                    className="bg-add border-2 border-solid rounded border-black font-bold capitalize text-base leading-loyalty tracking-loyal w-full mt-9 py-2.5 px-12"
                  >
                    search
                  </button>
                </div>
              </div>

              <div className="flex gap-4 pb-6 pt-11">
                <button
                  className="items-center p-2 text-base font-medium border-2 border-black border-solid rounded-3xl leading-loyalty "
                  onClick={exportToPdf}
                >
                  <PdfIcon /> Download As PDF
                </button>
                <button
                  className="items-center p-2 text-base font-medium border-2 border-black border-solid rounded-3xl leading-loyalty "
                  onClick={exportToExcel}
                >
                  <XlsxIcon /> Download As xlsx
                </button>
              </div>
              <div className="w-full min-h-[600px] overflow-x-auto ">
                {showFilteredTable ? (
                  <table className="w-[1200px]" id="table">
                    <thead className="bg-button">
                      <tr className="flex-col w-full gap-9">
                        <th className="py-2.5 text-center text-white">Date</th>
                        <th className="py-2.5 capitalize text-white text-center">
                          Transaction type
                        </th>
                        <th className="py-2.5 w-[10%] text-center text-white">
                          Description
                        </th>
                        <th className="py-2.5 text-center text-white">
                          Credit
                        </th>
                        <th className="py-2.5 text-center text-white">Debit</th>
                        {/* <th className="py-2.5 text-center text-white">
                          Cost of shipping
                        </th> */}
                        <th className="py-2.5 text-center text-white">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData?.length > 0 ? (
                        currentPageData?.map((data, i) => (
                          <tr
                            className={`${
                              i % 2 === 1 ? "bg-table" : ""
                            } "space-x-3"`}
                            key={i}
                          >
                            <td className="py-2.5 w-[10%] text-center">
                              {moment(data.Transaction_date).format(
                                "MM/DD/YYYY"
                              )}
                            </td>
                            <td className="py-4 w-[10%] text-center">
                              {data.Transaction_type}
                            </td>
                            <td className="py-2.5 max-w-xs flex-none text-center">
                              <div className="truncate">{data.Description}</div>
                            </td>
                            <td className="py-2.5 w-[10%] text-center">
                              {formatValue(
                                data?.Points_gained.replace(/-/g, "0")
                              )}
                              &nbsp;FirstCoins
                            </td>
                            <td className="py-2.5 w-[10%] text-center">
                              {formatValue(
                                data?.Points_used.replace(/-/g, "0")
                              )}
                              &nbsp;FirstCoins
                            </td>
                            {/* <td className="py-2.5 w-[10%] text-center">
                              {formatValue(data?.Shipping_points)} FirstCoins
                            </td> */}
                            <td
                              onClick={() => {
                                handleOpenViewModal(data);
                              }}
                              className="py-2.5 w-[10%] text-center hover:text-blue-600 cursor-pointer"
                            >
                              View receipt
                            </td>
                          </tr>
                        ))
                      ) : (
                        <h6 className="text-red-500">No Date Found</h6>
                      )}
                    </tbody>
                  </table>
                ) : showFilteredTableResult ? (
                  <>
                    <table className="w-[1200px] " id="table">
                      <thead className="bg-button">
                        <tr className="flex-col w-full gap-9">
                          <th className="py-2.5 px-2 text-center text-white">
                            Date
                          </th>
                          <th className="py-2.5 px-2 capitalize text-white text-center">
                            Transaction type
                          </th>
                          <th className="py-2.5 px-2 w-[10%] text-center text-white">
                            Description
                          </th>
                          <th className="py-2.5 px-2 text-center text-white">
                            Credit
                          </th>
                          <th className="py-2.5 px-2 text-center text-white">
                            Debit
                          </th>
                          {/* <th className="py-2.5 text-center text-white">
                            Cost of Shipping
                          </th> */}

                          <th className="py-2.5 px-2 text-center text-white">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCurrentData.length > 0 &&
                          filteredCurrentData?.map((data, i) => (
                            <tr
                              className={`${
                                i % 2 === 1 ? "bg-table" : ""
                              } "space-x-3"`}
                              key={i}
                            >
                              <td className="py-2.5 text-center w-[10%]">
                                {moment(data.Transaction_date).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>

                              <td className="py-4 w-[10%] text-center">
                                {data.Transaction_type}
                              </td>
                              
                              <td className="py-2.5 max-w-xs flex-none text-center">
                                <div className="truncate">
                                  {data.Description}
                                </div>
                              </td>
                              <td className="py-2.5 w-[10%] text-center">
                                {formatValue(
                                  data.Points_gained.replace(/-/g, "0")
                                )}
                                &nbsp;FirstCoins
                              </td>
                              <td className="py-2.5 w-[10%] text-center">
                                {formatValue(
                                  data.Points_used.replace(/-/g, "0")
                                )}{" "}
                                &nbsp;FirstCoins
                              </td>
                              {/* <td className="py-2.5 w-[10%] text-center">
                                {formatValue(data?.Shipping_points)} FirstCoins
                              </td> */}
                              <td
                                onClick={() => {
                                  handleOpenViewModal(data);
                                }}
                                className="py-2.5 w-[10%] text-center hover:text-blue-600 cursor-pointer"
                              >
                                View receipt
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <h4>{filteredTableResult.message}</h4>
                )}
              </div>
              {showFilteredTable ? (
                <Pagination
                  itemsPerPage={10}
                  data={statement}
                  handlePageChange={handlePageChange}
                  currentPage={currentNewPage}
                />
              ) : (
                <Pagination
                  itemsPerPage={10}
                  data={filteredCurrentData}
                  handlePageChange={handleFilteredPageChange}
                  currentPage={currentFilteredPage}
                />
              )}
            </div>
          )}
          {/* STATEMENT ENDS */}

          {/* ORDER HISTORY BEGIN */}

          {/* ORDER HISTORY ENDS */}

          {/* HANDLE TRANSFER STARTS */}
          {transfer && (
            <div className="transfer-coin-outer-container">
              <div className="transfer-coin-container">
                <h6 className="transfer-header-text">Transfer</h6>
                <div className="transfer-coin-btn-container">
                  <button
                    onClick={() => setBeneficiary(false)}
                    className={
                      beneficiary ? "transferActive" : "beneficiaryActive"
                    }
                  >
                    Transfer FirstCoins
                  </button>
                  <button
                    onClick={() => setBeneficiary(true)}
                    className={
                      beneficiary ? "beneficiaryActive" : "transferActive"
                    }
                  >
                    Beneficiaries
                  </button>
                </div>
                <div className="transfer-error">{transferCoinError}</div>

                {!beneficiary && (
                  <div>
                    <div className="transfer-input-field-container">
                      <p className="beneficiary-text">Beneficiary</p>
                      <input
                        type="text"
                        name="recepient_id"
                        value={transferData.recepient_id}
                        onChange={handleTransferChange}
                        className="transfer-input-field"
                        placeholder="Enter beneficiary ID"
                      />
                    </div>
                    <div className="transfer-input-field-container">
                      <p className="beneficiary-text1">Amount</p>
                      <input
                        type="number"
                        name="amount"
                        value={transferData.amount}
                        onChange={handleTransferChange}
                        className="transfer-input-field"
                        placeholder="Enter FirstCoins amount"
                        step="1"
                        onKeyDown={(e) => {
                          // Prevent non-numeric characters
                          if (
                            e.key === "e" || // Prevent exponential notation
                            e.key === "." || // Prevent decimals
                            e.key === "-" || // Prevent negative numbers
                            e.key === "+" || // Prevent '+' sign
                            e.key === " " // Prevent spaces
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onInput={(e) => {
                          // Remove any invalid characters like symbols or decimals
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </div>
                    <div className="transfer-checkbox-container">
                      <input
                        type="checkbox"
                        name="checkbox"
                        checked={transferData.checkbox}
                        onChange={() =>
                          setTransferData({
                            ...transferData,
                            checkbox: 1,
                          })
                        }
                      />
                      <p className="beneficiary-text2">Save this beneficiary</p>
                    </div>
                    <div className="transfer-btn-container">
                      <button
                        onClick={handleTransferSubmit}
                    
                      >
                        {transferPoints ? <TailwindSpinner /> : "Proceed"}
                      </button>
                    </div>
                  </div>
                )}

                {beneficiary && (
                  <div className="beneficiaries-main-container">
                    <h5>Frequent beneficiaries</h5>
                    <div className="beneficiaries-main-inner-container">
                      {beneficiariesData?.map((each) => {
                        return (
                          <div
                            className="beneficiaries-container"
                            key={each.benefit_enrollment_id}
                            // onClick={() => [
                            //   (transferData.recepient_id =
                            //     each.benefit_enrollment_id),
                            //   setBeneficiary(false),
                            // ]}
                          >
                            <div
                              className="beneficiaries-inner-left-container"
                              onClick={() => [
                                (transferData.recepient_id =
                                  each.benefit_enrollment_id),
                                setBeneficiary(false),
                              ]}
                            >
                              <div className="beneficiary-name-icon">
                                {each.benefit_firstname[0] +
                                  each.benefit_lastname[0]}
                              </div>
                              <div className="beneficiary-fullname-id">
                                <p>
                                  {`${each.benefit_firstname} ${each.benefit_lastname}`}
                                </p>
                                <h2>{each.benefit_enrollment_id}</h2>
                              </div>
                            </div>
                            <div
                              className="beneficiaries-inner-right-container"
                              onClick={() => [
                                setBeneficiaryDeleteModal(true),
                                setBeneficiaryId(each.id),
                              ]}
                            >
                              <BeneficiaryDelete />
                              <p>Delete</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* HANDLE TRANSFER ENDS */}

          {/* PASSWORD BEGINS */}
          {password && (
            <div className="m-7">
              <div>
                <h6 className="text-2xl font-bold leading-loyal tracking-loyal">
                  Enter New Password
                </h6>
                <p>To protect your account, enter a new password below</p>
              </div>
              <div className="pt-4">
                <div className="flex flex-col py-3 space-y-3 lg:py-7">
                  <label className="text-lg" htmlFor="">
                    Old Password
                  </label>
                  <div className="relative w-full">
                    <input
                      autoComplete="new-password"
                      className={
                        oldPassswordCheck || profileErrorOne
                          ? "profileInputError"
                          : "profileInput"
                      }
                      type={showOldPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      value={oldPasssword}
                      onChange={(e) => (
                        setOldPassword(e.target.value),
                        setProfileErrorOne(false),
                        setProfilePasswordStatus(null)
                      )}
                    />
                    <div
                      className="absolute w-fit top-2 right-4"
                      onClick={handleOldPasswordToggle}
                    >
                      {showOldPassword ? (
                        <OffVisibilityIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </div>
                  </div>
                  {profileErrorOne && (
                    <h6 className="text-red-900">Field cannot be empty</h6>
                  )}
                  {similarPassword && (
                    <h6 className="text-red-900">
                      You can't use the same old password.
                    </h6>
                  )}
                  {oldPassswordCheck && (
                    <div className="">
                      <h6 className="text-red-500">
                        {profilePasswordStatus?.message}
                      </h6>
                    </div>
                  )}
                </div>
                <div className="flex flex-col space-y-3 py- lg:py-7">
                  <label className="text-lg" htmlFor="">
                    New Password
                  </label>
                  <div className="relative w-full">
                    <input
                      className={
                        isPasswordMatch || profileErrorTwo
                          ? "profileInputError"
                          : "profileInput"
                      }
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Enter New Password"
                      value={newPassword}
                      onChange={(e) => (
                        setNewPassword(e.target.value),
                        setProfileErrorTwo(false),
                        setSimilarPassword(false),
                        // setPasswordLengthCheck(false),
                        setProfilePasswordStatus(null)
                      )}
                    />

                    <div
                      className="absolute w-fit top-2 right-4"
                      onClick={handleNewPasswordToggle}
                    >
                      {showNewPassword ? (
                        <OffVisibilityIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </div>
                    <ul className="password-character-check-container">
                      <li
                        style={{
                          color: hasUppercase ? "green" : "red",
                          fontSize: "11px",
                          margin: "0px",
                        }}
                      >
                        {hasUppercase ? <PasswordMark /> : <PasswordTimes />} At
                        least one uppercase
                      </li>
                      <li
                        style={{
                          color: isEightChars ? "green" : "red",
                          fontSize: "11px",
                          margin: "0px",
                        }}
                      >
                        {isEightChars ? <PasswordMark /> : <PasswordTimes />} At
                        least 8 characters
                      </li>
                      <li
                        style={{
                          color: hasLetter ? "green" : "red",
                          fontSize: "11px",
                          margin: "0px",
                        }}
                      >
                        {hasLetter ? <PasswordMark /> : <PasswordTimes />} At
                        least one letter
                      </li>
                      <li
                        style={{
                          color: hasNumber ? "green" : "red",
                          fontSize: "11px",
                          margin: "0px",
                        }}
                      >
                        {hasNumber ? <PasswordMark /> : <PasswordTimes />} At
                        least one number
                      </li>
                      <li
                        style={{
                          color: hasSpecialChar ? "green" : "red",
                          fontSize: "11px",
                          margin: "0px",
                        }}
                      >
                        {hasSpecialChar ? <PasswordMark /> : <PasswordTimes />}{" "}
                        At least one special character (@$!%*#?&)
                      </li>
                    </ul>
                  </div>

                  {profileErrorTwo && (
                    <h6 className="text-red-900">field cannot be empty</h6>
                  )}

                  {/* {passwordLengthCheck && (
                    <h6 className="text-red-900">
                     Password must be minimum of 8 characters, at least one letter, one number and one special character.
                    </h6> 
                  )} */}
                  {isPasswordMatch && (
                    <div className="">
                      <h6 className="text-red-500">
                        {profilePasswordStatus?.message}
                      </h6>
                    </div>
                  )}
                </div>
                <div className="flex flex-col py-3 space-y-3 lg:py-7">
                  <label className="text-lg" htmlFor="">
                    Confirm Password
                  </label>
                  <div className="relative w-full">
                    <input
                      className={
                        isPasswordMatch || profileErrorThree
                          ? "profileInputError"
                          : "profileInput"
                      }
                      type={showConfirmNewPassword ? "text" : "password"}
                      placeholder="Confirm New Password"
                      value={confirmNewPassword}
                      onChange={(e) => (
                        setConfirmNewPassword(e.target.value),
                        setProfileErrorThree(false),
                        setProfilePasswordStatus(null)
                      )}
                    />
                    <div
                      className="absolute w-fit top-2 right-4"
                      onClick={handleConfirmNewPasswordToggle}
                    >
                      {showConfirmNewPassword ? (
                        <OffVisibilityIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </div>
                  </div>

                  {profileErrorThree && (
                    <h6 className="text-red-900 capitalize">
                      field cannot be empty
                    </h6>
                  )}
                </div>
              </div>
              {isPasswordMatch && (
                <div className="">
                  <h6 className="text-red-500">
                    {profilePasswordStatus?.message}
                  </h6>
                </div>
              )}
              <div className="flex justify-center py-12">
                <button
                  disabled={
                    [
                      isEightChars,
                      hasLetter,
                      hasNumber,
                      hasSpecialChar,
                      hasUppercase,
                    ].some((each) => !each) ||
                    confirmNewPassword !== newPassword
                  }
                  onClick={handleSubmitPassword}
                  type="submit"
                  className="w-full h-12 text-base font-bold border-2 border-black border-solid rounded bg-add leading-loyalty lg:w-4/5"
                >
                  {isSendingPassword ? <TailwindSpinner /> : "Save Changes"}
                </button>
              </div>
            </div>
          )}
          {/* PASSWORD ENDS */}

          {/* PIN BEGINS */}
          {pin && (
            <div className="m-7">
              <div>
                <h6 className="text-2xl font-bold leading-loyal tracking-loyal">
                  Reset PIN
                </h6>
                <p>
                  Click on the button below to get a new PIN in your registered
                  email address
                </p>
              </div>

              <div className="flex justify-center py-12">
                <button
                  onClick={handleSubmitPin}
                  className="w-full h-12 text-base font-bold border-2 border-black border-solid rounded bg-add leading-loyalty lg:w-4/5"
                >
                  Reset PIN
                </button>
              </div>
            </div>
          )}
          {/* PIN ENDS */}
        </div>
      </div>

      {/* view statement */}
      {viewModalIsOpen && (
        <StatementModal
          visible={viewModalIsOpen}
          onClose={handleCloseViewModal}
        />
      )}
      {/* Change Password Success Modal */}
      {passwordModalIsOpen && (
        <PasswordModal
          visible={passwordModalIsOpen}
          onClose={handleClosePasswordModal}
        />
      )}
      {/* Reset Pin Success Modal */}
      {pinModalIsOpen && (
        <PinModal visible={pinModalIsOpen} onClose={handleClosePinModal} />
      )}

      {/* Bid Auction claim success Modal */}
      {auctionModalIsOpen && (
        <AuctionModal
          visible={auctionModalIsOpen}
          onClose={handleCloseAuctionModal}
        />
      )}

      {beneficiaryDeleteModal && (
        <DeleteBeneficiaryModal
          HandleDeleteBeneficiary={HandleDeleteBeneficiary}
          setBeneficiaryDeleteModal={setBeneficiaryDeleteModal}
        />
      )}

      {transferSuccessModal && (
        <TransferSuccessModal
          setTransferSuccessModal={setTransferSuccessModal}
          setConfirmCoinTransferError={setConfirmCoinTransferError}
        />
      )}

      {forgotPinModal && (
        <ForgotPinModal
          setForgotPinModal={setForgotPinModal}
          handleForgotPin={handleForgotPin}
        />
      )}
      {passwordModal && (
        <TransferCoinModal
          confirmPointsTransfer={confirmPointsTransfer}
          transferCoinPassword={transferCoinPassword}
          setTransferCoinPassword={setTransferCoinPassword}
          setPasswordModal={setPasswordModal}
          handleConfirmTransferSubmit={handleConfirmTransferSubmit}
          confirmCoinTransferError={confirmCoinTransferError}
          setForgotPinModal={setForgotPinModal}
          transferData={transferData}
        />
      )}
      <ToastContainer />
    </div>
  );
};
export default Profile;
