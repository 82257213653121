import React, { useEffect, useState } from "react";
import "./ResetPassword.css";
import {
  showPassword,
  hidePassword,
  hideConfirmPassword,
  showConfirmPassword,
} from "../../../redux-toolkit/features/password/showPasswordSlice";
import logo from "../../../assets/images/logo.png";
import { useParams } from "react-router-dom";
// import { Envelop, Message, Passkey } from "../../assets/icons";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import PasswordResetModal from "../../../components/PasswordResetModal/PasswordResetModal";
import axios from "axios";
import { endpoints } from "../../../utils/constant";
import { PasswordMark, PasswordTimes } from "../../../assets/icons";
import { TailwindSpinner } from "../../../components/Spinner/TailwindSpinner";

const ResetPassword = ({ nagateNav }) => {
  const [showModal, setShowModal] = useState(false);
  const { show, visibility } = useSelector((store) => store.password);

  const dispatch = useDispatch();
  const [newPassword, setNewpassword] = useState("");
  const [confirmPassword, setConfirmpassword] = useState("");
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(null);
  const [isEightChars, setIsEightChars] = useState(false);
  const [hasLetter, setHasLetter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [passwordType, setPasswordType] = useState(false);
  const [allFieldRequired, setAllFieldRequired] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState(false);
  // const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  // const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [duplicatePassword, setDuplicatePassword] = useState(false);
  const { memberid } = useParams();
const [isLoading, setIsLoading] = useState(false);
  const PasswordReset = async () => {
    setIsLoading(true)
    try {
      const passwordResponse = await axios.post(
        `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.setPassword}new_password=${newPassword}&confirm_password=${confirmPassword}&membership_id=${memberid}`
      );
      if (passwordResponse?.data?.status === 0) {
        setPasswordStatus(passwordResponse);
        setIsLoading(false)
      }
      if (passwordResponse?.data?.status === 1) {
        setShowModal(true);
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    nagateNav(false);
  }, []);

  const handlePasswordLength = () => {
    if (newPassword.length >= 8 || confirmPassword.length >= 8) {
      PasswordReset();
    } else {
      setPasswordLength(true);
    }
  };

  const handleSubmit = () => {
    if (Object.values({ newPassword, confirmPassword }).includes("")) {
      setAllFieldRequired(true);
      return;
    } else if (newPassword !== confirmPassword) {
      setErrorMessageState(true);
      return;
    } else {
      PasswordReset();
    }
  };

  useEffect(() => {
    setErrorMessageState(false);
    setAllFieldRequired(false);
    setDuplicatePassword(false);
    setPasswordType(false);
  }, [newPassword]);

  useEffect(() => {
    setIsEightChars(newPassword.length >= 8);
    setHasLetter(/(?=.*[A-Za-z])/.test(newPassword));
    setHasNumber(/(?=.*\d)/.test(newPassword));
    setHasSpecialChar(/(?=.*[@$!%*#?&])/.test(newPassword));
    setHasUppercase(/(?=.*[A-Z])/.test(newPassword));
  }, [newPassword]);

  const handleSubmitt = (e) => {
    e.preventDefault();
    handlePasswordLength();
  };

  return (
    <>
      <div className="reset-password-container">
        <div className="logo-container-position">
          <img src={logo} alt="first bank logo" />
        </div>
        <div className="reset-password-outter">
          <div className="text-container">
            <div className="inner-text-container">
              <div className="reset-password-header-text">
                <h1>Create New Password</h1>
              </div>

              <div className="reset-password-mobile-adjust">
                <div className="title-and-input-container">
                  <div className="reset-password-input-container" >
                    <div className="forgot-password">
                      <p>New Password</p>
                    </div>
                    <input
                      placeholder="Enter A New Password"
                      type={visibility ? "text" : "password"}
                      className="first-time-login-input"
                      name="password"
                      value={newPassword}
                      onClick={() => setPasswordLength(false)}
                      onChange={(e) => {
                        setNewpassword(e.target.value);
                      }}
                    />
                    {visibility ? (
                      <AiOutlineEye
                        className="eye-icon-reset-password"
                        onClick={() => dispatch(hideConfirmPassword())}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="eye-icon-reset-password"
                        onClick={() => dispatch(showConfirmPassword())}
                      />
                    )}
                  </div>
                  <ul className="password-character-check-container">
                    <li
                      style={{
                        color: hasUppercase ? "green" : "red",
                        fontSize: "11px",
                        margin: "0px",
                      }}
                    >
                      {hasUppercase ? <PasswordMark /> : <PasswordTimes />} At
                      least one uppercase
                    </li>
                    <li
                      style={{
                        color: isEightChars ? "green" : "red",
                        fontSize: "11px",
                        margin: "0px",
                      }}
                    >
                      {isEightChars ? <PasswordMark /> : <PasswordTimes />} At
                      least 8 characters
                    </li>
                    <li
                      style={{
                        color: hasLetter ? "green" : "red",
                        fontSize: "11px",
                        margin: "0px",
                      }}
                    >
                      {hasLetter ? <PasswordMark /> : <PasswordTimes />} At
                      least one letter
                    </li>
                    <li
                      style={{
                        color: hasNumber ? "green" : "red",
                        fontSize: "11px",
                        margin: "0px",
                      }}
                    >
                      {hasNumber ? <PasswordMark /> : <PasswordTimes />} At
                      least one number
                    </li>
                    <li
                      style={{
                        color: hasSpecialChar ? "green" : "red",
                        fontSize: "11px",
                        margin: "0px",
                      }}
                    >
                      {hasSpecialChar ? <PasswordMark /> : <PasswordTimes />} At
                      least one special character (@$!%*#?&)
                    </li>
                  </ul>
                  
                  <div className="reset-password-input-container">
                    <div className="forgot-password fp-adjust">
                      <p>Confirm Password</p>
                    </div>
                    <input
                      placeholder="Enter Password Again"
                      type={show ? "text" : "password"}
                      className="first-time-login-input"
                      value={confirmPassword}
                      onClick={() => setPasswordLength(false)}
                      name="confirmPassword"
                      onChange={(e) => {
                        setConfirmpassword(e.target.value);
                      }}
                    />
                    {show ? (
                      <AiOutlineEye
                        className="eye-icon-reset-password"
                        onClick={() => dispatch(hidePassword())}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="eye-icon-reset-password"
                        onClick={() => dispatch(showPassword())}
                      />
                    )}
                  </div>
                </div>
                {errorMessageState && (
                  <p className="change-password-warning-text">
                    Your passwords do not match, please try again.
                  </p>
                )}
                {duplicatePassword && (
                  <p className="change-password-warning-text">
                    You cannot use the same password again
                  </p>
                )}
                {allFieldRequired && (
                  <p className="change-password-warning-text">
                    All fields are required, please fill them out.
                  </p>
                )}

                {passwordStatus ? (
                  <p className=" text-[#FA2323]">
                    {passwordStatus?.data?.message}
                  </p>
                ) : null}

                <button
                  className="login-button"
                  disabled={
                    [
                      isEightChars,
                      hasLetter,
                      hasNumber,
                      hasSpecialChar,
                      hasUppercase,
                    ].some((each) => !each) || confirmPassword !== newPassword
                  }
                  onClick={(e) => handleSubmit(e)}
                >
                  {isLoading ?  <TailwindSpinner/> : "Save Changes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <PasswordResetModal setShowModal={setShowModal} />}
    </>
  );
};

export default ResetPassword;
