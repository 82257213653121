import React, { useState, useRef, useEffect } from "react";
import MovieIcon from "../../assets/svg/movieIcon.svg";
import { MoviesList } from "../../components/Cards/CardList/Movie/MoviesList";
import MoviesModal from "../../components/Modals/MoviesModal";
import Categories from "../../components/Categories/Categories";
import '../../components/Cards/CardList/Movie/movies.css'

function Movies() {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://res.cloudinary.com/due7xlwsg/image/upload/v1685077303/MovieBanner2_tiofn7.png",
    "https://res.cloudinary.com/due7xlwsg/image/upload/v1685077302/MovieBanner1_lwdlxu.png"
  ];

  const safeDocument = typeof document !== "undefined" ? document : {};
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;


  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;
    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    html.style.position = "relative"; /* [1] */
    html.style.overflow = "hidden"; /* [2] */
    body.style.position = "relative"; /* [1] */
    body.style.overflow = "hidden"; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;
    html.style.position = "";
    html.style.overflow = "";
    body.style.position = "";
    body.style.overflow = "";
    body.style.paddingRight = "";
    scrollBlocked.current = false;
  };

  const handleOnClose = () => {
    setShowModal(!showModal);
    allowScroll();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, [images]);

  return (
    <div>
      <Categories />
      <div className="sm:px-[100px] flex pb-12 flex-col bg-[#111111]">
        <div className="flex justify-end gap-x-11 py-3">
          <div
            className="flex cursor-pointer gap-x-2.5 text-white"
            onClick={() => {
              setShowModal(!showModal);
              blockScroll();
            }}
          >
            <img src={MovieIcon} alt="Movies" />
            <h3 className="text-base capitalize text-white my-1 leading-[120%] font-bold -tracking-[0.01em] pr-4 sm:pr-0">
              Search movies
            </h3>
          </div>
        </div>

        <div className="relative w-full h-60">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`slider ${index}`}
              className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ${
                index === currentImageIndex ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
        </div>

        <div className="whole-movie-container">
          <p className="flex text-white font-bold text-3xl justify-start pt-14 pb-4">
            Now Showing
          </p>
          <MoviesList />
        </div>
        <MoviesModal onClose={handleOnClose} visible={showModal} />
      </div>
    </div>
  );
}

export default Movies;
