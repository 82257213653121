import React from "react";
import { Link } from "react-router-dom";
import Err404 from "../../../src/assets/images/err404.png";

const Error = () => {
  return (
    <div className="flex justify-center mx-auto">
      <div className="">
        <p className="flex font-medium text-base leading-loyalty -tracking-wide my-4 justify-center">
          Sorry but we don’t have that page. You can go back or to our Homepage
        </p>
        <div className="flex justify-center my-9">
          <Link
            to="/shops"
            className=" bg-add text-black px-20 py-2.5 rounded border-black border-2 capitalize font-bold"
          >
            go to homepage
          </Link>
        </div>

        <img src={Err404} alt="error" />
      </div>
    </div>
  );
};

export default Error;
