import React from "react";
import Categories from "../../../components/Categories/Categories";
import SubNav from "../../../components/Navbar/subNav";
import { SearchList } from "./SearchList";


export const Search = () => {
  return (
    <div>
      <Categories />
      <SubNav />
      {/* <SingleCategoryHero /> */}
     <SearchList />
    </div>
  );
};
