import React from "react";
import { EventCalendar } from "../../assets/icons";
import "./EventCard.css";

const EventCard = ({
  artwork,
  title,
  date,
  tickets,
  amount,
  id,
  handleShowSingleEvent,
}) => {
  return (
    <div key={id} className="event-card">
      <div className="event-image-container">
        <img
          src={`${String(
            artwork?.replace(
              "https://nairabox.s3.amazonaws.com/tickets/thumbs/",
              ""
            )
          )}`}
          // src={}
          alt={title}
          className="event-image"
          onClick={() => handleShowSingleEvent(id)}
        />
      </div>
      <div className="event-description-container">
        <div className="events-title-container">
          <h1 className="event-title">{title}</h1>
        </div>

        <div className="event-time-and-date-container">
          <EventCalendar />
          <p className="event-date">{date}</p>
          {/* <p className="event-time">{time}</p> */}
        </div>

        <p className="event-address">{tickets?.venue}</p>
        <p className="event-amount">{amount}</p>
      </div>
    </div>
  );
};

export default EventCard;
