import { useEffect } from "react";
import ScrollToTop from "../components/ScrollToTop";
import LandingPage from "./LandingPage/LandingPage";

function MainApp({ nagateNav }) {
  useEffect(() => {
    nagateNav(false);
  }, [nagateNav]);
  return (
    <div className="App">
      <LandingPage />
      <ScrollToTop />
    </div>
  );
}

export default MainApp;
