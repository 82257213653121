import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
  videoAdjustment: false
};

const scrollSlice = createSlice({
  name: "scrollToTop",
  initialState,
  reducers: {
    showScrollToTopIcon: (state, action) => {
      state.visible = true
     
    },
    hideScrollToTopIcon: (state, action)=>{
        state.visible = false
    },
    adjustVideo:(state)=>{
      state.videoAdjustment = true
    },
    initialVideoPosition:(state)=>{
      state.videoAdjustment= false
    }
  },
});

export const { showScrollToTopIcon ,hideScrollToTopIcon,adjustVideo, initialVideoPosition } = scrollSlice.actions;
export default scrollSlice.reducer;
