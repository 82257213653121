import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllEventsList } from "../../../components/EventsListService/EventsListService";

export const getAllEvents = createAsyncThunk(
  "events/getAllEvents",
  async () => {
    try {
      const response = await getAllEventsList();
    
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState: {
    isLoading: true,
    events: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllEvents.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllEvents.fulfilled, (state, action) => {
        state.events =  action.payload;
        state.isLoading = false;
        
    });
    builder.addCase(getAllEvents.rejected, (state)=>{
        state.isLoading = false;
    })
  },
});

export const { events, isLoading} = eventsSlice.actions;
export default eventsSlice.reducer;

