import React from "react";
import AdBanner from "../../components/AdBanner/AdBanner";
import Categories from "../../components/Categories/Categories";
import "./SweepStakes.css";
import { getAccessToken, logout } from "../../config/helper";

import ApiConfig from "../../service/ApiConfig";
import SweepStakeCard from "../../components/SweepStakes/SweepStakeCard";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import SweepStakeEndedCard from "../../components/SweepStakes/SweepStakeEndedCard";
import axiosProperty from "../../service/HttpService";
import { useQuery } from "react-query";
import { LoadingSVG } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const SweepStakes = () => {
  const navigate = useNavigate();

  const fetchSweepstakes = async () => {
    try {
      const response = await axiosProperty.get(ApiConfig.getSweepStakes, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });

      
    
      return response?.data?.data;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(()=>{

          logout()
        }, 4000)
     
      } 
      console.error(error);
    }
  };

  const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  const {
    data: sweepStakes,
    isLoading,
    isError,
  } = useQuery("sweepstakes", fetchSweepstakes, {
    cacheTime: twentyFourHoursInMs,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: true,
  });


 
  

  return (
    <>
      <Categories />
      <AdBanner />
      {/* <div>
        <Breadcrumb />
      </div> */}
      

      <div className="sweepstakes-container">
        {(!sweepStakes?.activeSweepstakes?.length || !sweepStakes?.inActiveSweepstakes?.length) ? (
          <div className="single-sweepstake-loader">
            {" "}
            <LoadingSVG />{" "}
          </div>
        ) : (
          <>
            <div>
              <h3 className="sweepstakes-header">Ongoing</h3>
            </div>
            <div className="sweepstakes-card-container">
              {sweepStakes?.activeSweepstakes?.map((sweepstake) => (
                <SweepStakeCard
                  sweepStake={sweepstake}
                  key={sweepstake.Sweepstake_name}
                />
              ))}
            </div>
            <div className="sweepstakes-ended-container">
              <h3 className="sweepstakes-ended-header">Ended Sweepstakes</h3>
              <div className="sweepstakes-ended-card-container">
                {sweepStakes?.inActiveSweepstakes?.map((sweepstake) => (
                  <SweepStakeEndedCard
                    sweepStake={sweepstake}
                    key={sweepstake.Sweepstake_name}
                  />
                ))}
              </div>
            </div>
          </>
        )}
           <ToastContainer />
      </div>
    </>
  );
};

export default SweepStakes;
