import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unDescribe: true,
  veilWarranty: false,
  veilShipping: false,
  veilReturn: false,
  productColor: true,
  warrantyColor: false,
  shippingColor: false,
  returnColor: false
};

const DescriptionSlice = createSlice({
  name: "description",
  initialState,
  reducers: {
    Describe: (state) => {
      state.unDescribe = true;
      state.veilWarranty = false;
      state.veilShipping = false;
      state.veilReturn = false;
       state.productColor = true;
       state.warrantyColor = false;
       state.shippingColor = false;
       state.returnColor = false;
    },
    Warranty: (state) => {
      state.veilWarranty = true;
      state.unDescribe = false;
      state.veilShipping = false;
      state.veilReturn = false;
       state.warrantyColor = true;
       state.shippingColor = false;
       state.returnColor = false;
       state.productColor = false;
    },
    Shipping: (state) => {
      state.veilShipping = true;
      state.veilWarranty = false;
      state.unDescribe = false;
      state.veilReturn = false;
      state.shippingColor = true;
      state.returnColor = false;
      state.productColor = false;
      state.warrantyColor = false;
    },
    Returns: (state) => {
      state.veilReturn = true;
      state.veilShipping = false;
      state.unDescribe = false;
      state.veilWarranty = false;
       state.returnColor = true;
       state.productColor = false;
       state.warrantyColor = false;
       state.shippingColor = false;
    },
    // ProColor: (state) => {
    //   state.productColor = true;
    //   state.warrantyColor = false;
    //   state.shippingColor = false;
    //   state.returnColor = false;
    // },
    // WarColor: (state) => {
    //   state.warrantyColor = true;
    //   state.shippingColor = false;
    //   state.returnColor = false;
    //   state.productColor = false;
    // },
    // ShiColor: (state) => {
    //   state.shippingColor = true;
    //   state.returnColor = false;
    //   state.productColor = false;
    //   state.warrantyColor = false;
    // },
    // RetColor: (state) => {
    //   state.returnColor = true;
    //   state.productColor = false;
    //   state.warrantyColor = false;
    //   state.shippingColor = false;
    // }
  }
});

export const {
  Describe,
  Warranty,
  Shipping,
  Returns,

} = DescriptionSlice.actions;

export default DescriptionSlice.reducer;
