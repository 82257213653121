import React from 'react'
import "./FlightOrderModal.css"
import modalImage from "../../assets/images/flight-and-hotel-modal.png"
const FlightOrderModal = () => {
  return (
    <div className='delete-modal-container'>

      <div className='inner-modal-container'>
        <img src={modalImage} alt="success modal" className='success-modal' />
        <p className='booking-text'>Booking Confirmed!</p>
        <p className='booking-text-1'>Please check your provided email for your ticket and access details</p>
   <button className='thanks-btn' >Okay Thanks!</button>
      </div>
    </div>
  )
}

export default FlightOrderModal