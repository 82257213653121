import React from "react";
import "../../pages/Sweepstake/SweepStakes.css";

const SweepStakeEndedCard = ({ sweepStake }) => {
  return (
    <div>
      <div className="sweepstake-ended-card">
        <div className="sweepstake-ended-card-image">
          <img
            className="sweepstake-ended-img"
            src={sweepStake?.Prize_image}
            alt={sweepStake?.Prize + sweepStake?.Prize_image}
          />
        </div>
        <div className="sweepstake-ended-card-title-and-description">
          <h1 className="sweepstake-ended-card-title">{sweepStake?.Sweepstake_name}</h1>
          <p className="sweepstake-ended-card-description">
            {sweepStake?.Prize_description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SweepStakeEndedCard;
