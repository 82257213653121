import React from "react";
import "../../pages/Sweepstake/SweepStakes.css";
import modalImage from "../../assets/images/flight-and-hotel-modal.png";
import { useNavigate } from "react-router-dom";

const SweepStakeModal = ({setWinnerModal}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    setWinnerModal(false);
    navigate('/sweepstakes');
    
  };

  return (
    <div className="sweepstake-modal-container">
      <div className="sweepstake-modal-content">
        <div className="sweepstake-modal-image-container">
          <img
            src={modalImage}
            alt="modal"
            className="sweepstake-modal-image"
          />
        </div>
        <div className="sweepstake-modal-message-container">
          <h5 className="sweepstake-modal-header">You’re in!</h5>
          <p className="sweepstake-modal-message">
            Please check your registered FirstBank email for your sweepstakes
            information
          </p>
          <button className="sweepstake-modal-button" onClick={handleClick} >Okay Thanks!</button>
        </div>
      </div>
    </div>
  );
};

export default SweepStakeModal;
