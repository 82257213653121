//export const baseurl = "https://firstapi.perxclm4.com";
export const baseurl = "https://firstbankapi-dpf7ajceb9czfbe2.francecentral-01.azurewebsites.net/api";
export const movieChekoutBaseURL = 'https://firstapi.perxclm4.com'
export const myStore = "first-bank-loyalty";
export const token = "LSLonlypass";
export const customerPortal =
  "https://firstapi.perxclm4.com/first_bank_loyalty/home/customer_portal_folder/first_bank_portal/sync.php?key=";
export const categoryStore = "first-bank-categories";
export const rewardBoxUrl = "https://rewardsboxnigeria.com/rewardsbox/api/v4";
export const wakaNowToken = "4904GEGFF6IFGE4";

const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
});

export const doPost = (setter, data, endpoint) => {
  fetch(`${baseurl}/${endpoint}`, {
    method: "POST",
    body: data,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Accept: "Application/Json",
    },
  })
    .then((response) => response.json())
    .then((data) => setter(data));
  //setter(response)
};

export const doNonJsonPost = (setter, data, endpoint) => {
  // console.log("doNonJsonPost data", data);
  fetch(`${baseurl}/${endpoint}`, {
    method: "POST",
    body: data,
    headers: {
      "Content-type": "multipart/form-data; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => setter(data));
  //setter(response)
};

export const doGet = (setter, endpoint) => {
// console.log(getAccessToken(), "getAccessToken()");
 
  fetch(`${baseurl}/${endpoint}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Accept": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    
  })
  .then((response) => response.json())
  .then((data) => setter(data))
  
  .catch((error) => {
      console.error("An error occurred:", error);
    });
};

export const storeData = (storeName, data) => {
  sessionStorage.setItem(storeName, JSON.stringify({ data: data }));
};

export const setAccessToken = (accessToken) => {
  sessionStorage.setItem("accessToken", accessToken);
};

export const getAccessToken = () => {
  return sessionStorage.getItem("accessToken");
};

// axios.defaults.headers.common['Authorization'] = `Bearer ${getAccessToken()}`

export const authHeaders = {
  Accept: "application/json",
  Authorization: `Bearer ${getAccessToken()}`,
};

export const formatAuctionDate = (inputDate) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const date = new Date(inputDate);
  let output = date.toLocaleDateString(undefined, options).toString();
  output = output.replace(",", "");
  return output.replace(" at ", ", ");
  // return date.toLocaleDateString(undefined, options);
  // const date = new Date(inputDate.replace(' at ', ', '));
};

export const deleteAccessToken = () => {
  sessionStorage.removeItem("accessToken");
};

export const storeMealsData = (storeName, data) => {
  const savedData =
    sessionStorage.getItem(storeName) !== null
      ? JSON.parse(sessionStorage.getItem(storeName))
      : [];
  const mergedData = savedData?.data?.concat(data);
  const reverseData = mergedData?.reverse();
  const finalData = [];
  let includedData = [];
  reverseData?.map((rd) => {
    if (!includedData.includes(rd.Company_merchandize_item_code)) {
      finalData.push(rd);
    }
    includedData.push(rd.Company_merchandize_item_code);
  });
  //console.log('included data', includedData, finalData.pop(), finalData);
  sessionStorage.setItem(storeName, JSON.stringify({ data: finalData }));
};

export const handleRequest = (setLogin, data, endpoint) => {
  return doPost(setLogin, data, endpoint);
};

export const handleLogout = (url) => {
  sessionStorage.removeItem(myStore);
  window.location = url;
};

export const removeData = (data) => {
  sessionStorage.removeItem(data);
};

export const getData = (storeName) => {
  if (!storeName) return "";

  const storeResult = sessionStorage.getItem(storeName);

  if (!storeResult) return null;

  // Check if storeResult is defined before parsing
  try {
    const parsedResult = JSON.parse(storeResult);
    return parsedResult;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};

export const getOrderData = async (storeName) => {
  if (!storeName) return "";
  return await JSON.parse(sessionStorage.getItem(storeName));
};

export const formatValue = (value) => {
  return formatter.format(value);
};

export const getMemberId = () => {
  let result = JSON.parse(sessionStorage.getItem("first-bank-loyalty"));
  console.log(
    "result?.data.customer.Membership_id",
    result?.data.customer.Membership_id
  );
  return result?.data.customer.Membership_id;
};

export const formatDate = (date) => {
  const inputDate = new Date(date);

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat("en-NG", options).format(
    inputDate
  );
  return formattedDate;
};
export const handleCount = (value, setter, sign) => {
  if (sign === "-") {
    if (value === 1) {
      return setter(1);
    } else {
      return setter(value - 1);
    }
  } else if (sign === "+") {
    if (value === 10) {
      return setter(10);
    } else {
      return setter(value + 1);
    }
  }
};

export const alterRedemption = (
  x,
  // setter,
  array,
  arraySetter,
  setSelectField
) => {
  // setter(false);
  if (x === 2) {
    setSelectField("none");
  }
  return arraySetter({ ...array, Delivery_method: x });
};

export const sortArrayByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const logout = () => {
  sessionStorage.removeItem(myStore);

  deleteAccessToken();
  window.location = "/login";
};

export function formatSweepstakeDate(dateString) {
  // const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleDateString(undefined, { month: "long" });
  const year = date.getFullYear();
  const suffix = getDaySuffix(day);

  return `${day}${suffix} ${month} ${year}`;
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
