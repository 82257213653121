import React, {useRef, useEffect} from 'react';
import './OptOutModal.css';
import {Cancel} from '../../assets/icons';
import { TailwindSpinner } from '../../components/Spinner/TailwindSpinner';


const OptOutModal = ({setConsentModal, handleOptout, isLoading}) => {
  

  const modalRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setConsentModal(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="delete-modal-container">
      <div className="inner-modal-container" ref={modalRef}>
        <div
          className="cancel-button"
          onClick={() => setConsentModal(false)}>
          <Cancel />
        </div>

        <p className="opt-text">Opt out</p>
        <p className="opt-text-1">Are you sure you would like to opt out?</p>
        <div className="btn-container">
          <button className="confirm-btn" onClick={handleOptout}>
           { isLoading ? <TailwindSpinner/>  : "Yes, Continue"}
          </button>

          <button
            className="cancel-btn-1"
            onClick={() => setConsentModal(false)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default OptOutModal;
