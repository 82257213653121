import React, { useEffect } from "react";
import { MoviesList } from "../../components/Cards/CardList/Movie/MoviesList";
import timex from "../../assets/svg/time.svg";
import location from "../../assets/svg/location.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  authHeaders,
  baseurl,
  movieChekoutBaseURL,
  formatDate,
  formatValue,
  getMemberId,
} from "../../config/helper";
import axios from "axios";
import RedeemedModal from "../../components/Modals/RedeemedModal";

export default function Tickets() {
  const { state } = useLocation();
  const [time, setTimes] = useState(state?.time);
  const [showModal, setShowModal] = useState(false);
  const [movie, setMovie] = useState(state?.movie);
  const [productCode, setProductCode] = useState(state?.productCode);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState([]);

  const navigator = useNavigate();

 

  useEffect(() => {
    const totalPricex = selectedTickets.reduce((acc, item) => {
      const price = parseFloat(item.price);
      const quantity = parseFloat(item.quantity);
      return acc + price * quantity;
    }, 0);

    setTotalPrice(totalPricex);
  }, [selectedTickets]);

  const redeemMovie = (e) => {
    e.preventDefault();
    if (quantity > 0) {
      const data = {
        api_type: "movie_redemption",
        price: totalPrice,
        qty: quantity,
        location: movie?.cinemas_name,
        signature: productCode,
        type: null,
        memberid: getMemberId(),
        show_time: time?.date_time,
        name: movie?.title,
      };
      axios
        .post(`${process.env.REACT_APP_FIRSTBANK_BASEURL}/redeem_movie`, data, { headers: authHeaders })
        .then((res) => {
          setShowModal(true);
          // setQuantity(0)
          // setTotalPrice(0)
        })
        .catch((err) => console.log("err", err));
    }
  };

  const handleSelectTicket = (event) => {
    const { name, value } = event.target;
    const price = event.target.getAttribute("dataprice");
    setQuantity(value);

    if (value === "0") {
      // Remove the option from selectedOptions when quantity is 0
      setSelectedTickets((prevState) =>
        prevState.filter((option) => option.name !== name)
      );
      return;
    }
    const optionIndex = selectedTickets.findIndex(
      (option) => option.name === name
    );

    if (optionIndex !== -1) {
      // Option with the same name already exists, update the quantity
      setSelectedTickets((prevState) => {
        const updatedOptions = [...prevState];
        updatedOptions[optionIndex].quantity = value;
        return updatedOptions;
      });
    } else {
      // Option with the same name doesn't exist, add it to the state
      setSelectedTickets((prevState) => [
        ...prevState,
        {
          name,
          price,
          quantity: value,
        },
      ]);
    }
  };

  return (
    <div className="flex flex-col text-white bg-[#111111] sm:px-[70px]">
      <div className="px-6 pt-6 flex flex-col sm:flex-row gap-6 sm:pt-14 sm:px-[80px]">
        <div className="flex flex-col gap-y-5 sm:w-2/3">
          <div className="bg-ticket">
            <p className="py-3 items-center pl-7 font-bold text-modal text-xl leading-loyalty tracking-loyal capitalize">
              chose tickets
            </p>
          </div>
          <div className="bg-ticket">
            <div className="flex gap-4 py-3 px-7 border-b  border-horizontal">
              <img
                className="w-[122px] h-[161px]"
                src={movie?.artwork}
                alt={movie?.artwork}
              />
              <div>
                <p className="text-modal">{movie?.title}</p>
                <p className="font-medium text-modal text-[13px] pt-3 leading-loyalty tracking-loyal">
                  {movie?.description}
                </p>
                <div className="flex justify-between pt-6 flex-col space-y-4 sm:flex-row sm:space-y-0">
                  <span className="flex flex-row gap-2">
                    <img className="w-6 h-6" src={timex} alt="time" />
                    <h2 className=" text-sm font-medium leading-loyalty tracking-loyal text-modal">
                      {/* Tue Sep 5, 9:00 AM */}
                      {formatDate(time?.date_time)}
                    </h2>
                  </span>
                  <span className="flex gap-2">
                    <img className="h-6 w-6" src={location} alt="location" />
                    <h2 className="text-sm font-medium leading-loyalty tracking-loyal text-modal">
                      {`${movie?.cinemas_name}, ${movie?.state}`}
                    </h2>
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 py-3 mx-7  uppercase mb-11">
              <h2 className="text-modal leading-loyalty text-sm tracking-loyal">
                available tickets
              </h2>

              {time?.ticket?.map((ticket) => (
                <div
                  key={ticket.id}
                  className="flex border-b capitalize border-horizontal pb-3 justify-between"
                >
                  <div>
                    <h2 className="text-modal font-bold text-base leading-loyalty tracking-loyal">
                      {ticket?.type}
                    </h2>
                    <p className="text-modal font-medium text-base leading-loyalty tracking-loyal">
                      {(2 * ticket?.price).toLocaleString()} FirstCoins
                    </p>
                  </div>
                  <div>
                    <label className="pr-3 text-modal capitalize">Qty</label>
                    <select
                      onChange={handleSelectTicket}
                      className="border border-solid text-modal px-4 py-2.5 bg-ticket rounded-sm"
                      name={ticket?.type}
                      dataprice={ticket.price}
                    >
                      <option value="0">0</option>
                      {/* className="bg-dropdown" */}
                      {Array.from(
                        { length: ticket.max_quantity },
                        (_, quantityIndex) => (
                          <option key={quantityIndex} value={quantityIndex + 1}>
                            {quantityIndex + 1}
                          </option>
                        )
                      )}
                      {/* {[...Array(10)].map((e, i) => {
                        return <option  key={i} value={i + 1}>
                          {i + 1}
                        </option>
                      })} */}
                    </select>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:w-2/5">
          <div className="bg-ticket">
            <div className="flex pt-5 pb-1 mx-6 border-b border-horizontal  justify-between">
              <p className="capitalize font-bold text-xl leading-loyalty tracking-loyal text-modal">
                summary
              </p>
              <p className="text-modal leading-loyalty font-normal tracking-loyal">
                {selectedTickets.length} items
              </p>
            </div>

            <div className="mx-7 border-b border-horizontal pb-2">
              {selectedTickets?.map((ticket) => (
                <div className="flex mt-6 justify-between">
                  <h6 className="text-modal leading-loyalty font-normal tracking-loyal">
                    {ticket?.quantity} x {ticket?.name}
                  </h6>
                  <p className="capitalize text-modal leading-loyalty font-normal tracking-loyal">
                    {2 * ticket?.quantity * ticket?.price} FirstCoins
                  </p>
                </div>
              ))}

              <div className="flex justify-between border-t border-horizontal mt-4 pt-2">
                <p className="capitalize font-bold text-xl leading-loyalty tracking-loyal text-modal">
                  total
                </p>
                <h3 className="capitalize font-bold text-xl leading-loyalty tracking-loyal text-modal">
                  {Number(2 * totalPrice).toLocaleString()} FirstCoins
                </h3>
              </div>
            </div>

            <form onSubmit={(e) => console.log("first", e)} method="post">
              <div className="flex flex-col mx-7 py-8 gap-3">
                <div className="flex flex-col gap-3 capitalize">
                  <label
                    className="capitalize text-modal font-normal text-base tracking-loyal leading-5"
                    htmlFor=""
                  >
                    first name
                  </label>
                  <input
                    required
                    className="capitalize px-2 text-white py-2 text-sm rounded border-solid border border-dropdown bg-ticket"
                    type="text"
                    placeholder="enter your first name"
                  />
                </div>
                <div className="flex flex-col gap-3 capitalize">
                  <label
                    className="capitalize text-modal font-normal text-base tracking-loyal leading-5"
                    htmlFor=""
                  >
                    last name
                  </label>
                  <input
                    required
                    className="capitalize px-2 text-white py-2 text-sm rounded border-solid border decoration-dropdown bg-ticket"
                    type="text"
                    placeholder="enter your last name"
                  />
                </div>
                <div className="flex flex-col gap-3 capitalize">
                  <label
                    className="capitalize text-modal font-normal text-base tracking-loyal leading-5"
                    htmlFor=""
                  >
                    email address
                  </label>
                  <input
                    required
                    className="capitalize px-2 text-white py-2 text-sm rounded border-solid border decoration-dropdown bg-ticket"
                    type="email"
                    placeholder="enter recipient's your email address"
                  />
                </div>
                <button
                  disabled={quantity === 0}
                  className="bg-submit font-bold text-base leading-loyalty items-center tracking-loyal text-white capitalize py-3 rounded"
                  type="button"
                  onClick={(e) => redeemMovie(e)}
                >
                  Place order
                </button>
              </div>
            </form>
          </div>
          <div className="flex flex-col gap-3 mt-4">
            <p className="text-modal font-medium leading-product tracking-loyal">
              By processing an order, you agree to provider’s Terms and Privacy
              policy
            </p>
            <p className="text-modal font-medium leading-product tracking-loyal">
              Information and update on this order will be sent to your
              FirstBank registered email address
            </p>
          </div>
        </div>
      </div>

      <div className="mx-6 pt-20">
        <h2 className="capitalize text-modal pb-6 flex justify-center font-bold text-3xl leading-loyalty tracking-loyal">
          you may like to watch
        </h2>
        <MoviesList />
      </div>

      {showModal && (
        <RedeemedModal
          // setShowModal={setShowModal}
          action={() => navigator("/movies")}
          title={"Redemption Successful!"}
          text={"A notification with the details of your movie ticket will be sent to your registered email address."}
          buttonText={"Continue"}
        />
      )}
    </div>
  );
}
