import React, { useEffect, useState } from "react";
import "./ForgotPassword.css";
import logo from "../../../assets/images/logo.svg";
import { useDispatch } from "react-redux";
import { getMembershipID } from "../../../redux-toolkit/features/forgotPassword/forgotPasswordSlice";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../utils/constant";
import { TailwindSpinner } from "../../../components/Spinner/TailwindSpinner";

const ForgotPassword = ({ nagateNav }) => {
  const navigate = useNavigate();
  const [membershipID, setMembershipID] = useState("");
  const [errorId, setErrorId] = useState(false);
  const [emptyInput, setEmptyInput] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch();
  
  const passwordOTP = async () => {
    setIsLoading(true)
    try {
      const getPin = await axios.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.resetPassword}${membershipID}`
      );
      if (getPin?.data?.status === 0) {
        setErrorId(true);
        setEmptyInput(true);
        setIsLoading(false)
        return;
      }
      if (getPin?.data?.status === 1) {
        // setDisplayMessage(true);
        setIsLoading(false)
        navigate(`/otp-input/${membershipID}`);
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setMembershipID(value);
    setErrorId(false);
  };

  const handleClick = () => {
    setEmptyInput(false);
  };

  const InputVerify = () => {
    if (membershipID.length == 0) {
      setEmptyInput(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getMembershipID(membershipID));
    setMembershipID("");
    InputVerify();
    passwordOTP();
  };

  useEffect(() => {
    nagateNav(false);
  }, []);

  return (
    <>
      <div className="forgot-password-container-main">
        <div className="logo-container-position">
          <Link to='/login'>
          
          <img src={logo} alt="first bank logo" />
          </Link>
        </div>
        <div className="forgot-password-outter-container">
          <div className="text-container-main">
            <div className="inner-text-container">
              <div className="forgot-password-header-text__">
                <h1>Forgot Your Password?</h1>
                <p>
                  Looks like you forgot your password. Please enter your
                  membership ID below and it will be sent to your linked email
                  address
                </p>
              </div>

              <div className="forgot-password-adjust-container">
                <div className="forgot-password-main">
                  <p>Membership ID</p>
                  <Link
                    to="/forgot-membershipid"
                    className="forgot-password-text no-underline"
                  >
                    Forgot Membership ID?
                  </Link>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="input-and-btn-conatainer-main">
                    <input
                      placeholder="Membership ID"
                      value={membershipID}
                      className={` ${
                        emptyInput || errorId
                          ? "border-1 border-[#fA2323] border-solid"
                          : "border-[#CCCCCC]"
                      } forgot-password-input`}
                      onChange={handleChange}
                      onClick={handleClick}
                    />
                    {emptyInput || errorId ? (
                      <p className="pt-1 font-medium text-sm text-[#FA2323]">
                        Invalid membership ID
                      </p>
                    ) : null}
                    {displayMessage ? (
                      <p className="pt-3 font-medium text-sm text-[#1f732b]">
                        Otp has been sent to your mail{" "}
                      </p>
                    ) : null}
                    {/* <form className="otp-box"> */}
                    {/* <input
                      type="radio"
                      id="sms_otp"
                      name="otp"
                      value="SMS"
                      className="otp-input"
                    /> */}
                     
                    {/* <label htmlFor="sms" className="otp-text">
                      SMS OTP
                    </label>
                     
                    <input
                      type="radio"
                      id="email_otp"
                      name="otp"
                      value="EMAIL"
                      className="otp-input-adjust"
                    />
                      */}
                    {/* <label htmlFor="email" className="otp-text">
                      EMAIL OTP
                    </label> */}
                    {/* </form> */}
                    <button
                      className="get-forgot-password"
                      onClick={handleSubmit}
                    >
                     { isLoading ? <TailwindSpinner/> : "Get One-Time Password"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="fogotpassword-login-last-section-mobile">
          <p className="fogotpassword-login-last-section-mobile-text"> Copyright 2022 FirstBank of Nigeria limited. An FBNHoldings Company.</p>
        </div> */}
    </>
  );
};

export default ForgotPassword;
