import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Categories from "../../components/Categories/Categories";
import axios from "axios";
import { endpoints } from "../../utils/constant";
import {
  authHeaders,
  baseurl,
  formatValue,
  getAccessToken,
  logout,
} from "../../config/helper";
// import { useNavigate } from "react-router-dom"
import RedeemedModal from "../../components/Modals/RedeemedModal";
import { TailwindSpinner } from "../../components/Spinner/TailwindSpinner";
import axiosProperty from "../../service/HttpService";
import { ToastContainer, toast } from "react-toastify";
import "./airtimeandbills.css";

import { useMutation, useQuery } from "react-query";
import { AppContext } from "../../components/AppContext";

const BillsCheckOut = () => {
  // const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [biller, setBiller] = useState([]);
  const { id } = useParams();
  const [customerNumber, setCustomerNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [customerNumberError, setCustomerNumberError] = useState(null);
  const [canVend, setCanVend] = useState(false);
  const [countBills, setCountBills] = useState(0);
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const { balanceStateCheck, setBalanceStateCheck } = useContext(AppContext);

  const categoryArray = ["Airtime", "Data"];
  const billerIdArray = ["628-B1", "628-B2", "628-B3", "628-B4"];

  const checkoutAirtelAirtime = {
    Name: "Airtel Airtime",
    BillerName: "Airtel Airtime",
    ConsumerIdField: "phone_number",
    Amount: "0",
    BillerId: "628-B1",
    PaymentCode: "90106",
  };

  const checkoutMtnAirtime = {
    Name: "Mtn Airtime",
    BillerName: "Mtn Airtime",
    ConsumerIdField: "phone_number",
    Amount: "0",
    BillerId: "628-B2",
    PaymentCode: "10906",
  };

  const checkoutGloAirtime = {
    Name: "Glo Airtime",
    BillerName: "Glo Airtime",
    ConsumerIdField: "phone_number",
    Amount: "0",
    BillerId: "628-B3",
    PaymentCode: "91309",
  };

  const checkout9MobileAirtime = {
    Name: "9Mobile Airtime",
    BillerName: "9Mobile Airtime",
    ConsumerIdField: "phone_number",
    Amount: "0",
    BillerId: "628-B4",
    PaymentCode: "90806",
  };

  const FetchBills = async () => {
    if (id === "628-B1") {
      setCheckoutBillerData(checkoutAirtelAirtime);
    } else if (id === "628-B2") {
      setCheckoutBillerData(checkoutMtnAirtime);
    } else if (id === "628-B3") {
      setCheckoutBillerData(checkoutGloAirtime);
    } else if (id === "628-B4") {
      setCheckoutBillerData(checkout9MobileAirtime);
    } else {
      try {
        const response = await axiosProperty.post(
          `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.single_biller}`,
          { biller_id: id },
          { headers: authHeaders }
        );

        // if(response?.data)

        setBiller(response?.data);
        // setCountBills(biller?.data?.length);
        // setSelectedSignature(response?.data?.data[0].signature);
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          toast.error("Authentication failed. Please log in again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            logout();
          }, 4000);
        }
      }
    }
  };

  useEffect(() => {
    FetchBills();
  }, []);

  //VALIDATE RECIPIENT NUMBER START

  const [paymentCode, setPaymentCode] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [validateCustomerNumber, setValidateCustomerNumber] = useState(false);
  const [billerAmount, setBillerAmount] = useState("");
  const [numberNotValid, setNumberNotValid] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("");
  const [amountCheck, setAmountCheck] = useState(false);
  const [cifID, setCifid] = useState("");
  const [checkoutBillerData, setCheckoutBillerData] = useState([]);
  const [visibleSelectOption, setVisibleSelectOption] = useState(true);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const customerDetails = {
    payment_code: paymentCode,
    customer_id: customerID,
    customer_mobile: formData.phone,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCustomerNumberChange = (event) => {
    // setCanVend(false);
    const newcustomerNumber = event.target.value;
    setCustomerID(newcustomerNumber);
    // if (newcustomerNumber.length >= 11) {
    //   verifycustomerNumber();
    // }
  };

  const verifyRecipientNumber = async () => {
    try {
      const response = await axiosProperty.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}/validate_customer`,
        customerDetails,

        { headers: authHeaders }
      );

      if (
        billerIdArray.includes(id) ||
        categoryArray.includes(selectedCategoryName)
      ) {
        setValidateCustomerNumber(true);
        setNumberNotValid(false);
      } else {
        if (response.data.ResponseCode === "90000") {
          setValidateCustomerNumber(true);
          setNumberNotValid(false);
        } else {
          setNumberNotValid(true);

          toast.error("Validation failed, try again.!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
          });
        }
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: customerData,
    isLoading: isLoadingData,
    isError: isErrorData,
    refetch,
  } = useQuery(
    ["customerData", formData.phone, customerID],
    () => verifyRecipientNumber(formData.phone, customerID),
    {
      enabled: formData.phone.length === 11,
    }
  );

  useEffect(() => {
    if (formData.phone.length === 11 && customerID) {
      refetch();
    }
  }, [customerID, formData.phone]);

  // console.log(customerData, "customerData");

  //VALIDATE RECIPIENT NUMBER END

  // const verifycustomerNumber = () => {
  //   setCustomerNumberError("");
  //   try {
  //     axios(
  //       `${process.env.REACT_APP_AIRTIME_AND_BILLS_URL}/api/validate_customer`,
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           // token: process.env.REACT_APP_MOVIE_KEY,
  //         },
  //       }
  //     )
  //       .then((response) => {
  //         if (response.data.status === 1) {
  //           setCanVend(true);
  //         } else {
  //           setCanVend(false);
  //           setCustomerNumberError(
  //             `Invalid ${
  //               countBills > 0 ? biller?.data[0]?.customer_id_text : ""
  //             }  entered`
  //           );
  //         }
  //       })
  //       .catch((error) => console.log("error", error));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (id === "628-B1") {
      setCheckoutBillerData(checkoutAirtelAirtime);
      setPaymentCode("90106");
    } else if (id === "628-B2") {
      setCheckoutBillerData(checkoutMtnAirtime);
      setPaymentCode("10906");
    } else if (id === "628-B3") {
      setCheckoutBillerData(checkoutGloAirtime);
      setPaymentCode("91309");
    } else if (id === "628-B4") {
      setCheckoutBillerData(checkout9MobileAirtime);
      setPaymentCode("90806");
    } else {
      const billerData = biller?.PaymentItemList?.PaymentItem;
      setCheckoutBillerData(billerData);
    }
  }, [biller, customerID]);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;

    if (checkoutBillerData?.length === undefined) {
      setPaymentCode(checkoutBillerData?.PaymentCode);
      //CONVERSION AMOUNT
      if (checkoutBillerData?.Amount === 0) {
        setBillerAmount(checkoutBillerData?.Amount);
      } else {
        setBillerAmount(checkoutBillerData?.Amount / 100);
      }
    } else {
      const filteredOption = checkoutBillerData.find(
        (each) => each.Name === selectedValue
      );
      // console.log(filteredOption, "filtered");
      setPaymentCode(filteredOption?.PaymentCode);

      //CONVERSION AMOUNT

      if (filteredOption?.Amount === 0) {
        setBillerAmount(filteredOption?.Amount);
      } else {
        setBillerAmount(filteredOption?.Amount / 100);
      }
      // setBillerAmount(filteredOption?.Amount);
    }
    // console.log(paymentCode, "paymentCode");
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    if (billerAmount === "0") {
      setAmountCheck(true);
      setBillerAmount("");
    }
  }, [billerAmount]);

  //SUBMIT BILLER START

  const billerDetails = {
    amount: `${billerAmount}`,
    payment_code: paymentCode,
    customer_account_number: "3049084350",
    customer_mobile: `${
      categoryArray.includes(selectedCategoryName) ? customerID : formData.phone
    }`,
    customer_email: formData.email,
    customer_id: customerID,
    cifid: cifID,
  };

  const handleRedeemBiller = async () => {
    try {
      const response = await axiosProperty.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}/sendbill_advice`,
        billerDetails,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      if (response.status === 200) {
        setBalanceStateCheck(!balanceStateCheck);
        setShowModal(true);
        setCustomerID("");
        setBillerAmount("");
        setFormData({
          phone: "",
          email: "",
        });
        setSelectedOption("");
        setValidateCustomerNumber(false);
      }

      // console.log(response, "response");
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    mutate,
    isLoading: mutateLoading,
    isError: mutateError,
  } = useMutation(handleRedeemBiller);

  // useEffect(() => {
  //   const balace = sessionStorage?.getItem("currentBalance");
  //   setCurrentBalance(balace);
  // }, []);

  useEffect(() => {
    const balace = sessionStorage?.getItem("currentBalance");
    const categoryName = sessionStorage?.getItem("selectedCategoryName");
    const customerData = JSON.parse(
      sessionStorage?.getItem("first-bank-loyalty")
    );
    const cifID = customerData?.data?.customer?.cifid;
    setSelectedCategoryName(categoryName);
    setCifid(cifID);
    setCurrentBalance(balace);
  }, [billerAmount]);

  const handleSubmit = () => {
    if (Number(currentBalance) < billerAmount) {
      toast.error("Insuffcient points!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    } else {
      mutate();
    }
  };

  useEffect(() => {
    if (billerIdArray.includes(id)) {
      setVisibleSelectOption(false);
      setAmountCheck(true);
      setValidateCustomerNumber(true);
    } else {
      setVisibleSelectOption(true);
    }
  }, [id]);

  useEffect(() => {
    if (selectedCategoryName === "Data") {
      setVisibleSelectOption(true);
      setAmountCheck(false);
      setValidateCustomerNumber(true);
    }

    if (selectedCategoryName === "Airtime") {
      setVisibleSelectOption(false);
      setAmountCheck(true);
      setValidateCustomerNumber(true);
    }
  }, [selectedCategoryName]);

  //SUBMIT BILLER END

  useEffect(() => {
   

    if (customerID.length < 11) {
      setIsDisabled(true);
      return;
    }
    
    if (selectedOption === "" && billerAmount.length < 2) {
      setIsDisabled(true);
      return;
    }
    
    setIsDisabled(false);
    
  }, [selectedOption, customerID, billerAmount]);
  return (
    <div>
      <Categories />
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-12 md:px-[100px] lg:px-[250px] xl:px-[400px] py-8 bg-[#f8f8f8]">
        <div className="biller-checkout-name-container">
          {checkoutBillerData?.length === undefined
            ? checkoutBillerData?.BillerName
            : checkoutBillerData[0]?.BillerName}
        </div>
        <div className="w-full lg:w-[350px]">
          <div className="bg-white space-y-6 p-3">
            <h4 className="font-bold text-xl tracking-loyal leading-loyalty capitalize">
              Pay for your{" "}
              {checkoutBillerData?.length === undefined
                ? checkoutBillerData?.BillerName
                : checkoutBillerData[0]?.BillerName}
            </h4>
            {/* <p className="font-medium text-base leading-loyalty tracking-loyal text-[#666666]">
              Use it at any Chicken Republic location nationwide to get
              delicious meals and drinks
            </p> */}
            {visibleSelectOption && (
              <div className="space-y-3">
                <h6 className="font-normal text-sm capitalize tracking-loyal">
                  Choose a Package
                </h6>

                <select
                  className="w-full h-11 px-3 border-[#ccc] border-solid rounded border"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="">Select an option</option>

                  {checkoutBillerData?.length === undefined ? (
                    <option
                      key={checkoutBillerData?.Name}
                      className="right-3 "
                      value={checkoutBillerData}
                    >
                      {checkoutBillerData?.Name}
                    </option>
                  ) : (
                    checkoutBillerData?.map((option) => (
                      <option
                        key={option.price + option.Name}
                        className="right-3 "
                        value={option.Name}
                      >
                        {option.Name}
                      </option>
                    ))
                  )}
                </select>
              </div>
            )}

            <div>
              <h6 className="font-normal text-sm capitalize">
                Enter Recepient's{" "}
                {checkoutBillerData?.length === undefined
                  ? checkoutBillerData?.ConsumerIdField
                  : checkoutBillerData[0]?.ConsumerIdField}
              </h6>
              <input
                onChange={handleCustomerNumberChange}
                value={customerID}
                className={`w-full h-11 px-3 border-current  ${
                  numberNotValid
                    ? "invalid-cutomer-number"
                    : "valid-cutomer-number"
                }`}
                placeholder={`Enter Recepient's  ${
                  checkoutBillerData?.length === undefined
                    ? checkoutBillerData?.ConsumerIdField
                    : checkoutBillerData[0]?.ConsumerIdField
                }`}
                type="number"
              />
              {/* 08099450091 */}
              {customerNumberError && (
                <small className="text-red-600">{customerNumberError}</small>
              )}
            </div>

            {amountCheck && (
              <div>
                <h6 className="font-normal text-sm capitalize">Enter Amount</h6>
                <input
                  type="number"
                  className="w-full h-11 px-3 biller-inputs"
                  value={billerAmount}
                  onChange={(e) => setBillerAmount(e.target.value)}
                />
              </div>
            )}
            {!categoryArray.includes(selectedCategoryName) && (
              <div>
                <h6 className="border-b-2 border-solid pb-4 text-[#666666] font-bold text-base  leading-5 tracking-loyal">
                  Recipient
                </h6>
                <div>
                  <h6 className="font-normal text-sm ">Email Address</h6>
                  <input
                    onChange={handleInputChange}
                    className="w-full h-11 px-3 border-current"
                    placeholder="enter your email"
                    name="email"
                    type="email"
                    value={formData.email}
                  />
                </div>

                <div>
                  <h6 className="font-normal text-sm my-3">Phone Number</h6>
                  <input
                    className="w-full h-11 px-3 border-current"
                    onChange={handleInputChange}
                    name="phone"
                    value={formData.phone}
                    type="number"
                  />
                </div>
              </div>
            )}
            {/* {validateCustomerNumber && ( */}
            <>
              <span className="flex py-6 border-y-2 border-solid justify-between">
                <p className="font-bold text-base leading-loyalty">Total</p>
                <p className="font-bold text-lg leading-loyalty text-search">
                  {(2 * billerAmount).toLocaleString()} FirstCoins
                </p>
              </span>
              <div className="flex justify-center mx-auto">
                <button
                  onClick={handleSubmit}
                  disabled={isDisabled}
                  className="bg-added rounded w-3/4  font-bold text-white leading-loyalty  py-3.5"
                >
                  {isLoadingData || mutateLoading ? (
                    <TailwindSpinner />
                  ) : (
                    "Place Order"
                  )}
                </button>
              </div>
            </>
            {/* )} */}
          </div>
          <span className="flex flex-col px-4 lg:px-0 py-4 space-y-3">
            <p>
              By placing an order, you agree to FirstBank Terms and Privacy
              policy
            </p>
            <p>
              Information and update on this order will be sent to your
              FirstBank registered email address
            </p>
          </span>
        </div>
      </div>
      {showModal && (
        <RedeemedModal
          action={() => setShowModal(false)}
          title={"Stay Connected!"}
          text={
            "Please check your registered FirstBank email for your redemption details"
          }
          buttonText={"Okay, Thanks!"}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default BillsCheckOut;
