import React, { useState, useEffect, useRef } from "react";
import { ChevronRight } from "../../assets/icons";
import arrowLeft from "../../assets/svg/arrow-left.svg";
import user from "../../assets/svg/user.svg";
import {
  getData,
  myStore,
  categoryStore,
  customerPortal,
  formatValue,
} from "../../config/helper";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  showPassword,
  hidePassword,
} from "../../redux-toolkit/features/password/showPasswordSlice";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../utils/constant";
import {
  AuctionIcon,
  AvatarModalIcon,
  ChangeIcon,
  DashboardIcon,
  DeliveryIcon,
  OrderIcon,
  SilverNavIcon,
  StatementIcon,
  TierIcon,
  TransferIcon,
} from "../../assets/svg/icons";
import {
  setDashboard,
  setTier,
  setAuctions,
  setStatements,
  setOrder,
  setTransfer,
  setDelivery,
  setPin,
  setPassword,
} from "../../redux-toolkit/features/dashboard/dashboardSlice";


const NavigationModal = ({ setShowModal, allowScroll, handlePointToCash }) => {
  const mobileModalArray = [];
  const { show } = useSelector((store) => store.password);
  const dispatch = useDispatch();
  const [mainCategories, setMainCategories] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [shopCategories, setShopCategories] = useState(false);
  const [defaultPage, setDefaultPage] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [shopHeader, setShopHeader] = useState("shop");
  const [profilePage, setProfilePage] = useState(false);
  const [shopPage, setShopPage] = useState(true);
  let [counter, setCounter] = useState(1);
  const [lastSeen, setLastSeen] = useState('');
  const dataFetchedRef = useRef(false);

  const handleShopClick = (merchantcategoryid) => {
    var array = mainCategories[mainCategories.length - 1];
    var subcategories = array.find(
      (item) => item?.Merchandize_category_id === merchantcategoryid
    );
    var newArray = mainCategories.push(subcategories.sub_categories);
    setAllCategories(newArray);

    var parent = mainCategories[mainCategories.length - 2].find(
      (item) =>
        item.Merchandize_category_id ===
        subcategories.sub_categories[0].Parent_id
    );
    setShopHeader(parent.Merchandize_category_name);
    setCounter((counter += 1));
  };

  const {
    dashboard,
    tier,
    auctions,
    statements,
    order,
    transfer,
    delivery,
    pin,
    password,
  } = useSelector((store) => store.dashboard);

  const handleShop = () => {
    setShopCategories(true);
    setDefaultPage(false);
  };

  const handleProfileShop = (event) => {
    setShopPage(true);
    setProfilePage(false);
  };

  const handleProfile = () => {
    setProfilePage(true);
    setShopPage(false);
  };

  const handleReverse = () => {
    if (mainCategories?.length <= 1) {
      return;
    }
    var newArray = mainCategories.pop();
    setAllCategories(newArray);

    if (mainCategories.length > 1) {
      var parentId = mainCategories[mainCategories.length - 1][0].Parent_id;
      var parent = mainCategories[mainCategories.length - 2].find(
        (item) => item.Merchandize_category_id === parentId
      );
      setShopHeader(parent.Merchandize_category_name);
    } else {
      setShopHeader("Shop");
    }
    setCounter((counter -= 1));
  };

  const handleVisibility = () => {
    setShowModal(false);
    allowScroll();
  };
  const handleVisible = () => {
    setVisible(!visible);
  };
  const handleVisible2 = () => {
    setVisible2(!visible2);
  };

  const handleDashboard = () => {
    dispatch(setDashboard());
    handleVisibility();
  };

  const handleTier = () => {
    dispatch(setTier());
    handleVisibility();
  };

  const handleStatement = () => {
    dispatch(setStatements());
    //  setShowFilteredTable(true);
    handleVisibility();
  };

  const handleOrder = () => {
    dispatch(setOrder());
    handleVisibility();
  };

  const handleAuction = () => {
    dispatch(setAuctions());
    handleVisibility();
  };

  const handleTransfer = () => {
    dispatch(setTransfer());
    handleVisibility();
  };

  const handleDelivery = () => {
    dispatch(setDelivery());
    handleVisibility();
  };

  const handlePassword = () => {
    dispatch(setPassword());
    handleVisibility();
  };

  const handlePin = () => {
    dispatch(setPin());
    handleVisibility();
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    const fetchShop = async () => {
      try {
        const ShopResponse = await axios(
          `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.categories}`
        );
        setAllCategories(ShopResponse.data.Categories);
        ShopResponse.data.Categories.forEach((element) => {
          mobileModalArray.unshift(element);
        });
        let x = mobileModalArray.reverse();
        var newArray = [];
        newArray.unshift(x);
        let anotherArray = newArray.reverse();
        setMainCategories(anotherArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchShop();
  }, []);

  useEffect(()=>{
    const lastSeenObject =  sessionStorage.getItem('first-bank-loyalty')
    setLastSeen(JSON.parse(lastSeenObject)?.data?.customer?.Last_login_datetime)
  }, [])

  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-col px-6 pb-4 space-y-4 bg-button">
        <div className="flex justify-between pt-8">
          {getData(myStore)?.data?.customer?.Membership_id ? (
            <>
              <p className="font-bold tracking-tight text-white leading-loyalty">
                <AvatarModalIcon /> Hi,
                {getData(myStore)?.data?.customer?.Membership_id}
              </p>
              <Link
                className="font-medium tracking-tight text-white underline leading-loyalty"
                to="/logout"
              >
                Sign Out
              </Link>
            </>
          ) : (
            <>
              <p className="text-white">Hi, Visitor </p>
              <Link className="text-white" to="/">
                Login
              </Link>
            </>
          )}
        </div>
        <div className="flex items-center gap-2 lg:hidden">
          <li className="flex text-white">
            {getData(myStore)?.data?.customer?.Membership_id
              ? show
                ? formatValue(getData(myStore)?.data?.customer?.Current_balance)
                : "****"
              : "0.00"}
            &nbsp; Coins
          </li>

          <div className="flex lg:hidden">
            {show ? (
              <div
                className="text-white"
                onClick={() => dispatch(hidePassword())}
              >
                <AiOutlineEye />
              </div>
            ) : (
              <div
                onClick={() => dispatch(showPassword())}
                className="text-white"
              >
                <AiOutlineEyeInvisible />
              </div>
            )}
          </div>
        </div>

        <button
          type="button"
          className="convert-point-btn-mobile"
          onClick={handlePointToCash}
        >
          Convert points to cash
        </button>

        {/* <div className="flex gap-2">
          <SilverNavIcon />
          <p className="text-white capitalize">Silver Member</p>
        </div> */}
          <li className="nav-item navbar-item last-seen-li-mobile">
            <div className='last-seen-container'>
              <p className='last-seen-title'>Last Seen</p>
              <p className='last-seen-time'>{lastSeen}</p>
            </div>
          </li>
      </div>

      {shopPage && (
        <div className="px-6 pt-3 bg-white">
          {defaultPage ? (
            <>
              <ul className="pl-0 space-y-6">
                {/* <li className="">
                  <div
                    onClick={handleShop}
                    className="text-base font-medium tracking-tight text-black leading-loyal">
                    Shop
                  </div>
                </li> */}
                <li className="">
                  <Link
                    to="/airtime&bills"
                    className="text-base font-medium tracking-tight text-black leading-loyal"
                    aria-current="page"
                    onClick={handleVisibility}
                  >
                    Airtime & Bills
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={handleVisibility}
                    to="/movies"
                    className="text-base font-medium tracking-tight text-black leading-loyal"
                  >
                    Movies
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={handleVisibility}
                    to="/events"
                    className="text-base font-medium tracking-tight text-black leading-loyal"
                  >
                    Events
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={handleVisibility}
                    to="/profile/point-transfer"
                    className="text-base font-medium tracking-tight text-black leading-loyal"
                  >
                    Points Transfer
                  </Link>
                </li>

                {/* <li className="flex flex-col">
                  <div
                    onClick={handleVisible}
                    className="flex items-center justify-between text-base font-medium tracking-tight text-black leading-loyal">
                    <p>Movies & Events</p>
                    <ChevronRight />
                  </div>
                  {visible ? (
                    <div className="flex flex-col pt-3">
                      <Link
                        onClick={handleVisibility}
                        to="/movies"
                        className="py-2 text-base font-medium tracking-tight text-black leading-loyal">
                        Movies
                      </Link>
                      <Link
                        onClick={handleVisibility}
                        to="/events"
                        className="py-2 text-base font-medium tracking-tight text-black leading-loyal">
                        Events
                      </Link>
                    </div>
                  ) : null}
                </li> */}
                {/* <li className="">
                  <Link
                    to="/experience"
                    onClick={handleVisibility}
                    className="text-base font-medium tracking-tight text-black leading-loyal">
                    Experience
                  </Link>
                </li> */}
                {/* <li className="flex flex-col">
                  <div
                    onClick={handleVisible2}
                    className="flex items-center justify-between text-base font-medium tracking-tight text-black leading-loyal">
                    <p>Flights & Hotels</p>
                    <ChevronRight />
                  </div>
                  {visible2 ? (
                    <div className="flex flex-col pt-3">
                      <Link
                        onClick={handleVisibility}
                        to="/flight"
                        className="py-2 text-base font-medium tracking-tight text-black leading-loyal">
                        Flights
                      </Link>
                      <Link
                        onClick={handleVisibility}
                        to="/hotel"
                        className="py-2 text-base font-medium tracking-tight text-black leading-loyal">
                        Hotels
                      </Link>
                    </div>
                  ) : null}
                </li> */}

                <li className="">
                  <a
                    href={
                      customerPortal +
                      getData(myStore)?.data?.token?.token?.id +
                      "&path=vouchers"
                    }
                    className="text-base font-medium tracking-tight text-black leading-loyal"
                    aria-current="page"
                    onClick={handleVisibility}
                  >
                    Vouchers
                  </a>
                </li>
                <li className="">
                  <Link
                    to="/discounts"
                    className="text-base font-medium tracking-tight text-black leading-loyal"
                    aria-current="page"
                    onClick={handleVisibility}
                  >
                    Partner Discounts
                  </Link>
                </li>
                {/* <li className="" onClick={handleVisibility}>
                  <Link
                    to="/meals"
                    className="text-base font-medium tracking-tight text-black leading-loyal">
                    Meals
                  </Link>
                </li> */}
              </ul>
              <Link
                to="/profile"
                className="flex gap-3 py-4 text-black border-t-2"
                onClick={handleProfile}
              >
                <img src={user} alt="user" />
                <div className="text-base font-medium tracking-tight text-black leading-loyal">
                  Profile
                </div>
              </Link>
            </>
          ) : (
            <ul className="pl-0 text-black ">
              {mainCategories !== null ? (
                <>
                  <div className="flex">
                    {mainCategories?.length > 1 ? (
                      <img
                        onClick={handleReverse}
                        src={arrowLeft}
                        alt="back arrow"
                      />
                    ) : (
                      <div></div>
                    )}
                    {counter === 1 ? (
                      <img
                        onClick={() => setDefaultPage(true)}
                        src={arrowLeft}
                        alt="back arrow"
                      />
                    ) : null}
                    <div className="flex mx-auto">
                      <h3 className="flex mx-auto text-lg w-fit">
                        {shopHeader.toUpperCase()}
                      </h3>
                    </div>
                  </div>
                </>
              ) : null}
              {mainCategories !== null
                ? mainCategories[mainCategories.length - 1].map((mobile) => {
                    return (
                      <li>
                        {mobile?.sub_categories?.length > 0 ? (
                          <div
                            onClick={() =>
                              handleShopClick(mobile?.Merchandize_category_id)
                            }
                            className="flex my-1.5 justify-between text-black font-medium text-base leading-loyal tracking-tight"
                          >
                            <p>
                              {mobile?.Merchandize_category_name.toUpperCase()}
                            </p>
                            <ChevronRight />
                          </div>
                        ) : (
                          <div className="flex my-1.5 justify-between text-black font-medium text-base leading-loyal tracking-tight">
                            <Link
                              onClick={handleVisibility}
                              to={`/single-category/${mobile?.Merchandize_category_id}`}
                              className="text-black"
                            >
                              {mobile?.Merchandize_category_name.toUpperCase()}
                            </Link>
                          </div>
                        )}
                      </li>
                    );
                  })
                : null}
            </ul>
          )}
        </div>
      )}

      {profilePage && (
        <div className="px-6 pt-3 bg-white">
          <div className="flex justify-between py-3">
            <img onClick={handleProfileShop} src={arrowLeft} alt="back" />
            <h6 className="capitalize w-fit">profile</h6>
            <div></div>
          </div>
          <ul className="pl-0 space-y-6">
            <li to="/profile" onClick={handleDashboard} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <DashboardIcon /> <h6>Account Dashboard</h6>
              </span>
            </li>
            <li onClick={handleTier} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <TierIcon /> <h6> Tier Status</h6>
              </span>
            </li>
            <li onClick={handleStatement} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <StatementIcon /> <h6>Statement</h6>
              </span>
            </li>
            <li onClick={handleOrder} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <OrderIcon /> <h6> Order History</h6>
              </span>
            </li>
            <li onClick={handleAuction} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <AuctionIcon /> <h6>Auction Bid History</h6>
              </span>
            </li>
            <li onClick={handleTransfer} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <TransferIcon /> <h6>Transfer Coin</h6>
              </span>
            </li>
            <li onClick={handleDelivery} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <DeliveryIcon /> <h6>Delivery Address</h6>
              </span>
            </li>
            <li onClick={handlePassword} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <ChangeIcon /> <h6>Change Password</h6>
              </span>
            </li>
            <li onClick={handlePin} className="">
              <span
                className="flex gap-3 text-base font-medium tracking-tight text-black leading-loyal"
                aria-current="page"
              >
                <ChangeIcon /> <h6>Reset Pin</h6>
              </span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavigationModal;
