import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboard: true,
    tier: false,
    auctions: false,
    statements: false,
    order: false,
    transfer: false,
    delivery: false,
    pin: false,
    password: false,
  },

  reducers: {
    setDashboard: (state) => {
      state.dashboard = true;
      state.tier = false;
      state.auctions = false;
      state.statements = false;
      state.order = false;
      state.transfer = false;
      state.delivery = false;
      state.pin = false;
      state.password = false;
    },
    setTier: (state) => {
      state.dashboard = false;
      state.tier = true;
      state.auctions = false;
      state.statements = false;
      state.order = false;
      state.transfer = false;
      state.delivery = false;
      state.pin = false;
      state.password = false;
    },
    setAuctions: (state) => {
      state.dashboard = false;
      state.tier = false;
      state.auctions = true;
      state.statements = false;
      state.order = false;
      state.transfer = false;
      state.delivery = false;
      state.pin = false;
      state.password = false;
    },
    setStatements: (state) => {
      state.dashboard = false;
      state.tier = false;
      state.auctions = false;
      state.statements = true;
      state.order = false;
      state.transfer = false;
      state.delivery = false;
      state.pin = false;
      state.password = false;
    },
    setOrder: (state) => {
      state.dashboard = false;
      state.tier = false;
      state.auctions = false;
      state.statements = false;
      state.order = true;
      state.transfer = false;
      state.delivery = false;
      state.pin = false;
      state.password = false;
    },
    setTransfer: (state) => {
      state.dashboard = false;
      state.tier = false;
      state.auctions = false;
      state.statements = false;
      state.order = false;
      state.transfer = true;
      state.delivery = false;
      state.pin = false;
      state.password = false;
    },
    setDelivery: (state) => {
      state.dashboard = false;
      state.tier = false;
      state.auctions = false;
      state.statements = false;
      state.order = false;
      state.transfer = false;
      state.delivery = true;
      state.pin = false;
      state.password = false;
    },
    setPin: (state) => {
      state.dashboard = false;
      state.tier = false;
      state.auctions = false;
      state.statements = false;
      state.order = false;
      state.transfer = false;
      state.delivery = false;
      state.pin = true;
      state.password = false;
    },
    setPassword: (state) => {
      state.dashboard = false;
      state.tier = false;
      state.auctions = false;
      state.statements = false;
      state.order = false;
      state.transfer = false;
      state.delivery = false;
      state.pin = false;
      state.password = true;
    },
  },
});

export const {
  setDashboard,
  setTier,
  setAuctions,
  setStatements,
  setOrder,
  setTransfer,
  setDelivery,
  setPin,
  setPassword,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
