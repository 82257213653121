import axios from "axios";
import moment from "moment/moment";
import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import Categories from "../../components/Categories/Categories";
import NotificationModal from "../../components/Modals/NotificationModal";
import { endpoints } from "../../utils/constant";
import read from "../../assets/svg/read.svg";
import unread from "../../assets/svg/unread.svg";
import { ReadIcon, UnReadIcon } from "../../assets/svg/icons";
import { authHeaders, logout } from "../../config/helper";
import { ToastContainer, toast } from "react-toastify";


const updateData = [
  {
    id: 1,
    updateType: "FirstBank Rewards",
    Subject: "New Survey",
    Plain_contents:
      "This survey would take approximately 15 minutes to complete...",
    Creation_date: "Nov 1"
  },
  {
    id: 2,
    updateType: "FirstBank Rewards",
    Subject: "New Survey",
    Plain_contents:
      "This survey would take approximately 15 minutes to complete...",
    Creation_date: "Nov 1"
  },
  {
    id: 3,
    updateType: "FirstBank Rewards",
    Subject: "New Survey",
    Plain_contents:
      "This survey would take approximately 15 minutes to complete...",
    Creation_date: "Nov 1"
  },
  {
    id: 4,
    updateType: "FirstBank Rewards",
    Subject: "New Survey",
    Plain_contents:
      "This survey would take approximately 15 minutes to complete...",
    Creation_date: "Nov 1"
  },
  {
    id: 5,
    updateType: "FirstBank Rewards",
    Subject: "New Survey",
    Plain_contents:
      "This survey would take approximately 15 minutes to complete...",
    Creation_date: "Nov 1"
  }
];

const Notification = () => {
  
  const [updateStatus, setUpdateStatus] = useState(true);
  const [mailStatus, setMailStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mailNotification, setMailNotification] = useState([]);
  const [readMail, setReadMail] = useState(0);
  const [currentId, setCurrentId] = useState(null);
  const [notify, setNotify] = useState(null);
  const { memberid } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const handleUpdateStatus = () => {
    setUpdateStatus(true);
    setMailStatus(false);
  };

  const handleMailStatus = () => {
    setMailStatus(true);
    setUpdateStatus(false);
  };

  const safeDocument = typeof document !== "undefined" ? document : {};
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;
    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    html.style.position = "relative"; /* [1] */
    html.style.overflow = "hidden"; /* [2] */
    body.style.position = "relative"; /* [1] */
    body.style.overflow = "hidden"; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;
    html.style.position = "";
    html.style.overflow = "";
    body.style.position = "";
    body.style.overflow = "";
    body.style.paddingRight = "";
    scrollBlocked.current = false;
  };

  const handleModal = (id) => {
    setShowModal(true);
    allowScroll();
    setReadMail(1);
    setCurrentId(id);
    fetchSingleNotification(id);
    // fetchNotification();
  };

  const handleCloseModal = () => {
   
    
    setShowModal(false);
    
    window.location.reload(true);
    // fetchNotification(setMailNotification);
  };

  const fetchNotification = async () => {
    try {
      const notificationRes = await axios(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.allNotification}${memberid}`,{headers: authHeaders}
      );
      setMailNotification(notificationRes?.data?.data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(()=>{

          logout()
        }, 4000)
     
      } 
    }
  };


  useEffect(() => {
    fetchNotification(setMailNotification);
  }, [setMailNotification]);

  const fetchSingleNotification = async (currentId) => {
    if (currentId) {
      try {
        const singleRes = await axios(
          `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.singleNotification}${currentId}`,{headers: authHeaders}
        );
        setNotify(singleRes?.data?.data);
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          toast.error("Authentication failed. Please log in again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          logout()
          //<ToastContainer />
         // import { ToastContainer, toast } from "react-toastify";
        } 
      }
    }
  };

    const handleClick = () => {
      setCurrentPage(currentPage + 1);
    };

      const paginatedData = mailNotification.slice(0, currentPage * 10);

      
  return (
    <div className="bg-[#f8f8f8]">
      <Categories />
      <div className="mx-[10px] md:mx-[50px] lg:mx-[220px] py-4">
        <div>
          <h4 className="w-fit flex justify-start capitalize font-bold text-[34px] leading-loyalty tracking-[130%]">
            messages
          </h4>
        </div>
        <div className="flex gap-3 py-3">
          <h4
            onClick={() => handleUpdateStatus()}
            className={`${
              updateStatus ? "text-button" : "text-black"
            } cursor-pointer capitalize font-bold leading-loyalty text-[15px] tracking-tight`}
          >
            updates
          </h4>
          <h4
            onClick={() => handleMailStatus()}
            className={`${
              mailStatus ? "text-button" : "text-black"
            } cursor-pointer capitalize font-bold leading-loyalty text-[15px] tracking-tight`}
          >
            email
          </h4>
        </div>
        <div>
          {updateStatus ? (
            <>
              {updateData.map((update) => (
                <div
                  key={update.User_notification_id}
                  className={`flex cursor-pointer bg-white border-t border-solid py-6 justify-between gap-4 sm:gap-20 px-4`}
                >
                  <p className="leading-loyalty font-bold text-base tracking-tight">
                    {update.Subject}
                  </p>
                  <p className="leading-loyalty font-bold text-base tracking-tight">
                    {update.Plain_contents}
                  </p>

                  <p className="leading-loyalty font-bold text-base tracking-tight">
                    {update.Creation_date}
                  </p>
                </div>
              ))}
            </>
          ) : (
            <>
              {paginatedData.map((mail) => {
                return (
                  <div
                    key={mail?.User_notification_id }
                    onClick={() => handleModal(mail?.User_notification_id)}
                    className={`flex cursor-pointer bg-white border-t border-solid items-center py-6 justify-between sm:gap-6 lg:gap-2 px-2 sm:px-4`}
                  >
                    <div className="flex w-[18%] lg:w-[15%]  lg:gap-2">
                      <div className="w-fit">
                        {mail.Note_open === readMail && <UnReadIcon />}

                        {mail.Note_open !== readMail && <ReadIcon />}
                      </div>

                      <p className="leading-loyalty capitalize  truncate  px-2.5 font-bold text-sm sm:text-base tracking-tight">
                        {mail.Subject.toLowerCase()}
                      </p>
                    </div>
                    <div className="flex w-1/2 px-1 md:w-2/4 xl:w-3/5 lg:w-4/6 items-center">
                      <p className="leading-loyalty truncate font-medium text-border text-base tracking-tight">
                        {mail.Plain_contents}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <p className="leading-loyalty font-bold text-base tracking-tight">
                        {moment(mail.Creation_date).format("MMM, DD")}
                      </p>
                    </div>
                  </div>      

                );
              })}
              <button className="bg-add flex mx-auto px-[20%] py-2 my-4 font-bold rounded" onClick={handleClick}>Load More</button>
            </>
          )} 
        </div>
       
        
        {showModal && (
          <NotificationModal
            onClose={handleCloseModal}
           // visible={showModal}
            notify={notify}
          />
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Notification;
