import React, { useEffect, useRef } from "react";
import cancel from "../../assets/svg/x-modal.svg";
import pdf from "../../assets/svg/saveAsPdf.svg";
import "./notificationModal.css";
// import imsave from "../../assets/svg/saveAsImage.svg";
// import { endpoints } from "../../utils/constant";
// import html2pdf from "html2pdf.js";
// import { PDFDocument, StandardFonts } from "pdf-lib";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

const NotificationModal = ({  onClose, notify }) => {
  const contentRef = useRef(null);
  const modalRef = useRef(null);


  useEffect(() => {
    const handleClose = (event) => {
   
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); 
      }
    };
  
  
    document.addEventListener("click", handleClose);
  
   
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);
  






  const handleDownload = () => {
    const content = contentRef.current;

    if (content) {
      const { body } = document;
      const originalOverflow = body.style.overflow;
      body.style.overflow = "hidden";

      const contentClone = content.cloneNode(true);
      const wrapper = document.createElement("div");
      wrapper.appendChild(contentClone);

      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>&nbsp;</title>
          </head>
          <body>
            ${wrapper.innerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
        body.style.overflow = originalOverflow;
      }, 1000);
    }
  };

  
  return (
    <div className="fixed overflow-y-hidden justify-center items-center top-0 left-0 w-full h-full z-10 bg-[#808080] bg-opacity-10 backdrop-blur-sm flex">

      <div className="h-5/6 w-[98%] lg:w-1/2 overflow-y-auto">

    
      <div className="h-3/4  overflow-y-auto" >
        {notify !== null &&
          notify.map((notified) => (
            <div
              key={notified?.id}
              className="bg-white relativ rounded px-4 py-8"
            >
              {/* <button
                onClick={onClose}
                className="flex lg:hidden absolute top-[14%] right-[14%] bg-[#f0f0f0] rounded p-2 gap-2 items-center h-fit"
              >
                <img className="h-5 w-5" src={cancel} alt="close" />
                <p className="capitalize font-medium text-base leading-loyalty tracking-loyal">
                  close
                </p>
              </button> */}
              <div className="flex justify-between my-3 items-center" ref={modalRef}>
                <div>
                  <h2 className="font-bold text-2xl leading-loyalty tracking-loyal">
                    {notified?.subject}
                  </h2>
                  <p className="text-[10px] lg:text-base">
                    From: loyalty.services@loyaltysolutionsnigeria.com
                  </p>
                </div>

                <button
                  onClick={onClose}
                 
                  className="lg:flex bg-[#f0f0f0] rounded p-2 gap-2 items-center h-fit"
                >
                  <img className="h-5 w-5" src={cancel} alt="close" />
               
                </button>
              </div>

              <div >
         

            

              

                <div
                  id="modal-content"
                  ref={contentRef}
                  className="text-white"
                  dangerouslySetInnerHTML={{ __html: notified?.fullContent }}
                />
                 

              
              </div>
            
            </div>
          ))}
      </div>
      <div className="flex px-4 py-10 bg-[#fff]">
                  <button
                    onClick={handleDownload}
                    className="flex gap-2 items-center capitalize bg-[#f0f0f0] border rounded-[40px] px-2 py-2 border-solid"
                  >
                    <img src={pdf} alt="save_as_pdf" /> save as PDF
                  </button>
                </div>
      </div>

    </div>
  );
};

export default NotificationModal;
