import React, {useState} from 'react';
// import logo from '../../../assets/logo.png';
import './Navbar.css';
import {motion} from 'framer-motion';
import {navAnimation} from '../../../animation';
import {Link} from 'react-router-dom';
import {useAnimationInView} from '../../LandingPage/components/useAnimationInView';
import {EntryNavHamburger, LinkedIn} from '../../../assets/icons';
import {getMemberId} from '../../../config/helper';
import logo from '../../../assets/images/logo.svg';
import useLockScrolling from '../../../components/lockScroll/lockScroll';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [element, controls] = useAnimationInView();

  useLockScrolling(isOpen);

  const result = getMemberId();

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <motion.nav
      ref={element}
      variants={navAnimation}
      transition={{delay: 0.1}}
      animate={controls}>
      <div className="entrynav-container">
        <div>
          <Link to="/">
            <img src={logo} alt="first bank logo" />
          </Link>
        </div>

        <ul className="entrynav-ul">
          <li className="nav-item">
            <Link to="#" className="nav-link" aria-current="page" href="#">
              Home
            </Link>
          </li>

          <li className="nav-item">
            <button onClick={() => scrollToSection('about')}>
              <Link className="nav-link">About First Reward</Link>
            </button>
          </li>

          <li className="nav-item">
            <button onClick={() => scrollToSection('redeem')}>
              <Link to="" className="nav-link">
                Redeem Points
              </Link>
            </button>
          </li>

          <li className="nav-item">
            <button onClick={() => scrollToSection('earn-point')}>
              <Link to="#" className="nav-link">
                Earn Points
              </Link>
            </button>
          </li>
        </ul>

        <div>
          {result ? (
            <Link to="/redeem">
              <button className="btn-big">Go To Shop</button>
            </Link>
          ) : (
            <Link to="/login">
              <button className="btn-big">Login</button>
            </Link>
          )}

          <span className="entrynav-hamburger" onClick={handleIsOpen}>
            <EntryNavHamburger />
          </span>
        </div>
      </div>
      {isOpen && (
        <div className="mobile-entrynav">
          <ul className="entrynav-ul-mobile">
            <li className="nav-item">
              <Link to="#" className="nav-link" aria-current="page" href="#">
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="#">
                About First Reward
              </Link>
            </li>

            <li className="nav-item">
              <Link to="" className="nav-link">
                Redeem Points
              </Link>
            </li>

            <li className="nav-item">
              <Link to="#" className="nav-link">
                Earn Points
              </Link>
            </li>
            <li className="nav-item">
              {result ? (
                <Link to="/redeem">
                  <button className="btn-small">Go To Shop</button>
                </Link>
              ) : (
                <Link to="/login">
                  <button className="btn-small">Login</button>
                </Link>
              )}
            </li>
          </ul>
        </div>
      )}
    </motion.nav>
  );
};

export default Navbar;
