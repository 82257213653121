import React, { useEffect, useState } from "react";
import "./SearchResult.css";
import companyLogo from "./../../assets/images/company-logo.png";

import arrowRight from "./../../assets/images/arrow-right.png";
import mobileArrow from "./../../assets/images/mobileflightarrow.png";
import { endpoints } from "../../utils/constant";
import axios from "axios";
import { doPost, formatValue, storeData } from "../../config/helper";

const SearchResult = ({ setShowFlightCheckout, onMobile, flightSearchResult }) => {
  const [toFrom, setToFrom] = useState({});
  storeData("", flightSearchResult)
  const handleView = (view) => {
    setShowFlightCheckout(false);
    const result = flightSearchResult?.data?.filter((each) => each.id === view);
  };
  return (
    <>
      { (
        <div className="mobile-search-results">
          <div className="search-result-container-inner">
            {flightSearchResult?.data?.map((each) => {
              console.log(each)
              return (
                <div className="non-stop-container" key={each?.product_code}>
                  <div className="non-stop-l_">
                    <div className="mobile-flight-adjust-1">
                      <img src={each?.FlightCombination?.FlightModels[0]?.AirlineLogoUrl} alt={each?.FlightCombination?.FlightModels[0]?.Airline} className="company-logo" />
                      <p className="company-name">{each?.FlightCombination?.FlightModels[0]?.AirlineName}</p>
                    </div>
                    <div className="mobile-flight-adjust-2">
                      <div className="leaving-time-mobile">
                        <p className="leaving-time">{each?.FlightCombination?.FlightModels[0]?.DepartureTime}</p>
                        <p className="from">{each?.FlightCombination?.FlightModels[0]?.DepartureName}</p>
                      </div>
                      <div className="arrow-container">
                        <p className="duration">{}</p>
                        <img src={mobileArrow} alt="arrow-right" />
                        <p className="non-stop">{each?.FlightCombination?.FlightModels[0]?.FlightLegs[0]?.IsStop ===true ?"Stop Over": "Non Stop"}</p>
                      </div>
                      <div className="leaving-time-mobile">
                        <p className="arriving-time">{each?.FlightCombination?.FlightModels[0]?.ArrivalTime}</p>
                        <p className="from">{each?.FlightCombination?.FlightModels[0]?.ArrivalName}</p>
                      </div>
                      <div className="non-stop-r-container">
                        <div className="non-stop-r">
                          <p className="r-from">from</p>
                          <p className="r-cowries">{formatValue(each?.FlightCombination?.Price)} Coins</p>
                          <button
                            className="r-btn"
                            onClick={()=>handleView(each?.product_code) }
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

   
      <div className="load-more-btn-container">
        <button className="load-more-btn">Load More</button>
      </div>
    </>
  );
};

export default SearchResult;
