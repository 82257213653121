import React from "react";
import Pop from "../../assets/images/flight-and-hotel-modal.png";


const AuctionModal = ({ visible, onClose }) => {
  if (!visible) return null;
  return (
    <div className="fixed overflow-y-hidden justify-center top-0 left-0 w-full h-full z-10 bg-[#808080] bg-opacity-10 backdrop-blur-sm flex">
      <div className="bg-white rounded px-4 my-20 w-[95%] h-fit lg:w-[482px] lg:h-[430px]">
        <div className="flex justify-center py-4">
          <img className="w-[50%]" src={Pop} alt="image" />
        </div>
        <div className="space-y-2">
          <h4 className="flex w-fit mx-auto font-semibold text-2xl leading-loyalty">
            Rewards Claimed, Hurray 🎁🎁🎁
          </h4>
          <p className="flex lg:w-[376px] flex-wrap text-delivery justify-center mx-auto font-medium text-xs lg:text-base leading-loyalty">
            You have claimed a reward
          </p>
        </div>

        <button className="bg-add border-black border-solid border-2 w-full py-2.5 lg:py-4 my-3 font-bold text-base leading-loyalty rounded">
          <div onClick={onClose} className="text-black">
            Continue
          </div>
        </button>
      </div>
    </div>
  );
};

export default AuctionModal;
