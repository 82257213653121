import React from "react";
import styled from "styled-components";
import { FaChevronUp } from "react-icons/fa";
import {
  showScrollToTopIcon,
  hideScrollToTopIcon,
} from "../redux-toolkit/features/scroll/scrollSlice";
import { useDispatch, useSelector } from "react-redux";

function ScrollToTop() {
  const { visible } = useSelector((store) => store.scroll);
  const dispatch = useDispatch();
  window.addEventListener("scroll", () => {
    window.pageYOffset > 2500
      ? dispatch(showScrollToTopIcon())
      : dispatch(hideScrollToTopIcon());
  });

  const scrollToHero = () => {
    if (visible) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Div>
      <span className={`${visible ? "block" : "none"}`} onClick={scrollToHero}>
        <FaChevronUp />
      </span>
    </Div>
  );
}

const Div = styled.div`
  max-width: 100vw;
  .none {
    opacity: 0;
    visibility: hidden;
  }
  .block {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #f0bd2d;
    padding: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    transition: 0.4s ease-in-out;
    svg {
      color: #000000;
      font-size: 1.3rem;
    }
  }
`;


export default ScrollToTop;
