import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const InactivityHandler = () => {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const logout = () => {
    sessionStorage.clear("first-bank-loyalty");
    sessionStorage.clear("accessToken");
    sessionStorage.clear("currentBalance");
    navigate("/login");
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  
    timeoutRef.current = setTimeout(logout, 900000);
    
};


useEffect(() => {
    const events = ["click", "mousemove", "keydown", "scroll", "touchstart"];
    events.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });

    resetTimeout();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return null;
};

export default InactivityHandler;
