import React, { useEffect, useState } from "react";
import "./Flight.css";
import Categories from "../../components/Categories/Categories";
import CurrentPageNav from "../../components/Navbar/CurrentPageNav";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector, useDispatch } from "react-redux";
import AddFlightSection from "../../components/AddFlightSection/AddFlightSection";
import {
  searchFlightResults,
  searchFlight
} from "../../redux-toolkit/features/Flight/flightSlice";

import {
  ChevronDown,
  FlightDown,
  FlightUp,
  Incremental,
  Decrement,
  Times,
  Adding,
  MobileBackIcon,
  MobilePen
} from "../../assets/icons";
import FlightBanner from "../../components/FlightBanner/FlightBanner";
import SearchResult from "../../components/SearchResult/SearchResult";
import FlightCheckout from "../../components/FlightCheckout/FlightCheckout";
import AdBanner from "../../components/AdBanner/AdBanner";
import { doPost } from "../../config/helper";
import { endpoints } from "../../utils/constant";
import axios from "axios";
// import { current } from "@reduxjs/toolkit";

const ReturnTicket = () => {
  const dispatch = useDispatch();
  const ref = React.createRef(null);
  const reff = React.createRef(null);

  const { searchedResults, isGettingResults, search } = useSelector(
    (store) => store.flight
  );

  const current = new Date();

  const flightDetail1 = [
    { value: "Return", id: 1 },
    { value: "One-Way", id: 2 }
    // { value: "Multi-stop", id: 3 },
  ];
  const flightDetail2 = [
    { value: "Economy", id: 1 },
    { value: "Business", id: 2 },
    { value: "First Class", id: 3 }
  ];

  const flightDetail3 = [
    { value: "Adult", num: 1, age: "> 12", id: 1 },
    { value: "Children", num: 0, age: "2 - 11", id: 2 },
    { value: "Toddler", num: 0, age: "< 2", id: 3 }
  ];

  const [showFlightDetail1, setShowFlightDetail1] = useState(false);
  const [showFlightDetail2, setShowFlightDetail2] = useState(false);
  const [showFlightDetail3, setShowFlightDetail3] = useState(false);
  const [showLeavingCalendar, setShowLeavingCalendar] = useState(false);
  const [showLeavingCalendarOption, setShowLeavingCalendarOption] =
    useState(false);
  const [selectedFlightOption1, setSelectedFlightOption1] = useState("Return");
  const [selectedFlightOption2, setSelectedFlightOption2] = useState("Economy");
  const [allPassangers, setAllPassangers] = useState(1);
  const [hideReturnDate, setHideReturnDate] = useState(true);
  const [hideEconomySelection, setHideEconomySelection] = useState(true);
  const [multistopSelection, setMultistopSelection] = useState(false);
  const [hideEconomyState, setHideEconomyState] = useState(false);
  const [hideFlightecton1, setHideFlightSecton1] = useState(true);
  const [addFlightList, setAddFlightList] = useState([]);
  const [hideBtn, setHideBtn] = useState(true);
  const [returnAndLeavingCalendar, setReturnAndLeavingCalendar] =
    useState(false);
  const [disableDoubleCalendar, setDisableDoubleCalendar] = useState(true);
  const [showSearchedResults, setShowSearchedResults] = useState(true);
  const [showFlightCheckout, setShowFlightCheckout] = useState(true);
  const [onMobileDetails, setOnMobileDetails] = useState(true);
  const [flightPassangerProp, setFlightPassangerProp] = useState(flightDetail3);
  const [travellerNum, setTravellerNum] = useState(1);
  const [onMobile, setOnMobile] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [getDate1, setGetDate1] = useState(current.getDate());
  const [getDate2, setGetDate2] = useState(current.getDate() + 1);
  const [getMonth1, setGetMonth1] = useState(current.getMonth());
  const [getMonth2, setGetMonth2] = useState(current.getMonth());
  const [flightSearchData, setFlightSearchData] = useState([]);
  const [inputFrom, setInputFrom] = useState("");
  const [inputTo, setInputTo] = useState("");
  const [showFlightSearchedResult, setShowFlightSearchedResult] =
    useState(false);

  const [flightResultBox1, setFlightResultBox1] = useState(false);
  const [flightResultBox2, setFlightResultBox2] = useState(false);
  const [resultBody, setResultBody] = useState(false);
  const [monitorDispatch, setMonitorDispatch] = useState(false);
  const [serachResult, setSerachReult] = useState([])
  useEffect(() => {
    if (monitorDispatch) {
      dispatch(searchFlight(""));
    } else {
      dispatch(searchFlight(inputFrom));
    }
  }, [monitorDispatch, inputFrom]);

  useEffect(() => {
    if (monitorDispatch) {
      dispatch(searchFlight(""));
    } else {
      dispatch(searchFlight(inputTo));
    }
  }, [monitorDispatch, inputTo]);

  useEffect(() => {
    if (search) {
      dispatch(searchFlightResults(search));
    } else {
      dispatch(searchFlightResults(search));
    }
  }, [search]);

  const handleMouseOutOne = (currentRef) => {
    if (document.activeElement === currentRef) {
      setShowFlightSearchedResult(true);
      setResultBody(false);
      setMonitorDispatch(false);
      setFlightResultBox1(true);
    } else {
      setFlightResultBox1(false);
      setShowFlightSearchedResult(false);
    }
  };

  const handleMouseOutTwo = (currentReff) => {
    if (document.activeElement === currentReff) {
      setResultBody(true);
      setFlightResultBox2(false);
      setMonitorDispatch(false);
      setShowFlightSearchedResult(true);
    } else {
      setFlightResultBox2(false);
      setShowFlightSearchedResult(false);
    }
  };

  const handleFlightMovement = (data) => {
    if (resultBody) {
      setInputTo(data);
    } else {
      setInputFrom(data);
    }

    dispatch(searchFlight(""));
    setShowFlightSearchedResult(false);
    setMonitorDispatch(true);
  };

  const handleChange1 = (e) => {
    e.preventDefault();
    setInputFrom(e.target.value.toLowerCase());
  };

  const handleChange2 = (e) => {
    e.preventDefault();
    setInputTo(e.target.value.toLowerCase());
  };
  //  useEffect(()=>{

  //  }, [input])

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth } = window;
    return innerWidth;
  }

  // console.log(allPassangers, "total");

  useEffect(() => {
    if (!showSearchedResults) {
      setShowFlightDetail1(false);
      setShowFlightDetail2(false);
      setShowFlightDetail3(false);
      setOnMobileDetails(false);
    }
  }, [showSearchedResults]);

  // Hiding flight detail on other screen start

  useEffect(() => {
    if (!showSearchedResults && windowSize <= 480) {
      setOnMobileDetails(false);
    } else {
      setOnMobileDetails(true);
    }
  }, [windowSize, showSearchedResults]);

  // Hiding flight detail on other screen end

  useEffect(() => {
    if (windowSize <= 480) {
      setOnMobile(false);
      setDisableDoubleCalendar(false);
    } else {
      setOnMobile(true);
      setDisableDoubleCalendar(true);
    }
  }, [windowSize]);

 

  const handleSearchedResults = async () => {    
      try {
        const searchResponse = await axios.post(
          `${process.env.REACT_APP_EXPERIENCE_URL}${endpoints.searchFlight}`,
          {
            children: 0,
            adults: 1,
            infants: 0,
            ticket_class: "M",
            flight_search_type: "Oneway",
            itineraries: [
              {
                Departure: "LOS",
                Destination: "DXB",
                DepartureDate: "11-09-2023"
              }
            ]
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              token: `${process.env.REACT_APP_FLIGHT_KEY}`
            }
          }
        );
        setSerachReult(searchResponse?.data);
        console.log(searchResponse?.data)
        console.log(serachResult?.data);
        setShowSearchedResults(false);
        setOnMobileDetails(false);
      } catch (error) {
        console.log(error.message);
      }
   // };
   
  };

  const handleLeavingCalendar = () => {
    if (showLeavingCalendarOption) {
      setShowLeavingCalendarOption(false);
    } else {
      setShowLeavingCalendarOption(true);
    }
  };

  const handleLeavingAndReturnCalendar = () => {
    if (returnAndLeavingCalendar) {
      setReturnAndLeavingCalendar(false);
    } else {
      setReturnAndLeavingCalendar(true);
    }
  };

  const dateArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const date1 = `${getDate1} ${dateArray[getMonth1]} ${current.getFullYear()}`;

  const date2 = `${getDate2} ${dateArray[getMonth2]} ${current.getFullYear()}`;

  //  console.log(date2, d,  "date2 and d");

  const [value, onChange] = useState([
    new Date(),
    new Date(new Date().setDate(current.getDate() + 1))
  ]);
  // console.log(value);
  // console.log(date1, date2, "date1/date2");

  const [value1, onChange1] = useState(new Date());

  const [leavingDate, setLeavingDate] = useState(date1);

  const [returningDate, setReturningDate] = useState(date2);

  const [leavingAloneDate, setLeavingAloneDate] = useState(date1);

  const tileDisabled = ({ activeStartDate, date, view }) => {
    let d = new Date();
    return date < d.setDate(d.getDate() - 1);
  };

  useEffect(() => {
    setLeavingAloneDate(
      `${value1.getDate()} ${
        dateArray[value1.getMonth()]
      } ${value1.getFullYear()}`
    );

    setShowLeavingCalendarOption(false);
  }, [value1]);

  useEffect(() => {
    setLeavingDate(
      `${value[0].getDate()} ${
        dateArray[value[0].getMonth()]
      } ${value[0].getFullYear()}`
    );

    // current.getFullYear(), current.getMonth()+1, 0

    const lastDayOfMonth = new Date(value[0]);
    const nextMonth = (current.getMonth() + 1) % 12;

    if (lastDayOfMonth.getDate() === current.getDate()) {
      setReturningDate(
        `${value[1].getDate()} ${
          dateArray[value[1].getMonth()]
        } ${value[1].getFullYear()}`
      );
    } else {
      setReturningDate(
        `${value[1].getDate()} ${
          dateArray[value[1].getMonth()]
        } ${value[1].getFullYear()}`
      );
    }

    setReturnAndLeavingCalendar(false);
  }, [value[1]]);

  //////////////////////////// FLIGHT PASSANGER FUNCTION ////////////////////////////////////

  const handlePassangerIncrement = (tot) => {
    const result = flightPassangerProp.map((e) => e);
    // console.table(flightPassangerProp,  "main result");
    const totalPassangerNum = result.reduce(
      (total, current) => total + current.num,
      0
    );

    setAllPassangers(totalPassangerNum);

    if (totalPassangerNum < 9) {
      const numResult = result.filter((each, index) => {
        if (index === tot - 1) {
          if (index === 2 && each.num >= result[0].num) {
            each.num = result[0].num;
          } else {
            each.num++;
          }
        }
      });
    }
    setFlightPassangerProp(result);
  };

  const handlePassangerDecrement = (tot) => {
    const result = flightPassangerProp.map((e) => e);

    // console.table(result, result[tot - 1], tot, "main result");

    const totalPassangerNum = result.reduce(
      (total, current) => total + current.num,
      0
    );

    setAllPassangers(totalPassangerNum);

    if (totalPassangerNum > 1) {
      const numResult = result.filter((each, index) => {
        if (index === tot - 1) {
          if (index === 0 && result[index].num === 1) {
            each.num = result[index].num;
          } else if (result[index].num === 0 && index !== 0) {
            each.num = 0;
          } else {
            each.num--;
          }
        }
      });
    }

    setFlightPassangerProp(result);
  };

  useEffect(() => {
    const flightNum = flightPassangerProp.reduce(
      (acc, prop) => acc + prop.num,
      0
    );
    setTravellerNum(flightNum);
  }, [flightPassangerProp]);

  // console.log(flightPassangerProp, "flightPassangerProp");

  //////////////////////////// FLIGHT PASSANGER FUNCTION END ////////////////////////////////////

  // TARGETTING TYPE OF FLIGHT DETAILS

  const handleFlightDetails1 = (num, data) => {
    setSelectedFlightOption1(data);
    
    if (data === "Return") {
      setHideEconomySelection(true);
      setMultistopSelection(false);
      setHideReturnDate(true);
      setHideEconomyState(false);
      setShowLeavingCalendar(false);
      setShowLeavingCalendarOption(false);
    } else if (data === "One-Way") {
      setHideReturnDate(false);
      setHideEconomySelection(true);
      setMultistopSelection(false);
      setHideEconomyState(false);
      setShowLeavingCalendar(true);
      setReturnAndLeavingCalendar(false);
    } else {
      setMultistopSelection(true);
      setHideEconomySelection(false);
      setHideEconomyState(true);
    }
    setShowFlightDetail1(false);
  };

  const handleFlightDetails2 = (num, data) => {
    setSelectedFlightOption2(data);
    // console.log(num, data, "id-needed");
  };

  return (
    <>
      <div className="return-ticket-container">
        <Categories />
        {!showSearchedResults && <AdBanner />}
        {/* <CurrentPageNav /> */}
        {showFlightCheckout && (
          <div>
            {showSearchedResults && (
              <div className="flight-banner">
                <div className="flight-text-container">
                  <h1>
                    Find a flight for your next trip and pay with your FirstCoins
                  </h1>
                </div>
              </div>
            )}

            {!showSearchedResults && (
              <div className="replace-flight-details">
                <div className="replace-flight-details-inner">
                  <div className="replace-back">
                    <MobileBackIcon />
                  </div>
                  <div className="replace-flight-detail">
                    <p>Lagos - Ghana</p>
                    <div className="replace-flight-detail-inner">
                      <p>12 Oct - 14 Nov</p>
                      <p>•</p>
                      <p>2 Adult</p>
                      <p>•</p>
                      <p>Economy</p>
                    </div>
                  </div>
                  <div
                    className="replace-pen"
                    onClick={() => setOnMobileDetails(true)}
                  >
                    <MobilePen />
                  </div>
                </div>
              </div>
            )}

            {/* ECONOMY CONTAINER START */}

            {onMobileDetails && (
              <div className="all-flight-details-container">
                {showFlightDetail1 && (
                  <div className="flight-details1 flight_detail_adjust1">
                    {flightDetail1.map((each) => (
                      <div
                        key={each.id}
                        className="flight-details1-innerContainer cursor-pointer"
                        onClick={() =>
                          handleFlightDetails1(each.id, each.value)
                        }
                      >
                        <p>{each.value}</p>
                      </div>
                    ))}
                  </div>
                )}

                {showFlightDetail2 && (
                  <div className="flight-details2 flight_detail_adjust2">
                    {flightPassangerProp.map((each) => {
                      const { value, age, num, id } = each;

                      return (
                        <div className="increment-container cursor-pointe" key={id}>
                          <p>
                            {value}
                            <span className="flight-age-span">{age}</span>
                          </p>
                          <div className="flight-increment-num">
                            <span
                              className="increment-decrement-icon"
                              onClick={() => handlePassangerDecrement(id)}
                            >
                              <Decrement />
                            </span>
                            <span className="flight-traveller-number">
                              {num}
                            </span>
                            <span
                              className="increment-decrement-icon"
                              onClick={() => handlePassangerIncrement(id)}
                            >
                              <Incremental />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {showFlightDetail3 && (
                  <div className="flight-details1 flight-detail-mobile-adjust">
                    {flightDetail2.map((each) => (
                      <div
                        key={each.id}
                        className="flight-details1-innerContainer cursor-pointer"
                        onClick={() =>
                          handleFlightDetails2(each.id, each.value)
                        }
                      >
                        <p>{each.value}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {/* ECONOMY CONTAINER END */}

            {/* DARK BLUE CONTAINER START */}

            {onMobileDetails && (
              <div>
                <div
                  className={
                    !showSearchedResults
                      ? "flight-info-container-for-banner-adjust"
                      : "flight-info-container"
                  }
                >
                  <div className="flight-info-check">
                    <div
                      className="flight-info"
                      onClick={() => {
                        setShowFlightDetail2(false);
                        setShowFlightDetail1(true);
                        setShowFlightDetail3(false);
                      }}
                    >
                      <span>{selectedFlightOption1}</span>
                      <ChevronDown />
                    </div>
                    <div
                      className="flight-info"
                      onClick={() => {
                        setShowFlightDetail2(true);
                        setShowFlightDetail1(false);
                        setShowFlightDetail3(false);
                      }}
                    >
                      <span>{travellerNum} Traveller</span>
                      <ChevronDown />
                    </div>
                    {hideEconomySelection && (
                      <div
                        className="flight-info"
                        onClick={() => {
                          setShowFlightDetail2(false);
                          setShowFlightDetail1(false);
                          setShowFlightDetail3(true);
                        }}
                      >
                        <span>{selectedFlightOption2}</span>
                        <ChevronDown />
                      </div>
                    )}
                  </div>
                  {!multistopSelection && (
                    <div className="selected-flight-info">
                      <div className="selected-flight-info-inner">
                        <div>
                          <span className="selected-header-title">From</span>
                          <FlightUp />
                        </div>
                        <input
                          placeholder="Enter City or airport"
                          className="airport-input"
                          value={inputFrom}
                          onChange={handleChange1}
                          ref={ref}
                          onFocus={() => handleMouseOutOne(ref.current)}
                        />
                      </div>
                      <div className="selected-flight-info-inner-1">
                        <div>
                          <span className="selected-header-title">To</span>
                          <FlightDown />
                        </div>
                        <input
                          placeholder="Murtala Muhammed Airport"
                          className="airport-input"
                          value={inputTo}
                          onChange={handleChange2}
                          ref={reff}
                          onFocus={() => handleMouseOutTwo(reff.current)}
                        />
                      </div>

                      <div className="leave-return-container">
                        <div className="lt-twist">
                          <div className="flight-date-container leaving-date-adjust">
                            <span className="selected-header-title">
                              Leaving on
                            </span>
                            <div>
                              <p
                                className="flight-detail-and-date"
                                onClick={
                                  showLeavingCalendar
                                    ? handleLeavingCalendar
                                    : handleLeavingAndReturnCalendar
                                }
                              >
                                {showLeavingCalendar
                                  ? leavingAloneDate
                                  : leavingDate}
                              </p>
                            </div> 
                          </div>

                          {hideReturnDate && <div className="slash">_</div>}

                          {hideReturnDate && (
                            <div className="flight-date-container adjust-returning-date">
                              <span className="selected-header-title">
                                Returning On
                              </span>
                              <div>
                                <p
                                  className="flight-detail-and-date"
                                  onClick={handleLeavingAndReturnCalendar}
                                >
                                  {returningDate}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="flight-search-box">
                        
                          <input
                            type="button"
                            className="flight-search"
                            value="Search" 
                            onClick={handleSearchedResults}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {multistopSelection && (
                    <div className="flight-info-container_">
                      <div className="flight-information">
                        <div className="selected-flight-info">
                          <div className="selected-flight-info-inner">
                            <div>
                              <span className="selected-header-title">
                                From
                              </span>
                              <FlightUp />
                            </div>
                            <input
                              placeholder="Enter City or Airport"
                              className="airport-input"
                            />
                          </div>
                          <div className="selected-flight-info-inner-1">
                            <div>
                              <span className="selected-header-title">To</span>
                              <FlightDown />
                            </div>
                            <input
                              placeholder="Murtala Muhammed Airport"
                              className="airport-input"
                            />
                          </div>
                          <div className="leave-return-container">
                            <div className="flight-date-container leaving-date-adjust">
                              <span className="selected-header-title">
                                Leaving on
                              </span>
                              <div>
                                <p
                                  className="flight-detail-and-date"
                                  // onClick={handleLeavingCalendar}
                                >
                                  {leavingDate}
                                </p>
                              </div>
                            </div>
                            {hideEconomyState && (
                              <div className="flight-date-container ticket-adjust">
                                <span className="selected-header-title">
                                  Ticket
                                </span>
                                <div className="flight-infoo">
                                  <span>Economy</span>
                                  <ChevronDown />
                                </div>
                                <div className="correction-times"></div>
                              </div>
                            )}
                          </div>
                        </div>
                        {hideFlightecton1 && (
                          <div className="selected-flight-info">
                            <div className="selected-flight-info-inner">
                              <div>
                                <span className="selected-header-title">
                                  From
                                </span>
                                <FlightUp />
                              </div>
                              <input
                                placeholder="Enter City or Airport"
                                className="airport-input"
                              />
                            </div>
                            <div className="selected-flight-info-inner-1">
                              <div>
                                <span className="selected-header-title">
                                  To
                                </span>
                                <FlightDown />
                              </div>
                              <input
                                placeholder="Murtala Muhammed Airport"
                                className="airport-input"
                              />
                            </div>
                            <div className="leave-return-container">
                              <div className="flight-date-container leaving-date-adjust">
                                <span className="selected-header-title">
                                  Leaving on
                                </span>
                                <div>
                                  <p
                                    className="flight-detail-and-date"
                                    // onClick={handleLeavingCalendar}
                                  >
                                    {leavingDate}
                                  </p>
                                </div>
                              </div>
                              {hideEconomyState && (
                                <div className="flight-date-container ticket-aadjust">
                                  <span className="selected-header-title">
                                    Ticket
                                  </span>
                                  <div className="flight-infoo">
                                    <span>Economy</span>
                                    <ChevronDown />
                                  </div>
                                  <div></div>
                                </div>
                              )}
                              <span
                                className="times-container"
                                onClick={() => setHideFlightSecton1(false)}
                              >
                                <Times />
                              </span>
                            </div>
                          </div>
                        )}
                        {addFlightList}
                        <div>
                          <div className="adding-new-flight-container">
                            {hideBtn && (
                              <div

                              // onClick={()=>onAddBtnClick()}
                              >
                                <span>
                                  <Adding />
                                </span>
                                Add Another Flight
                              </div>
                            )}
                            <div
                              className={hideBtn ? "" : "flight-search-box_"}
                            >
                              <input
                                type="button"
                                className="flight-search"
                                value="Search"
                                
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="calendar-container-1">
                  {showFlightSearchedResult && (
                    <div
                      className={
                        resultBody
                          ? "searchedResult-container_"
                          : "searchedResult-container"
                      }
                    >
                      {!isGettingResults
                        ? searchedResults?.map((each) => {
                            return (
                              <div
                                className="airport-search-result-continer"
                                key={each.code}
                                onClick={() =>
                                  handleFlightMovement(each.name_code)
                                }
                              >
                                <div className="airport-search-result-continer-inner">
                                  <p className="airport-search-p1">
                                    {each.city}, {each.country}
                                  </p>
                                  <p className="airport-search-p2">
                                    {each.name_code}
                                  </p>
                                </div>
                                <button className="btn-code-container">
                                  {each.code}
                                </button>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  )}

                  {showLeavingCalendarOption && (
                    <Calendar
                      onChange={onChange1}
                      value={value1}
                      tileDisabled={tileDisabled}
                      prev2Label={null}
                      next2Label={null}
                    />
                  )}

                  {returnAndLeavingCalendar && (
                    <Calendar
                      onChange={onChange}
                      value={value}
                      prev2Label={null}
                      next2Label={null}
                      tileDisabled={tileDisabled}
                      showDoubleView={disableDoubleCalendar}
                      selectRange={true}
                    />
                  )}
                </div>
              </div>
            )}

            {/* DARK BLUE CONTAINER END */}

            {showSearchedResults && (
              <div className="voucher-hotel-container">
                <div className="voucher-hotel">
                  <div className="voucher-redeem-price-container-1">
                    <div className="voucher-redeem-price"></div>
                    <div className="voucher-redeem-price_">
                      <p>From 2,400 FirstCoins</p>
                    </div>
                    <div className="uber-voucher">
                      <p>Redeem Uber Vouchers</p>
                    </div>
                    <div className="view-vouchers">
                      <a>See Vouchers</a>
                    </div>
                  </div>
                </div>
                <div className="voucher-hotel">
                  <div className="voucher-redeem-price-container-2">
                    <div className="voucher-redeem-price"></div>
                    <div className="voucher-redeem-price_">
                      <p>From 2,400 FirstCoins</p>
                    </div>
                    <div className="book-hotel">
                      <p>Book A Hotel Room</p>
                    </div>
                    <div className="view-hotels">
                      <a>See Hotels</a>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!showSearchedResults && (
              <SearchResult
                setShowFlightCheckout={setShowFlightCheckout}
                onMobile={onMobile} flightSearchResult={serachResult}
              />
            )}

            {showSearchedResults && <FlightBanner />}
            {!onMobile && !showSearchedResults && <FlightBanner />}
          </div>
        )}
        {!showFlightCheckout && (
          <FlightCheckout
            flightPassangerProp={flightPassangerProp}
            onMobile={onMobile}
          />
        )}
      </div>
    </>
  );
};

export default ReturnTicket;
