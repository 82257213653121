import React from "react";
import { formatValue, getData } from "../../config/helper";

const StatementModal = ({ visible, onClose }) => {
  const statementDetails = getData("statement")?.data;
  if (!visible) return null;

  return (
    <div className="fixed overflow-y-hidden justify-center top-0 left-0 w-full h-full z-10 bg-[#808080] bg-opacity-10 backdrop-blur-sm flex">
      <div className="bg-white rounded px-4 my-20 py-2 w-[95%] h-fit lg:w-1/3 lg:h-fit">
        <div className="flex justify-between items-center py-3">
          <h6 className="font-medium text-base leading-loyalty">
            Transaction Receipt
          </h6>
          <p
            className="bg-[#f0f0f0] cursor-pointer font-medium text-base leading-loyalty px-2 py-1.5 rounded"
            onClick={onClose}
          >
            Close
          </p>
        </div>
        <div className="space-y-8 flex flex-col my-2">
          <div className="">
            <h6 className="font-bold text-base leading-loyalty">Date</h6>
            <p className="font-normal text-base leading-loyalty">
              {statementDetails?.Transaction_date}
            </p>
          </div>
          <div className="">
            <h6 className="font-bold text-base leading-loyalty">
              Transaction Type
            </h6>
            <p className="font-normal text-base leading-loyalty">
              {statementDetails?.Transaction_type}
            </p>
          </div>
          <div className="">
            <h6 className="font-bold text-base leading-loyalty">Description</h6>
            <p className="font-normal text-base leading-loyalty">
              {statementDetails?.Description}
            </p>
          </div>
          <div className="">
            <h6 className="font-bold text-base leading-loyalty">Credit</h6>
            <p className="font-normal text-base leading-loyalty">
              {isNaN(
                formatValue(statementDetails?.Points_gained).replace(/-/g, "0")
              )
                ? 0
                : formatValue(statementDetails?.Points_gained).replace(
                    /-/g,
                    "0"
                  )}
              &nbsp;Coins
            </p>
          </div>
          <div className="">
            <h6 className="font-bold text-base leading-loyalty">Debit</h6>
            <p className="font-normal text-base leading-loyalty">
              {isNaN(
                formatValue(statementDetails?.Points_used).replace(/-/g, "0")
              )
                ? formatValue(statementDetails?.Points_used).replace(/-/g, "0")
                : formatValue(statementDetails?.Points_used).replace(/-/g, "0")}
              &nbsp;Coins
            </p>
          </div>
            {/* <div className="">
              <h6 className="font-bold text-base leading-loyalty">
                Cost Of Shipping
              </h6>
              <p className="font-normal text-base leading-loyalty">
                {formatValue(statementDetails?.Shipping_points)} Coins
              </p>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default StatementModal;
