import React from "react";
import "./LoginTandC.css";
import { TailwindSpinner } from "../Spinner/TailwindSpinner";

const LoginTandC = ({ mutate, isLoadingConsent }) => {
  const handleConsentSubmit = () => {
    mutate();
  };

  return (
    <div className="tandc-container">
      <div className="tandc-inner-container">
        <h1 className="tandc-title">Terms & Conditions</h1>
        <div className="tandc-text">
          <article className="article-2">
            <h3>1. Overview </h3>
            <p>
              The FirstBank of Nigeria Loyalty Programme which is offered by
              FirstBank of Nigeria to reward and encourage our loyal customers
              to keep using their accounts for various bank transactions. Based
              on the set criteria, eligible customers will be auto-enrolled in
              the loyalty program and can start to earn points when they perform
              qualifying transactions stipulated by FirstBank. The program will
              make use of a currency known as {/*****/}which can be used to
              redeem various rewards items, benefits, and/or rebates from the
              customer website or various touch points of the Bank.“DISCOUNTS”
              earned by customers can be used at various selected Merchant
              stores.
              <br />
              <br />
              The FirstBank of Nigeria Loyalty Programme is accessible through
              the Program Website and FBN Mobile App and any other related sites
              and applications referred to on the Program Website. <br />
              This agreement may be modified from time to time to govern your
              participation in the FirstBank of Nigeria Loyalty Programme. The
              bank in its sole discretion can restrict, suspend, amend, extend
              or otherwise alter this terms and conditions at any time or any
              portion thereof on 30 days prior written notice, and if we
              terminate the program, you will have 30 days from the program
              termination date to redeem your accumulated Loyalty Points.
              However, we may change or substitute rewards and adjust Point
              levels required for specific rewards at any time without prior
              notification. <br />
              <br />
              All rewards are subject to availability. We encourage Customer
              (referred also as members) to read and understand this terms and
              conditions each time they use the program.
            </p>
            <p>
              The bank reserves the right to defer the program, of its free
              will, at any time. Termination, modification, or suspension may
              affect the User’s ability to redeem accumulated points.
            </p>
            <p>
              We will notify you of material changes to the program terms and
              conditions and, unless you decide to opt-out of the program, you
              will be bound by the revised terms and conditions. We also reserve
              the right to suspend or terminate the program, or your
              participation in the program, at any time without compensation to
              you. e messaging channel accessed from a mobile phone and allows
              user to interact with FinPay.
            </p>
          </article>

          <article>
            <h3>2. Acceptance</h3>
            <p>
              By using or enrolling in the FirstBank of Nigeria Loyalty
              Programme, you accept and agree to be bound by these Terms and
              Conditions. It is important you read these Terms and Conditions,
              and the related policies and FAQs frequently to understand the
              terms and conditions that apply to the Program as they may change
              from time to time. The FirstBank of Nigeria Loyalty Programme
              Terms do not alter in any way the terms or conditions of any other
              agreement you may have with First Bank, including any agreement
              for products or services. If you do not agree to these Program
              Terms, and Privacy Policy, you cannot participate in the Program.
            </p>
          </article>

          <article>
            <h3>3. Program eligibility and enrolment</h3>
            <p>
              Membership is free and there is no annual fee for membership in
              this Program. To be enrolled in the FirstBank of Loyalty Programme
              and become a program member, customers must have an active
              FirstBank account and must meet the minimum eligibility criteria
              according to the FirstBank product they hold. Once you have been
              enrolled successfully, you will receive a membership ID and
              password which you would use to log in to the program website.
              Upon your first login to your dedicated loyalty account, you must
              change your initial password (which is a system-generated one-time
              password) to any password of your choice after accepting this
              terms and conditions. It is your responsibility to keep your
              password safe and known only to you because you are responsible
              for all activity in your loyalty account. It is advised that you
              provide accurate information at this stage. You can also log in to
              your loyalty account via the Firstbank Mobile App using a single
              sign-on. <br /> In the case of more than one FirstBank account,
              all of these accounts will be merged into one customer ID, and all
              points earned through all these accounts from qualifying
              transactions will be accumulated to your membership ID.
            </p>
          </article>
          <article>
            <h3>4. Change in information </h3>
            <p>
              Customers are responsible to advise the bank immediately of any
              change to their address or other contact information. FirstBank
              will not be responsible for any communication not received by a
              program Member, provided that FirstBank sent such communication to
              the address or other contact information then on file from such
              program member. You may update your contact information by
              visiting the program website and updating your account profile, by
              calling our call center on phone using 0708 062 5000, or by
              sending an email to firstcontact@firstbanknigeria.com.
            </p>
          </article>

          <article>
            <h3>5. Inactive accounts </h3>
            <p>
              Once an Account is deemed to be inactive, all points in such
              Account may, in the FirstBank’s sole discretion, be deemed to have
              been forfeited by such Member, with or without notice.
            </p>
          </article>

          <article>
            <h3>6. Account closure</h3>

            <p>
              In a case where a Customer’s account or First bank account is
              closed, all points earned are reduced to zero. It is advisable to
              use up your points before closing the account.
            </p>
          </article>
          <article>
            <h3>7. Earning Points</h3>
            <p>
              FirstBank customers who meet the set criteria will be
              auto-enrolled and can start accumulating points from performing
              qualifying transactions approved by the bank. Qualifying
              transactions include all income accruing transactions and are not
              limited to mobile application usage, online transfer, bill
              payments, USSD transaction, debit, and credit card usage. Points
              can also be gotten via the transfer of points between enrolled
              Customers. <br />
              Ineligible transactions include transactions fraudulently
              initiated, counterfeit or canceled card transactions, balance
              transfers, and account fees or finance charges if any, are not
              eligible to earn points. FirstBank also reserves the right, at our
              sole discretion and at any time to include or exclude certain
              types of transactions from the meaning of qualifying transactions.
            </p>
          </article>
          <article>
            <h3>8. Rewards Points Expiration</h3>
            <p>
              Loyalty points accrued in your FirstBank of Nigeria Loyalty
              Account will be carried forward until they expire. Points will
              expire after one year following the date on which points were
              earned. For example, reward points awarded on September 1, 2022,
              will expire on September 1, 2023, unless FirstBank terminates the
              Program. In this case, you will have only 30 days from the Program
              termination date to redeem any earned Points. Point expiration
              will be communicated via email notification and a reminder will
              also be sent before points finally expires. If you cancel your
              enrolment in the Program, any unredeemed Points shall be forfeited
              immediately upon cancellation. If you have enough Points to be
              eligible for a reward, you must request the reward before giving
              us notice of the cancellation. There are no exchanges or refunds
              for unused Points.
            </p>
          </article>

          <article>
            <h3>9. Opting out of the program </h3>
            <p>
              Customers who choose to opt out of the program can either go to
              their account dashboard in their profile or reach FirstContact via
              call on 0708 062 5000 or send an email on
              firstcontact@firstbanknigeria.com.
            </p>
          </article>

          <article>
            <h3>10. Loyalty Points Collection and Redemption</h3>

            <p>
              Loyalty points are earned by Customers who perform qualifying
              transactions identified by the bank and displayed on the program
              website regardless of the channels used provided that the
              transactions meet set criteria and link to a Membership Number.
              Points can be redeemed for rewards. When enough points have been
              accumulated to obtain the reward(s) desired, redemption occurs
              online and/or in physical locations as set forth on the Program
              website. The redemption schedule, available rewards, and other
              information are available on the program website.
              <br /> A member must log in to the program website with his/her
              Membership Number when redeeming points to protect the integrity
              of the Member’s points balance. Each Member is responsible for
              ensuring that all Personal Information is correct and up-to-date.
              FirstBank reserves the right to block redemptions where the Member
              information is inaccurate or incomplete. Members are solely and
              entirely responsible for keeping their Accounts secure. <br />
              Merchandise & Rewards fulfillment services are provided by our
              fulfilment partner which assumes all liability and responsibility
              for the provision of, or failure to provide, the services. They
              operate as an independent contractor and are not affiliated with
              FirstBank. Neither FirstBank nor our fulfilment partner shall be
              liable for any bodily harm and/or property damage that may result
              from participation in the program for any reason.
            </p>
          </article>

          <article>
            <h3>11 Value</h3>

            <p>
              Loyalty points have no cash value and are not exchangeable for
              cash. The accumulation of points does not entitle the Customers to
              any vested rights, and FirstBank does not guarantee in any way the
              continued availability of any reward, redemption level, rebate, or
              any other benefit. The bank assumes no liability to Members as
              regards the addition or deletion of items from or for which points
              can be collected and/or redeemed.
            </p>
          </article>
          <article>
            <h3>12 Discounts</h3>

            <p>
              Discounts are not cash, you may not sell, issue, exchange, barter
              or redeem Discounts for cash. Your Discounts may only be used at
              any of our selected Merchant stores. You may not exchange or sell
              any other goods or services for Discounts. <br />
              <ul className="list-disc">
                <li>
                  Customers may redeem discounts for any item at any of our
                  selected Merchant stores. Discount options are explained in
                  the online catalog.
                </li>
                <li>
                  All discounts are subject to the percentage assigned to a
                  particular merchant store. · Discounts granted cannot be
                  exchanged for cash under any circumstances.
                </li>
                <li>
                  Discounts can only be redeemed at participating Merchant
                  stores.
                </li>
                <li>
                  Unless otherwise stated, Discounts are valid for 30 days ·
                </li>
                <li>
                  Discounts must be redeemed in accordance with Program Terms
                  and Conditions.
                </li>
                <li>
                  FirstBank and its participating partner are not responsible
                  for the replacement of lost, unclaimed, or stolen items, and
                  neither shall FirstBank be liable for any loss or damage that
                  the customer may suffer from the non-replacement
                </li>
              </ul>
            </p>
          </article>
          <article>
            <h3>13 Transferability</h3>

            <p>
              The FirstBank of Nigeria Loyalty Programme allows loyalty points
              to be transferred among program users using their PIN. Members can
              request their PIN whenever they need to make a point transfer.
              Firstbank does not in any way support the purchase or selling of
              loyalty points.
            </p>
          </article>
          <article>
            <h3>14 Promotion</h3>

            <p>
              From time to time, FirstBank may advertise or offer exclusive
              offers like sweepstakes, and auctions to select members to redeem
              points for items other than a discount reward, or receive other
              benefits or discounts.
            </p>
          </article>
        </div>
        <div className="tandc-button-container">
          <button className="tandc-button" onClick={handleConsentSubmit}>
            {" "}
            {isLoadingConsent ? <TailwindSpinner /> : "Accept & Proceed"}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginTandC;
