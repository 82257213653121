import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: true,
}

const OfflineSlice = createSlice({
    name: 'offline',
    initialState,
    reducers: {
        loadSpinner: (state) =>{
            state.isOpen = false
        }
    }
})

export const {loadSpinner} = OfflineSlice.actions;

export default OfflineSlice.reducer;