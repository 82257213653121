import React, { useRef, useEffect } from "react";
import "./Voucher.css";
import modalImage from "../../assets/images/flight-and-hotel-modal.png";

const VoucherModal = ({ showSuccessModal }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        showSuccessModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="delete-modal-container">
      <div className="inner-modal-container" ref={modalRef}>
        <img src={modalImage} alt="success modal" className="success-modal" />
        <p className="booking-text">Voucher Generated!</p>
        <p className="booking-text-1">
          Please check your registered FirstBank email for your voucher details
          and code
        </p>
        <button className="thanks-btn" onClick={() => showSuccessModal(false)}>
          Okay Thanks!
        </button>
      </div>
    </div>
  );
};

export default VoucherModal;
