import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import CryptoJS from "crypto-js";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import logo from "../../../assets/images/logo.svg";
import "./DefaultLogIn.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  showPassword,
  hidePassword,
} from "../../../redux-toolkit/features/password/showPasswordSlice";
import { login } from "../../../redux-toolkit/features/defaultLogin/loginSlice";
import {
  authHeaders,
  getAccessToken,
  // getData,
  handleRequest,
  myStore,
  setAccessToken,
  storeData,
} from "../../../config/helper";
import img1 from "../../../assets/images/img-login1.png";
import img2 from "../../../assets/images/img-login2.png";
import img3 from "../../../assets/images/img-login3.svg";
import { TailwindSpinner } from "../../../components/Spinner/TailwindSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import axiosProperty from "../../../service/HttpService";
import { LoadingIcon } from "../../../assets/icons";
import LoginTandC from "../../../components/LoginTandC/LoginTandC";
import { useMutation } from "react-query";
import ChangePasswordModal from "../../../components/ChangePasswordModal/ChangePasswordModal";
import { AppContext } from "../../../components/AppContext";
import axios from "axios";

const DefaultLogin = ({ nagateNav }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { show } = useSelector((store) => store.password);
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    membershipId: "",
    password: "",
  });
  const [loginResponse, setLoginResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [checkMem, setCheckMem] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [mainCIFID, setMainCIFID] = useState("");
  const passwordRef = useRef(null);
  const membershipIdRef = useRef(null);
  const [consentModal, setConsentModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setLoginData({ ...loginData, [e.target.name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (loginData.membershipId === "") {
      setIsLoading(false);
      setErrorMessages("Input your membershipID");
      return;
    }

    if (loginData.password === "") {
      setIsLoading(false);
      setErrorMessages("Input your Password");
      return;
    }

    if (loginData.membershipId === "" && loginData.password === "") {
      setIsLoading(false);
      setErrorMessages("Input your membershipID and Password");
      return;
    }

    const iv = CryptoJS.lib.WordArray.random(16);

    if (process.env.REACT_APP_ENCRYPTION_KEY.length !== 16) {
      throw new Error("Encryption key must be 16 characters for AES-128-CTR.");
    }

    const encryptedPassword = CryptoJS.AES.encrypt(
      loginData.password,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY),
      {
        iv: iv,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding,
      }
    ).toString();

    const payload = {
      membership_id: membershipIdRef.current.value,
      password: encryptedPassword,
      iv: iv.toString(CryptoJS.enc.Base64),
    };

    if (loginData.membershipId !== "" && loginData.password !== "") {
      handleRequest(
        setLoginResponse,
        JSON.stringify(payload),
        "/first-bank-loyalty/login"
      );

      setErrorMessages("");
    }
  };

  useEffect(() => {
    nagateNav(false);
  }, [nagateNav]);

  ///////////////CONSENT CHECK START/////////////
  const [token, setToken] = useState("");

  useEffect(() => {
    const getToken = getAccessToken();
    setToken(getToken);
  }, [consentModal]);

  const consentCheck = async () => {
    const params = new URLSearchParams(location.search);
    const myCifid = params.get("cifid");
    try {
      const response = await axiosProperty.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}/update_consent`,
        { cifid: myCifid || mainCIFID },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setConsentModal(false);
        if (mainCIFID !== "") {
          setChangePasswordModal(true);
        } else {
          window.location = "/redeem";
        }
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    mutate,
    isLoading: isLoadingConsent,
    isError: isErrorConsent,
  } = useMutation(consentCheck);

  //////////CONSENT CHECK END//////////////

  useEffect(() => {
    if (loginResponse?.status === 1) {
      storeData(myStore, loginResponse?.success);

      setAccessToken(loginResponse?.success?.token.accessToken);

      setMainCIFID(loginResponse?.success?.customer?.cifid);

      if (loginResponse?.success?.customer?.LoggedIn_flag === 0) {
        window.location = "/login";
      } else {
        if (loginResponse?.success?.customer?.consent_flag === 0) {
          setConsentModal(true);
        } else {
          window.location = "/redeem";
          setIsLoading(false);
        }
      }
    } else {
      setErrorMessages(loginResponse?.message);
    }
  }, [loginResponse, errorMessages]);

  useEffect(() => {
    if (errorMessages?.length > 1) {
      setIsLoading(false);
    }
  }, [errorMessages]);


  
  


  return (
    <>
      <section className="first-time-login-container">
        <form onSubmit={handleSubmit} className="first-time-login-left_">
          <div className="input-container">
            <div className="default-fb-logo-container">
              <Link to="/">
                <img
                  src={logo}
                  alt="first bank logo"
                  className="default-fb-logo"
                />
              </Link>
            </div>
            <h1 className="header-text">Login To Your Loyalty Account</h1>

            <div className="title-and-input-container-default">
              <div className="w-full">
                <div className="forgot-password">
                  <p>Membership ID</p>
                  <Link
                    to="/forgot-membershipid"
                    className="no-underline forgot-password-text"
                  >
                    Forgot Membership ID?
                  </Link>
                </div>
                <input
                  placeholder="Membership ID"
                  type="text"
                  className={
                    errorMessages?.membership_id
                      ? "warning-mem border-red-800"
                      : "first-time-login-input"
                  }
                  name="membershipId"
                  value={loginData.membershipId.replace(/[^a-zA-Z0-9]/g, "")} // Sanitizes input
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    ); // Remove non-alphanumeric characters
                    setLoginData((prev) => ({
                      ...prev,
                      membershipId: sanitizedValue, // Update state
                    }));
                  }}
                  ref={membershipIdRef}
                />

                {errorMessages?.membership_id && (
                  <div className="warning warning-adjust_">
                    {errorMessages?.membership_id.map((err) => {
                      return <small key={err}>{err}</small>;
                    })}
                  </div>
                )}
                {typeof errorMessages == "string" && (
                  <p className="warning warning-adjust_">{errorMessages}</p>
                )}
              </div>
              <div className="w-full">
                <div className="forgot-password">
                  <p>Password</p>
                  <Link
                    to="/forgot-password"
                    className="no-underline forgot-password-text"
                  >
                    Forgot Password
                  </Link>
                </div>
                <div className="defult-login-password-container">
                  <input
                    placeholder="Password"
                    type={show ? "text" : "password"}
                    className={
                      errorMessages?.password
                        ? "warning-password  border-red-800"
                        : "first-time-login-input"
                    }
                    value={loginData.password}
                    name="password"
                    onChange={handleChange}
                    ref={passwordRef}
                  />
                  {show ? (
                    <AiOutlineEye
                      className="eye-icon-default"
                      onClick={() => dispatch(hidePassword())}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="eye-icon-default"
                      onClick={() => dispatch(showPassword())}
                    />
                  )}
                </div>
                {errorMessages?.password && (
                  <div className="warning warning-adjust_">
                    {errorMessages?.password.map((err) => {
                      return <small key={err}>{err}</small>;
                    })}
                  </div>
                )}
              </div>
              <button
                className="login-button"
                onClick={(e) => handleSubmit(e)}
                disabled={
                  loginData.membershipId === "" ||
                  loginData.password === "" ||
                  loginData.password.length <= 7 ||
                  isLoading
                }
              >
                {isLoading ? <TailwindSpinner /> : "Login"}
              </button>
            </div>
          </div>
        </form>
        <div className="bg-search w-full lg:w-1/2 py-[9%]">
          <div className="flex flex-col h-4/5 space-y-14">
            <div className="flex w-4/5 mx-auto">
              <h5 className="font-bold welcome-text-default text-white leading-loyalty text-center">
                Welcome to the FirstBank Customer Loyalty Program
              </h5>
            </div>
            <div className="w-4/5 flex h-[80%] gap-8 mx-auto">
              <div className="w-[65%] flex flex-col space-y-7">
                <div className="flex h-[90%] gap-8">
                  <div className="w-1/3">
                    <img
                      className="object-cover object-center h-full rounded"
                      src={img1}
                      alt=""
                    />
                  </div>
                  <div className="w-2/3">
                    <img
                      className="object-cover object-center h-full rounded"
                      src={img2}
                      alt=""
                    />
                  </div>
                </div>

                <div className="flex flex-col space-y-3">
                  <p className="text-white">Not a customer yet?</p>
                  <Link
                    to="https://openaccounts2.firstbanknigeria.com/onboarding/"
                    className="w-full p-3 text-base font-semibold text-center text-black capitalize bg-white rounded leading-loyalty"
                  >
                    open an account
                  </Link>
                </div>
              </div>
              <div className="w-[35%]">
                <img className="w-[90%] h-[100%]" src={img3} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="first-time-login-last-section-mobile">
          <p className="first-time-login-last-section-mobile-text"> Copyright 2022 FirstBank of Nigeria limited. An FBNHoldings Company.</p>
        </div> */}
      </section>

      {consentModal && (
        <LoginTandC mutate={mutate} isLoadingConsent={isLoadingConsent} />
      )}
      {changePasswordModal && (
        <ChangePasswordModal
          membershipId={loginData.membershipId}
          currentPassword={loginData.password}
        />
      )}
    </>
  );
};

export default DefaultLogin;
