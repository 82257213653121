import React, {useState, useEffect} from 'react';
// import plus from '../../../assets/svg/plus.svg';
// import subtract from '../../../assets/svg/subtract.svg';
// import pick from '../../../assets/svg/pickup.svg';
// import delivery from '../../../assets/svg/delivery.svg';
// import ProductList from '../../../components/Cards/CardList/Shops/ProductsList';
// import PopularList from '../../../components/Cards/CardList/Shops/PopularList';
// import Order from './Order';
// import NewArrivalList from '../../../components/Cards/CardList/Shops/NewArrivalList';
// import {Link} from 'react-router-dom';
import pointToCashLady from '../../../assets/images/point-to-cash-lady.png'
import Categories from '../../../components/Categories/Categories';
import {categoryStore, getData, storeData} from '../../../config/helper';
import {endpoints} from '../../../utils/constant';
import axios from 'axios';
//import {getSideBarCategories} from '../../../redux-toolkit/features/SideBarSlice/SideBarSlice';
import {useDispatch} from 'react-redux';
import EventSlider from '../../../components/EventSlider/EventSlider'
import RedeemCarousel from '../../../components/RedeemCarousel/RedeemCarousel';
import './ShopMain.css'
import { PlayIcon } from '../../../assets/icons';
import discountImage from "../../../assets/images/Discounts-min.png"
import movieImage from "../../../assets/images/Movies.png"
import airtimeImage from '../../../assets/images/Airtime.png'
import PointToCash from '../../../components/PointToCash/PointToCash';

export const ShopMain = ({nagateNav}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [pointToCash, setPointToCash] = useState(false);
  const [successConversion, setSuccessConversion] = useState(false);
  const handlePointToCash = ()=>{
    setPointToCash(!pointToCash);
    setSuccessConversion(false)
  }
  
  
  

  const dispatch = useDispatch();
  const [shopCategories, setShopCategories] = useState(
    getData(categoryStore + '2')?.data,
  );

  useEffect(() => {
    nagateNav(true);
  }, [nagateNav]);

  const fetchData = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_SHOP_CATEGORIES}${endpoints.categories}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );

      setShopCategories(response?.data);
      storeData(categoryStore + '2', response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  /* A react hook that is used to fetch data from the server. */
  // useEffect(() => {
  //   dispatch(getSideBarCategories());
  // }, []);

  // useEffect(() => {
  //   fetchData();
  // }, [setShopCategories]);

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? 'down' : 'up';
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener('scroll', updateScrollDirection);
      return () => {
        window.removeEventListener('scroll', updateScrollDirection);
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  const scrollDirection = useScrollDirection();
  const [view, setView] = useState(false);
  const ViewPage = () => {
    setView(!view);
  };

  const images = [
  
    {
      url: movieImage,
      to: '/movies',
      id: 1,
    },
    {
      url: airtimeImage,
      to: 'airtime&bills',
      id: 2,
    },
    {
      url: discountImage,
      to: '/meals',
      id: 0,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(Math.trunc(Math.random() * images.length));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [images]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative pb-10 min-h-[100vh]">
      <Categories />
      <div>
        <EventSlider sliderImage1={images[0].url} sliderImage2={images[1].url} sliderImage3={images[2].url} />
        {/* <div className="relative h-[180px] lg:h-[500px] w-full">
          {images.map((image, index) => (
            <Link key={image.url} to={images[currentImageIndex].to}>
              <img
                src={image.url}
                alt={`slider ${image.to}`}
                className={`absolute top-0 left-0 w-full h-full object-center object-fill transition-opacity duration-500 ${
                  image.id === currentImageIndex ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </Link>
          ))}
        </div> */}
      </div>

      <div className='shopmain-carousel-section'>
        <h1 className='shopmain-carousel-title'>Redeem your points</h1>
        <p className='shopmain-carousel-subtitle'>Get amazing rewards using your earned points</p>
        <RedeemCarousel/>
      </div>

      <div className='point-to-cash-section-container'>
      <div className='point-to-cash-section-inner-container'>
        <div className='point-to-cash-left'>
          <h1>Points to Cash</h1>
          <div className='point-to-cash-section-paragragh-1'>
            <div className='play-icon-container'>

            <PlayIcon/>
            </div>
            <p>With our program, you can convert your points into real cash to spend on anything you desire.</p>
          </div>
          <div className='point-to-cash-section-paragragh-2'>
            <div className='play-icon-container'>

          <PlayIcon/>
            </div>
            <p>It's simple and secure - just log in to your account and choose how much you'd like to convert.</p>
          </div>
          <button className='point-to-cash-convert-point' onClick={handlePointToCash}>Convert points</button>
        </div>
        <div className='point-to-cash-right'>
          <img src={pointToCashLady} className='point-to-cash-lady-img' alt="point to cash" />
        </div>
      </div>

      </div>

      {/* <div className="px-2 bg-products lg:flex sm:px-[115px]">
        <div className="py-9 px-5 lg:py-48 lg:w-1/3">
          <h2 className="leading-loyalty text-center tracking-tighter capitalize text-button font-bold pb-2 text-[25px] flex mx-auto w-fit sm:pb-5 sm:text-5xl sm:text-left">
            products of the day
          </h2>
          <p className="leading-shop tracking-loyal w-fit font-medium text-base mx-auto text-center hidden sm:text-sm sm:block sm:text-start">
            Based on products most viewed by other customers
          </p>
          <p className="font-medium text-sm leading-shop tracking-loyal text-center w-fit sm:mx-0 sm:text-left sm:hidden">
            Based on products most viewed by other customers
          </p>
        </div>
        <div className="lg:w-2/3">
          <ProductList />
        </div>
      </div> */}

      {/* <div>
        <h2 className="font-bold w-full text-center justify-center flex mx-auto text-[165%] leading-loyalty tracking-tighter text-button pt-5 sm:w-fit sm:mx-auto sm:pt-0 sm:text-[42px]">
          Shop Popular Categories
        </h2>
        <PopularList />
      </div> */}

      {/* <Order /> */}

      {/* <div>
        <div className="">
          <h2 className="flex justify-center px-12 text-center  text-button text-3/4 font-bold leading-[35px] tracking-tight sm:text-[42px]">
            New arrivals this week
          </h2>
          <h5 className="flex pb-2 justify-center text-[#666666] text-base font-medium leading-[170%] tracking-tight sm:pb-12">
            New product of the week
          </h5>
        </div>
        <div className="">
          <NewArrivalList />
        </div>
      </div> */}

      {/* onscroll accordion */}
      {/* <div
        className={`fixed ${
          scrollDirection === 'down' ? 'hidden' : 'top-56'
        } right-1 cursor-pointer transition-all duration-500`}
        onClick={ViewPage}>
        {!view ? (
          <div className="hidden sm:flex w-72">
            <div className="flex rounded border-solid border bg-add justify-between items-center py-2 px-2">
              <div className="ml-4 mr-10">
                <h2 className="font-bold text-lg leading-loyalty tracking-tight ">
                  Select your preferred mode of shopping
                </h2>
              </div>
              <img src={plus} alt="view shop" />
            </div>
          </div>
        ) : (
          <div className="w-72">
            <div className="flex rounded-t-lg border border-solid bg-add justify-between items-center py-2 px-2">
              <div className="ml-4 mr-10">
                <h2 className="font-bold text-lg leading-loyalty tracking-tight ">
                  Select your preferred mode of shopping
                </h2>
              </div>
              <img src={subtract} alt="view shop" />
            </div>
            <Link
              to="/order-pick-up"
              className="flex no-underline border-l-2 border-r-2 bg-white justify-between items-center py-2 px-2">
              <div className="ml-4 mr-10">
                <h2 className="font-bold text-lg leading-loyalty tracking-tight ">
                  Order Pickup
                </h2>
                <p className="font-medium text-delivery text-base leading-loyalty tracking-tight">
                  Shop and pickup your items
                </p>
              </div>
              <img src={pick} alt="item pick" />
            </Link>
            <Link
              to="/order-delivery"
              className="flex no-underline border-2 rounded-b-lg border-solid bg-white justify-between items-center py-2 px-2">
              <div className="ml-4 mr-10">
                <h2 className="font-bold text-lg leading-loyalty tracking-tight ">
                  Order Delivery
                </h2>
                <p className="font-medium text-delivery text-base leading-loyalty tracking-tight">
                  Have your items delivered to you
                </p>
              </div>
              <img src={delivery} alt="item deivery" />
            </Link>
          </div>
        )}
      </div> */}

{pointToCash && <PointToCash   setPointToCash={setPointToCash} setSuccessConversion={setSuccessConversion} pointToCash={pointToCash} successConversion={successConversion} />}
    </div>
  );
};
