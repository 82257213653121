import React, {useState, useEffect} from 'react';
import {ShopCategoryItems} from '../../../components/Cards/CardItems/CardItem';
import dress from '../../../assets/images/dress-up.png';
import price_up from '../../../assets/svg/price_up.svg';
import price_down from '../../../assets/svg/price_down.svg';
import axios from 'axios';
import {endpoints} from '../../../utils/constant';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadSpinner} from '../../../redux-toolkit/features/Offline/OfflineSlice';
import {Dna} from 'react-loader-spinner';
import {doPost, getData} from '../../../config/helper';
import './Search.css';

export const SearchList = () => {
  const {isOpen} = useSelector(store => store.offline);
  const dispatch = useDispatch();

  const [catalogueItems, setCatalogueItems] = useState();
  const {Merchandize_item_code} = useParams();
  const [activePopularity, setActivePopularity] = useState(true);
  const [activeAscending, setActiveAscending] = useState(false);
  const [activeDesecending, setActiveDescending] = useState(false);

  const handlePopularity = () => {
    setActiveDescending(false);
    setActiveAscending(false);
    setActivePopularity(true);
  };

  const handleAscendingCategory = () => {
    const categoryAscend = [...catalogueItems].sort(
      (a, b) => a.Price - b.Price,
    );
    setCatalogueItems(categoryAscend);
    setActiveAscending(true);
    setActiveDescending(false);
    setActivePopularity(false);
  };

  const handleDescendingCategory = () => {
    const categoryDescend = [...catalogueItems].sort(
      (a, b) => b.Price - a.Price,
    );
    setCatalogueItems(categoryDescend);
    setActiveDescending(true);
    setActiveAscending(false);
    setActivePopularity(false);
  };

  function getSearch() {
    let searchRes = getData('search');
    setCatalogueItems(searchRes?.data);
  }

  useEffect(() => {
    getSearch();
  }, []);

  return (
    <>
      <div className="bg-[url('./assets/images/order_hero.png')] bg-no-repeat py-20 bg-cover">
        <h2 className="flex font-extrabold text-4xl leading-loyalty tracking-tighter text-button justify-center capitalize">
          showing search for {getData('search-name')?.data}
        </h2>
        <p className="flex justify-center pt-3 font-medium text-base leading-shop text-pick tracking-loyal mx-[27px] text-center sm:mx-[440px]"></p>
      </div>
      <div className="">
        <div className="flex justify-end gap-6 my-4 items-center">
          <p className="capitalize my-2 font-medium text-base leading-loyalty tracking-tight text-list">
            sort:
          </p>
          <div className="flex mr-24 w-fit gap-4 items-center">
            {/* <button
            onClick={handlePopularity}
            className={` ${
              activePopularity ? "bg-button text-white" : "bg-white text-black"
            } flex font-bold text-base leading-loyalty tracking-tight capitalize items-center rounded border-solid border-sub border px-2 py-2.5`}
          >
            popularity
          </button> */}

            <button
              onClick={handleAscendingCategory}
              className={`${
                activeAscending ? 'bg-button text-white' : 'bg-white text-black'
              } flex gap-2 font-bold text-base leading-loyalty tracking-tight capitalize items-center rounded border-solid border-sub border px-2 py-2.5`}>
              price: lowest to the highest <img src={price_up} alt="price up" />
            </button>

            <button
              onClick={handleDescendingCategory}
              className={`${
                activeDesecending
                  ? 'bg-button text-white'
                  : 'bg-white text-black'
              } flex gap-2 font-bold text-base leading-loyalty tracking-tight capitalize items-center rounded border-solid border-sub border px-2 py-2.5`}>
              price: highest to lowest
              <img src={price_down} alt="pricedown" />
            </button>
          </div>
        </div>
        <div className="search-outter-container">
          <div className="search-container">
            {catalogueItems !== undefined ? (
              catalogueItems?.map((item, index) => (
                <ShopCategoryItems key={index} card={item} />
              ))
            ) : (
              <div className="mx-auto mt-14 mb-28 w-fit py-2 px-16 text-base leading-loyalty font-bold rounded border-solid border-2 border-list capitalize">
                No Item Found
              </div>
            )}
          </div>
        </div>
        {/* <div className="mx-auto mt-14 mb-28 w-fit py-2 px-16 text-base leading-loyalty font-bold rounded border-solid border-2 border-list capitalize">
        load more
      </div> */}
        {/* <img className="" src={dress} alt="your advert here" /> */}
      </div>
    </>
  );
};
