import {useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {MovieTicket, Movies, SingleMovie} from './pages/Movies';
import {Voucher, VoucherCheckout} from './pages/Voucher';
import {
  ShopMain,
  OrderDeliveryItems,
  OrderPickUpItems,
  Search,
  SingleCategory,
  SingleItem,
} from './pages/shop';
import Discount from './pages/Discount/Discount';
import CartItems from './pages/CartItems/CartItems';
import Checkout from './pages/Checkout/Checkout';
import WishList from './pages/WishList/WishList';
import Footer from './components/Footer/Footer';
import {
  DefaultLogin,
  FirstTimeLogIn,
  ForgotMembershipId,
  ForgotPassword,
  Logout,
  OtpInput,
  ResetPassword,
} from './pages/Auth';
import Nav from './components/Navbar/CategoryNav';
import {Hotel, HotelCheckout, HotelSearch} from './pages/Hotel';

import Meals from './pages/Meals/Meals';
import RestaurantMenu from './pages/Meals/RestaurantMenu';
import MealsCheckout from './pages/Meals/MealsCheckout';
import MealOrderReceipt from './pages/Meals/MealOrderReceipt';

import HotelSingleRoom from './components/Cards/CardList/Hotel/HotelSingleRoom';
import Flight from './pages/Flight/Flight';
import Experience from './pages/Experience/Experience';
import EventsLandingPage from './pages/Events/EventsLandingPage';
import SingleExperienceInformation from './pages/Experience/SingleExperienceInformation';
import TermsAndCondition from './pages/TermsAndCondition/TermsAndCondition';
import ReturnPolicy from './pages/ReturnPolicy/ReturnPolicy';
import ContactPage from './pages/ContactPage/ContactPage';
import Error from './pages/Error/Error';
import Notification from './pages/Notification/Notification';
import FAQ from './pages/FAQ/FAQ';
import FinalCheckOutpage from './components/FinalCheckOutpage/FinalCheckOutpage';
import {AirtiimeAndBills} from './pages/AirtimeAndBills/AirtiimeAndBills';
import BillsCheckOut from './pages/AirtimeAndBills/BillsCheckOut';
import Profile from './pages/Profile/Profile';
import OptOut from './pages/Profile/OptOut';
import StickyCategoryModal from './components/StickyCategoryModal/StickyCategoryModal';
// import LandingPage from "./pages/LandingPage/LandingPage";
import {myStore} from './config/helper';
import New from './pages/New/New';
import Auctions from './pages/Auction/Auctions';
import SingleAuction from './pages/Auction/SingleAuction';
import SweepStakes from './pages/Sweepstake/SweepStakes';
import SingleSweepStake from './pages/Sweepstake/SingleSweepStakes';
import Surveys from './pages/Survey/Surveys';
import SingleSurvey from './pages/Survey/SingleSurvey';
import MainApp from './pages/MainApp';
import EntryPage from './pages/EntryPage/EntryPage';
import NetworkStatusListener from './components/NetworkStatusListener/NetworkStatusListener';
import CheckoutReceipt from './components/CheckoutReceipt/CheckoutReceipt';
import HelpWidget from './components/HelpWidget/HelpWidget';
import InactivityHandler from './components/InactivityHandler';
import SingleSignOn from './pages/Auth/SingleSignOn/SingleSignOn';

function App() {
  const [showNav, setShowNav] = useState(true);

  return (
    <Router>
      <NetworkStatusListener />
      <InactivityHandler/>
      {showNav && <Nav />} 
      <Routes>
        {/* <Route
          path="/checkout-receipt"
          element={<CheckoutReceipt nagateNav={setShowNav} />}
        /> */}
        <Route path="/redeem" element={<ShopMain nagateNav={setShowNav} />} />
        {/* <Route path="/la" element={<MainApp nagateNav={setShowNav} />} /> */}
        <Route path="/" element={<EntryPage nagateNav={setShowNav} />} />
        <Route path="/movies" element={<Movies />} />
        <Route
          path="/login"
          element={<DefaultLogin nagateNav={setShowNav} />}
        />


<Route
          path="/firstbankloyalty/sso/login"
          element={<SingleSignOn nagateNav={setShowNav} />}
        />
        <Route path="" element={<FirstTimeLogIn nagateNav={setShowNav} />} />
        <Route element={<Logout />} path="/logout" />
        <Route
          path="forgot-password"
          element={<ForgotPassword nagateNav={setShowNav} />}
        />

        {/* <Route path="/cart-items" element={<CartItems />} /> */}
        <Route path="/vouchers" element={<Voucher />} />
        <Route path="/discounts" element={<Discount />} />
        <Route
          path="/vouchers/checkout/:name/:partner_id"
          element={<VoucherCheckout />}
        />
        {/* <Route path="cart-items/checkout" element={<Checkout />} /> */}
        <Route path="/optout" element={<OptOut />} />
        {/* <Route
          path="cart-items/order_confirmation"
          element={<FinalCheckOutpage />}
        /> */}
        <Route
          path="otp-input/:memberid"
          element={<OtpInput nagateNav={setShowNav} />}
        />
        <Route
          path="reset-password/:memberid"
          element={<ResetPassword nagateNav={setShowNav} />}
        />
        <Route
          path="forgot-membershipid"
          element={<ForgotMembershipId nagateNav={setShowNav} />}
        />
        <Route path="/events" element={<EventsLandingPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/flight" element={<Flight />} />
        <Route path="/single-movie/:product_code" element={<SingleMovie />} />
        {/* route check */}
        <Route
          path="/single-category/:Merchandize_category_id"
          element={<SingleCategory />}
        />
        {/* <Route
          path="/single-category/:Merchandize_category_id/pickup&delivery"
          element={<SingleCategory />}
        /> */}
        {/* route check ends */}
        <Route path="/search" element={<Search />} />
        <Route path="/movie-tickets" element={<MovieTicket />} />
        {/* <Route path="/wish-list" element={<WishList />} />
        <Route path="/order-pick-up" element={<OrderPickUpItems />} />
        <Route path="/order-delivery" element={<OrderDeliveryItems />} /> */}
        <Route />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        {/* <Route path="/hotel" element={<Hotel />} /> */}
        {/* <Route path="/hotel-search" element={<HotelSearch />} />
        <Route path="/hotel-single-room" element={<HotelSingleRoom />} />
        <Route path="/hotel-checkout" element={<HotelCheckout />} /> */}
        <Route path="/experience" element={<Experience />} />
        <Route
          path="/single-experience-info/:product_code"
          element={<SingleExperienceInformation />}
        />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        <Route
          path="/single-item/:Company_merchandise_item_id"
          element={<SingleItem />}
        />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/notification/:memberid" element={<Notification />} />
        {/* <Route path="/meals" element={<Meals />} /> */}
        {/* <Route path="/restaurant-menu" element={<RestaurantMenu />} /> */}
        <Route
          path="/restaurant-menu/:Partner_id/:Branch_id"
          element={<RestaurantMenu />}
        />
        {/* <Route path="/restaurant-checkout" element={<MealsCheckout />} />
        <Route path="/meal-order" element={<MealOrderReceipt />} /> */}
        <Route path="/airtime&bills" element={<AirtiimeAndBills />} />
        <Route path="/bills&airtimecheckout/:id" element={<BillsCheckOut />} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/new" element={<New />} />

        {/* Auction starts */}

        {/* <Route path="auctions" element={<Auctions />} />
        <Route path="auctions/:Auction_id" element={<SingleAuction />} /> */}

        {/* Auction end */}

        {/* SweepStake starts */}



        <Route path="sweepstakes" element={<SweepStakes />} />




        <Route
          path="sweepstakes/:SweepStake_id"
          element={<SingleSweepStake />}
        />

        {/* SweepStake end */}

        {/* Survey starts */}

        {/* <Route path="surveys" element={<Surveys />} />
        <Route path="surveys/:Survey_id" element={<SingleSurvey />} /> */}

        {/* Survey end */}

        <Route path="*" element={<Error />} />
        <Route path="404" element={<Error />} />
      </Routes>
      {/* <StickyCategoryModal /> */}
      <HelpWidget/>
      {showNav && <Footer />}
    </Router>
  );
}

export default App;
