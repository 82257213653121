import React, { useEffect, useState } from "react";
import "./ChangePasswordModal.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import axiosProperty from "../../service/HttpService";
import { endpoints } from "../../utils/constant";
import { useMutation } from "react-query";
import { TailwindSpinner } from "../Spinner/TailwindSpinner";
import { authHeaders, getAccessToken } from "../../config/helper";
import { PasswordMark, PasswordTimes } from "../../assets/icons";

const ChangePasswordModal = ({ membershipId, currentPassword }) => {
  const [errorMessageState, setErrorMessageState] = useState(false);
  const [allFieldRequired, setAllFieldRequired] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [duplicatePassword, setDuplicatePassword] = useState(false);
  const [passwordType, setPasswordType] = useState(false);
  const pattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const [isEightChars, setIsEightChars] = useState(false);
  const [hasLetter, setHasLetter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);

  const [password, setPassword] = useState({
    confirm_password: "",
    current_password: currentPassword,
    new_password: "",
    membership_id: membershipId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPassword((prevPassword) => ({
      ...prevPassword,
      [name]: value,
    }));
  };

  const [token, setToken] = useState('')

  useEffect(()=>{
    const getToken = getAccessToken()
    setToken( getToken)
  },[membershipId, currentPassword])

  const changePassword = async () => {
    try {
      const response = await axiosProperty.post(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.profile_password}`,
        password,
        { headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        } }
      );

      
      if (response.status === 200) {
        window.location = "/redeem";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(changePassword);

  const handleSubmit = () => {
    if (Object.values(password).includes("")) {
      setAllFieldRequired(true);
      return;
    } else if (password.new_password !== password.confirm_password) {
      setErrorMessageState(true);
      return;
    } else if (
      password.new_password === password.current_password &&
      password.current_password === password.confirm_password
    ) {
      setDuplicatePassword(true);
      return;
    } else {
      mutate();
    }
  };

  useEffect(() => {
    setErrorMessageState(false);
    setAllFieldRequired(false);
    setDuplicatePassword(false);
    setPasswordType(false);
  }, [password]);

  useEffect(() => {
    setIsEightChars(password.new_password.length >= 8);
    setHasLetter(/(?=.*[A-Za-z])/.test(password.new_password));
    setHasNumber(/(?=.*\d)/.test(password.new_password));
    setHasSpecialChar(/(?=.*[@$!%*#?&])/.test(password.new_password));
    setHasUppercase(/(?=.*[A-Z])/.test(password.new_password));
  }, [password]);

  //console.log(password, "password");

  return (
    <div className="change-password-modal-container">
      <div className="change-password-modal">
        <h1 className="change-password-modal-title">Create New Password</h1>
        <p className="change-password-modal-paragraph">
          For security reasons kindly enter a new password for your account
        </p>
        <div className="change-password-form-modal">
          <div className="change-password-modal-input-container">
            <label htmlFor="">New Password</label>
            <input
              type={newPasswordVisible ? "text" : "password"}
              className="change-password-input"
              placeholder="New Password"
              name="new_password"
              value={password.new_password}
              onChange={handleChange}
            />

            <div className="change-password-visibility">
              {newPasswordVisible ? (
                <span
                  className="change-password-icon-cover"
                  onClick={() => setNewPasswordVisible(false)}
                >
                  <AiFillEyeInvisible />
                </span>
              ) : (
                <span
                  className="change-password-icon-cover"
                  onClick={() => setNewPasswordVisible(true)}
                >
                  <AiFillEye />
                </span>
              )}
            </div>
          </div>
          <ul className="password-character-check-container">
          <li style={{ color: hasUppercase ? "green" : "red", fontSize: '11px', margin: '0px' }}>
                        {hasUppercase ? <PasswordMark/> : <PasswordTimes/>} At least one uppercase
                      </li>
            <li
              style={{
                color: isEightChars ? "green" : "red",
                fontSize: "11px",
                margin: "0px",
              }}
            >
              {isEightChars ? <PasswordMark /> : <PasswordTimes />} At least 8
              characters
            </li>
            <li
              style={{
                color: hasLetter ? "green" : "red",
                fontSize: "11px",
                margin: "0px",
              }}
            >
              {hasLetter ? <PasswordMark /> : <PasswordTimes />} At least one
              letter
            </li>
            <li
              style={{
                color: hasNumber ? "green" : "red",
                fontSize: "11px",
                margin: "0px",
              }}
            >
              {hasNumber ? <PasswordMark /> : <PasswordTimes />} At least one
              number
            </li>
            <li
              style={{
                color: hasSpecialChar ? "green" : "red",
                fontSize: "11px",
                margin: "0px",
              }}
            >
              {hasSpecialChar ? <PasswordMark /> : <PasswordTimes />} At least
              one special character (@$!%*#?&)
            </li>
          </ul>
          <div className="change-password-modal-input-confirm-container">
            <label htmlFor="">Confirm Password</label>
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              className="change-password-input"
              placeholder="Confirm Password"
              name="confirm_password"
              value={password.confirm_password}
              onChange={handleChange}
            />
            <div className="change-password-visibility-confirm">
              {confirmPasswordVisible ? (
                <span
                  className="change-password-icon-cover"
                  onClick={() => setConfirmPasswordVisible(false)}
                >
                  <AiFillEyeInvisible />
                </span>
              ) : (
                <span
                  className="change-password-icon-cover"
                  onClick={() => setConfirmPasswordVisible(true)}
                >
                  <AiFillEye />
                </span>
              )}
            </div>
            {errorMessageState && (
              <p className="change-password-warning-text">
                Your passwords do not match, please try again.
              </p>
            )}
            {duplicatePassword && (
              <p className="change-password-warning-text">
                You cannot use the same password again
              </p>
            )}
            {allFieldRequired && (
              <p className="change-password-warning-text">
                All fields are required, please fill them out.
              </p>
            )}
            {passwordType && (
              <p className="change-password-warning-text">
                Password must be minimum of 8 characters, at least one letter,
                one number and one special character
              </p>
            )}
          </div>
          <div className="change-password-button-container">
            <button
              disabled={
                [isEightChars, hasLetter, hasNumber, hasSpecialChar, hasUppercase].some(
                  (each) => !each
                ) || password.confirm_password !== password.new_password
              }
              className="change-password-button"
              onClick={handleSubmit}
            >
              {isLoading ? <TailwindSpinner /> : "Save Changes"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
