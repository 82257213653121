import React, {useEffect} from 'react';
import './EntryPage.css';
import Hero from './Components/Hero';
import SectionOne from './Components/SectionOne';
import SectionTwo from './Components/SectionTwo';
import SectionThree from './Components/SectionThree';
import SectionFour from './Components/SectionFour';
import SectionFive from './Components/SectionFive';
import SectionSix from './Components/SectionSix';
import SectionSeven from './Components/SectionSeven';
import Footer from '../../components/Footer/Footer';

const EntryPage = ({nagateNav}) => {
  useEffect(() => {
    nagateNav(false);
  }, [nagateNav]);

  return (
    <div className="entry-page-container">
      <Hero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <Footer />
    </div>
  );
};

export default EntryPage;
