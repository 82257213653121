import React, {useState} from 'react';
import {TailwindSpinner} from '../../components/Spinner/TailwindSpinner';
import './Discount.css';

const Card = ({each, handleClick, isLoadingMerchant}) => {


  return (
    <div
      className="card-discount"
      onClick={() => handleClick(each.merchant_id, each.discount_percentage)}>
     
      <div className='discount-card-image-container'>

      <img src={each.merchant_img} alt={each.merchant_name} className='discount-card-image' />
      </div>
      <div className="card--text">
        <p className='discount-merchant-name'>{each.merchant_name}</p>
      
        <p className='discount-percentage-value'>Enjoy {Number(each.discount_percentage)}% Discount</p>
        {/* <h5>{each.discount_percentage}%</h5>
        <p>Discount</p> */}
  
        <button
          onClick={() =>
            handleClick(each.merchant_id, each.discount_percentage)
          }>
          {isLoadingMerchant ? <TailwindSpinner /> : 'View Discount Locations'}
        </button>
      </div>
    </div>
  );
};

export default Card;
