import React, { useEffect, useState } from "react";
import { MovieItem } from "../../CardItems/CardItem";
import { Dna } from "react-loader-spinner";
import axios from "axios";
import { endpoints } from "../../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { loadSpinner } from "../../../../redux-toolkit/features/Offline/OfflineSlice";
import { LoadingIcon } from "../../../../assets/icons";
import { getAccessToken, logout } from "../../../../config/helper";
import { ToastContainer, toast } from "react-toastify";

export const MovieList = ({ product_code }) => {
  const { isOpen } = useSelector((store) => store.offline);
  const dispatch = useDispatch();
  const [movieItem, setMovieItem] = useState([]);

  const fetchData = async (data) => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_FIRSTBANK_BASEURL}${endpoints.movie}${product_code}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      data(response?.data);
      dispatch(loadSpinner());
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        //import { ToastContainer, toast } from "react-toastify";
        // <ToastContainer />
        setTimeout(() => {
          logout();
        }, 4000);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(setMovieItem);
  }, [setMovieItem]);

  console.log(movieItem, "movie-details");

  if (isOpen) {
    return (
      <div className="flex py-12 justify-center">
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      </div>
    );
  }

  return (
    <div className="">
      <div className=" text-white">
        <div className="bg-[#111111]">
          {movieItem?.status === 1 ? (
            movieItem?.data === null ? (
              <div className="bg-[#fff] h-[50vh] flex items-center justify-center">
                {" "}
                <h1>Movie is not available</h1>{" "}
              </div>
            ) : (
              <MovieItem

              
                key={1}
                card={movieItem?.data?.data}
                productCode={product_code}
              />
            )
          ) : (
            <div className="min-h-96 flex items-center justify-center">
              <LoadingIcon />
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
