import HttpService from "../../service/HttpService";
import ApiConfig from "../../service/ApiConfig";
let result = JSON.parse(sessionStorage.getItem("first-bank-loyalty"));
const membershipID = result?.data.customer.Membership_id;

export const getCartItemsApi = async () => {
  try {
    return await HttpService.get(ApiConfig.items);
  } catch (error) {
    console.error(error);
  }
};


export const redemptionApi = async (addressID, deliveryFee) => {
 
  const params = {
    member_id: parseInt(membershipID),
    shipping_address_id: addressID,
    delivery_fee: deliveryFee,
  };

  

  try {
    return await HttpService.post(ApiConfig.redemption, params);
  } catch (error) {
    console.error(error);
  }
};
