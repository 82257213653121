let result = JSON.parse(sessionStorage.getItem('first-bank-loyalty'));
const membershipID = result?.data.customer.Membership_id;
const root = 'https://firstbankapi-dpf7ajceb9czfbe2.francecentral-01.azurewebsites.net/api';
const auctionEndpoint = `${root}/api/auctions`;
const sweepstakeEndpoint = `${root}/api/sweepstakes`;

const baseUrl = `${root}/api/shipping/${membershipID}/`;
const itemUrl = `${root}/api/checkout/items/${membershipID}`;
const eventsBaseUrl = 'https://rewardsbox.perxclm.com/';
const flightSearchUrl =
  'https://rewardsboxnigeria.com/rewardsbox/api/v3/flight/?api=';
const citiesUrl = `${root}/api/`;
const pickingAddressUrl = `${root}/api/shipping/select_address`;
const createAndUpdateAddress = `${root}/api/shipping/`;
const editAddress = `${root}/api/shipping/`;
const redemptionUrl = `${root}/api/cart/checkout`;
const deleteAddress = `${root}/api/shipping/`;
const sideBarCategories = `${root}/api/mobile/multi_categories`;

const ApiConfig = {
  forgotMembershiID: `${root}/forgot_membership`,
  userAddress: `${baseUrl}addresses`,
  getSweepStakes: `${root}/sweepstakes/`,
  getSingleSweepStakes: `${root}/sweepstakes`,
  events: `${root}/all_events`,
  flightSearch: `${flightSearchUrl}airports&search=`,
  cities: `${citiesUrl}states`,
  items: `${itemUrl}`,
  selectedAddress: `${pickingAddressUrl}`,
  newAddress: `${createAndUpdateAddress}store`,
  updateAddress: `${createAndUpdateAddress}`,
  edit: `${editAddress}`,
  redemption: `${redemptionUrl}`,
  deleteAddress: `${deleteAddress}`,
  sideBar: `${sideBarCategories}`,
  auctionEndpoint,
  sweepstakeEndpoint,
  rootAPI: root,
  pointToCashEndPoint: `${root}/redeem_points_to_cash`,
  helpWidgetSend: `${root}/send_help`,
  optout: `${root}/opt_out`
};

export default ApiConfig;
