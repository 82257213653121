import React, {useEffect} from 'react';
import './SectionTwo.css';
import openAccountFrom from '../../../assets/openAccountForm.png';
import gift from '../../../assets/images/earngreen.png';
import {motion} from 'framer-motion';
import purple from '../../../assets/images/earnPurple.png';
// import {useAnimationInView} from ;
import {
  sectionTwoImageAnimationOne,
  sectionTwoTextAnimation,
} from '../../../animation';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
import {useAnimationInView} from '../../LandingPage/components/useAnimationInView';

const SectionTwo = () => {
  const [element, controls] = useAnimationInView();

  useEffect(() => {
    // import { gsap } from "gsap";
    // import { ScrollTrigger } from "gsap/ScrollTrigger.js";
    gsap.registerPlugin(ScrollTrigger);

    let proxy = {skew: 0},
      skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'), // fast
      clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      onUpdate: self => {
        let skew = clamp(self.getVelocity() / -300);
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {
            skew: 0,
            duration: 0.5,
            ease: 'power3',
            overwrite: true,
            onUpdate: () => skewSetter(proxy.skew),
          });
        }
      },
      onScroll: () => {
        ScrollTrigger.update();
      },
    });

    gsap.set('.skewElem', {transformOrigin: 'right center', force3D: true});

    // Cleanup function to unregister ScrollTrigger on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);
  return (
    <>
      <div ref={element} className="skewElem" id="earn-point">
        <div className="sectiontwo-container">
          <div className="start-earning-headers" id="start-earning-header">
            <h1>Start Earning FirstCoins</h1>
            <h1>Right Away</h1>
          </div>
          <div className="below-start-earning-parag">
            <p>
              Whether you’re banking online or instant, you can earn FirstCoins
              when you transact. These FirstCoins add up to give you exciting
              reward.
            </p>
          </div>
          <div className="sectiontwo-img-container">
            <div className="acc-img">
              <motion.img
                variants={sectionTwoImageAnimationOne}
                animate={controls}
                transition={{
                  delay: 0.03,
                  type: 'tween',
                  duration: 0.8,
                }}
                whileHover={{scale: 0.8, borderRadius: '100%'}}
                src={openAccountFrom}
                alt="Open Account Form"
                className="open-acct-image_"
              />
            </div>

            <motion.div
              className="open-right-text-mobile-1"
              variants={sectionTwoTextAnimation}
              animate={controls}
              transition={{
                delay: 0.02,
                type: 'tween',
                duration: 0.8,
              }}>
              {/* <div > */}
              <h1 className="open-acct-header_">Open any FirstBank Account</h1>
              {/* </div> */}
              {/* <div> */}
              <p className="open-acct-parag-mobile-1">
                A brief copy about the various accounts one can open with
                FirstBank and any additonal text.
              </p>
              {/* </div> */}
            </motion.div>
          </div>
        </div>

        <div className="mobile-sections-container">
          <div className="">
            <div className="top-section-mobile-container">
              <h1>Start Earning FirstCoins Right Away</h1>

              <p>
                Whether you’re banking online or instant, you can earn
                FirstCoins when you transact. These FirstCoins add up to give
                you exciting reward.
              </p>
            </div>
            <div className="earn-point-1">
              <img
                src={openAccountFrom}
                alt="Open Account Form"
                className="top-section-image-mobile"
              />

              <div className="open-account-text-mobile-container">
                <h1>Open any FirstBank Account</h1>

                <p>
                  A brief copy about the various accounts one can open with
                  FirstBank and any additonal text.
                </p>
              </div>
            </div>
          </div>

          <div className="earn-point-2">
            <img
              src={purple}
              alt="Perform Operaton"
              className="perform-operation-image-mobile"
            />
            <div className="perform-operation-text-mobile-container">
              <h1>Perform transactions on any of our channels</h1>
              <p>
                A brief copy about the various transactions one can perform with
                FirstBank and any additonal text.
              </p>
            </div>
          </div>

          <div className="earn-point-3">
            <img
              src={gift}
              alt="Earn Points"
              className="earn-point-mobile-image"
            />

            <div className="earn-point-text-mobile-container">
              <h1>Earn FirstCoins, Get Rewards</h1>
              <p>
                Earn FirstCoins and Redeem amazing products on the rewards
                platform. From meals to airtime and more
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionTwo;
