import React from "react";
import NewArrivalList from "../../components/Cards/CardList/Shops/NewArrivalList";
import Categories from "../../components/Categories/Categories";

const New = () => {
  return (
    <div>
      <Categories />
      <div className=" py-10">
        <div className="mb-10 bg-[url('./assets/images/order_hero.png')] bg-no-repeat py-20 bg-cover">
          <h2
            className="flex font-extrabold text-4xl leading-loyalty tracking-tighter text-button justify-center"
            style={{ textT: "capitalise" }}
          >
          <h3 className="capitalize text-search font-bold text-4xl">new arrival</h3>
          </h2>
          <p className="flex justify-center pt-3 font-medium text-base leading-shop text-pick tracking-loyal mx-[27px] text-center sm:mx-[440px]"></p>
        </div>
        <NewArrivalList />
      </div>
    </div>
  );
};

export default New;
