import React, { useState } from 'react'
import { useEffect } from 'react'

function AuctionParticipantIcon({firstName, lastName, isLarge, index}) {
    const [initials, setInitials] = useState('')
    
    useEffect(() => {
        let firstInitial = firstName[0]
        let lastInitial = lastName[0]
        setInitials(firstInitial+lastInitial)
    }, [firstName, lastName])
    
  return (
    <div>
        {isLarge && 
            <div className={`rounded-[50%] flex justify-center items-center w-[65px] h-[65px] ${index === 0  ? 'bg-[#0D7141]' : 'bg-[#DA6901]'} `}>
                <p className="text-white font-bold text-[18px] tracking-[-1%] leading-[21.6px]"> {initials}</p>
            </div> 
        }
        
        {!isLarge && 
            <div className={`rounded-[50%] flex justify-center items-center w-[49px] h-[49px] ${index === 0  ? 'bg-[#0D7141]' : 'bg-[#DA6901]'} `}>
                <p className="text-white font-bold text-[18px] tracking-[-1%] leading-[21.6px]"> {initials}</p>
            </div> 
        }
    </div>
  )
}

export default AuctionParticipantIcon