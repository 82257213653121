import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import { nanoid } from "nanoid";

const FlightCheckoutForm = ({
  countries,
  redRevFactorial,
  index,
  setAdultDataArray,
  adultDataArray,
}) => {
  const months = [
    {
      name: "Jan",
      id: 1,
      days: 31,
    },
    {
      name: "Feb",
      id: 2,
      days: 28,
    },
    {
      name: "Mar",
      id: 3,
      days: 31,
    },
    {
      name: "Apr",
      id: 4,
      days: 30,
    },
    {
      name: "May",
      id: 5,
      days: 31,
    },
    {
      name: "Jun",
      id: 6,
      days: 30,
    },
    {
      name: "Jul",
      id: 7,
      days: 31,
    },
    {
      name: "Aug",
      id: 8,
      days: 31,
    },
    {
      name: "Sep",
      id: 9,
      days: 30,
    },
    {
      name: "Oct",
      id: 10,
      days: 31,
    },
    {
      name: "Nov",
      id: 11,
      days: 30,
    },
    {
      name: "Dec",
      id: 12,
      days: 31,
    },
  ];
  const title = [
    {
      id: 1,
      name: "Mr",
    },
    {
      id: 2,
      name: "Mrs",
    },
    {
      id: 3,
      name: "Ms",
    },
    {
      id: 4,
      name: "Sir",
    },
    {
      id: 5,
      name: "Dr",
    },
    {
      id: 6,
      name: "HRH",
    },
    {
      id: 7,
      name: "Hon",
    },
    {
      id: 8,
      name: "Pst",
    },
    {
      id: 9,
      name: "Sen",
    },
  ];
  const gender = [
    { gender: "Agender", id: 1 },
    { gender: "Androgyne", id: 2 },
    { gender: "Androgynous", id: 3 },
    { gender: "Bigender", id: 4 },
    { gender: "Man", id: 6 },
    { gender: "Male", id: 7 },
    { gender: "Intersex", id: 8 },
    { gender: "Intergender", id: 9 },
    { gender: "Gender Fluid", id: 10 },
    { gender: "Genderweird", id: 11 },
    { gender: "Genderqueer", id: 12 },
    { gender: "Femme", id: 13 },
    { gender: "Feminine", id: 14 },
    { gender: "Female", id: 15 },
    { gender: "Demiguy", id: 16 },
    { gender: "Demigirl", id: 17 },
    { gender: "Cis Woman", id: 18 },
    { gender: "Cis Masculine", id: 19 },
    { gender: "Cis Male", id: 38 },
    { gender: "Cis Feminine", id: 21 },
    { gender: "Cis Female", id: 22 },
    { gender: "Masculine", id: 23 },
    { gender: "Neutrois", id: 24 },
    { gender: "Nonbinary", id: 25 },
    { gender: "Other", id: 26 },
    { gender: "Pangender", id: 27 },
    { gender: "Third Gender", id: 28 },
    { gender: "Trans Female", id: 29 },
    { gender: "Trans Male", id: 30 },

    { gender: "Trans Man", id: 31 },
    { gender: "Trans Feminine", id: 32 },
    {
      gender: "Trans Femme",
      id: 33,
    },
    { gender: "Trans Woman", id: 34 },
    { gender: "Woman", id: 35 },
  ];
  const [country, setCountry] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [passangerTitle, setPassangerTitle] = useState([]);
  const [passangerGender, setPassangerGender] = useState([]);
  //   const [dropDownValue, setDropDownValue] = useState("");
  const [yearArray, setYearArray] = useState([]);
  const [passangerDOBMonth, setPassangerDOBMonth] = useState([]);
  const [passangerDOBYear, setPassangerDOBYear] = useState([]);
  const [passangerDOBDay, setPassangerDOBDay] = useState([]);
  const [dayArray, setDayArray] = useState([]);
  const [lastDay, setLastDay] = useState(0);
  const [adultPassangerData, setAdultPassangerData] = useState({
    title: "",
    lastName: "",
    firstName: "",
    middleName: "",
    nationality: "",
    gender: "",
    year: "",
    month: "",
    day: "",
    passportNumber: "",
    passportExpDate: "",
    passportIssuing: "",
    id: nanoid(),
  });
  //   const [adultDataArray, setAdultDataArray] = useState([]);
  const dateArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //   console.log(adultDataArray, "setAdultDataArray");

  useEffect(() => {
    if (passangerDOBDay.length) {
      setOtherData({
        ...otherData,
        day: passangerDOBDay[0].day,
      });
    }

    if (passangerTitle.length) {
      setOtherData({
        ...otherData,
        title: passangerTitle[0].name,
      });
    }

    if (country.length) {
      setOtherData({
        ...otherData,
        nationality: country[0].name,
      });
    }

    if (passangerGender.length) {
      setOtherData({
        ...otherData,
        gender: passangerGender[0].gender,
      });
    }

    // if (passangerDOBDay.length) {
    //   console.log(passangerDOBDay);
    //   setOtherData({
    //     ...otherData,
    //     day: passangerDOBDay[0].day,
    //   });
    // }

    // if (passangerDOBMonth.length) {
    //   console.log(passangerDOBMonth);
    //   setOtherData({
    //     ...otherData,
    //     month: passangerDOBMonth[0].name,
    //   });
    // }

    if (passangerDOBYear.length) {
      console.log(passangerDOBYear);
      setOtherData({
        ...otherData,
        year: passangerDOBYear[0].year,
      });
    }
    if (passangerDOBDay.length) {
      setOtherData({
        ...otherData,
        day: passangerDOBDay[0].day,
      });
    }
  }, [
    passangerTitle,
    country,
    lastDay,
    passangerDOBYear,
    passangerDOBMonth,
    passangerDOBDay,
    passangerGender,
    dayArray,
    yearArray,
  ]);

  const handleChange = (e) => {
    const value = e.target.value;

    if (passangerDOBMonth.length) {
      console.log(passangerDOBMonth);
      setOtherData({
        ...otherData,
        month: passangerDOBMonth[0].name,
      });
    }

    setAdultPassangerData({
      ...adultPassangerData,
      ...otherData,

      [e.target.name]: value,
    });

    const data = adultDataArray.some(
      (each) => each.id === adultPassangerData.id
    );
    if (data) {
      const updateAdultDataArray = adultDataArray.map((each) => {
        if (each.id === adultPassangerData.id) {
          return { ...each, ...otherData, [e.target.name]: value };
        } else {
          return each;
        }
      });
      setAdultDataArray(updateAdultDataArray);
    }

    if (!data) {
      setAdultDataArray([...adultDataArray, adultPassangerData]);
    }
  };

//   console.log(otherData, "otherData");
//   console.log(adultPassangerData, "allPassangers");

  function generateArrayOfYears(k) {
    var max = new Date().getFullYear() - 12;
    var min = max - k;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push({ year: i, id: i });
    }
    // console.log(years);
    setYearArray(years);
    return years;
  }

  useEffect(() => {
    generateArrayOfYears(90);
  }, []);

  //   console.log(yearArray, "My functions");

  function generateLastDay(month, year) {
    return setLastDay(new Date(year, month, 0).getDate());
  }

  console.log(lastDay, "lastDay");

  function generateDaysArray() {
    const days = [];
    for (let i = 1; i <= lastDay; i += 1) {
      days.push({ day: i, id: i });
    }
    return setDayArray(days);
  }

  useEffect(() => {
    if (passangerDOBMonth.length > 0 && passangerDOBYear.length > 0) {
      const monthIndex = dateArray.indexOf(`${passangerDOBMonth[0].name}`);
      generateLastDay(monthIndex + 1, passangerDOBYear[0].year);
      generateDaysArray();
    }
  }, [passangerDOBMonth, passangerDOBYear, lastDay]);

  return (
    <div className="populated-flight-form">
      <div className="passenger-details-parag-cont">
        <div className="passenger-details-parag">
          Passenger {index + 2} (Adult)
        </div>
      </div>
      <div>
        <div className="detail-line-1">
          <div className="title-dropdown-container">
            <p className="line-p">
              Surname<sup className="red-span">*</sup>
            </p>
            <div className="title-select-inner">
              <div className="title-select">
                <Select
                  options={title}
                  labelField="name"
                  valueField="id"
                  placeholder="title"
                  onChange={(value) => setPassangerTitle(value)}
                  searchable={true}
                  searchBy="name"
                />
              </div>
              <div className="title-lastname-container">
                <input
                  type="text"
                  name="lastName"
                  value={adultPassangerData.lastName}
                  onChange={handleChange}
                  placeholder="LastName"
                  className="title-lastname"
                />
              </div>
            </div>
          </div>

          <div className="title-dropdown-container title___">
            <p className="line-p">
              First Name<sup className="red-span">*</sup>
            </p>

            <div className="intl-name-container">
              <input
                type="text"
                name="firstName"
                value={adultPassangerData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                className="title-lastname title-lastname_"
              />
            </div>
          </div>
        </div>

        <div className="section-2-detail-container">
          <div className="mid-name">
            <p className="line-p">
              Middle Name<sup className="red-span">*</sup>
            </p>

            <div className="intl-name-container_1">
              <input
                type="text"
                name="middleName"
                value={adultPassangerData.middleName}
                onChange={handleChange}
                placeholder="Middle Name"
                className="title-lastname__ "
              />
            </div>
          </div>

          <div className="right-nationality-container">
            <div className="right-nationality-container-inner">
              <p className="line-p">
                Nationality<sup className="red-span">*</sup>
              </p>
              <div className="intl-name-container_1 intl-name-container_1-adjust">
                <Select
                  options={countries}
                  labelField="name"
                  valueField="id"
                  placeholder="Nationality"
                  onChange={(values) => setCountry(values)}
                  searchable={true}
                  searchBy="name"
                  className="nationality-container"
                />
              </div>
            </div>

            <div className="gender-input-adjust">
              <p className="line-p">
                Gender<sup className="red-span">*</sup>
              </p>
              <div className="intl-name-container_1">
                <Select
                  options={gender}
                  labelField="gender"
                  valueField="id"
                  placeholder="Gender"
                  onChange={(values) => setPassangerGender(values)}
                  searchable={true}
                  searchBy="gender"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="checkout-dob">
          <div>
            <p className="line-p">
              Date of birth <span className="red-span">*</span>
            </p>
          </div>
          <div className="checkout-dob-inner">
            <div className="title-select">
              <Select
                options={yearArray}
                labelField="year"
                valueField="id"
                placeholder="Year"
                onChange={(value) => setPassangerDOBYear(value)}
                searchable={true}
                searchBy="year"
                className="year-day"
              />
            </div>
            <div className="title-select ts_adjust">
              <Select
                options={months}
                labelField="name"
                valueField="id"
                placeholder="Month"
                onChange={(value) => setPassangerDOBMonth(value)}
                searchable={true}
                searchBy="name"
              />
            </div>
            <div className="title-select">
              <Select
                options={dayArray}
                labelField="day"
                valueField="id"
                placeholder="Day"
                onChange={(values) => setPassangerDOBDay(values)}
                searchable={true}
                searchBy="day"
                className="year-day"
              />
            </div>
          </div>
        </div>

        <div className="passenger-details-parag">Passport Details</div>

        <div className="flight-detail-instruction_">
          <div className="rev-fac-outter">
            <img
              src={redRevFactorial}
              alt="reverse factorial"
              className="rev-fac"
            />
          </div>
          <div className="instruction-parag-outter">
            <p className="instruction-parag">
              All details provided by you must be accurate, true and
              non-misleading. In the event you provide the wrong information you
              shall be held liable for such misconduct.
            </p>
          </div>
        </div>
        <div className="passport-details">
          <div className="passport-details-inner">
            <p className="line-p">
              Passport Number <span className="red-span">*</span>
            </p>
            <input
              type="text"
              name="passportNumber"
              value={adultPassangerData.passportNumber}
              onChange={handleChange}
              className="passport-details-input"
              placeholder="Passport Number"
            />
          </div>
          <div className="passport-details-inner">
            <p className="line-p">
              Passport Expiry Date<span className="red-span">*</span>
            </p>
            <input
              type="text"
              name="passportExpDate"
              value={adultPassangerData.passportExpDate}
              onChange={handleChange}
              className="passport-details-input"
              placeholder="Passport Expiry Date"
            />
          </div>
          <div className="passport-details-inner">
            <p className="line-p">
              Passport Issuing Authority
              <span className="red-span">*</span>
            </p>
            <input
              type="text"
              name="passportIssuing"
              value={adultPassangerData.passportIssuing}
              onChange={handleChange}
              className="passport-details-input"
              placeholder="Passport Issuing A..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightCheckoutForm;
