import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import plus from "../../../src/assets/svg/add.svg";
import minus from "../../../src/assets/svg/minus.svg";
import singleLocation from "../../../src/assets/svg/singleLocation.svg";
import { endpoints } from "../../utils/constant";
import Categories from "../../components/Categories/Categories";

const SingleExperienceInformation = () => {
  const [singleExperience, setSingleExperience] = useState(null);
  const { product_code } = useParams();

  const fetchData = async () => {
    try {
      let urlparam = { api: "product_details" };
      const response = await axios.post(
        `${process.env.REACT_APP_EXPERIENCE_URL}${endpoints.singleExperience}${product_code}`,
        urlparam,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            token: process.env.REACT_APP_EXPERIENCE_KEY
          }
        }
      );
      setSingleExperience(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [product_code]);

  let [experienceValue, setExperienceValue] = useState(1);

  let valueIncrement = () => {
    if (experienceValue < 10) {
      setExperienceValue((experienceValue += 1));
    }
  };

  let valueDecrement = () => {
    if (experienceValue > 1 ) {
      setExperienceValue((experienceValue -= 1));
    }
  };

  let handleExperienceChange = (event) => {
    setExperienceValue(event.target.value);
  };


  return (
    <div>
      <Categories />

      <div className="px-28 pt-4 bg-[#f8f8f8]">
        {singleExperience !== null && (
          <div>
            <div className="flex gap-8">
              <div className="w-3/5">
                <div className="w-full h-[352px]">
                  <img
                    className="w-full h-full"
                    src={`${singleExperience?.image}`}
                    alt={`${singleExperience?.product}`}
                  />
                </div>
              </div>

              <div className="flex gap-8 w-2/5 h-80 ">
                <div className="flex flex-col gap-8 w-full h-full ">
                  <div className="w-full h-1/2">
                    <img
                      className="w-full h-full"
                      src={`${singleExperience?.images[0]}`}
                      alt={`${singleExperience?.product}`}
                    />
                  </div>
                  <div className="w-full h-1/2">
                    <img
                      className="w-full h-full"
                      src={`${singleExperience?.images[1]}`}
                      alt={`${singleExperience?.product}`}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-8 w-full h-full">
                  <div className="w-full h-1/2">
                    <img
                      className="w-full h-full"
                      src={`${singleExperience?.images[2]}`}
                      alt={`${singleExperience?.product}`}
                    />
                  </div>
                  <div className="w-full h-1/2">
                    <img
                      className="w-full h-full"
                      src={`${singleExperience?.image}`}
                      alt={`${singleExperience?.product}`}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="flex gap-8 pb-4">
                <div className="px-8 w-3/5 h-fit bg-white rounded">
                  <div className="border-b py-4 ">
                    <h6 className="py-2 font-bold capitalize text-2xl leading-loyalty tracking-loyal">
                      {`${singleExperience?.name}`}
                    </h6>
                    <div className="flex gap-3">
                      <img src={singleLocation} alt="calendar" />
                      <p className="text-[#7e7e7e] leading-loyalty font-medium text-base tracking-loyal">
                        {`${singleExperience?.location}`}
                      </p>
                    </div>
                  </div>

                  <div className="py-8">
                    <p className="font-medium text-base leading-loyalty tracking-loyal">
                      {`${singleExperience?.description}`}
                    </p>
                  </div>

                  <div>
                    <p className="font-medium text-sm leading-loyalty tracking-loyal text-[#666666]">
                      AVAILABLE TICKETS
                    </p>
                    <div className="flex justify-between border-b pb-3 items-center my-4">
                      <div className="">
                        <h6 className="capitalize font-bold text-base leading-loyalty tracking-loyal  ">
                          adults
                        </h6>
                        <p className="capitalize font-medium text-base leading-loyalty tracking-loyal text-[#767676]">
                          {`${singleExperience?.price.toLocaleString()}`} FirstCoins
                        </p>
                      </div>

                      <div className="flex items-center gap-3">
                        <p className="capitalize">qty</p>

                        <div className="flex gap-3 items-center">
                          <button className="">
                            <img
                              src={minus}
                              alt="minus"
                              onClick={valueDecrement}
                            />
                          </button>
                          <input
                            className="w-8 px-2"
                            value={experienceValue}
                            onChange={handleExperienceChange}
                          />
                          <button className=" ">
                            <img
                              src={plus}
                              alt="plus"
                              onClick={valueIncrement}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-2/5 h-fit">
                  <div className="px-8 bg-white rounded">
                    <div className="flex justify-between  border-b py-4">
                      <h6 className="font-bold text-lg leading-loyalty tracking-loyal capitalize">
                        summary
                      </h6>
                      <p className="">{experienceValue} tickets</p>
                    </div>
                    <div className="flex justify-between py-4">
                      <p className="">{experienceValue} x Adults</p>
                      <p className="capitalize">
                        {experienceValue}
                        {(singleExperience?.price.toLocaleString())} FirstCoins
                      </p>
                    </div>
                    <div className="">
                      <h6 className="my-2">Adult 1</h6>
                      <div className="flex flex-col my-2">
                        <label className="capitalize my-1" htmlFor="">
                          full name
                        </label>
                        <input
                          className="capitalize pl-2"
                          type="text"
                          placeholder="enter your name"
                        />
                      </div>
                      <div className="flex flex-col my-2">
                        <label className="capitalize my-2" htmlFor="">
                          phone number
                        </label>
                        <input
                          className="capitalize pl-2"
                          type="text"
                          placeholder="enter phone number"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h6 className="my-2">Adult 2</h6>
                      <div className="flex flex-col my-2">
                        <label className="capitalize my-1" htmlFor="">
                          full name
                        </label>
                        <input
                          className="capitalize pl-2"
                          type="text"
                          placeholder="enter your name"
                        />
                      </div>
                      <div className="flex flex-col my-2">
                        <label className="capitalize my-2" htmlFor="">
                          phone number
                        </label>
                        <input
                          className="capitalize pl-2"
                          type="text"
                          placeholder="enter phone number"
                        />
                      </div>
                    </div>
                    <div className="flex my-8  py-6 border-y">
                      <p className="capitalize">2 x children</p>
                      <p className="capitalize">4,800 FirstCoins</p>
                    </div>
                    <div className="flex justify-between">
                      <h6 className="capitalize font-bold text-lg leading-loyalty tracking-loyal">
                        total
                      </h6>
                      <h6 className="capitalize font-bold text-lg leading-loyalty tracking-loyal">
                        9,600 FirstCoins
                      </h6>
                    </div>
                    <button className="bg-submit w-full rounded text-white py-3 my-4 font-bold text-base leading-loyalty capitalize tracking-loyal">
                      process order
                    </button>
                  </div>
                  <div className="py-4 space-y-4">
                    <p className="font-medium text-sm tracking-loyal leading-">
                      By placing an order, you agree to (provider)’s Terms and
                      Privacy policy
                    </p>
                    <p className="font-medium text-sm tracking-loyal leading-">
                      Information and update on this order will be sent to your
                      FirstBank registered email address
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleExperienceInformation;
