import React from 'react'
import "./FlightBanner.css"
const images = [
  require('../../assets/images/ads/FirstMobile1126X177.jpg'),
  require('../../assets/images/ads/FirstMobile1240x129px.jpg'),
  require('../../assets/images/ads/vdmc1126X177px.jpg'),
  require('../../assets/images/ads/vdmc1240x129px.jpg'),
  require('../../assets/images/ads/VirtuaCard1126X177px.jpg'),
  require('../../assets/images/ads/VirtualCard1240x129px.jpg'),
]
const FlightBanner = () => {
  return (
    <>
    <a href="https://www.firstbanknigeria.com/" rel="noreferrer" target="_blank"  className="ad-bannr" style={{marginBottom: '2em'}}>
      {/* <h3>Ad Banner</h3> */}
      <img style={{width:'100vw'}} src={images[Math.floor(Math.random() * images.length)]} alt="ddd" />
    </a>
{/* <div className='flight-ad-banner'>
    <div className='inner-flight-ad-banner'>
    Flight Banner
    </div>
</div> */}
    </>
  )
}

export default FlightBanner