import React, {useEffect} from 'react';
import './SectionFive.css';
// import ItemsSlider from './ItemsSlider';
import {Link} from 'react-router-dom';
// import MobileItemList from './MobileItemList/MobileItemList';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
import ItemsSlider from './ItemsSlider';
import MobileItemList from './MobileItemList/MobileItemList';
import {getMemberId} from '../../../config/helper';
import aeroplane from '../../../assets/images/travel.png';
import movie from '../../../assets/images/movie-and-event.png';
import balloon from "../../../assets/images/balloon.png"
import airtime from '../../../assets/images/airtime-and-bill.png';
import discount from "../../../assets/images/discount.png"

const SectionFive = () => {


  const data = [
    {
      image: aeroplane,
      title: 'Travel',
      id: 3,
      text: 'Explore the world with ease. Redeem points for flights, hotels, and more.',
    },

    {
      image: balloon,
      title: 'Experiences',
      id: 2,
      text: 'Unlock unforgettable moments. Use your points for exclusive experiences.',
    },

    {
      image: movie,
      title: 'Movies & Events',
      id: 4,
      text: 'Get front-row access to entertainment. Redeem points for movies and events.',
    },
    {
      image: discount,
      title: 'Discounts',
      id: 6,
      text: 'Save more on every purchase. Your points unlock unbeatable discounts.',
    },
    {
      image: airtime,
      title: 'Airtime & Bills',
      id: 10,
      text: 'Top up your phone and settle bills effortlessly. Earn points on every transaction.',
    },
    {
      image: aeroplane,
      title: 'Travel',
      id: 628,
      text: 'Explore the world with ease. Redeem points for flights, hotels, and more.',
    },

    {
      image: balloon,
      title: 'Experiences',
      id: 615,
      text: 'Unlock unforgettable moments. Use your points for exclusive experiences.',
    },
  ];
  const result = getMemberId();
  useEffect(() => {
    // import { gsap } from "gsap";
    // import { ScrollTrigger } from "gsap/ScrollTrigger.js";
    gsap.registerPlugin(ScrollTrigger);

    let proxy = {skew: 0},
      skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'), // fast
      clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      onUpdate: self => {
        let skew = clamp(self.getVelocity() / -300);
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {
            skew: 0,
            duration: 0.5,
            ease: 'power3',
            overwrite: true,
            onUpdate: () => skewSetter(proxy.skew),
          });
        }
      },
      onScroll: () => {
        ScrollTrigger.update();
      },
    });

    gsap.set('.skewElem', {transformOrigin: 'right center', force3D: true});

    // Cleanup function to unregister ScrollTrigger on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);
  return (
    <div className="subsection-five skewElem" id="redeem">
      <div className="get-reward-text">
        <h1 className="get-amazing-rewards-text">Get Amazing Rewards</h1>
      </div>
      <div className="get-reward-parag">
        <p>
          Whether you’re banking online or instant, you can earn FirstCoins when
          you transact. These FirstCoins add up to give you exciting reward.
        </p>
      </div>
      <ItemsSlider data={data} />
      <MobileItemList data={data} />
      <div className="section_five_btn_container">
        {result ? (
          <Link to="/redeem">
            <button className="itemslider-login-btn">Go To Shop</button>
          </Link>
        ) : (
          <Link to="/login">
            <button className="itemslider-login-btn">Login</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SectionFive;
