import React, { useState, useEffect } from "react";
import "./SingleEvent.css";
import {
  Decrement,
  Incremental,
  SingleEventCalendar,
  SingleEventLocationIcon,
} from "../../assets/icons";
import { endpoints } from "../../utils/constant";
import axios from "axios";
import ApiConfig from "../../service/ApiConfig";
import { toast } from "react-toastify";
import Toastr from "../Toastr/Toastr";
import { TailwindSpinner } from "../Spinner/TailwindSpinner";
import { authHeaders, getMemberId } from "../../config/helper";

const SingleEvent = ({
  pickedEvent,
  setShowSuccessModal,
  suggestItems,
  handleShowSingleEvent,
}) => {
  // GETTING TICKETS IN THE ticketArray START

  const [ticketArray, setTicketArray] = useState([]);
  const [ticketCounter, setTicketCounter] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [totalCowries, setTotalCowries] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleTicketArray = () => {
    const result = pickedEvent.tickets.map((each) => ({
      title: each.title,
      value: 0,
      price: each.price,
      signature: each.signature,
    }));
    setTicketArray(result);
  };

  const handlePushPhoneNumber = () => {
    const updatedArray = selectedTickets.map((obj) => ({
      ...obj,
      phone_no: phoneNumber,
    }));
  };

  useState(() => {
    handleTicketArray();
  }, [ticketArray]);

  // GETTING TICKETS IN THE ticketArray END

  //FUNCTIONS FOR INCREASING AND DECREASING TICKET VALUES START

  const handleIncreaseTicketNum = (num) => {
    const ticketArrayCopy = ticketArray.map((e) => e);
    const result = ticketArrayCopy.filter((each, index) => {
      if (index === num) {
        each.value++;
      }
    });
    setTicketArray(ticketArrayCopy);
  };

  useEffect(() => {
    const ticketArrayCopy = ticketArray.map((e) => {
      e.token = e.signature;
      e.quantity = e.value;

      return e;
    });
    const result = ticketArrayCopy.filter((e) => e.value > 0);
    setSelectedTickets(result);
    setTicketCounter(result?.length);
    const ticketTotal = result.reduce(
      (total, ticket) => total + ticket.price * ticket.value,
      0
    );

    setTotalCowries((2 * ticketTotal).toLocaleString());
  }, [ticketArray]);

  const handleDecreaseTicketNum = (num) => {
    const ticketArrayCopy = ticketArray.map((e) => e);
    const result = ticketArrayCopy.filter((each, index) => {
      if (index === num) {
        if (each.value >= 1) {
          each.value--;
        } else {
          each.value = each.value;
        }
      }
    });

    setTicketArray(ticketArrayCopy);
  };

  //FUNCTIONS FOR INCREASING AND DECREASING TICKET VALUES END

  const { artwork, title, date, tickets, amount, description, organizer, id } =
    pickedEvent;

  const handleSuccessModal = (e) => {
    e.preventDefault();
    if (selectedTickets.length === 0) {
      toast("Please select at least a ticket to continue");
      return;
    }
    setIsProcessing(true);
    const data = JSON.stringify({
      data: selectedTickets,
      email: "Ifeanyi.Nwideh@loyaltysolutionsnigeria.com",
      phone_no: phoneNumber,
      event_name: title,
      event_location: organizer,
      event_date: date,
      member_no: getMemberId(),
    });
    //console.log('{ data }', data);
    axios
      .post(
        `${ApiConfig.rootAPI}/redeem_event`,
        {
          payload: data,
          api_type: "lsl_event_redemption",
          membership_id: getMemberId(),
        },
        { headers: authHeaders }
      )
      .then((response) => {
        let data = response.data;

        if (data.status == 200) {
          setShowSuccessModal(true);
          setPhoneNumber("");
        } else {
          toast("Unable to process order!");
        }
      })
      .catch((error) => {
        toast("Unable to process order!");
        console.log("error", error);
      })
      .finally((e) => setIsProcessing(false));

    handlePushPhoneNumber();
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length <= 11) {
      setPhoneNumber(numericValue);
    }
  };

  return (
    <>
      <div className="single-event-container">
        <div className="single-event-left">
          <div className="single-event-choose-ticket">
            <p>Choose Tickets</p>
          </div>
          <div className="lower-choose-ticket" key={tickets.signature}>
            <div className="picked-event-image">
              <img
                src={`${String(
                  artwork.replace(
                    "https://nairabox.s3.amazonaws.com/tickets/thumbs/",
                    ""
                  )
                )}`}
                alt="single event"
                className="single-event-img"
              />
            </div>
            <div className="lower-choose-ticket-inner">
              <h1 className="single-event-title">{title}</h1>
              <p className="single-event-description">{description}</p>
              <div className="p1-p2-container p1-p2-container-adjust">
                <span>
                  <SingleEventCalendar />
                </span>
                <p className="single-event-p1">{date}</p>
              </div>
              <div className="p1-p2-container">
                <span>
                  <SingleEventLocationIcon />
                </span>
                <p className="single-event-p1">{tickets[0]?.venue}</p>
              </div>
            </div>
          </div>
          <div className="single-event-section3">
            <p className="available-ticket">AVAILABLE TICKETS</p>

            {ticketArray.map((each, index) => {
              const { title, price, value } = each;

              return (
                <div className="early-bird-section" key={title}>
                  <div className="early-bird">
                    <h4>{title}</h4>
                    <p>{(2 * price).toLocaleString()} FirstCoins</p>
                  </div>
                  <div className="event-increment-num">
                    <span
                      className="increment-decrement-icon"
                      onClick={() => handleDecreaseTicketNum(index)}
                    >
                      <Decrement />
                    </span>

                    <span className="event-ticket-number">{value}</span>
                    <span
                      className="increment-decrement-icon"
                      onClick={() => handleIncreaseTicketNum(index)}
                    >
                      <Incremental />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="single-event-right">
          <div className="single-event-section1">
            <p className="single-event-section1-p1">Summary</p>
            <p>{ticketCounter} ticket</p>
          </div>

          {selectedTickets.map((each) => {
            const { value, price, title } = each;

            return (
              <div className="all-ticket-cont" key={title}>
                <div className="all-ticket-value-title-container">
                  <p>
                    {value} x {title}
                  </p>
                </div>

                <p> {(2 * price * value).toLocaleString()} FirstCoins </p>
              </div>
            );
          })}

          <div className="ticket-total">
            <h4>Total</h4>
            <p> {totalCowries.toLocaleString()} FirstCoins</p>
          </div>
          <form action="" className="single-event-form h-fit">
            {/* <div className="single-event-form-inner">
              <p>First Name</p>
              <input type="text" />
            </div>
            <div className="single-event-form-inner">
              <p>Last Name</p>
              <input type="text" />
            </div> */}
            <div className="single-event-form-inner">
              <p>Phone Number</p>
              <input
                type="tel"
                className="px-2"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                maxLength={11}
                minLength={11}
              />
            </div>
            <button
              disabled={phoneNumber.length !== 11}
              className="single-event-btn"
              onClick={(e) => handleSuccessModal(e)}
            >
              {isProcessing ? <TailwindSpinner /> : "Process Order"}
            </button>
          </form>
          <div className="single-event-info">
            <p>
              By placing an order, you agree to (provider)’s Terms and Privacy
              policy
            </p>
            <p>
              Information and update on this order will be sent to your
              FirstBank registered email address
            </p>
          </div>
        </div>
      </div>

      <div className="other-events">
        <div className="other-events-title">
          <h1>You May Like To Attend</h1>
        </div>
        <div className="other-events-inner-container">
          {suggestItems?.map((each) => (
            <div key={each.id} className="event-single-card" onClick={()=> handleShowSingleEvent(each.id)}>
              <img
                src={`${String(
                  each.artwork?.replace(
                    "https://nairabox.s3.amazonaws.com/tickets/thumbs/",
                    ""
                  )
                )}`}
                alt={each?.title}
                className="event-image"
                // onClick={() => handleShowSingleEvent(id)}
              />

              <div className="event-description-container">
                <h1 className="event-title">{each?.title}</h1>

                <div className="event-time-and-date-container">
                  {/* <EventCalendar /> */}
                  <p className="event-date">{each?.date}</p>
                </div>
                <p className="event-address">{each?.tickets[0]?.venue}</p>
                <p className="event-amount">{each?.amount}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Toastr />
    </>
  );
};

export default SingleEvent;
