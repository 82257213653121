import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Describe,
  Warranty,
  Shipping,
  Returns,
} from '../../../redux-toolkit/features/Shop/SingleItemDescriptionSlice';

const SingleItemDescriptiveDetails = ({singleItem}) => {
  const {
    unDescribe,
    veilShipping,
    veilWarranty,
    veilReturn,
    productColor,
    warrantyColor,
    shippingColor,
    returnColor,
  } = useSelector(store => store.description);
  const dispatch = useDispatch();

  return (
    <div>
      {singleItem !== null && (
        <div>
          <div className="flex border-b-2 pb-3 gap-3 sm:gap-0 sm:justify-between">
            <h3
              onClick={() => dispatch(Describe())}
              className={`${
                productColor
                  ? 'text-button sm:text-lg text-[15px]'
                  : 'text-delivery text-[15px] sm:text-base'
              } cursor-pointer font-medium leading-loyalty -tracking-wide`}>
              Product Description
            </h3>
            <h3
              onClick={() => dispatch(Warranty())}
              className={`${
                warrantyColor
                  ? 'text-button sm:text-lg text-[15px]'
                  : 'text-delivery text-[15px] sm:text-base'
              } cursor-pointer font-medium leading-loyalty -tracking-wide`}>
              Warranty
            </h3>
            <h3
              onClick={() => dispatch(Shipping())}
              className={`${
                shippingColor
                  ? 'text-button sm:text-lg text-[15px]'
                  : 'text-delivery text-[15px] sm:text-base'
              } cursor-pointer font-medium leading-loyalty -tracking-wide`}>
              Shipping
            </h3>
            <h3
              onClick={() => dispatch(Returns())}
              className={`${
                returnColor
                  ? 'text-button sm:text-lg text-[15px]'
                  : 'text-delivery text-[15px] sm:text-base'
              } cursor-pointer font-medium leading-loyalty -tracking-wide`}>
              Returns
            </h3>
          </div>
          <div className="flex py-8 flex-col">
            {unDescribe ? (
              <p className="text-delivery font-medium text-base leading-product -tracking-wide">
                {singleItem[0]?.Merchandise_item_description}
              </p>
            ) : null}
            {veilWarranty ? (
              <p className="text-delivery font-medium text-base leading-product -tracking-wide">
                Products with a warranty are limited to either the repair or
                replacement of the defective product during its warranty period.
                The warranty does not cover:
                <br />
                <br /> 1. Products found to be defective after the warranty
                period has expired.
                <br />
                <br /> 2. Products subjected to misuse or abuse, whether by
                accident or other causes. Such product conditions will be
                determined by Loyalty Solutions at its sole and unfettered
                discretion.
                <br />
                <br /> 3. Products damaged due to a natural disaster, including
                but not limited to lightning, flooding, earthquake, or fire.
                <br />
                <br />
                4. Products modified in any way NOT described explicitly in the
                corresponding product's user manuals. (NOTE: The product's user
                manuals provide instructions for installing, configuring, and
                maintaining your Moxa product. Please refer to the user manuals
                before installing or configuring the product.)
              </p>
            ) : null}
            {veilShipping ? (
              <p className="text-delivery font-medium text-base leading-product -tracking-wide">
                All order(s) are processed and shipped within fifteen (15)
                working days to the indicated address. <br />
                <br /> Orders are not shipped or delivered on weekends and
                public holidays. <br />
                <br /> Kindly note that shipping cost is calculated on a
                per-product basis, and this is based on the distance and the
                weight of the item(s)
              </p>
            ) : null}
            {veilReturn ? (
              <p className="text-delivery font-medium text-base leading-product -tracking-wide">
                Thank you for your redemption. In other to be eligible for a
                refund, the Vendor ( also referred to as “We” in this context)
                must receive your request within 7 days of receiving your item.
                We’ll only accept return requests for items that are unopened,
                unused, and with the original tags still intact. The product(s)
                must be in its original packaging, if applicable, in the same
                condition it was received. We do not accept returns for
                perishable items, intimate items, gift cards, and beauty items.{' '}
                <br /> <br />
                Exchanges- In the unlikely event that you receive a damaged or
                defective item, the Partner Merchant will exchange the item for
                a new one. Please send an email to order@rewardsboxng.com
                containing the complaints about the item delivered and reasons
                for return/exchange and also attached the delivery invoice that
                came with it (as proof that it was delivered by us) <br />
                <br />
                Refunds- once we receive your returned item, our team will
                review your request and inspect the item. We will send you an
                email to confirm that we’ve received your item and are
                processing your request. We’ll follow up with another email once
                the request has been processed to let you know if your return
                and reason were approved. If approved, you will be contacted by
                our team to resolve the issue either by exchanging the item with
                the one that suits you or refund your loyalty point to your
                loyalty account within two weeks. Please note that this refund
                will be a bit lesser than the initial points deducted due to the
                shipping cost.
              </p>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleItemDescriptiveDetails;
