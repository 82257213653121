import React, { useEffect, useState, useRef } from "react";
import Categories from "../../components/Categories/Categories";
import airtel from "../../assets/images/airtel.png";
import axios from "axios";
import { endpoints } from "../../utils/constant";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import ApiConfig from "../../service/ApiConfig";
import axiosProperty from "../../service/HttpService";
import "./airtimeandbills.css";
import { LoadingIcon } from "../../assets/icons";
import { TailwindSpinner } from "../../components/Spinner/TailwindSpinner";
import { authHeaders, logout } from "../../config/helper";
import banner from "../../assets/images/stay.png"
import { ToastContainer, toast } from "react-toastify";

export const AirtiimeAndBills = () => {
  const [leftBills, setLeftBills] = useState([]);
  const dataFetchedRef = useRef(false);
  const [currentBill, setCurrentBill] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selected, setSelected] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedDataName, setSelectedDataName] = useState("");
  const [billerData, setBillerData] = useState([]);

  function handleSelectChange(event) {
    const item = leftBills?.find((x) => x?.Id === event.target.value);
    setCurrentBill(item);
  }

  // GET CATEGORIES START

  const airtimeData = [
    { Name: "Airtel", Id: "628-B1" },
    { Name: "Mtn", Id: "628-B2" },
    { Name: "Glo", Id: "628-B3" },
    { Name: "9Mobile", Id: "628-B4" },
  ];

  
  const fetchBillsData = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_AIRTIME_AND_BILLS_URL}${endpoints.airtime_bills}`,{headers: authHeaders}
      );


     

      // ["Airtime", "Data", "Electricity Bills", "Cable TV", "ISP", "Renewable Energy", "Internet Services"]
      const namesToFilter = ["Airtime", "Data"];
      
      const data = response?.data?.CategoryList?.Category
const filteredData = data.filter(each => namesToFilter.includes(each.Name));


        //console.log(data, filteredData, "second-data");
        

      setSelectedCategory(data[0].Id);
      setSelectedCategoryName(data[0].Name);
      setLeftBills(filteredData);

     
      // if(response.status === 401){
      //   window.location.href = '/login';
      // }
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

         //import { ToastContainer, toast } from "react-toastify";
         // <ToastContainer />
        setTimeout(()=>{

          logout()
        }, 4000)
     
      } 
      console.log(error);
    }
  };

  const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  const {
    data: categories,
    isLoading: loadingCategories,
    isError: isErrorLoadingCategories,
  } = useQuery("Categories", fetchBillsData, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    cacheTime:twentyFourHoursInMs,

    retry: true,
    //staleTime: twentyFourHoursInMs,
  });

  

//console.log(loadingCategories,isErrorLoadingCategories, "loading categories");

  // GET CATEGORIES END

  // GET CATEGORY DATA START

  const fetchCategoryData = async (id) => {
    try {
      const response = await axiosProperty.post(
        `${process.env.REACT_APP_AIRTIME_AND_BILLS_URL}/getbillers`,
        {
          cat_id: id,
        },{headers: authHeaders}
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: categoryData,
    isLoading: loadingCategoryData,
    isError: isErrorLoadingCategoryData,
  } = useQuery(["category data", selectedCategory], () =>
    fetchCategoryData(selectedCategory)
  );

  

  useEffect(() => {
    const billers = categoryData?.data?.BillerList?.Category?.Biller;
    if(selectedCategoryName === "Airtime"){
      setBillerData(airtimeData)
      //console.log('airtime-data');
    }else{

      setBillerData(billers);
    }
    sessionStorage.setItem('selectedCategoryName', selectedCategoryName)
  }, [categoryData, selectedCategoryName]);



  // GET CATEGORY DATA END

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);



  return (
    <div className="w-screen">
      <Categories />
      <div>
        {/* <div className="bg-[url('./assets/images/stay.png')]  lg:w-full  lg:h-[700px] flex items-center my-auto bg-no-repeat bg-contain   sm:pt-8 sm:pb-32 object-contain w-[100%]"></div> */}
<div>
  <img src={banner} alt="banner" />
</div>
        {leftBills.length > 0 ? (
          <div className="flex flex-col justify-center lg:flex-row gap-10 w-4/5 my-14 py-8 mx-auto">
            <div
              className={`hidden lg:flex flex-col w-1/3  ${"outer-biller-sidebar-container"}`}
            >
              {leftBills?.map((leftBill, index) => (
                <ul key={index} className="w-fit pl-0 ml-4 space-y-8">
                  <li
                    style={{ margin: "0px" }}
                    onClick={() => {
                      setCurrentBill(leftBill);
                      setSelected(index);
                      setSelectedCategory(leftBill.Id);
                      setSelectedCategoryName(leftBill.Name);
                    }}
                    className={` ${
                      selected === index
                        ? "active-biller-category"
                        : "inactive-biller-category"
                    } capitalize cursor-pointer`}
                  >
                    {leftBill?.Name}
                  </li>
                </ul>
              ))}
            </div>

            <div className="lg:hidden">
              <select
                className="w-full h-11 capitalize px-3 border-[#ccc] border-solid rounded border"
                onChange={(e) => handleSelectChange(e)}
              >
                {leftBills?.map((option) => (
                  <option
                    className=" capitalize text-base leading-loyalty tracking-loyal font-medium"
                    key={option.Id}
                    value={option.Id}
                  >
                    {option?.Name}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full px-4 space-y-12">
              {
                <>
                  <div className="w-fit">
                    <h3 className="capitalize">{selectedCategoryName}</h3>
                  </div>

                  { selectedCategoryName !== '' && billerData === undefined && loadingCategoryData === false ? (
                    <div className="biller-data-loader">
                      <p> {selectedCategoryName} category is empty</p>
                    </div>
                  ) : loadingCategoryData ? (
                    <div className="biller-data-loader">
                      {" "}
                      <TailwindSpinner />{" "}
                    </div>
                  ) : (
                    <div className="space-y-10 md:space-y-0 md:flex md:flex-wrap gap-7 sm:flex sm:flex-wap ">
                      {billerData?.length !== undefined ? (
                        billerData?.map((cats) => (
                          <Link
                          style={{width: 'fit-content'}}
                            to={`/bills&airtimecheckout/${cats?.Id}`}
                            className="flex"
                            key={cats?.Name}
                          >
                            <div
                              className="bill-data-container"
                              onClick={() => setSelectedDataName(cats.Name)}
                            >
                              <p className="biller-name">{cats?.Name}</p>
                            </div>

                            {/* <div className=" space-y-6">
                        <img
                          className="w-[300px] md:w-[265px] lg:w-[156px] rounded border-solid items-center lg:object-contain object-center lg:h-[100px]"
                          src={cats?.LogoUrl}
                          alt={cats?.Name}
                        />
                        <p className="font-bold text-base leading-loyalty tracking-loyal capitalize">
                          {cats?.Name}
                        </p>
                      </div> */}
                          </Link>
                        ))
                      ) : (
                        <Link
                        style={{width: 'fit-content'}}
                          to={`/bills&airtimecheckout/${billerData?.Id}`}
                          className=""
                        >
                          <div className="bill-data-container">
                            <p className="biller-name">{billerData?.Name}</p>
                          </div>
                        </Link>
                      )}
                    </div>
                  )}
                </>
              }
            </div>
          </div>
        ) : (
          <div className="airtime-bills-loading-icon">
            {" "}
            <LoadingIcon />{" "}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
