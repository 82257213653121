import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import like from '../../assets/svg/like.svg';
import unlike from '../../assets/svg/unlike.svg';
import {doGet, doPost, getData, myStore, token} from '../../config/helper';

const WishList = ({item}) => {
  const [liked, setLike] = useState(true);
  const [isInCart, setIsInCart] = useState(0);
  const [pointer, setPointer] = useState('');
  const [response, setResponse] = useState({});
  const [itemState, setItemState] = useState(item);
  // const membershipId = getData(myStore)?.data?.customer?.Membership_id;
  // const itemCode = itemState?.Merchandize_item_code;

  useEffect(() => {
    doGet(
      setIsInCart,
      `api/checkcart?membership_id=${
        getData(myStore)?.data?.customer?.Membership_id
      }&item_code=${itemState?.Merchandize_item_code}&token=${token}&type=2`,
    );
    // if (isInCart?.status === 0) {
    //   setPointer('');
    //   setLike(false);
    // } else {
    //   setPointer('none');
    //   setLike(true);
    // }
  }, []);

  // const fetchWishList = async (membershipId, itemCode, token) => {
  //   const response = await fetch(
  //     `api/checkcart?membership_id=${membershipId}&item_code=${itemCode}&token=${token}&type=2`,
  //   );
  //   const data = await response.json();
  //   setIsInCart(data);
  //   return data;
  // };

  // const {
  //   data: cartInfo,
  //   isLoading,
  //   isError,
  // } = useQuery(['cartInfo', membershipId, itemCode, token], () =>
  //   fetchWishList(membershipId, itemCode, token),
  // );

  const Liked = () => {
    if (
      itemState?.Redemption_method === 1 &&
      itemState?.branch_code === undefined
    ) {
    } else {
      // setPointer('none');
      let data = JSON.stringify({
        redemption_method:
          itemState.Delivery_method === 3 ? 3 : itemState.Delivery_method,
        branch: itemState.branch_code,
        item_code: itemState.Merchandize_item_code
          ? itemState.Merchandize_item_code
          : itemState?.Item_code,
        membership_id: getData(myStore)?.data?.customer?.Membership_id,
        item_price: itemState.Billing_price_in_points
          ? itemState?.Billing_price_in_points
          : itemState?.Price,
        item_quantity: 1,
        remark: itemState.item_attr_id,
        type: 2,
      });
      doPost(setResponse, data, 'api/cart/add');
    }
  };

  return (
    <div>
      {response?.status === 1 ||
      isInCart?.status === 1 ||
      isInCart?.status === 3 ||
      response?.status === 3 ? (
        <button
          className="flex  mb-2"
          style={{pointerEvents: pointer}}
          onClick={Liked}>
          <img alt="like" src={like} className="w-7 h-7 cursor-pointer" />
        </button>
      ) : (
        <button className="flex mb-2" onClick={Liked}>
          <img alt="unlike" className="w-7 h-7 cursor-pointer" src={unlike} />
        </button>
      )}
    </div>
  );
};

export default WishList;
