import React, {useEffect, useState} from 'react';
import CurrentPageNav from '../../components/Navbar/CurrentPageNav';
import './TermsAndCondition.css';
import {Link} from 'react-router-dom';

const TermsAndCondition = () => {
  const [showPolicy, setShowPolicy] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <CurrentPageNav /> */}
      <div className="TermsAndCondition-container">
        <div className="TermsAndCondition-left">
          <div
            className={showPolicy ? 'view-terms' : 'view-terms_and_policy'}
            onClick={() => setShowPolicy(false)}>
            <p>Terms & Conditions</p>
          </div>
          <div
            className={showPolicy ? 'view-terms_and_policy' : 'view-terms'}
            onClick={() => setShowPolicy(true)}>
            <p>Privacy Policy</p>
          </div>
        </div>
        {showPolicy ? (
          <div className="TermsAndCondition-right">
            <div className="">
              <h1 className="font-bold text-3xl leading-10">
                YOUR PRIVACY FIRST
              </h1>
              {/* <p>Updated: January 18, 2022.</p> */}
            </div>

            <article className="article-1">
              <p>
                At FirstBank of Nigeria Limited (FirstBank) and/or its
                Subsidiaries, we put you first and are thus committed to
                protecting and respecting your privacy. We are committed to
                being transparent about how we collect, process, share and
                manage data about you (our customers and other natural persons
                where applicable).
              </p>
            </article>
            <article>
              <h3>1. FIRSTBANK AND YOU</h3>
              <p>
                This Privacy Policy stipulates the bank’s approach to handling
                your data and your rights with regards to our collection, use,
                storage and sharing of your personal data which may be collected
                by FirstBank and/or Subsidiaries in the course of providing you
                with exceptional products and services, collectively referred to
                herein as FirstBank’s “services”, across all our delivery
                channels to meet your needs.
              </p>
              <div>
                <p>The Subsidiaries of FirstBank covered by this Policy are;</p>
                <ol type="a">
                  <li>FBNBank Ghana Limited</li>
                  <li>FBNBank Guinea Limited</li>
                  <li>FBNBank Senegal Limited</li>
                  <li>FBNBank Sierra Leone Limited</li>
                  <li>FBNBank DRC Limited</li>
                  <li>FBNBank Gambia Limited</li>
                  <li>First Pension Custodian Nigeria Limited</li>
                </ol>
              </div>
            </article>

            <article>
              <h3>2. YOUR PERSONAL INFORMATION</h3>
              <p>
                In the course of consuming services provided to you by FirstBank
                and/or Subsidiaries to meet your needs, through this and other
                channels available, we collect information you provide us via
                forms, phone calls, and correspondence by mail or emails,
                service point interfaces, and any other channels we may make
                available to you from time to time. The information we collect
                may include but not limited to: identity verification, services
                consumed, and services desired/required, mode of consumption,
                preferences, location, general events, instructions and
                transactions relating to the services.
              </p>
              <p>
                We may also use information about you collected by third parties
                and other service partners to better serve your needs. Third
                party sources are not controlled by FirstBank or Subsidiaries
                and as such are not liable for how they use it.
              </p>
            </article>

            <article>
              <h3>3. CONSENT</h3>
              <p>
                We don't ask for your personal information unless we need it to
                provide or improve our products and services for you. We want to
                be sure we have your consent to collect, use and, where
                necessary, share your information with our partners and
                suppliers that help us serve you. Whenever we introduce new
                services and technologies, we'll ensure you understand and agree
                to any new ways in which your information is handled. You will
                be considered to have given your consent to FirstBank and/or
                Subsidiaries for the processing of your personal data when;
              </p>
              <ol type="i">
                <li>
                  You complete any form, brochure or material issued by
                  FirstBank and/or Subsidiaries at any of our service points
                  (mobile, online, in-branch etc.) requesting for such personal
                  information
                </li>
                <li>
                  You register, check or tick the acceptance box on any of our
                  electronic platforms (Online or Mobile) relating to terms and
                  conditions of any service or product offered by FirstBank
                  and/or Subsidiaries
                </li>
                <li>
                  You send a request, complaint or other communication to
                  FirstBank and/or Subsidiaries
                </li>
                <li>
                  You use any service or product offered by FirstBank and/or
                  Subsidiaries
                </li>
              </ol>
            </article>

            <article>
              <h3>4.USE OF YOUR INFORMATION</h3>
              <p>
                FirstBank and/or Subsidiaries will process your personal
                information for the following purposes;
              </p>
              <ol>
                <li>
                  To offer and provide our Products and Services tailored to
                  meet your unique needs
                </li>
                <li>
                  To fulfil the terms of any service contract(s) you might have
                  with us
                </li>
                <li>To improve your service experience with us</li>
                <li>To conduct our business</li>
                <li>To manage our relationship with you</li>
                <li>To comply with Laws and Regulations</li>
                <li>To provide information to Credit Agencies</li>
                <li>To update your records</li>
                <li>To develop statistics as may be required </li>
                <li>To comply with our Internal Policies</li>
                <li>To communicate with you when necessary</li>
              </ol>
              <p>
                FirstBank and/or Subsidiaries will limit the collection and use
                of your personal information for the stated purposes.
              </p>
            </article>
            <article>
              <h3>5. COOKIES </h3>

              <p>
                Please note that for our digital channels, we may collect
                information about your computer (or mobile device), including
                where available or necessary, your IP address(es), operating
                system and browser type for system administration or for our own
                commercial purposes. This is statistical data about our users'
                browsing actions and patterns and does not identify any
                individual. Further details can be found in our{' '}
                <Link
                  to={
                    'https://www.firstbanknigeria.com/home/legal/cookies-policy/'
                  }>
                  Cookie Policy
                </Link>
              </p>
            </article>
            <article>
              <h3>6. INFORMATION SHARING AND DISCLOSURE</h3>
              <p>
                FirstBank and/or Subsidiaries will not sell or rent your
                personally identifiable information to anyone. FirstBank and/or
                Subsidiaries may share or disclose your personal information to
                third parties where:
              </p>
              <ol>
                <li>
                  We have your consent to share or disclose such personal
                  information;
                </li>
                <li>
                  We are required by law to share or disclose such personal
                  information
                </li>
                <li>
                  We respond to subpoenas, court orders or other legal
                  processes;
                </li>
                <li>
                  We find that your actions on our electronic platforms violate
                  any of our Policies for the purpose of investigations,
                  reporting and enforcing any of our rights.
                </li>
                <li>Required for audit purposes</li>
              </ol>

              <p>
                FirstBank and/or Subsidiaries may use and share your personal
                information with its affiliates and members of FBN Holdings Plc
                for providing services and service-related activities such as
                collecting subscription fees for such services, notifying or
                contacting you regarding any problem with, or the expiration of
                such services. In this regard, the Affiliates and members of FBN
                Holdings Plc shall process the information as provided in this
                Privacy Policy.
              </p>

              <p>
                FirstBank and/or Subsidiaries may also disclose or share your
                personal information where it is necessary to enforce the terms
                and conditions of any of our Products and Services or any of our
                rights as well as to protect our operations and customers.
              </p>
            </article>
            <article>
              <h3>7. INFORMATION PROTECTION AND RETENTION</h3>
              <p>
                FirstBank and Subsidiaries will always ensure that your personal
                information is adequately protected. We have put in place
                processes and technologies to ensure that your personal
                information is not modified, lost, damaged or destroyed. Our
                people are trained to ensure that your personal information is
                not disclosed and safe as stated in this policy.
              </p>

              <p>
                Where access and use of our electronic platforms requires
                authentication of the user, you shall be responsible for the use
                and safety of your authentication credential(s) including but
                not limited to User Name, Personal Identification Number (PIN),
                Password, One Time Passwords (OTP) and Tokens
              </p>
              <p>
                We will retain your personal information for such length of time
                as may be required by law, regulation, the internal policies of
                FirstBank and/or Subsidiaries.
              </p>
            </article>
            <article>
              <h3>8. YOUR RIGHTS UNDER THIS POLICY</h3>
              <p>
                The following rights are available to you under this Policy:
              </p>

              <ol type="i">
                <li>
                  You may at any time request for access to your personal
                  information held by FirstBank and/or Subsidiaries or request
                  that your personal information be made available to a third
                  party. Your request may specify the format in which the
                  information should be made available subject to FirstBank
                  and/or Subsidiaries having the capacity to provide the
                  personal information in the requested format.
                </li>
                <li>
                  You may request to update your personal information with
                  FirstBank and/or Subsidiaries at any time.
                </li>
                <li>
                  You may elect to withdraw your consent at any time. Save where
                  there is a legal or operational reason to continue with the
                  processing of your personal data, FirstBank and/or
                  Subsidiaries shall discontinue the processing of your personal
                  data upon receipt of your notice withdrawing consent. Such
                  withdrawal however may impact FirstBank and/or Subsidiaries
                  ability to provide some products or services to you if your
                  consent is mandatory for the execution for providing such
                  services.
                </li>
                <li>
                  Your right to withdraw consent extends to objecting or
                  restricting the processing of your personal data by FirstBank
                  and/or Subsidiaries.
                </li>
                <li>
                  You may request that your personal information be deleted. We
                  may continue to retain such personal information as may be
                  required for compliance with legal, regulatory or policy
                  requirements.
                </li>
              </ol>
            </article>
            <article>
              <h3>9. REMEDIES</h3>
              <p>
                Where you have concerns relating to the processing of your
                personal information by FirstBank and/or Subsidiaries, or
                require any clarification on this policy, please notify us
                through or contact details provided below:
              </p>
              <p>
                <strong>Email:</strong>
                <Link to={'mailto:firstcontact@firstbanknigeria.com'}>
                  firstcontact@firstbanknigeria.com
                </Link>
              </p>
              <p>
                We will respond to your concerns within 30 days of receiving
                your notice.
              </p>
            </article>

            <article>
              <h3>UPDATES TO THIS PRIVACY POLICY</h3>
              <p>
                We may update this policy from time to time. Where there are
                changes in the way we use your personal information we will
                notify you by posting a prominent notice on our website.
              </p>
            </article>
          </div>
        ) : (
          <div className="TermsAndCondition-right">
            <div className="TermsAndCondition-right-top">
              <h1>Terms & Conditions</h1>
            </div>

            <article className="article-1">
              {/* <p>
                The First rewards loyalty program from FirstBank Bank Plc allows
                its customers to earn the program currency called “Rewards
                Points” for their various banking activities with the bank.
                “Rewards Points” earned by customers can be freely exchanged for
                merchandise, travel, banking, and other benefits.
              </p>
              <p className="article-1-p1">
                “FirstBank” reserves the right to amend, modify or change,
                including adding or deleting terms to the “First Rewards Loyalty
                Program” (“Program”) Terms and Conditions at any time.{" "}
              </p> */}
            </article>

            <article className="article-2">
              <h3>1. Overview </h3>
              <p>
                The FirstBank of Nigeria Loyalty Programme which is offered by
                FirstBank of Nigeria to reward and encourage our loyal customers
                to keep using their accounts for various bank transactions.
                Based on the set criteria, eligible customers will be
                auto-enrolled in the loyalty program and can start to earn
                points when they perform qualifying transactions stipulated by
                FirstBank. The program will make use of a currency known as{' '}
                {/*****/}which can be used to redeem various rewards items,
                benefits, and/or rebates from the customer website or various
                touch points of the Bank.“DISCOUNTS” earned by customers can be
                used at various selected Merchant stores.
                <br />
                <br />
                The FirstBank of Nigeria Loyalty Programme is accessible through
                the Program Website and FBN Mobile App and any other related
                sites and applications referred to on the Program Website.{' '}
                <br />
                This agreement may be modified from time to time to govern your
                participation in the FirstBank of Nigeria Loyalty Programme. The
                bank in its sole discretion can restrict, suspend, amend, extend
                or otherwise alter this terms and conditions at any time or any
                portion thereof on 30 days prior written notice, and if we
                terminate the program, you will have 30 days from the program
                termination date to redeem your accumulated Loyalty Points.
                However, we may change or substitute rewards and adjust Point
                levels required for specific rewards at any time without prior
                notification. <br />
                <br />
                All rewards are subject to availability. We encourage Customer
                (referred also as members) to read and understand this terms and
                conditions each time they use the program.
              </p>
              <p>
                The bank reserves the right to defer the program, of its free
                will, at any time. Termination, modification, or suspension may
                affect the User’s ability to redeem accumulated points.
              </p>
              <p>
                We will notify you of material changes to the program terms and
                conditions and, unless you decide to opt-out of the program, you
                will be bound by the revised terms and conditions. We also
                reserve the right to suspend or terminate the program, or your
                participation in the program, at any time without compensation
                to you. e messaging channel accessed from a mobile phone and
                allows user to interact with FinPay.
              </p>
            </article>

            <article>
              <h3>2. Acceptance</h3>
              <p>
                By using or enrolling in the FirstBank of Nigeria Loyalty
                Programme, you accept and agree to be bound by these Terms and
                Conditions. It is important you read these Terms and Conditions,
                and the related policies and FAQs frequently to understand the
                terms and conditions that apply to the Program as they may
                change from time to time. The FirstBank of Nigeria Loyalty
                Programme Terms do not alter in any way the terms or conditions
                of any other agreement you may have with First Bank, including
                any agreement for products or services. If you do not agree to
                these Program Terms, and Privacy Policy, you cannot participate
                in the Program.
              </p>
            </article>

            <article>
              <h3>3. Program eligibility and enrolment</h3>
              <p>
                Membership is free and there is no annual fee for membership in
                this Program. To be enrolled in the FirstBank of Loyalty
                Programme and become a program member, customers must have an
                active FirstBank account and must meet the minimum eligibility
                criteria according to the FirstBank product they hold. Once you
                have been enrolled successfully, you will receive a membership
                ID and password which you would use to log in to the program
                website. Upon your first login to your dedicated loyalty
                account, you must change your initial password (which is a
                system-generated one-time password) to any password of your
                choice after accepting this terms and conditions. It is your
                responsibility to keep your password safe and known only to you
                because you are responsible for all activity in your loyalty
                account. It is advised that you provide accurate information at
                this stage. You can also log in to your loyalty account via the
                Firstbank Mobile App using a single sign-on. <br /> In the case
                of more than one FirstBank account, all of these accounts will
                be merged into one customer ID, and all points earned through
                all these accounts from qualifying transactions will be
                accumulated to your membership ID.
              </p>
            </article>
            <article>
              <h3>4. Change in information </h3>
              <p>
                Customers are responsible to advise the bank immediately of any
                change to their address or other contact information. FirstBank
                will not be responsible for any communication not received by a
                program Member, provided that FirstBank sent such communication
                to the address or other contact information then on file from
                such program member. You may update your contact information by
                visiting the program website and updating your account profile,
                by calling our call center on phone using 0708 062 5000, or by
                sending an email to firstcontact@firstbanknigeria.com.
              </p>
            </article>

            <article>
              <h3>5. Inactive accounts </h3>
              <p>
                Once an Account is deemed to be inactive, all points in such
                Account may, in the FirstBank’s sole discretion, be deemed to
                have been forfeited by such Member, with or without notice.
              </p>
            </article>

            <article>
              <h3>6. Account closure</h3>

              <p>
                In a case where a Customer’s account or First bank account is
                closed, all points earned are reduced to zero. It is advisable
                to use up your points before closing the account.
              </p>
            </article>
            <article>
              <h3>7. Earning Points</h3>
              <p>
                FirstBank customers who meet the set criteria will be
                auto-enrolled and can start accumulating points from performing
                qualifying transactions approved by the bank. Qualifying
                transactions include all income accruing transactions and are
                not limited to mobile application usage, online transfer, bill
                payments, USSD transaction, debit, and credit card usage. Points
                can also be gotten via the transfer of points between enrolled
                Customers. <br />
                Ineligible transactions include transactions fraudulently
                initiated, counterfeit or canceled card transactions, balance
                transfers, and account fees or finance charges if any, are not
                eligible to earn points. FirstBank also reserves the right, at
                our sole discretion and at any time to include or exclude
                certain types of transactions from the meaning of qualifying
                transactions.
              </p>
            </article>
            <article>
              <h3>8. Rewards Points Expiration</h3>
              <p>
                Loyalty points accrued in your FirstBank of Nigeria Loyalty
                Account will be carried forward until they expire. Points will
                expire after one year following the date on which points were
                earned. For example, reward points awarded on September 1, 2022,
                will expire on September 1, 2023, unless FirstBank terminates
                the Program. In this case, you will have only 30 days from the
                Program termination date to redeem any earned Points. Point
                expiration will be communicated via email notification and a
                reminder will also be sent before points finally expires. If you
                cancel your enrolment in the Program, any unredeemed Points
                shall be forfeited immediately upon cancellation. If you have
                enough Points to be eligible for a reward, you must request the
                reward before giving us notice of the cancellation. There are no
                exchanges or refunds for unused Points.
              </p>
            </article>

            <article>
              <h3>9. Opting out of the program </h3>
              <p>
                Customers who choose to opt out of the program can either go to
                their account dashboard in their profile or reach FirstContact
                via call on 0708 062 5000 or send an email on
                firstcontact@firstbanknigeria.com.
              </p>
            </article>

            <article>
              <h3>10. Loyalty Points Collection and Redemption</h3>

              <p>
                Loyalty points are earned by Customers who perform qualifying
                transactions identified by the bank and displayed on the program
                website regardless of the channels used provided that the
                transactions meet set criteria and link to a Membership Number.
                Points can be redeemed for rewards. When enough points have been
                accumulated to obtain the reward(s) desired, redemption occurs
                online and/or in physical locations as set forth on the Program
                website. The redemption schedule, available rewards, and other
                information are available on the program website.
                <br /> A member must log in to the program website with his/her
                Membership Number when redeeming points to protect the integrity
                of the Member’s points balance. Each Member is responsible for
                ensuring that all Personal Information is correct and
                up-to-date. FirstBank reserves the right to block redemptions
                where the Member information is inaccurate or incomplete.
                Members are solely and entirely responsible for keeping their
                Accounts secure. <br />
                Merchandise & Rewards fulfillment services are provided by our
                fulfilment partner which assumes all liability and
                responsibility for the provision of, or failure to provide, the
                services. They operate as an independent contractor and are not
                affiliated with FirstBank. Neither FirstBank nor our fulfilment
                partner shall be liable for any bodily harm and/or property
                damage that may result from participation in the program for any
                reason.
              </p>
            </article>

            <article>
              <h3>11 Value</h3>

              <p>
                Loyalty points have no cash value and are not exchangeable for
                cash. The accumulation of points does not entitle the Customers
                to any vested rights, and FirstBank does not guarantee in any
                way the continued availability of any reward, redemption level,
                rebate, or any other benefit. The bank assumes no liability to
                Members as regards the addition or deletion of items from or for
                which points can be collected and/or redeemed.
              </p>
            </article>
            <article>
              <h3>12 Discounts</h3>

              <p>
                Discounts are not cash, you may not sell, issue, exchange,
                barter or redeem Discounts for cash. Your Discounts may only be
                used at any of our selected Merchant stores. You may not
                exchange or sell any other goods or services for Discounts.{' '}
                <br />
                <ul className="list-disc">
                  <li>
                    Customers may redeem discounts for any item at any of our
                    selected Merchant stores. Discount options are explained in
                    the online catalog.
                  </li>
                  <li>
                    All discounts are subject to the percentage assigned to a
                    particular merchant store. · Discounts granted cannot be
                    exchanged for cash under any circumstances.
                  </li>
                  <li>
                    Discounts can only be redeemed at participating Merchant
                    stores.
                  </li>
                  <li>
                    Unless otherwise stated, Discounts are valid for 30 days ·
                  </li>
                  <li>
                    Discounts must be redeemed in accordance with Program Terms
                    and Conditions.
                  </li>
                  <li>
                    FirstBank and its participating partner are not responsible
                    for the replacement of lost, unclaimed, or stolen items, and
                    neither shall FirstBank be liable for any loss or damage
                    that the customer may suffer from the non-replacement
                  </li>
                </ul>
              </p>
            </article>
            <article>
              <h3>13 Transferability</h3>

              <p>
                The FirstBank of Nigeria Loyalty Programme allows loyalty points
                to be transferred among program users using their PIN. Members
                can request their PIN whenever they need to make a point
                transfer. Firstbank does not in any way support the purchase or
                selling of loyalty points.
              </p>
            </article>
            <article>
              <h3>14 Promotion</h3>

              <p>
                From time to time, FirstBank may advertise or offer exclusive
                offers like sweepstakes, and auctions to select members to
                redeem points for items other than a discount reward, or receive
                other benefits or discounts.
              </p>
            </article>
          </div>
        )}
      </div>
    </>
  );
};

export default TermsAndCondition;
