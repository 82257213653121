import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Categories.css";
import Dropdown from "react-bootstrap/Dropdown";
import ShopModal from "../Modals/ShopModal";
// import {customerPortal, getData, myStore} from '../../config/helper';

const Categories = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [shopColor, setShopColor] = useState(true);
  const [airtimeColor, setAirtimeColor] = useState(false);
  const [movieColor, setMovieColor] = useState(false);
  const [experienceColor, setExperienceColor] = useState(false);
  const [flightColor, setFlightColor] = useState(false);
  const [vouchersColor, setVouchersColor] = useState(false);
  const [specialColor, setSpecialColor] = useState(false);
  const [surveyColor, setSurveyColor] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleShop = () => {
    setShopColor(true);
    setAirtimeColor(false);
    setMovieColor(false);
    setExperienceColor(false);
    setFlightColor(false);
    setVouchersColor(false);
    setShopColor(false);
    setSurveyColor(false);
  };

  return (
    <div className="hidden md:flex">
      <nav className="navbar navbar-expand-lg navbar-light categories-container">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0 categories">
          {/* <li onMouseOver={handleMouseOver} className="nav-item navbar-item">
            <Link
              onClick={handleShop}
              className={`${shopColor ? 'text-#F0BD2D' : 'text-white'}`}
              aria-current="page"
              to="/shops">
              Shop
              {isHovering && (
                <div
                  onMouseOut={() => {
                    setIsHovering(false);
                  }}
                  className="absolute 2xl:left-[-2%]] xl:left-[-100px] top-[60px] w-full z-[1000]">
                  {<ShopModal setIsHovering={setIsHovering} />}
                </div>
              )}
            </Link>
          </li> */}
          <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" aria-current="page" to="/airtime&bills">
              Airtime & Data
            </Link>
          </li>
          {/* <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic_"
                className="font-black">
                Movies & Events
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/events">Events</Dropdown.Item>
                <Dropdown.Item href="/movies">Movies</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}

          <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" aria-current="page" to="/movies">
              Movies
            </Link>
          </li>
          <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" aria-current="page" to="/events">
              Events
            </Link>
          </li>

          {/* <Dropdown onMouseOver={handleMouseOut}>
            <Dropdown.Toggle
              variant="success"
              className="font-black"
              id="dropdown-basic_">
              Flights & Hotels
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/flight">Flights</Dropdown.Item>
              <Dropdown.Item href="/hotel">Hotels</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          {/* <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" aria-current="page" to="/hotel">
            Hotels
            </Link>
          </li> */}

          <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link
              className="nav-link"
              aria-current="page"
              to="/profile/point-transfer"
            >
              Points Transfer
            </Link>
          </li>

          <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" aria-current="page" to="/vouchers">
              Vouchers
            </Link>
          </li>

          {/* <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" aria-current="page" to="/discounts">
              Discount
            </Link>
          </li> */}
          {/* <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" to="/sweepstakes">
              Sweepstakes
            </Link>{" "}
          </li> */}

          <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" to="/discounts">
              <button className="discount-nav-button">Partner Discounts</button>
            </Link>
          </li>
          {/* <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" to="/auctions">
              Auctions
            </Link>
          </li>
          <li onMouseOver={handleMouseOut} className="nav-item navbar-item">
            <Link className="nav-link" to="/sweepstakes">
              Sweepstakes
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default Categories;
