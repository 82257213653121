import React, { useContext } from "react";
import "./PointToCash.css";
import { Cancel, LoadingIcon, LoadingSVG } from "../../assets/icons";
import { useRef } from "react";
import { useEffect } from "react";
// import useLockScrolling from "../lockScroll/lockScroll";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import RedeemedModal from "../Modals/RedeemedModal";
import axiosProperty from "../../service/HttpService";
import ApiConfig from "../../service/ApiConfig";
import { useMutation } from "react-query";
import { TailwindSpinner } from "../Spinner/TailwindSpinner";
import { AppContext } from "../AppContext";
import { authHeaders, getAccessToken, logout } from "../../config/helper";

const PointToCash = ({
  // point,
  setPointToCash,
  // handleConvertPointToCash,
  // handleChange,
  pointToCash,
  setSuccessConversion,
  successConversion,
}) => {
  const modalRef = useRef();
  const [currentBalance, setCurrentBalance] = useState("");
  const [cifID, setCifid] = useState("");
  const [point, setPoint] = useState("");

  const { balanceStateCheck, setBalanceStateCheck } = useContext(AppContext);

  useEffect(() => {
    const balace = sessionStorage?.getItem("currentBalance");
    const customerData = JSON.parse(
      sessionStorage?.getItem("first-bank-loyalty")
    );
    const cifID = customerData?.data?.customer?.cifid;
    setCifid(cifID);
    setCurrentBalance(balace);
  }, [pointToCash]);

  useEffect(() => {
    if (!getAccessToken()) {
      toast.error("Authentication failed. Please log in again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      //import { ToastContainer, toast } from "react-toastify";
      // <ToastContainer />
      setTimeout(() => {
        logout();
      }, 4000);
    }
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setPoint(value);
  };

  const handleCloseSuccessModal = () => {
    setSuccessConversion(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setPointToCash(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const submitPoint = async () => {
    try {
      const response = await axiosProperty.post(
        ApiConfig.pointToCashEndPoint,
        { cifid: cifID, amount: point },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccessConversion(true);
        setBalanceStateCheck(!balanceStateCheck);
      } else {
        toast.error("Could not fetch customer account details", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred during the request.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      if (error.response.status === 401) {
        toast.error("Authentication failed. Please log in again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        logout();
      }
    }
  };

  const { mutate, isLoading, isError } = useMutation(submitPoint);

  const handleConvertPointToCash = () => {
    if (Number(currentBalance) >= point && point !== "") {
      mutate();
    } else {
      if (point === "") {
        toast.error("Point must be greater than 0!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });
      } else {
        toast.error("Insuffcient points!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });
      }
    }
  };

  return (
    <>
      <div className="point-to-cash">
        <div className="point-to-cash-form" ref={modalRef}>
          <div className="flex justify-between items-center">
            <p className="convert-text">Convert your points</p>
            <span
              className="cursor-pointer"
              onClick={() => setPointToCash(false)}
            >
              <Cancel />
            </span>
          </div>
          <div className="input-point-container">
            <label className="point-label" htmlFor="">
              How many points would you like to convert?
            </label>
            <input
              className="point-input"
              type="number"
              placeholder="1000"
              name="point"
              value={point}
              onChange={handleChange}
              step="1"
              onKeyDown={(e) => {
                if (e.key === "." || e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
            <div className="equivalent-naira-value-container">
              <p className="equivalent-naira-value">
                Exchange rate 1 point : 50kobo
              </p>
              <p className="equivalent-naira-value">
                Naira equivalent: ₦{(0.5 * point).toLocaleString()}{" "}
              </p>
            </div>
          </div>

          <button
            type="button"
            onClick={handleConvertPointToCash}
            className="convert-point-to-cash-btn"
            disabled={point?.length<2}
          >
            {isLoading ? <TailwindSpinner /> : "Convert Points"}
          </button>
        </div>
      </div>
      <ToastContainer />
      {successConversion && (
        <RedeemedModal
          action={handleCloseSuccessModal}
          title="Conversion successful!"
          text="Your funds will reflect in your bank account within 24hrs."
          buttonText="Okay Thanks!"
        />
      )}
    </>
  );
};

export default PointToCash;
