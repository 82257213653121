import React from "react";
// import "../../components/Footer/Footer.css";
import logo from "../../assets/images/first-bank-white.svg";
import gems from "../../assets/svg/firstgem.svg";
import {
  LinkedIn,
  Facebook,
  Instagram,
  Youtube,
  Twitter,
  Google,
  AppStore,
  Ussd,
} from "../../assets/icons";
import { customerPortal, getData, myStore } from "../../config/helper";

import { Link } from "react-router-dom";

const Footer = () => {
  const phoneNumber = "+2347080625000";

  return (
    <footer className="bg-button px-8 sm:px-20 pt-4">
      <div className="space-y-4 sm:space-y-0 sm:flex justify-between sm:mb-4 pb-0 sm:pb-12">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="logo" />
        </Link>

        <ul className="pl-0 flex items-center gap-4">
          <li className="">
            <Link to="https://www.facebook.com/firstbankofnigeria">
              <Facebook />
            </Link>
          </li>
          <li className="">
            <Link to="http://instagram.com/firstbanknigeria">
              <Instagram />
            </Link>
          </li>
          <li className="">
            <Link to="https://twitter.com/firstbankngr">
              <Twitter />
            </Link>
          </li>
          <li className="">
            <Link to="https://www.linkedin.com/company/first-bank-of-nigeria-ltd/">
              <LinkedIn />
            </Link>
          </li>
          <li className="">
            <Link to="https://www.youtube.com/user/firstbankngr">
              <Youtube />
            </Link>
          </li>
        </ul>
      </div>

      <div className="flex flex-wrap sm:flex-nowrap space-between w-full gap-y-6 sm:border-y pt-6 pb-6 border-solid border-add">
        <div className="w-1/2 sm:w-1/3 lg:w-1/3">
          <h6 className="text-white">Go to</h6>
          <ul className="pl-0 space-y-3">
            <li>
              <Link to="https://fbn-sigma.vercel.app/ " className="text-white">
                Home
              </Link>
            </li>
            {/* <li>
              <Link to="/shops" className="text-white">
                Shop
              </Link>
            </li>
            <li>
              <Link to="/meals" className="text-white">
                Meals
              </Link>
            </li> */}
            <li>
              <Link to="/airtime&bills" className="text-white">
                Airtime and Bills
              </Link>
            </li>
            <li>
              <Link to="vouchers" className="text-white">
                Vouchers
              </Link>
            </li>
            <li>
              <Link to="profile" className="text-white">
                My Account
              </Link>
            </li>
            <li>
              <Link to="/faq" className="text-white">
                FAQs
              </Link>
            </li>
          </ul>
        </div>

        <div className="w-1/2 sm:w-1/3 lg:w-1/3 space-y-3">
          <h6 className="text-white">Get in touch</h6>
          <Link to="/contact" className="text-white">
            Contact Form
          </Link>
          <p className="text-white w-2/3">
            Samuel Asabia House 35 Marina P.O. Box 5216, Lagos, Nigeria.
          </p>
          <div className="flex py-2">
            <Link className="text-white" to={`tel:${phoneNumber}`}>
              {phoneNumber}
            </Link>
          </div>{" "}
        </div>

        <div className="sm:w-1/3 lg:w-1/3 ">
          <h6 className="text-white">Legal</h6>
          <div className="flex flex-col space-y-3">
            <Link to="https://www.firstbanknigeria.com/home/legal/privacy-policy/" className="text-white">
              Privacy Policy
            </Link>
            <Link to="/terms-and-condition" className="text-white">
              Terms and Condition
            </Link>
            <Link to="/return-policy" className="text-white">
              Returns Policy
            </Link>
          </div>
        </div>

        {/* <div className="sm:w-1/4">
          <div className="flex flex-col pb-6">
            <h6 className="text-white">Other Channels</h6>
            <div className="flex gap-2">
              <img src={gems} alt="firstgems" />
              <Ussd />
            </div>
          </div>

          <div className="flex flex-col">
            <h6 className="text-white">Get the FirstMobile App</h6>
            <div className="flex gap-2">
              <Google />
              <AppStore />
            </div>
          </div>
        </div> */}
      </div>
      <div className="py-4">
        <small className="text-white">
          &copy; 2024 FirstBank of Nigeria limited. An FBNHoldings Company.
        </small>
      </div>
    </footer>
  );
};

export default Footer;
