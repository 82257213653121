import React, {useEffect} from 'react';
import './SectionOne.css';

import {sectionOneAnimation, waveTextMagic} from '../../../animation';
import {motion} from 'framer-motion';
import WordsSlider from '../../../components/WordsSlider';
import elephant from '../../../assets/First-BankLogoAnim.png';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
import {useAnimationInView} from '../../LandingPage/components/useAnimationInView';
const SectionOne = () => {
  gsap.registerPlugin(ScrollTrigger);
  const [element, controls] = useAnimationInView();

  //   useEffect(() => {
  //     gsap.registerPlugin(ScrollTrigger);

  //     let proxy = {skew: 0},
  //       skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'), // fast
  //       clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

  //     ScrollTrigger.create({
  //       onUpdate: self => {
  //         let skew = clamp(self.getVelocity() / -300);
  //         if (Math.abs(skew) > Math.abs(proxy.skew)) {
  //           proxy.skew = skew;
  //           gsap.to(proxy, {
  //             skew: 0,
  //             duration: 0.1,
  //             ease: 'power3',
  //             overwrite: true,
  //             onUpdate: () => skewSetter(proxy.skew),
  //           });
  //         }
  //       },
  //       onScroll: () => {
  //         ScrollTrigger.update();
  //       },
  //     });

  //     gsap.set('.skewElem', {transformOrigin: 'right center', force3D: true});

  //     // Cleanup function to unregister ScrollTrigger on component unmount
  //     return () => {
  //       ScrollTrigger.getAll().forEach(trigger => trigger.kill());
  //     };
  //   }, []);

  return (
    <>
      <div className="skewElem" id="about">
        <div ref={element} className="sliding-elephant-container">
          <motion.div
            className="text-wave"
            variants={waveTextMagic}
            animate={controls}>
            <div className="text-wave-inner">
              {/* <WaveText controls={controls} /> */}
              <h1>
                Welcome to the FirstBank <br /> Loyalty Program
              </h1>
              <p className="text-wave-inner-p1">
                With over 125 years experience in the banking industry, we
                choose
                <br /> to say thank you to our customers in a delightful way.
              </p>
              <p className="text-wave-inner-p2">Welcome aboard.</p>
            </div>
          </motion.div>
          <div className="sliding-elephant-innercontainer">
            <motion.div
              variants={sectionOneAnimation}
              animate={controls}
              transition={{
                delay: 0.02,
                type: 'tween',
                duration: 40,
              }}
              className="sliding-elephant">
              <img src={elephant} alt="elephant" />
            </motion.div>
          </div>
        </div>

        <div className="mobile-sliding-elephant-container">
          <h1>Welcome to The FirstBank Loyalty Program</h1>
          <p>
            With over 25 years experience in the banking industry, we choose to
            say thank you to our customers in a delightful way.
          </p>
          <p>Welcome aboard.</p>
        </div>
        <div className="mobile-world-slider">
          <div className="mobile-world-slider-1">
            <p>Transact, earn points and redeem</p>
          </div>
          <div className="mobile-world-slider-2">
            <p>More, from the bank you love</p>
          </div>
          <div className="mobile-world-slider-3">
            <p>
            Earn and Redeem your{' '}
              <span className="first-cowries-text">FirstCoins</span>{' '}
            </p>
          </div>
          {/* <div className="mobile-world-slider-4">
            <p>Items you love, delivered to you</p>
          </div> */}
        </div>
      </div>
      <WordsSlider />
    </>
  );
};

export default SectionOne;
